import React from "react";
import { Circle } from "@react-google-maps/api";

const options = {
  strokeColor: "#FF0000",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#FF0000",
  fillOpacity: 0.1,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
};
export default ({ center, type, handleMarkerClick }) => {
  return (
    <Circle
      // key={index}
      center={center}
      radius={300}
      options={options}
    />
  );
};
