import React, { Component } from "react";
import { Segment, Grid } from "semantic-ui-react";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import Settings_Receiver from "../contexts/settings_context/Settings_Receiver";
import TheMapBox from "./common/mapping/TheMapBox";
import api from "../actions/api";
import axios from "axios";
class TheMap extends Component {
  repeatingTask = null;
  state = {
    jobs: [],
    radioAssets: [],
    floodAlerts: [],
    org: {},
  };
  componentDidMount() {
    let user = Authentication.getUserData();
    this.setState({
      org: user.organisation,
    });
    this.props.settings._refresh();
    this.getJobs();
    this.getFloodAlerts();
    this.getRadioAssetsData();
    this.repeatingTask = setInterval(() => {
      this.getRadioAssetsData();
      this.getJobs();
    }, 30 * 1000);
  }
  componentWillUnmount() {
    clearInterval(this.repeatingTask);
  }
  getJobs = () => {
    api.jobs.active().then((jobs) => {
      this.setState({
        jobs: jobs.jobLocations,
      });
    });
  };
  getFloodAlerts = () => {
    let user = Authentication.getUserData();
    if (this.state.org.county) {
      api.flood.alertsC(user.organisation.county).then((floodAlerts) => {
        this.setState(
          {
            floodAlerts: floodAlerts.items.filter((f) => {
              return f.severityLevel !== 4;
            }),
          },
          () => {
            // this.getPolygon();
          }
        );
      });
    }
  };
  getPolygon = () => {
    if (this.state.floodAlerts.length) {
      Promise.all(
        this.state.floodAlerts.map(async (a) => {
          let url = a.floodArea.polygon;
          var b = "s";
          var output = [url.slice(0, 4), b, url.slice(4)].join("");
          return axios.get(output).then((res) => res.data);
        })
      ).then((res) => {
        this.setState({
          polygons: res,
        });
      });
    }
  };
  getRadioAssetsData = () => {
    api.sarmap.assets().then((res) => {
      this.setState({
        radioAssets: res,
      });
    });
  };
  goto = (id) => {
    if (id) {
      this.props.history.push(`/jobs/${id}`);
    }
  };
  renderJobs = () => {
    return <Segment basic>Bang</Segment>;
  };
  renderKey() {
    return <Segment basic>Open In Progress Completed Closed</Segment>;
  }
  render() {
    let center = null;
    if (
      this.props.settings.defaultMapCenter &&
      this.props.settings.defaultMapCenter !== []
    ) {
      center = this.props.settings.defaultMapCenter;
    }
    if (this.state.org) {
      center = this.state.org.defaultMapCenter;
    }

    return (
      <Grid className="no-marg">
        <Grid.Column width={16} className="no-pad">
          <TheMapBox
            center={center}
            goto={this.goto}
            radioAssets={this.state.radioAssets.filter((a) => {
              return a.onlineStatus === "green";
            })}
            showFlood={this.state.floodAlerts.length}
            getFloodAlerts={this.getPolygon}
            floodAlerts={this.state.polygons}
            locations={[]}
            jobs={this.state.jobs}
            mapStyle={{ minHeight: "77vh", margin: 0 }}
            mapKey={() => this.renderKey()}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

export default Settings_Receiver(TheMap, (settings) => {
  return {
    settings,
  };
});
