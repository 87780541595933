import React from "react";
import { Grid, Segment, Table, Button, Modal, Header, Message } from "semantic-ui-react";
import Moment from "react-moment";
import API from "../../actions/api";
import Box from "../common/box";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";

class SentMessages extends React.Component {
  state = {
    loading: true,
    loadError: null,
    sentMessages: [],
    selected: {},
  };
  componentDidMount() {
    this.getData();
  }
  getData() {
    API.sms.sentMessages.all(
      (this.props.job !== undefined && this.props.job._id !== undefined ? this.props.job._id : null)
    ).then((res) => {
      this.setState({
        sentMessages: res,
      });
    })
      .catch((err) => {
        let errorMessage = "There was an unexpected error while retrieving data from the server.";
        //TODO put in a better error messages from the API
        this.setState({
          loadError: errorMessage,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  renderSentMessageDetails() {
    if (this.state.selected._id === undefined) {
      return;
    }
    return (
      <Modal open={true} onClose={() => this.setState({ selected: {} })} closeIcon centered={false}>
        <Header content={"View Sent Message: " + this.state.selected.messageId} />
        <Modal.Content>
          <Grid celled stackable>
            <Grid.Row>
              <Grid.Column width={4}>
                <Header>Inbox: {this.state.selected.inboxCode && <span>{this.state.selected.inboxCode}</span>}</Header>
                {this.state.selected.inboxExpires && (
                  <React.Fragment>
                    Expires: <Moment format="DD/MM/YY HH:mm">{this.state.selected.inboxExpires}</Moment>
                  </React.Fragment>
                )}
              </Grid.Column>
              <Grid.Column width={8}>
                <Header>Message: </Header>
                {this.state.selected.message}
              </Grid.Column>
              <Grid.Column width={4}>
                <Header>
                  Status: <span>{this.state.selected.status}</span>
                </Header>
                Sent At: <Moment format="DD/MM/YY HH:mm">{this.state.selected.sentAt}</Moment>
                {this.state.selected.failureReason && (
                  <React.Fragment>
                    <br />
                    Failure Reason: {this.state.selected.failureReason}
                  </React.Fragment>
                )}
                <br />
                Total Cost: {this.state.selected.totalCost}
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Phone Number</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Status Reported At</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.selected.sentTo.map((recipient, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{recipient.name}</Table.Cell>
                    <Table.Cell>{recipient.phoneNumber}</Table.Cell>
                    <Table.Cell>{recipient.status}</Table.Cell>
                    <Table.Cell>{recipient.statusReportedAt}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    );
  }
  render() {
    return (
      <Segment basic loading={this.state.loading}>
        <Box title="Sent SMS Messages">
          {this.renderSentMessageDetails()}
          {this.state.sentMessages.length !== 0 && !this.state.loading && this.state.loadError === null ? (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Sent At</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Message</Table.HeaderCell>
                  <Table.HeaderCell>Total Cost</Table.HeaderCell>
                  <Table.HeaderCell>Total Recipients</Table.HeaderCell>
                  <Table.HeaderCell>{""}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.sentMessages.map((message) => {
                  return (
                    <Table.Row key={message.messageId}>
                      <Table.Cell>
                        <Moment format="DD/MM/YY HH:mm">{message.sentAt}</Moment>
                      </Table.Cell>
                      <Table.Cell>{message.status}</Table.Cell>
                      <Table.Cell>{message.message}</Table.Cell>
                      <Table.Cell>{message.totalCost}</Table.Cell>
                      <Table.Cell>{message.sentTo.length}</Table.Cell>
                      <Table.Cell>
                        <Button onClick={() => this.setState({ selected: message })}>View</Button>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          ) : this.state.loadError ? (
            <Message color={"red"}>
              <Message.Header>Error</Message.Header>
              {this.state.loadError}
            </Message>
          ) : (
                <Segment basic>
                  <Message info>
                    <Message.Header>No Data</Message.Header>
                There are no Sent SMS Messages to display
              </Message>
                </Segment>
              )}
        </Box>
      </Segment>
    );
  }
}
export default Job_Receiver(SentMessages, (job) => {
  return {
    job,
  };
});