import React, { Component } from "react";
import { Form, Header, Button, Dropdown, Grid, Menu, Icon, Segment, Tab, Input, Divider, Checkbox } from "semantic-ui-react";
import Geosuggest from "react-geosuggest";
import moment from "moment";
import { DateTimeInput } from "semantic-ui-calendar-react";
import api from "../../actions/api";
import OrderedLocationList from "../common/lists/orderedLocationList";
import CommonFunctions from "../../assets/utils/commonFunctions.js";
import TaskMap from "../common/mapping/taskMap";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";
import Box from "../common/box";

const priority = [
  { key: 1, text: "Urgent", value: "Urgent" },
  { key: 2, text: "Emergency", value: "Emergency" },
  { key: 3, text: "Non Urgent", value: "Non Urgent" },
  { key: 4, text: "Relay Run", value: "Relay Run" },
  { key: 5, text: "Pre Booked", value: "Pre Booked" },
  { key: 6, text: "Request", value: "Request" },

  // { key: 3, text: "High", value: "High" }
];
const counter = [
  { key: 0, text: "None", value: "0" },
  { key: 1, text: "1", value: "1" },
  { key: 2, text: "2", value: "2" },
  { key: 3, text: "3", value: "3" },
  { key: 4, text: "4", value: "4" },
  { key: 5, text: "5", value: "5" },
  { key: 6, text: "6", value: "6" },
  { key: 7, text: "7", value: "7" },
  { key: 8, text: "8", value: "8" },
  { key: 9, text: "9", value: "9" },
  { key: 10, text: "10", value: "10" },
];

class EditJob extends Component {
  state = {
    center: [],
    data: {
      type: "Ad-Hoc Delivery",
      priority: "Non Urgent",
      location: {},
      locations: [],
      date: moment(),
      blood: 0,
      plasma: 0,
      milk: 0,
      nappies: 0,
      samples: 0,
      medication: 0,
      platelets: 0,
      equipment: 0,
      items: 0,
      formula: 0,
      extraData: [],
      otherRef: "",
      secondRef: "",
      flag: false,
    },
    addLocType: "predefined",
    depts: {
      formList: [],
    },
    location: {
      geoJSON: {
        type: "Feature",
        properties: {},
      },
    },
    errors: {},
    settings: {
      JobTypes: [],
      LocTypes: [],
    },
    locs: {
      formList: [],
    },
    clients: {
      formList: [],
    },
    locBox: false,
    w3w: {
      first: "",
      second: "",
      third: "",
    },
    marker: {
      geoJSON: {
        geometry: {},
      },
    },
    en: {
      easting: "",
      northing: "",
    },
    grid: "",
  };
  componentDidMount() {
    this.getData();
    this.props.job._refresh();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.predefined !== prevState.predefined) {
      if (this.state.predefined !== "") {
        this.getDepartments();
      }
    }
    if (this.props.job !== prevProps.job) {
      this.setState({
        data: {
          ...this.state.data,
          ...this.props.job,
        },
      });
    }
  }
  getData = () => {
    api.settings.formLists().then((settings) => {
      this.setState({
        settings,
      });
    });
    api.locations.all().then((locations) => {
      this.setState({
        locs: {
          formList: locations.formList,
          locations: locations.locations,
        },
      });
    });
    api.client.all().then((clients) => {
      this.setState({
        clients: {
          formList: clients.formList,
          clients: clients.clients,
        },
      });
    });
  };
  getDepartments = () => {
    if (this.state.predefined) {
      api.locations.depts(this.state.predefined).then((depts) => {
        this.setState({
          depts,
        });
      });
    }
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };
  handleLocationDropdownChange = (props, e) => {
    this.setState({
      location: {
        ...this.state.location,
        geoJSON: {
          ...this.state.location.geoJSON,
          properties: {
            ...this.state.location.geoJSON.properties,
            // task: this.state.data.type,

            [e.name]: e.value,
          },
        },
      },
    });
  };
  handlePredefinedDropdownChange = (props, e) => {
    if (e.value) {
      let loc = this.state.locs.locations.filter((l) => {
        return l._id === e.value;
      });
      this.setState({
        center: loc[0].geoJSON.geometry.coordinates,
        predefined: loc[0]._id,
        location: {
          ...this.state.location,
          geoJSON: {
            ...loc[0].geoJSON,
            properties: {
              ...this.state.location.geoJSON.properties,
              ...loc[0].geoJSON.properties,
            },
            geometry: {
              ...loc[0].geoJSON.geometry,
            },
          },
        },
      });
    }
  };
  handleExtraDropdownChange = (props, e) => {
    let extraData = [];
    if (this.state.data !== undefined && this.state.data.extraData !== undefined) {
      extraData = this.state.data.extraData;
    }
    let exists = extraData.filter((item) => item.name === e.name); // TODO check the field type too
    if (exists[0] !== undefined) {
      // Update the existing value
      extraData = extraData.map((item) => {
        if (item.name !== e.name) {
          return item;
        }
        return {
          ...item,
          value: e.value,
        };
      });
    } else {
      // New field of data so lets push it
      extraData.push({ name: e.name, value: e.value, dataType: "qty" }); // TODO set the field type too
    }
    this.setState({
      data: {
        ...this.state.data,
        extraData,
      },
    });
  };
  onSubmitJob = () => {
    let data = this.state.data;
    const errors = this.validate(data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      api.jobs.update(this.props.job._id, data).then((job) => {
        this.props.history.push(`/jobs/${job._id}`);
      });
    }
  };
  resetLoc = () => {
    this.setState({
      predefined: "",
      grid: "",
      location: {
        geoJSON: {
          type: "Feature",
          properties: {
            type: "",
          },
        },
      },
    });
    if (this._geoSuggest) {
      this._geoSuggest.clear();
    }
  };
  setLocType = (type) => {
    this.setState(
      {
        addLocType: type,
      },
      () => {
        this.resetLoc();
      }
    );
  };
  handleLocationChange = (e) => {
    this.setState({
      location: {
        ...this.state.location,
        geoJSON: {
          ...this.state.location.geoJSON,
          properties: {
            ...this.state.location.geoJSON.properties,
            [e.target.name]: e.target.value,
          },
        },
      },
    });
  };
  handleGridChange = (e) => {
    this.setState({
      grid: e.target.value.toUpperCase(),
    });
  };
  handleW3WChange = (e) => {
    this.setState({
      w3w: {
        ...this.state.w3w,
        [e.target.name]: e.target.value,
      },
      w3wError: "",
    });
  };
  handleENChange = (e) => {
    this.setState({
      en: {
        ...this.state.en,
        [e.target.name]: e.target.value,
      },
      w3wError: "",
    });
  };
  handleChange = (e) => {
    this.setState({
      location: {
        ...this.state.location,
        geoJSON: {
          ...this.state.location.geoJSON,
          properties: {
            ...this.state.location.geoJSON.properties,
            [e.target.name]: e.target.value,
          },
        },
      },
    });
  };
  getGrid = (lat, lng) => {
    return CommonFunctions.getGrid(lat, lng, this.props.settings.gridType);
  };
  getLatLng = (grid) => {
    return CommonFunctions.getLatLng(grid, this.props.settings.gridType);
  };
  getLatLngEN = (e, n) => {
    return CommonFunctions.getLatLngEN(e, n);
  };
  getLatLngUTM = (data) => {
    return CommonFunctions.getLatLngUTM(data);
  };
  findGrid = () => {
    let bob = this.getLatLng(this.state.grid);
    if (bob) {
      this.setState({
        location: {
          ...this.state.location,
          geoJSON: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: bob,
            },
            properties: {
              ...this.state.location.geoJSON.properties,
              name: this.state.grid,
              grid: this.state.grid,
            },
          },
        },
        grid: "",
      });
    }
  };
  findUTM = () => {
    const errors = this.validateGrid(this.state.grid);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.getLatLngUTM(this.state.grid);
      let geoJSON = {
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [data.longitude, data.latitude],
          },
          properties: {
            name: data.grid,
            grid: data.grid,
          },
        },
      };
      this.setState({
        location: {
          ...this.state.location,
          geoJSON: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [data.longitude, data.latitude],
            },
            properties: {
              ...this.state.location.geoJSON.properties,
              name: data.grid,
              grid: data.grid,
            },
          },
        },
        grid: "",
      });
    }
  };
  findEN = () => {
    const errors = this.validateEN(this.state.en.easting, this.state.en.northing);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.getLatLngEN(this.state.en.easting, this.state.en.northing);

      this.setState({
        location: {
          ...this.state.location,
          geoJSON: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [data.longitude, data.latitude],
            },
            properties: {
              ...this.state.location.geoJSON.properties,
              name: data.grid,
              grid: this.getGrid(data.latitude, data.longitude),
            },
          },
        },
        grid: "",
      });
    }
  };
  findW3w = () => {
    if (this.state.w3w.first && this.state.w3w.second && this.state.w3w.third) {
      CommonFunctions.findW3w(this.state.w3w.first, this.state.w3w.second, this.state.w3w.third)
        .then((w3w) => {
          this.setState({
            location: {
              ...this.state.location,
              geoJSON: {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [w3w.coordinates.lng, w3w.coordinates.lat],
                },
                properties: {
                  ...this.state.location.geoJSON.properties,
                  grid: this.getGrid(w3w.coordinates.lat, w3w.coordinates.lng),
                  name: w3w.words,
                  w3w: w3w.words,
                },
              },
            },
            w3wError: "",
          });
        })
        .catch((error) => {
          // catch errors here
          if (error.code) {
            this.setState({
              w3wError: error.code,
            });
          }
        });
    }
  };
  onSuggestSelect = (suggest) => {
    if (suggest) {
      let coords = [suggest.location.lng, suggest.location.lat];
      let components = suggest.gmaps.address_components;
      let admin_area = "";
      let postal_town = "";
      let postal_code = "";
      // eslint-disable-next-line
      let name = "";
      // eslint-disable-next-line
      let street = "";
      let county = "";
      let town = "";
      if (suggest.gmaps.name) {
        name = suggest.gmaps.name;
      }
      for (let component = 0; component < components.length; component++) {
        if (components[component].types[0] === "premise" && name === "") {
          name = components[component].long_name;
        }

        if (components[component].types[0] === "route") {
          street = components[component].long_name;
        }
        if (components[component].types[0] === "locality") {
          admin_area = components[component].long_name;
        }
        if (components[component].types[0] === "postal_town") {
          postal_town = components[component].long_name;
        }
        if (components[component].types[0] === "administrative_area_level_2") {
          county = components[component].long_name;
        }
        if (components[component].types[0] === "postal_code") {
          postal_code = components[component].long_name;
        }
        if (admin_area) {
          town = admin_area;
        } else {
          town = postal_town;
        }
      }
      this.setState({
        suggestion: true,
        location: {
          ...this.state.location,
          geoJSON: {
            ...this.state.location.geoJSON,
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coords,
            },
            properties: {
              ...this.state.location.geoJSON.properties,
              name: name,
              town: town,
              county: county,
              postcode: postal_code,
              address: suggest.gmaps.formatted_address,
              grid: this.getGrid(suggest.location.lat, suggest.location.lng),
            },
          },
        },

        // val: suggest,
        // zoom: 10
      });
    }
  };
  validateLoc = (data) => {
    const locErrors = {};
    if (data.geoJSON.geometry) {
      if (!data.geoJSON.geometry.coordinates) {
        locErrors.coords = "you need to add a location";
      } else if (data.geoJSON.geometry.coordinates[0] === undefined) {
        locErrors.coords = "you need to add a location";
      }
    }
    return locErrors;
  };
  validate = (data) => {
    const errors = {};
    if (!data.type) errors.type = "Can't be empty";
    if (!data.priority) errors.priority = "Can't be empty";

    return errors;
  };
  addLocation = (e) => {
    if (e) {
      e.preventDefault();
    }
    // console.log('&&&&&&&', this.state.locations);
    const locErrors = this.validateLoc(this.state.location);
    this.setState({ locErrors });
    if (Object.keys(locErrors).length === 0) {
      let locations = this.state.data.locations;
      locations.push(this.state.location);
      this.setState({
        data: {
          ...this.state.data,
          locations: locations,
        },
        location: {
          geoJSON: {
            type: "Feature",
            geometry: {},
            properties: {
              type: "",
              grid: "",
              w3w: "",
            },
          },
        },
        addLocType: "address",
        point: [],
        locErrors: {},
        clearMarker: true,
      });
      // this.props.clearClick(true);
    }
  };
  addLoc = (data) => {
    this.setState(
      {
        location: {
          ...this.state.location,
          geoJSON: {
            ...this.state.location.geoJSON,
            type: "Feature",
            properties: {
              ...this.state.location.geoJSON.properties,

              ...data.geoJSON.properties,
              job: this.props.job.jobNum,
            },
            geometry: {
              ...this.state.location.geoJSON.geometry,
              ...data.geoJSON.geometry,
            },
          },
        },
        locBox: false,
      },
      () => {
        this.addLocation();
        this.resetLoc();
      }
    );
  };
  onClickMap = (data) => {
    if (data) {
      let coords = [parseFloat(data[0].toFixed(5)), parseFloat(data[1].toFixed(5))];
      if (coords.length) {
        // this.props.data(coords);
        this.setState({
          center: coords,
          location: {
            geoJSON: {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: coords,
              },
              properties: {
                ...this.state.location.geoJSON.properties,
                grid: this.getGrid(coords[1], coords[0]),
              },
            },
          },
        });
      }
    }
  };
  handleOrderedLocationUpdate = (data) => {
    this.setState({
      data: {
        ...this.state.data,
        locations: data.locations.map((item, i) => {
          return {
            ...item,
            _id: i,
          };
        }),
      },
    });
  };

  /** Renderers **/
  renderLocations() {
    return (
      <OrderedLocationList
        submit={this.handleOrderedLocationUpdate}
        delete={this.handleOrderedLocationUpdate}
        data={this.state.data.locations.map((item, index) => {
          return {
            ...item,
            _id: index,
          };
        })}
        placeholder="task status.."
        title="Job Locations"
        field="locations"
      />
    );
  }
  renderPredefinedMethod() {
    let disabled = true;
    if (this.state.location.geoJSON.properties.type) {
      disabled = false;
    }
    return (
      <Form>
        <Form.Group>
          <Form.Field width={4}>
            <label>Type</label>
            <Dropdown
              name="type"
              label="Location Type"
              value={this.state.location.geoJSON.properties.type}
              onChange={this.handleLocationDropdownChange}
              options={this.state.settings.LocTypes}
              selection
              placeholder="Type"
              required={true}
            />
          </Form.Field>
          <Form.Field width={3}>
            <label>Time</label>
            <Input disabled={disabled} name="time" id={"time"} placeholder="10:00" onChange={this.handleChange} />
          </Form.Field>
          <Form.Field width={9}>
            <label>Location</label>
            <Dropdown
              disabled={disabled}
              name="location"
              label="Location"
              value={this.state.predefined}
              onChange={this.handlePredefinedDropdownChange}
              options={this.state.locs.formList}
              selection
              search
              placeholder="Location"
              required={true}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
  renderExtraData() {
    const list = this.props.settings.extraDataType.map((t, i) => {
      let value = "";
      if (this.state.data !== undefined && this.state.data.extraData !== undefined) {
        let fieldObject = this.state.data.extraData.filter((aa) => aa.name === t);
        if (fieldObject[0] !== undefined) {
          value = fieldObject[0].value;
        }
      }
      return (
        <Grid.Column key={i} width={2}>
          <Form.Dropdown name={t} label={t} onChange={this.handleExtraDropdownChange} options={counter} value={value} selection />
        </Grid.Column>
      );
    });
    return (
      <Segment basic className="no-pad">
        <Form>
          <Grid className="formGrid">
            <Grid.Row>{list}</Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  }
  renderDetails() {
    return (
      <Form>
        <Form.TextArea name="details" label="Details" value={this.state.data.details} onChange={this.onChange} placeholder="details about the job" />
      </Form>
    );
  }
  renderLocationTabold() {
    const { location } = this.state;
    let w3disabled = true;
    // eslint-disable-next-line
    if (this.state.w3w.first !== "" && this.state.w3w.second !== "" && this.state.w3w.third !== "") {
      w3disabled = false;
    }
    let endisabled = true;
    // eslint-disable-next-line
    if (this.state.en.easting !== "" && this.state.en.northing !== "") {
      endisabled = false;
    }
    let disabled = true;
    if (this.state.location.geoJSON.properties.type) {
      disabled = false;
    }
    return (
      <Segment basic className="no-pad">
        <Menu widths={5}>
          <Menu.Item active={this.state.addLocType === "predefined"} onClick={() => this.setLocType("predefined")}>
            <Icon name="map signs" size="small" />
            Predefind Address
          </Menu.Item>
          <Menu.Item active={this.state.addLocType === "address"} onClick={() => this.setLocType("address")}>
            <Icon name="map signs" size="small" />
            Address
          </Menu.Item>
          <Menu.Item active={this.state.addLocType === "grid"} onClick={() => this.setLocType("grid")}>
            <Icon name="map" size="small" />
            Grid
          </Menu.Item>
          <Menu.Item active={this.state.addLocType === "en"} onClick={() => this.setLocType("en")}>
            <Icon name="map" size="small" />
            E/N
          </Menu.Item>
          <Menu.Item fitted="horizontally" active={this.state.addLocType === "w3w"} onClick={() => this.setLocType("w3w")}>
            W3W
          </Menu.Item>
        </Menu>
        {/* {this.state.addLocType === "grid" && this.renderLocBox()} */}
        {/* {this.state.addLocType === "predefined" && this.renderPredefinedMethod()} */}
        <Grid className="no-marg">
          <Grid.Column width={8} className="no-hpad">
            <Segment basic>
              <Grid className="no-marg">
                {this.props.settings.gridType === "OSGB" && this.state.addLocType === "grid" && (
                  <Grid.Column width={16}>
                    <Form onSubmit={() => this.findGrid()}>
                      <Form.Field>
                        <label>Location Type</label>
                        <Dropdown
                          name="type"
                          label="Location Type"
                          value={this.state.location.geoJSON.properties.type}
                          onChange={this.handleLocationDropdownChange}
                          options={this.state.settings.LocTypes}
                          selection
                          placeholder="Type"
                          required={true}
                        />
                      </Form.Field>
                      <Form.Field error={!!this.state.errors.grid}>
                        <Input
                          disabled={disabled}
                          name="grid"
                          id={"grid-search"}
                          action={{ icon: "search" }}
                          placeholder="TQ1234..."
                          value={this.state.grid}
                          onChange={this.handleGridChange}
                        />
                      </Form.Field>
                    </Form>
                  </Grid.Column>
                )}
                {this.props.settings.gridType === "OSIE" && this.state.addLocType === "grid" && (
                  <Grid.Column width={16}>
                    <Form onSubmit={() => this.findGrid()}>
                      <Form.Field error={!!this.state.errors.grid}>
                        <Input name="grid" id={"grid-search"} action={{ icon: "search" }} placeholder="T01234..." value={this.state.grid} onChange={this.handleGridChange} />
                      </Form.Field>
                    </Form>
                  </Grid.Column>
                )}
                {this.props.settings.gridType === "UTM" && this.state.addLocType === "grid" && (
                  <Grid.Column width={16}>
                    <Form onSubmit={() => this.findUTM()}>
                      <Form.Field error={!!this.state.errors.grid}>
                        <Input name="grid" id={"grid-search"} action={{ icon: "search" }} placeholder="31 N358 567..." value={this.state.grid} onChange={this.handleGridChange} />
                      </Form.Field>
                    </Form>
                  </Grid.Column>
                )}
                {this.state.addLocType === "w3w" && (
                  <Grid.Column width={16}>
                    {/* <Header textAlign={"center"}>{this.state.marker !== null ? "Grid: " + this.state.marker.geoJSON.properties.grid : "Grid: --"}</Header> */}
                    {/* <Segment basic> */}
                    <Form>
                      <Form.Field>
                        <label>Location Type</label>
                        <Dropdown
                          name="type"
                          label="Location Type"
                          value={this.state.location.geoJSON.properties.type}
                          onChange={this.handleLocationDropdownChange}
                          options={this.state.settings.LocTypes}
                          selection
                          placeholder="Type"
                          required={true}
                        />
                      </Form.Field>
                      <Form.Group widths={"equal"}>
                        <Form.Field>
                          <input name="first" value={this.state.w3w.first} placeholder="first word" onChange={this.handleW3WChange} />
                        </Form.Field>
                        <Form.Field>
                          <input name="second" value={this.state.w3w.second} placeholder="second word" onChange={this.handleW3WChange} />
                        </Form.Field>
                        <Form.Field>
                          <input name="third" value={this.state.w3w.third} placeholder="third word" onChange={this.handleW3WChange} />
                        </Form.Field>
                      </Form.Group>
                      <Form.Field>
                        <Button onClick={() => this.findW3w()} color="blue" fluid disabled={w3disabled}>
                          Find
                        </Button>
                      </Form.Field>
                      {this.state.w3wError && (
                        <Header color="red" textAlign="center">
                          {this.state.w3wError}
                        </Header>
                      )}
                    </Form>
                    {/* </Segment> */}
                  </Grid.Column>
                )}
                {this.state.addLocType === "en" && (
                  <Grid.Column width={16}>
                    {/* <Header textAlign={"center"}>{this.state.marker !== null ? "Grid: " + this.state.marker.geoJSON.properties.grid : "Grid: --"}</Header> */}
                    {/* <Segment basic> */}
                    <Form>
                      <Form.Field>
                        <label>Location Type</label>
                        <Dropdown
                          name="type"
                          label="Location Type"
                          value={this.state.location.geoJSON.properties.type}
                          onChange={this.handleLocationDropdownChange}
                          options={this.state.settings.LocTypes}
                          selection
                          placeholder="Type"
                          required={true}
                        />
                      </Form.Field>
                      <Form.Group widths={"equal"}>
                        <Form.Field>
                          <input name="easting" value={this.state.en.easting} placeholder="easting" onChange={this.handleENChange} />
                        </Form.Field>
                        <Form.Field>
                          <input name="northing" value={this.state.en.northing} placeholder="northing" onChange={this.handleENChange} />
                        </Form.Field>
                      </Form.Group>
                      <Form.Field>
                        <Button onClick={() => this.findEN()} color="blue" fluid disabled={endisabled}>
                          Find
                        </Button>
                      </Form.Field>
                      {this.state.w3wError && (
                        <Header color="red" textAlign="center">
                          {this.state.w3wError}
                        </Header>
                      )}
                    </Form>
                    {/* </Segment> */}
                  </Grid.Column>
                )}
                {this.state.addLocType === "predefined" && <Grid.Column width={16}>{this.renderPredefinedMethod()}</Grid.Column>}
                {this.state.addLocType === "address" && (
                  <Grid.Column width={16}>
                    <Segment basic>
                      <Form>
                        <Form.Field>
                          <label>Location Type</label>
                          <Dropdown
                            name="type"
                            label="Location Type"
                            value={this.state.location.geoJSON.properties.type}
                            onChange={this.handleLocationDropdownChange}
                            options={this.state.settings.LocTypes}
                            selection
                            placeholder="Type"
                            required={true}
                          />
                        </Form.Field>

                        <Form.Field>
                          <label>Search Location</label>
                          <Geosuggest
                            disabled={disabled}
                            autoComplete="off"
                            ref={(el) => (this._geoSuggest = el)}
                            placeholder="Tesco, Ashford, Kent"
                            inputClassName="geosuggest__input"
                            country={this.props.settings.country}
                            //   location={new window.google.maps.LatLng(center[1], center[0])}
                            //   radius={10000}
                            onSuggestSelect={this.onSuggestSelect}
                            suggestsClassName="geosuggest__suggests"
                            suggestsHiddenClassName="geosuggest__suggests--hidden"
                            suggestItemClassName="geosuggest__item"
                          />
                        </Form.Field>
                      </Form>
                    </Segment>
                  </Grid.Column>
                )}
                {location.geoJSON.properties.grid !== undefined && this.renderBasicLocationForm()}
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column width={8}>{this.renderMap()}</Grid.Column>
        </Grid>
      </Segment>
    );
  }
  renderLocationTab() {
    const { location } = this.state;
    let w3disabled = true;
    // eslint-disable-next-line
    if (this.state.w3w.first !== "" && this.state.w3w.second !== "" && this.state.w3w.third !== "") {
      w3disabled = false;
    }
    let endisabled = true;
    // eslint-disable-next-line
    if (this.state.en.easting !== "" && this.state.en.northing !== "") {
      endisabled = false;
    }
    let disabled = true;
    if (this.state.location.geoJSON.properties.type) {
      disabled = false;
    }
    return (
      <Segment basic className="no-pad">
        <Menu widths={5}>
          <Menu.Item active={this.state.addLocType === "predefined"} onClick={() => this.setLocType("predefined")}>
            <Icon name="map signs" size="small" />
            Predefind Address
          </Menu.Item>
          <Menu.Item active={this.state.addLocType === "address"} onClick={() => this.setLocType("address")}>
            <Icon name="map signs" size="small" />
            Address
          </Menu.Item>
          <Menu.Item active={this.state.addLocType === "grid"} onClick={() => this.setLocType("grid")}>
            <Icon name="map" size="small" />
            Grid
          </Menu.Item>
          <Menu.Item active={this.state.addLocType === "en"} onClick={() => this.setLocType("en")}>
            <Icon name="map" size="small" />
            E/N
          </Menu.Item>
          <Menu.Item fitted="horizontally" active={this.state.addLocType === "w3w"} onClick={() => this.setLocType("w3w")}>
            W3W
          </Menu.Item>
        </Menu>
        {/* {this.state.addLocType === "grid" && this.renderLocBox()} */}
        {/* {this.state.addLocType === "predefined" && this.renderPredefinedMethod()} */}
        <Grid className="no-marg">
          <Grid.Column width={8} className="no-hpad">
            <Segment basic>
              {this.props.settings.gridType === "OSGB" && this.state.addLocType === "grid" && (
                <Grid className="no-marg">
                  <Grid.Column width={16}>
                    <Form onSubmit={() => this.findGrid()}>
                      <Form.Group>
                        <Form.Field width={4}>
                          <label>Location Type</label>
                          <Dropdown
                            name="type"
                            label="Location Type"
                            value={this.state.location.geoJSON.properties.type}
                            onChange={this.handleLocationDropdownChange}
                            options={this.state.settings.LocTypes}
                            selection
                            placeholder="Type"
                            required={true}
                          />
                        </Form.Field>
                        <Form.Field width={3}>
                          <label>Time</label>
                          <Input disabled={disabled} name="time" id={"time"} placeholder="10:00" onChange={this.handleChange} />
                        </Form.Field>
                        <Form.Field error={!!this.state.errors.grid} width={9}>
                          <label>Grid</label>
                          <Input
                            disabled={disabled}
                            name="grid"
                            id={"grid-search"}
                            action={{ icon: "search" }}
                            placeholder="TQ1234..."
                            value={this.state.grid}
                            onChange={this.handleGridChange}
                          />
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Grid.Column>
                </Grid>
              )}
              {this.props.settings.gridType === "OSIE" && this.state.addLocType === "grid" && (
                <Grid className="no-marg">
                  <Grid.Column width={16}>
                    <Form onSubmit={() => this.findGrid()}>
                      <Form.Field error={!!this.state.errors.grid}>
                        <Input name="grid" id={"grid-search"} action={{ icon: "search" }} placeholder="T01234..." value={this.state.grid} onChange={this.handleGridChange} />
                      </Form.Field>
                    </Form>
                  </Grid.Column>
                </Grid>
              )}
              {this.props.settings.gridType === "UTM" && this.state.addLocType === "grid" && (
                <Grid className="no-marg">
                  <Grid.Column width={16}>
                    <Form onSubmit={() => this.findUTM()}>
                      <Form.Field error={!!this.state.errors.grid}>
                        <Input name="grid" id={"grid-search"} action={{ icon: "search" }} placeholder="31 N358 567..." value={this.state.grid} onChange={this.handleGridChange} />
                      </Form.Field>
                    </Form>
                  </Grid.Column>
                </Grid>
              )}
              {this.state.addLocType === "w3w" && (
                <Grid className="no-marg">
                  <Grid.Column width={16}>
                    <Form>
                      <Form.Group>
                        <Form.Field width={4}>
                          <label>Location Type</label>
                          <Dropdown
                            name="type"
                            label="Location Type"
                            value={this.state.location.geoJSON.properties.type}
                            onChange={this.handleLocationDropdownChange}
                            options={this.state.settings.LocTypes}
                            selection
                            placeholder="Type"
                            required={true}
                          />
                        </Form.Field>
                        <Form.Field width={3}>
                          <label>Time</label>
                          <Input disabled={disabled} name="time" id={"time"} placeholder="10:00" onChange={this.handleChange} />
                        </Form.Field>

                        <Form.Field width={3}>
                          <label>First</label>
                          <input name="first" value={this.state.w3w.first} placeholder="first word" onChange={this.handleW3WChange} />
                        </Form.Field>
                        <Form.Field width={3}>
                          <label>Second</label>
                          <input name="second" value={this.state.w3w.second} placeholder="second word" onChange={this.handleW3WChange} />
                        </Form.Field>
                        <Form.Field width={3}>
                          <label>Third</label>
                          <input name="third" value={this.state.w3w.third} placeholder="third word" onChange={this.handleW3WChange} />
                        </Form.Field>
                      </Form.Group>
                      <Form.Field>
                        <Button onClick={() => this.findW3w()} color="blue" fluid disabled={w3disabled}>
                          Find
                        </Button>
                      </Form.Field>
                      {this.state.w3wError && (
                        <Header color="red" textAlign="center">
                          {this.state.w3wError}
                        </Header>
                      )}
                    </Form>
                  </Grid.Column>
                </Grid>
              )}
              {this.state.addLocType === "en" && (
                <Grid className="no-marg">
                  <Grid.Column width={16}>
                    <Form>
                      <Form.Group>
                        <Form.Field width={4}>
                          <label>Type</label>
                          <Dropdown
                            name="type"
                            label="Location Type"
                            value={this.state.location.geoJSON.properties.type}
                            onChange={this.handleLocationDropdownChange}
                            options={this.state.settings.LocTypes}
                            selection
                            placeholder="Type"
                            required={true}
                          />
                        </Form.Field>
                        <Form.Field width={3}>
                          <label>Time</label>
                          <Input disabled={disabled} name="time" id={"time"} placeholder="10:00" onChange={this.handleChange} />
                        </Form.Field>

                        <Form.Field>
                          <label>Easting</label>
                          <input name="easting" value={this.state.en.easting} placeholder="easting" onChange={this.handleENChange} />
                        </Form.Field>
                        <Form.Field>
                          <label>Northing</label>
                          <input name="northing" value={this.state.en.northing} placeholder="northing" onChange={this.handleENChange} />
                        </Form.Field>
                      </Form.Group>
                      <Form.Field>
                        <Button onClick={() => this.findEN()} color="blue" fluid disabled={endisabled}>
                          Find
                        </Button>
                      </Form.Field>
                      {this.state.w3wError && (
                        <Header color="red" textAlign="center">
                          {this.state.w3wError}
                        </Header>
                      )}
                    </Form>
                  </Grid.Column>
                </Grid>
              )}
              {this.state.addLocType === "predefined" && (
                <Grid className="no-marg">
                  <Grid.Column width={16}>{this.renderPredefinedMethod()}</Grid.Column>
                </Grid>
              )}
              {this.state.addLocType === "address" && (
                <Grid className="no-marg">
                  <Grid.Column width={16}>
                    <Form>
                      <Form.Group>
                        <Form.Field width={4}>
                          <label>Location Type</label>
                          <Dropdown
                            name="type"
                            label="Location Type"
                            value={this.state.location.geoJSON.properties.type}
                            onChange={this.handleLocationDropdownChange}
                            options={this.state.settings.LocTypes}
                            selection
                            placeholder="Type"
                            required={true}
                          />
                        </Form.Field>
                        <Form.Field width={3}>
                          <label>Time</label>
                          <Input disabled={disabled} name="time" id={"time"} placeholder="10:00" onChange={this.handleChange} />
                        </Form.Field>
                        <Form.Field width={9}>
                          <label>Search Location</label>
                          <Geosuggest
                            disabled={disabled}
                            autoComplete="off"
                            ref={(el) => (this._geoSuggest = el)}
                            placeholder="Tesco, Ashford, Kent"
                            inputClassName="geosuggest__input"
                            country={this.props.settings.country}
                            //   location={new window.google.maps.LatLng(center[1], center[0])}
                            //   radius={10000}
                            onSuggestSelect={this.onSuggestSelect}
                            suggestsClassName="geosuggest__suggests"
                            suggestsHiddenClassName="geosuggest__suggests--hidden"
                            suggestItemClassName="geosuggest__item"
                          />
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Grid.Column>
                </Grid>
              )}
              {location.geoJSON.properties.grid !== undefined && this.renderBasicLocationForm()}
            </Segment>
          </Grid.Column>
          <Grid.Column width={8}>{this.renderMap()}</Grid.Column>
        </Grid>
      </Segment>
    );
  }
  renderBasicLocationForm() {
    return (
      <Form style={{ width: "100%" }}>
        <Form.Field>
          <label>Location Name</label>
          <input name="name" onChange={this.handleChange} defaultValue={this.state.location.geoJSON.properties.name} placeholder={"name...."} />
        </Form.Field>
        <Form.Group widths={2}>
          <Form.Field>
            <label>Grid</label>
            <input defaultValue={this.state.location.geoJSON.properties.grid} readOnly />
          </Form.Field>
          <Form.Field>
            <label>Department</label>
            {this.state.predefined ? (
              <Dropdown
                name="department"
                label="department"
                value={this.state.location.geoJSON.properties.department}
                onChange={this.handleLocationDropdownChange}
                options={this.state.depts.formList}
                selection
                search
                placeholder="Dept"
                required={true}
              />
            ) : (
              <input name="department" defaultValue={this.state.location.geoJSON.properties.department} onChange={this.handleChange} />
            )}
          </Form.Field>
        </Form.Group>
        <Form.Group widths={2}>
          <Form.Field>
            <label>Address</label>
            <input name="address" onChange={this.handleChange} defaultValue={this.state.location.geoJSON.properties.address} placeholder={"name...."} />
          </Form.Field>
          <Form.Field>
            <label>Postcode</label>
            <input name="postcode" onChange={this.handleChange} defaultValue={this.state.location.geoJSON.properties.postcode} placeholder={"name...."} />
          </Form.Field>
        </Form.Group>

        <Divider />
        <Form.Group widths={"equal"} className={"form-actions"}>
          <Form.Field>
            <Button content="Cancel" negative fluid onClick={() => this.resetLoc()} labelPosition="left" icon="close" />
          </Form.Field>
          <Form.Field>
            <Button content="Add Location" fluid labelPosition="right" icon="checkmark" onClick={() => this.addLoc(this.state.location)} positive />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
  renderMap() {
    let center = [0.521725, 51.278708];
    if (this.state.center.length) {
      center = this.state.center;
    }
    return (
      <Segment basic>
        <TaskMap
          center={center}
          data={this.onClickMap}
          location={this.state.location && this.state.location.geoJSON.geometry ? this.state.location : null}
          zones={this.state.zones}
          locations={this.state.data.locations}
          mapStyle={{ height: "40vh" }}
        />
      </Segment>
    );
  }
  render() {
    const { data, errors } = this.state;
    //TODO get the center of the orgs county to be added to settings
    let formDisabled = true;
    if (data.locations.length) {
      formDisabled = false;
    }
    let loc = "";
    if (data.locations.length) {
      loc = "check";
    }
    const panes = [
      {
        menuItem: "Details",
        render: () => <Tab.Pane>{this.renderDetails()}</Tab.Pane>,
      },
      // {
      //   menuItem: "Locations",
      //   render: () => (
      //     <Tab.Pane className="no-pad">{this.renderLocationTab()}</Tab.Pane>
      //   ),
      // },
      {
        menuItem: "Extra Data",
        render: () => <Tab.Pane>{this.renderExtraData()}</Tab.Pane>,
      },
    ];

    return (
      <Segment basic>
        <Box title="Edit Job">
          <Grid celled className="no-marg">
            <Grid.Row>
              <Grid.Column computer={16}>
                <Segment basic>
                  <Form>
                    <Form.Group widths={4}>
                      <Form.Field>
                        <Form.Dropdown
                          name="type"
                          label="Job Type"
                          value={this.state.data.type}
                          onChange={this.handleDropdownChange}
                          options={this.state.settings.JobTypes}
                          selection
                          required={true}
                        />
                        {errors.type && <p style={{ color: "red" }}>{errors.type}</p>}
                      </Form.Field>
                      <Form.Field>
                        <DateTimeInput
                          autoComplete={"off"}
                          animation={"off"}
                          name="date"
                          label="Date/Scheduled Date"
                          dateTimeFormat="YYYY/MM/DD HH:mm"
                          value={moment(this.state.data.date).format("YYYY-MM-DD HH:mm")}
                          iconPosition="left"
                          onChange={this.handleDropdownChange}
                          startMode={"day"}
                          closable
                        />
                      </Form.Field>
                      <Form.Field>
                        <Form.Dropdown
                          name="priority"
                          label="Priority"
                          value={this.state.data.priority}
                          onChange={this.handleDropdownChange}
                          options={priority}
                          selection
                          required={true}
                        />
                        {errors.priority && <p style={{ color: "red" }}>{errors.priority}</p>}
                      </Form.Field>

                      <Form.Field>
                        <label>Client</label>
                        <Dropdown
                          name="client"
                          label="client"
                          value={this.state.data.client}
                          onChange={this.handleDropdownChange}
                          options={this.state.clients.formList}
                          selection
                          search
                          placeholder="Client"
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group widths={3}>
                      <Form.Field>
                        <label>Our Other Ref.</label>
                        <Form.Input name="secondRef" value={this.state.data.secondRef} onChange={this.onChange} placeholder="ABC:123" />
                      </Form.Field>
                      <Form.Field>
                        <label>3rd Party Ref/CAD Num</label>
                        <Form.Input name="otherRef" value={this.state.data.otherRef} onChange={this.onChange} placeholder="ABC:123" />
                      </Form.Field>
                      <Form.Field>
                        <label>Switches</label>
                        <Checkbox
                          toggle
                          checked={this.state.data.flag}
                          label="Flag/Highlight Job"
                          onChange={() =>
                            this.setState({
                              data: {
                                ...this.state.data,
                                flag: !this.state.data.flag,
                              },
                            })
                          }
                        />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Segment>
                {/* <Segment basic className="no-pad"> */}
                <Tab menu={{ secondary: true, pointing: false }} panes={panes} />
                {/* </Segment> */}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Segment basic>
                  <Form>
                    {this.renderLocations()}
                    <div className="formActions">
                      <Form.Button negative onClick={() => this.props.history.push(`/`)}>
                        Cancel
                      </Form.Button>
                      <Form.Button positive onClick={this.onSubmitJob} disabled={formDisabled}>
                        Update
                      </Form.Button>
                    </div>
                  </Form>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Box>
      </Segment>
    );
  }
}
let editTheJob = Settings_Receiver(EditJob, (settings) => {
  return {
    settings,
  };
});
export default Job_Receiver(editTheJob, (job) => {
  return {
    job,
  };
});
