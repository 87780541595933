import React from "react";
import Job from "./Job_Context";

const joinProps = (a, b) => {
  return {
    ...a,
    ...b,
  };
};
export default (Compo, mapContext) => {
  return (props) => {
    return <Job.Consumer>{(context) => <Compo {...joinProps(props, mapContext(context))} />}</Job.Consumer>;
  };
};
