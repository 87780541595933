import React, { Component } from "react";
import { Segment, Menu, Icon, Modal, Button, Form, Divider, Header } from "semantic-ui-react";

import Settings_Receiver from "../../../contexts/settings_context/Settings_Receiver";
import api from "../../../actions/api";

class AddClient extends Component {
  state = {
    addLoc: false,
    clients: [],
  };
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.client.all().then((clients) => {
      this.setState({
        clients: clients.clients,
      });
    });
  };

  render() {
    return <Segment>add</Segment>;
  }
}
export default Settings_Receiver(AddClient, (settings) => {
  return {
    settings,
  };
});
