import React from "react";

const Input = ({
  label,
  field,
  type = "text",
  onChange,
  placeholder,
  value,
  maxLength,
  style = "w-full",
  error,
  disabled = false,
}) => {
  // console.log("error", error);
  return (
    <div className={style}>
      <label
        htmlFor={field}
        className={`block text-sm font-medium  ${
          error ? "text-red-900" : "text-blue-400"
        }`}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        type={type}
        name={field}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        autoComplete="off"
        className={` p-2   placeholder-gray-600 block w-full shadow-sm border ${
          error ? "border-red-400" : "border-gray-700"
        } ${
          disabled
            ? "bg-gray-500 border-0 text-lg "
            : " bg-gray-800 border-gray-700 text-sm "
        } rounded-md`}
      />
      {error && (
        <span className="text-red-400 italic text-sm pl-2">{error}</span>
      )}
    </div>
  );
};
export default Input;
