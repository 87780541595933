import React from "react";
import {
  List,
  Segment,
  Button,
  Input,
  Form,
  Item,
  Header,
  Divider,
  Checkbox,
  Confirm,
  Icon,
} from "semantic-ui-react";
import Box from "../box";
import api from "../../../actions/api";
import { GithubPicker } from "react-color";

export default class RotaTypesWithRoles extends React.Component {
  state = {
    textvalue: "",
    color: "#1273DE",
    roles: [],
    role: "",
    isEdit: false,
    editId: null,
    openDelete: false,
    deleteID: null,
  };

  handleChange = (e) => {
    this.setState({
      textvalue: e.target.value,
    });
  };
  handleSubmit = () => {
    let field = this.props.field;
    let data = {};
    let fd = this.props.data;
    if (this.state.isEdit) {
      fd = fd.map((item) => {
        if (item._id !== this.state.editId) {
          return item;
        }
        return {
          ...item,
          name: this.state.textvalue,
          color: this.state.color,
          roles: this.state.roles,
        };
      });
    } else {
      fd.push({
        name: this.state.textvalue,
        color: this.state.color,

        roles: this.state.roles,
      });
    }
    data[field] = fd;
    this.props.submit(data);
    this.setState({
      textvalue: "",
      color: "",
      isEdit: false,
      role: "",
      roles: [],
    });
  };
  handleDelete = () => {
    let newList = this.props.data.filter((item, i) => {
      return this.state.deleteItem !== item;
    });
    let field = this.props.field;
    const data = {};
    data[field] = newList;

    api.rota.deleteByType(this.state.deleteItem.name);
    this.props.delete(data);
    this.closeDelete();
  };
  handleEdit = (item) => {
    this.setState({
      isEdit: true,
      editId: item._id,
      textvalue: item.name,
      color: item.color,
      modules: item.modules || {},
      roles: item.roles || {},
    });
  };
  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };
  closeDelete = () => {
    this.setState({
      openDelete: false,
      deleteID: null,
    });
  };
  openDelete = (item) => {
    this.setState({
      deleteItem: item,
      openDelete: true,
    });
  };
  handleToggleRole = (props, data) => {
    let roles = [].concat(this.state.roles);
    if (roles.indexOf(data.name) === -1) {
      roles.push(data.name);
    } else {
      roles = roles.filter((i) => i !== data.name);
    }
    this.setState({
      roles,
    });
  };
  renderRoles() {
    if (this.props.roles) {
      return this.props.roles.map((role) => {
        return (
          <div key={role} style={{ padding: 6 }}>
            <Checkbox
              name={role}
              label={role}
              checked={this.state.roles.indexOf(role) !== -1}
              onChange={this.handleToggleRole}
            />
          </div>
        );
      });
    }
  }
  render() {
    let addDis = true;
    if (this.state.textvalue !== "") {
      addDis = false;
    }
    const form = (
      <Segment basic>
        <Form onSubmit={this.handleSubmit}>
          {!this.props.disabled && (
            <Form.Group>
              <Form.Field width={6}>
                <GithubPicker
                  triangle="hide"
                  color={this.state.color}
                  colors={[
                    "#B80000",
                    "#DB3E00",
                    "#b0900a",
                    "#008B02",
                    "#006B76",
                    "#1273DE",
                  ]}
                  onChangeComplete={this.handleChangeComplete}
                />
              </Form.Field>
              <Form.Field width={10}>
                <Input
                  action={
                    this.state.isEdit
                      ? { icon: "pencil", color: "blue" }
                      : { icon: "plus", color: "green", disabled: addDis }
                  }
                  onChange={this.handleChange}
                  value={this.state.textvalue}
                  placeholder={this.props.placeholder}
                  required={true}
                  style={{ background: this.state.color }}
                />
              </Form.Field>
            </Form.Group>
          )}
          <Divider />
          Roles:
          <Segment basic style={{ display: "flex", flexWrap: "wrap" }}>
            {this.renderRoles()}
          </Segment>
          {this.state.isEdit && (
            <Button
              negative
              basic
              onClick={() =>
                this.setState({
                  isEdit: false,
                  textvalue: "",
                  modules: {},
                  roles: {},
                })
              }
            >
              Cancel
            </Button>
          )}
        </Form>
      </Segment>
    );
    let list;
    if (this.props.data) {
      let roles;
      list = this.props.data.map((item, i) => {
        if (item.roles) {
          roles = item.roles.join(", ");
        }
        return (
          <React.Fragment key={i}>
            <List.Item>
              <List.Content className="modules">
                <Header as="h2" style={{ paddingBottom: 10 }}>
                  {item.name}
                </Header>
                <Divider />
                <span style={{ color: "white", paddingRight: 10 }}>
                  Roles:
                </span>{" "}
                {roles}
                <Button
                  disabled={this.props.disabled}
                  circular
                  icon="close"
                  floated="right"
                  size="tiny"
                  color="red"
                  onClick={() => this.openDelete(item)}
                />
                <Button
                  disabled={this.props.disabled}
                  circular
                  icon="pencil"
                  floated="right"
                  size="tiny"
                  color="blue"
                  onClick={() => this.handleEdit(item)}
                />
              </List.Content>
            </List.Item>
            <Divider />
          </React.Fragment>
        );
      });
    }
    return (
      <Box title={this.props.title}>
        <Confirm
          header="This will delete this rota type and all rotas associated with it."
          open={this.state.openDelete}
          onCancel={this.closeDelete}
          onConfirm={this.handleDelete}
        />
        {form}
        <Divider />
        <Item>
          <List className="dataList no-pad " verticalAlign="middle">
            {list}
          </List>
        </Item>
      </Box>
    );
  }
}
