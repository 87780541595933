import React, { Component } from "react";
import { Segment, Header, Grid, Modal, Form, Icon, Popup } from "semantic-ui-react";
import api from "../actions/api";
import JobList from "./common/lists/jobList";
import Settings_Receiver from "../contexts/settings_context/Settings_Receiver";
import Box from "./common/box";
import moment from "moment";

const intervalTime = 60;
class Home extends Component {
  autoReloadInterval = null;
  state = {
    autoReload: false,
    jobs: [],
    addJob: false,
    loading: true,
    data: {
      type: "",
    },
    errors: {},
    settings: [],
    locations: [],
    rotas: [],
    rotaStaff: [],
  };
  componentDidMount() {
    this.props.settings._refresh();
    this.enableAutoReload();
    this.getData();
  }
  componentWillUnmount() {
    this.disableAutoReload();
  }
  getData = () => {
    api.jobs
      .active()
      .then((jobs) => {
        this.setState({
          jobs: jobs.jobs,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    api.settings.formLists().then((settings) => {
      this.setState({
        settings,
      });
    });
    api.rota.current().then((rotas) => {
      this.setState(
        {
          rotas,
        },
        () => {
          this.getRotaStaff();
        }
      );
    });
  };
  goto = (data) => {
    this.props.history.push(`/jobs/${data}`);
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };
  addJob = () => {
    this.setState({ addJob: true });
  };
  onCancel = (e) => {
    e.preventDefault();
    this.setState({ addJob: false });
  };
  getRotaStaff = () => {
    let rotas = this.state.rotas;
    if (rotas.length) {
      Promise.all(rotas.map((r) => api.rota.show(r._id))).then((res) => {
        let staff = res.map((r) => {
          return {
            id: r._id,
            name: r.type,
            staff: r.Staff.length,
            ref: r.rotaNum,
          };
        });
        this.setState({
          rotaStaff: staff,
        });
      });
    }
  };
  enableAutoReload = () => {
    this.setState({
      autoReload: true,
    });
    if (!this.autoReloadInterval) {
      this.autoReloadInterval = setInterval(() => {
        this.getData();
      }, intervalTime * 1000);
    }
  };
  disableAutoReload = () => {
    this.setState({
      autoReload: false,
    });
    if (this.autoReloadInterval) {
      clearInterval(this.autoReloadInterval);
    }
  };
  onSubmitJob = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      api.jobs.add(this.state.data).then(() => {
        this.getData();
        this.setState({ addJob: false });
      });
    }
  };
  validate = (data) => {
    const errors = {};
    if (!data.type) errors.type = "Can't be empty";
    // if (!data.lastName) errors.lastName = "Can't be empty";
    // if (!data.email) errors.email = "Can't be empty";
    return errors;
  };
  renderEvents() {
    let list = []
      .concat(this.state.jobs)
      .sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0))
      .map((item) => {
        return {
          ...item,
          flag: item.flag === true ? true : 0,
        };
      })
      .sort((a, b) => (a.flag < b.flag ? 1 : a.flag > b.flag ? -1 : 0))
      .filter((j) => {
        return j.date < moment().toISOString() || j.status !== "Open";
      });
    return <JobList data={list} action={this.goto} extended={true} settings={this.props.settings} />;
  }
  renderFutureJobs() {
    let list = []
      .concat(this.state.jobs)
      .sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0))
      .filter((j) => {
        return j.date > moment().toISOString();
      });
    return <JobList data={list} action={this.goto} future={true} />;
  }
  renderCurrentRota() {
    return (
      <Box title="Current Rota">
        <Segment basic className="statHolder">
          {this.state.rotaStaff.length > 0 ? (
            this.state.rotaStaff.map((r, i) => {
              return (
                <Segment basic className="no-marg statBox clickable" key={i} onClick={() => this.props.history.push(`/staff/rota/${r.id}`)}>
                  <h3>{r.name}</h3>
                  <h1>{r.staff}</h1>
                  <h4 className="subtle">{r.ref}</h4>
                </Segment>
              );
            })
          ) : (
            <div className="p-4 text-center">
              <div> No Active Rota's</div>
              <button onClick={() => this.props.history.push("/staff/rota")} className="mt-4 bg-green-700 p-2 rounded-md text-white">
                Add Rota
              </button>
            </div>
          )}
        </Segment>
      </Box>
    );
  }
  renderAddJob() {
    const { errors } = this.state;
    return (
      <Modal open={this.state.addJob} onClose={() => this.setState({ addJob: false })} centered={false} size="small" closeOnDimmerClick={true}>
        <Header icon="user" content="Add Job" />
        <Modal.Content>
          <Form onSubmit={this.onSubmitJob}>
            <Form.Group widths={2}>
              <Form.Field>
                <Form.Dropdown
                  name="type"
                  label="Job Type"
                  value={this.state.data.type}
                  onChange={this.handleDropdownChange}
                  options={this.state.settings.JobTypes}
                  selection
                  required={true}
                />
                {errors.type && <p style={{ color: "red" }}>{errors.type}</p>}
              </Form.Field>

              <Form.Dropdown name="locations" label="locations" value={this.state.data.locations} onChange={this.handleDropdownChange} options={this.state.locations} selection />
            </Form.Group>
            <Form.TextArea name="details" label="Details" value={this.state.data.details} onChange={this.onChange} placeholder="details about the job" />
            <div className="formActions">
              <Form.Button negative onClick={this.onCancel}>
                Cancel
              </Form.Button>
              <Form.Button positive type="submit">
                Add
              </Form.Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
  render() {
    // console.log(this.state.jobs);
    return (
      <Segment basic>
        {this.renderAddJob()}
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={16}>
              <Box
                title={
                  <Popup
                    basic
                    trigger={
                      <span>
                        Active Jobs <Icon name="info circle" color="yellow" />
                      </span>
                    }
                    content={"This includes all active jobs and closed jobs within the last 24hrs."}
                  />
                }
                action={() => this.props.history.push(`/jobs/add`)}
                icon={<Icon name="add" color="green" />}
                loading={this.state.loading}
              >
                {this.renderEvents()}
              </Box>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <Box title="Future Jobs" action={() => this.props.history.push(`/jobs/add`)} icon={<Icon name="add" color="green" />} loading={this.state.loading}>
                {this.renderFutureJobs()}
              </Box>
            </Grid.Column>
            <Grid.Column computer={6} mobile={16}>
              {this.props.settings.hasRotas && this.renderCurrentRota()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
export default Settings_Receiver(Home, (settings) => {
  return {
    settings,
  };
});
