import React, { Component } from "react";
import { Segment, Menu, Sidebar, Header } from "semantic-ui-react";
import {
  Map,
  TileLayer,
  GeoJSON,
  withLeaflet,
  Marker,
  Popup,
  Circle,
  Tooltip,
  ScaleControl,
} from "react-leaflet";
import PrintControlDefault from "react-leaflet-easyprint";
import Proj4 from "proj4leaflet";
import L from "leaflet";
import * as turf from "@turf/turf";
import { svg } from "../jobMarker";
import pin from "../../../../assets/images/pin.png";
// wrap `PrintControl` component with `withLeaflet` HOC
const PrintControl = withLeaflet(PrintControlDefault);

const crs = new Proj4.CRS(
  "EPSG:27700",
  "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999601 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.060,0.1502,0.2470,0.8421,-20.4894 +datum=OSGB36 +units=m +no_defs",
  {
    transformation: new L.Transformation(1, 238375, -1, 1376256),
    resolutions: [
      896.0,
      448.0,
      224.0,
      112.0,
      56.0,
      28.0,
      14.0,
      7.0,
      3.5,
      1.75,
      0.875,
      0.4375,
      0.21875,
      0.109375,
    ],
  }
);
const image = new Image();
image.src = "data:image/svg+xml;charset=utf-8;base64," + btoa(svg);

// var myIcon = L.icon({
//   iconUrl: pin,
//   // iconSize: [38, 38],
//   iconAnchor: [22, 94],
//   popupAnchor: [-3, -76],
// });
export default class OS_Map extends Component {
  state = {
    visible: true,
    map: "os",
    showTracks: false,
    showSweep: false,
    showPlans: true,
    showAreas: true,
    showTasks: true,
    showLocs: true,
    showJobs: true,
  };
  componentDidMount() {
    this.mapApi = this.refs.map.leafletElement;
    if (this.mapApi) {
      setTimeout(() => {
        this.mapBounds();
      }, 90);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    // if (this.props.locations !== prevProps.locations) {
    //   setTimeout(() => {
    //     this.mapBounds();
    //   }, 90);
    // }
  }
  zoneClick = (zone) => {
    this.props.onClick(zone);
  };
  createBounds = (bbox) => {
    return [
      [bbox[1], bbox[0]],
      [bbox[3], bbox[2]],
    ];
  };
  mapBounds = () => {
    let locBox = {};

    if (this.mapApi) {
      let data = [];
      if (this.props.locations && this.props.locations.length > 0) {
        this.props.locations.forEach((loc, i) => {
          if (loc) {
            if (loc.geoJSON.geometry && loc.geoJSON.geometry.coordinates) {
              data.push(loc.geoJSON.geometry.coordinates);
            }
          }
        });
        let list = turf.multiPoint(data);
        if (list.geometry.coordinates.length) {
          locBox = list;
        }
      }
      if (this.props.jobs && this.props.jobs.length > 0) {
        this.props.jobs.forEach((loc, i) => {
          if (loc) {
            if (loc.geometry && loc.geometry.coordinates) {
              data.push(loc.geometry.coordinates);
            }
          }
        });
        let list = turf.multiPoint(data);
        if (list.geometry.coordinates.length) {
          locBox = list;
        }
      }

      let fc = turf.featureCollection([locBox]);
      let combined = turf.combine(fc);
      let bbox1 = turf.bbox(combined);

      if (locBox.geometry) {
        this.mapApi.fitBounds(this.createBounds(bbox1), { maxZoom: 9 });
      }
    }
  };
  convertCoord = (data) => {
    return [data[1], data[0]];
  };
  onEachFeature = (data, layer) => {
    const icon = L.divIcon({
      className: "map-label",
      html: `<span>Label Text</span>`,
    });
    let center = turf.center(data.geometry);

    let coords = [
      center.geometry.coordinates[1],
      center.geometry.coordinates[0],
    ];

    return (
      <Marker position={coords}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>
    );
  };
  renderMapsBar() {
    return (
      <div className="mapBar1">
        <Menu vertical width="thin">
          <Menu.Item
            active={this.state.map === "baseMap"}
            onClick={() => this.setState({ map: "baseMap" })}
          >
            BaseMap
          </Menu.Item>
          <Menu.Item
            active={this.state.map === "os"}
            onClick={() => this.setState({ map: "os" })}
          >
            OS
          </Menu.Item>
          {/* <Menu.Item
            active={this.state.map === "roads"}
            onClick={() => this.setState({ map: "roads" })}
          >
            Roads
          </Menu.Item> */}
        </Menu>
      </div>
    );
  }
  renderLayersBar() {
    return (
      <Sidebar.Pushable className="layerBar1">
        <Sidebar
          as={Menu}
          animation="overlay"
          vertical
          visible={this.state.visible}
          width="thin"
        >
          {this.props.locations && this.props.locations.length > 0 && (
            <Menu.Item
              active={this.state.showLocs}
              onClick={() => this.setState({ showLocs: !this.state.showLocs })}
            >
              Locations
            </Menu.Item>
          )}
          {this.props.tracks && this.props.tracks.length > 0 && (
            <React.Fragment>
              <Menu.Item
                active={this.state.showTracks}
                onClick={() =>
                  this.setState({ showTracks: !this.state.showTracks })
                }
              >
                Tracks
              </Menu.Item>
              {this.props.sweep && this.state.showTracks === true && (
                <Menu.Item
                  active={this.state.showSweep}
                  onClick={() =>
                    this.setState({ showSweep: !this.state.showSweep })
                  }
                >
                  Sweep
                </Menu.Item>
              )}
            </React.Fragment>
          )}
          {this.props.tasks && this.props.tasks.length > 0 && (
            <Menu.Item
              active={this.state.showTasks}
              onClick={() =>
                this.setState({ showTasks: !this.state.showTasks })
              }
            >
              Tasks
            </Menu.Item>
          )}
          {this.props.plans && this.props.plans.length > 0 && (
            <Menu.Item
              active={this.state.showPlans}
              onClick={() =>
                this.setState({ showPlans: !this.state.showPlans })
              }
            >
              Plans
            </Menu.Item>
          )}
          {this.props.areas && this.props.areas.length > 0 && (
            <Menu.Item
              active={this.state.showAreas}
              onClick={() =>
                this.setState({ showAreas: !this.state.showAreas })
              }
            >
              Areas
            </Menu.Item>
          )}
          {this.props.masts && this.props.masts.length > 0 && (
            <Menu.Item
              active={this.state.showMasts}
              onClick={() =>
                this.setState({ showMasts: !this.state.showMasts })
              }
            >
              Masts
            </Menu.Item>
          )}
        </Sidebar>
      </Sidebar.Pushable>
    );
  }

  renderTasks() {
    if (this.props.tasks) {
      let tasks = this.props.tasks.map((area) => {
        let colour = "blue";
        if (
          area.geoJSON.properties &&
          area.geoJSON.properties.priority === "High"
        ) {
          colour = "red";
        }
        if (
          area.geoJSON.properties &&
          area.geoJSON.properties.priority === "Medium"
        ) {
          colour = "orange";
        }
        return (
          <GeoJSON
            key={area._id}
            data={area.geoJSON}
            style={{ color: colour, weight: 4 }}
            tooltip={"test"}
          />
        );
      });
      return tasks;
    }
  }
  renderJobs() {
    if (this.props.jobs) {
      let tasks = this.props.jobs.map((area, i) => {
        let latlng = {
          lat: area.geometry.coordinates[1],
          lng: area.geometry.coordinates[0],
        };
        return (
          <Marker key={i} position={latlng}>
            <Popup>
              Job: {area.properties.job}. <br /> Type: {area.properties.type}.
            </Popup>
          </Marker>
        );
        // return <GeoJSON key={i} onEachFeature={this.onEachFeature} data={area} style={{ color: colour, weight: 4 }} tooltip={"test"} />;
      });
      return tasks;
    }
  }
  renderLocations() {
    if (this.props.locations) {
      let plans = this.props.locations.map((loc) => {
        if (loc.geoJSON.geometry.type === "Polygon") {
          return (
            <GeoJSON
              key={loc._id}
              data={loc.geoJSON}
              style={{ color: "blue", weight: 4 }}
              onclick={() => {
                this.zoneClick(loc);
              }}
            />
          );
        }
        let coords = [
          loc.geoJSON.geometry.coordinates[1],
          loc.geoJSON.geometry.coordinates[0],
        ];
        return (
          <Marker position={coords} key={loc._id}>
            <Popup>
              <Header>{loc.geoJSON.properties.name}</Header>
              <p>{loc.geoJSON.properties.type}</p>
              <p>{loc.geoJSON.properties.grid}</p>
            </Popup>
          </Marker>
        );
      });
      return plans;
    }
  }

  renderData = (data) => {
    let features = data
      .filter((loc) => {
        if (loc.geoJSON.geometry.coordinates.length) {
          return true;
        }
        return false;
      })
      .map((loc) => {
        return loc.geoJSON;
      });
    return {
      type: "FeatureCollection",
      features: features,
    };
  };

  render() {
    let center = [0, 0];
    if (this.props.center) {
      center = this.convertCoord(this.props.center);
    }
    let mapStyle = {
      height: 100 + "%",
      width: 100 + "%",
    };
    if (this.props.mapStyle) {
      mapStyle = this.props.mapStyle;
    } else {
      mapStyle = {
        minHeight: 60 + "vh",
        width: 100 + "%",
      };
    }
    if (this.props.ipp) {
      center = this.convertCoord(this.props.ipp.geometry.coordinates);
    }
    let options27700 = {
      crs: crs,
    };
    let options = options27700;

    let options3857 = {
      // crs: L.CRS.Simple,
    };
    if (this.state.map === "osm") {
      options = options3857;
    }
    return (
      <Segment className="no-pad">
        <Map
          ref="map"
          style={mapStyle}
          center={center}
          //   maxZoom={9}
          zoom={8}
          crs={crs}
        >
          {this.renderLayersBar()}
          {this.renderMapsBar()}

          {this.state.map === "os" && (
            <TileLayer
              maxZoom={9}
              crs={crs}
              attribution='&copy; <a href="http://www.ordnancesurvey.co.uk/">Ordnance Survey</a>'
              // url="https://api.os.uk/maps/raster/v1/zxy/Leisure_27700/{z}/{x}/{y}.png?key=776AHwqgmkpdTYCppeq4Z1OKF0SzQxDy"
              url="https://tiles.sarmap.xyz/maps/ordnance_survey/Leisure_27700/{z}/{x}_{y}.png"
            />
          )}

          {this.state.map === "baseMap" && (
            <TileLayer
              maxZoom={14}
              attribution='&copy; <a href="http://www.ordnancesurvey.co.uk/">Ordnance Survey</a>'
              // url="https://api.os.uk/maps/raster/v1/zxy/Outdoor_27700/{z}/{x}/{y}.png?key=776AHwqgmkpdTYCppeq4Z1OKF0SzQxDy"
              url="https://tiles.sarmap.xyz/maps/ordnance_survey/Outdoor_27700/{z}/{x}_{y}.png"
            />
          )}
          {/* {this.state.map === "roads" && (
            <TileLayer
              maxZoom={16}
              attribution='&copy; <a href="http://www.ordnancesurvey.co.uk/">Ordnance Survey</a>'
              url="https://osdatahubapi.os.uk/OSMapsAPI/wmts/v1?key=QoeH1X4qGAZFcafcOGtZL2SuQCY6ojum&tileMatrixSet=EPSG:27700&version=1.0.0&style=default&layer=Road%2027700&service=WMTS&request=GetTile&tileCol={x}&tileRow={y}&tileMatrix={z}"
            />
          )} */}

          {this.state.showTasks && this.renderTasks()}
          {this.state.showJobs && this.renderJobs()}
          {this.state.showLocs && this.renderLocations()}
          <PrintControl
            position="topleft"
            sizeModes={["Current", "A4Portrait", "A4Landscape"]}
            hideControlContainer={true}
            hideClasses={["layerBar1", "mapBar1"]}
            title="Export as PNG"
            exportOnly
          />
          <PrintControl
            ref={(ref) => {
              this.printControl = ref;
            }}
            hideClasses={["layerBar1", "mapBar1"]}
            position="topleft"
            sizeModes={["Current", "A4Portrait", "A4Landscape"]}
            hideControlContainer={true}
          />
          <ScaleControl />
        </Map>
      </Segment>
    );
  }
}
