import React from "react";
import {
  Form,
  Button,
  TextArea,
  Message,
  Select,
  Checkbox,
  Divider,
  Segment,
} from "semantic-ui-react";
import Proptypes from "prop-types";
import InlineError from "../../common/forms/errorMessage";
import UploadField from "../../common/forms/uploadDropperField";

const priority = [
  { key: 1, text: "Low", value: "Low" },
  { key: 2, text: "Medium", value: "Medium" },
  { key: 3, text: "High", value: "High" },
];
const type = [
  { key: 1, text: "Info", value: "Info" },
  { key: 2, text: "Location", value: "Location" },
  { key: 3, text: "Misper", value: "Misper" },
  { key: 4, text: "Decision", value: "Decision" },
];

class LoggingForm extends React.Component {
  state = {
    data: {
      message: "",
      priority: "Low",
      resource: "",
      type: "Info",
      reqDecision: false,
      related: "",

      attachment: {},
    },

    log: {},
    default: false,
    loading: false,
    errors: {},
    related: "",
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  onDefaultChange = (e) =>
    this.setState({
      log: { ...this.state.log, [e.target.name]: e.target.value },
    });
  handleDropdownChange(props, e) {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  }
  handleCheckboxChange(props, e) {
    this.setState({
      data: { ...this.state.data, [e.name]: e.checked },
    });
  }
  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      data: {
        message: "",
        priority: "Low",
        resource: "",
        type: "Info",
        reqDecision: false,
        related: "",

        attachment: {},
      },
      related: "",
      log: {},
      default: false,
    });
    this.props.reset();
  };
  onSubmit = () => {
    let formData = this.state.data;
    if (this.state.logDefault && this.state.logDefault._id) {
      let message = this.state.log;
      let type = this.state.logDefault.name;
      let string = "";
      Object.keys(message).forEach(function (item) {
        string += item + ": " + message[item] + " | ";
      });
      formData = {
        ...formData,
        message: type + " " + string,
      };
    }
    const errors = this.validate(formData);
    this.setState(
      {
        errors,
        loading: true,
      },
      () => {
        if (Object.keys(errors).length === 0) {
          // let resource = [];
          // if (formData.resource !== null && formData.resource !== "") {
          //   let idSplit = formData.resource.split(":");
          //   resource = this.props.resources.filter((i) => i._type === idSplit[0] && i._id === idSplit[1]);
          // }
          // if (resource[0] !== undefined) {
          //   formData.resource = resource[0].name;
          // } else {
          //   formData.resource = "";
          // }

          this.props
            .submit(formData)
            .then((res) =>
              this.setState({
                data: {
                  type: "Info",
                  resource: "",
                  priority: "Low",
                  message: "",
                  reqDecision: false,
                  attachment: {},
                  CADNum: "",
                },
                log: {},
                default: false,
                logDefault: {},
              })
            )
            .catch((err) => {
              console.error(err);
              let errorMessage =
                "There was an unknown network error while retrieving data from the server";
              if (err.response !== undefined) {
                if (err.response.data.message !== undefined) {
                  errorMessage = err.response.data.message;
                }
              }
              this.setState({
                errors: {
                  ...this.state.error,
                  global: errorMessage,
                },
              });
            })
            .finally(() => {
              this.setState({
                loading: false,
              });
            });
        }
      }
    );
  };
  handleFileUpload = (file) => {
    this.setState({
      data: {
        ...this.state.data,
        attachment: file,
      },
    });
  };
  toggle = (e) => {
    e.preventDefault();
    this.setState({ data: { reqDecision: !this.state.data.reqDecision } });
  };
  clearRelated = (e) => {
    e.preventDefault();
  };

  handleDropdownChangeTask(props, e) {
    let newState = {
      data: {
        ...this.state.data,
        [e.name]: e.value,
      },
    };
    let task = this.props.tasks.filter((task) => task._id === e.value);
    if (task[0] !== undefined) {
      if (task[0].CADNum !== undefined && task[0].CADNum !== "") {
        newState.data.CADNum = task[0].CADNum;
      }
    }
    this.setState(newState);
  }

  validate = (data) => {
    const errors = {};
    if (!this.state.default) {
      if (!data.message) errors.message = "Can't be empty";
    }

    return errors;
  };
  setLogType = (e) => {
    this.setState({
      logDefault: e,
      // log: {
      //   name: e.name
      // },
      default: true,
    });
  };
  render() {
    const { data, errors, loading } = this.state;
    let resources = [{ key: "##BLANK##", text: "[NONE]", value: null }];

    return (
      <Segment basic>
        {this.props.disabled ? (
          <Message negative>Job Closed</Message>
        ) : (
          <Form onSubmit={this.onSubmit} loading={loading}>
            {errors !== undefined && errors.global !== undefined && (
              <Message negative>
                <Message.Header>Something went wrong</Message.Header>
                <p>{errors.global}</p>
              </Message>
            )}
            <Form.Group widths={3}>
              <Form.Field>
                <label htmlFor="type">Type</label>
                <Select
                  placeholder={"pick one"}
                  name={"type"}
                  options={
                    this.props.settings && this.props.settings.logTypes
                      ? this.props.settings.logTypes.map((t, i) => {
                          return { key: i, text: t, value: t };
                        })
                      : []
                  }
                  value={data.type}
                  onChange={this.handleDropdownChange.bind(this)}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="priority">Priority</label>
                <Select
                  placeholder={"pick one"}
                  name={"priority"}
                  options={priority}
                  value={data.priority}
                  onChange={this.handleDropdownChange.bind(this)}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="resource">Resource/Callsign</label>
                <Select
                  placeholder={"pick one"}
                  name={"resourceID"}
                  options={this.props.resources ? this.props.resources : []}
                  value={data.resourceID}
                  onChange={this.handleDropdownChange.bind(this)}
                />
              </Form.Field>
            </Form.Group>

            <Divider hidden={true} clearing={true} />
            <Form.Group>
              <Form.Field error={!!errors.message} width={12}>
                <label htmlFor="message">Message</label>
                <TextArea
                  name="message"
                  id="message"
                  placeholder="Tell us more"
                  style={{ minHeight: 100 }}
                  value={data.message}
                  onChange={this.onChange}
                />
                {errors.message && <InlineError text={errors.message} />}
              </Form.Field>

              <Form.Field width={4}>
                <label htmlFor="attachment">Attachment</label>
                {this.state.data.attachment.name !== undefined ? (
                  <Segment basic className="no-pad no-marg">
                    {/* <Header>Attachment</Header> */}
                    <p>
                      File: <strong>{this.state.data.attachment.name}</strong>
                    </p>
                    <Button
                      color={"red"}
                      basic
                      size={"tiny"}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          data: { ...this.state.data, attachment: {} },
                        });
                      }}
                    >
                      Remove
                    </Button>
                  </Segment>
                ) : (
                  <UploadField onFileToUpload={this.handleFileUpload} />
                )}
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <Checkbox
                  label="This requires a decision"
                  name="reqDecision"
                  onChange={this.handleCheckboxChange.bind(this)}
                  checked={this.state.data.reqDecision}
                />
              </Form.Field>
            </Form.Group>
            {this.props.related.refNum ? (
              <Message
                color="green"
                header={
                  " The log will relate to log: " + this.props.related.refNum
                }
                content={this.props.related.message}
              />
            ) : (
              ""
            )}
            <Divider />
            <Form.Group widths={"equal"} className={"form-actions"}>
              <Form.Field>
                <Button negative floated={"left"} onClick={this.resetForm}>
                  Reset
                </Button>
              </Form.Field>
              <Form.Field>
                <Button positive floated={"right"}>
                  Add
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>
        )}
      </Segment>
    );
  }
}
LoggingForm.propTypes = {
  submit: Proptypes.func.isRequired,
  related: Proptypes.any,
};
export default LoggingForm;
