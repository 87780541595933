import React, { Component } from "react";
import { Segment, Grid, Header } from "semantic-ui-react";
import Moment from "react-moment";
import api from "../../actions/api";
import Box from "../common/box";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import StaffList from "../common/lists/staffList";
import toast from "react-hot-toast";
import { Transition, Dialog } from "@headlessui/react";

class RotaPage extends Component {
  state = {
    data: {
      type: "",
    },
    formLists: {
      RotaTypes: [],
    },
    filter: "all",
    rota: {
      Staff: [],
    },
    staff: [],
    loading: true,
    isOpen: false,
  };

  componentDidMount() {
    this._getData();
  }
  _getData = () => {
    Promise.all([
      api.rota.show(this.props.match.params.id),
      api.staff.all(),
      api.settings.formLists(),
    ])
      .then((res) => {
        this.setState({
          rota: res[0],
          staff: res[1],
          formLists: res[2],
          loading: false
        });
      })
      .catch((err) => {
        //TODO error handler
      });
  };
  handleSetFilter = (type) => {
    this.setState({
      filter: type,
    });
  };
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };
  handleCheckboxChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.checked },
    });
  };
  filterList(list) {
    let assigned = this.state.rota.Staff;
    list = list.filter((i) => {
      let ii = assigned.filter((aa) => aa._id === i._id);
      if (ii[0] !== undefined) {
        return false;
      }
      return true;
    });

    let rolesLookingFor = [];
    if (
      this.props.settings.rotaTypes !== undefined &&
      this.state.rota.type !== undefined
    ) {
      let rotaSetting = this.props.settings.rotaTypes.filter(
        (item) => item.name === this.state.rota.type
      );
      if (rotaSetting[0] !== undefined) {
        rolesLookingFor = rotaSetting[0].roles;
      }
    }
    if (this.state.filter === "skill") {
      return list.filter((item) => {
        let match = false;
        for (let i in rolesLookingFor) {
          let role = rolesLookingFor[i];
          if (item.roles.indexOf(role) !== -1) {
            match = true;
            break;
          }
        }
        return match;
      });
    }
    return list;
  }
  addTo = (data) => {
    let staff = {
      staff: data._id,
    };
    api.rota.add2rota(this.props.match.params.id, staff).then(() => {
      this._getData();
    });
  };
  removeFrom = (data) => {
    let staff = {
      staff: data._id,
    };
    api.rota.removeFromrota(this.props.match.params.id, staff).then(() => {
      this._getData();
    });
  };
  deleteRota = () => {
    // console.log(this.state.rota);
    // toast("starting to delete rota......");
    api.rota
      .deleteRota(this.state.rota._id)
      .then(() => {
        toast.success("Rota removed.");
        this.props.history.push(`/staff/rota`);
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  cancelModal = () => {
    return (
      <Transition
        show={this.state.isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
          className="fixed z-10 inset-0 overflow-y-auto "
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="border-4 border-gray-700 inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
              <div className="pt-4 text-xl">
                <Dialog.Title>
                  Delete{" "}
                  <span className="italic"> {this.state.rota.type} </span>
                  this will also remove all responders from the rota?
                </Dialog.Title>
              </div>

              <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                <button
                  className="px-4 py-2 bg-blue-600 rounded-lg"
                  onClick={() => this.setState({ isOpen: false })}
                >
                  No close message
                </button>
                <button
                  onClick={this.deleteRota}
                  className="px-4 py-2 bg-red-600 rounded-lg disabled:bg-red-500"
                >
                  Yes Cancel Rota!
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  render() {
    const { rota } = this.state;
    return (
      <Segment basic loading={this.state.loading}>
        {this.cancelModal()}
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={8}>
              <Box
                title={`Rota ${rota.rotaNum} ${rota.type}`}
                className=" uppercase "
              >
                <div className="p-2 text-center">
                  <button
                    className="px-4 py-2 bg-red-900 rounded-lg p-2"
                    // onClick={this.deleteRota}
                    onClick={() => this.setState({ isOpen: true })}
                  >
                    Delete the Rota
                  </button>
                </div>
                <Grid celled textAlign="center" className={"dataGrid no-marg"}>
                  <Grid.Row columns={"equal"} className={"no-pad"}>
                    <Grid.Column className="data">
                      <Header>Start</Header>
                      <Header as={"h4"}>
                        <Moment format={"DD-MM-YYYY HH:mm"}>
                          {rota.startTime}
                        </Moment>
                      </Header>
                    </Grid.Column>
                    <Grid.Column className="data">
                      <Header>Finish</Header>
                      <Header as={"h4"}>
                        <Moment format={"DD-MM-YYYY HH:mm"}>
                          {rota.endTime}
                        </Moment>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Box>
              <div className="my-2"></div>
              <Box title={"On Rota"}>
                <StaffList data={this.state.rota.Staff} showOutArrow action={this.removeFrom}/>
              </Box>
            </Grid.Column>
            <Grid.Column width={8}>
              <Box title={"The Team"}>
                {/* <Button.Group widths={2}>
                  <Button
                    active={this.state.filter === "all"}
                    onClick={() => this.handleSetFilter("all")}
                    inverted
                  >
                    All Staff
                  </Button>
                  <Button active={this.state.filter === "skill"} onClick={() => this.handleSetFilter("skill")} inverted>
                    Staff with Required Role
                  </Button>
                </Button.Group> */}
                <StaffList data={this.filterList(this.state.staff)} showInArrow action={this.addTo}/>
              </Box>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Settings_Receiver(RotaPage, (settings) => {
  return {
    settings,
  };
});
