import React, { Component } from "react";
import {
  Segment,
  Header,
  Tab,
  Icon,
  Menu,
  Label,
  Grid,
  Table,
  Form,
  Divider,
  Button,
} from "semantic-ui-react";
import Geosuggest from "react-geosuggest";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import moment from "moment";
import Moment from "react-moment";
import TaskMap from "../common/mapping/taskMap";
import JobList from "../common/lists/jobList";
import api from "../../actions/api";
import Box from "../common/box";

class StaffPage extends Component {
  state = {
    location: {
      properties: {},
      geometry: {
        coordinates: [],
      },
    },
    staff: {
      Events: [],
    },
    tasks: [],
  };
  componentDidMount() {
    this._getData();
  }
  componentDidUpdate(prevProps, prevState) {}

  _getData = () => {
    if (this.props.match.params.id) {
      api.staff.show(this.props.match.params.id).then((staff) => {
        this.setState({
          staff,
        });
      });
    }
  };
  resetLoc = () => {
    this.setState({
      location: {
        properties: {},
        geometry: {
          coordinates: [],
        },
      },
    });

    this._geoSuggest.clear();
  };
  updateAddress = () => {
    let data = { location: { ...this.state.location } };
    console.log(data);
    api.staff.updateLocation(this.props.match.params.id, data).then(() => {
      this._getData();
      this._geoSuggest.clear();
      this.resetLoc();
    });
  };
  onSuggestSelect = (suggest) => {
    if (suggest) {
      let coords = [
        suggest.location.lng.toFixed(4),
        suggest.location.lat.toFixed(4),
      ];
      let components = suggest.gmaps.address_components;
      let admin_area = "";
      let postal_town = "";
      let postal_code = "";
      // eslint-disable-next-line
      let name = "";
      // eslint-disable-next-line
      let street = "";
      let county = "";
      let town = "";
      if (suggest.gmaps.name) {
        name = suggest.gmaps.name;
      }

      for (let component = 0; component < components.length; component++) {
        if (components[component].types[0] === "premise" && name === "") {
          name = components[component].long_name;
        }

        if (components[component].types[0] === "route") {
          street = components[component].long_name;
        }
        if (components[component].types[0] === "locality") {
          admin_area = components[component].long_name;
        }
        if (components[component].types[0] === "postal_town") {
          postal_town = components[component].long_name;
        }
        if (components[component].types[0] === "administrative_area_level_2") {
          county = components[component].long_name;
        }
        if (components[component].types[0] === "postal_code") {
          postal_code = components[component].long_name;
        }
        if (admin_area) {
          town = admin_area;
        } else {
          town = postal_town;
        }
      }
      this.setState({
        suggestion: true,
        location: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: coords,
          },
          properties: {
            ...this.state.location.properties,
            staff: this.state.staff.firstName + " " + this.state.staff.lastName,
            name: name,
            town: town,
            county: county,
            postcode: postal_code,
            address: suggest.gmaps.formatted_address,
          },
        },

        // val: suggest,
        // zoom: 10
      });
    }
  };
  renderDate = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YY HH:mm");
    } else {
      return "--";
    }
  };
  renderDetails() {
    const { staff } = this.state;
    let name = staff.firstName + " " + staff.lastName;
    return (
      <Box title={name}>
        <Grid celled textAlign="center" className={"dataGrid no-marg"}>
          <Grid.Row columns={"equal"} className={"no-pad"}>
            <Grid.Column className={"no-pad"}>
              <Menu fluid widths={3}>
                <Menu.Item onClick={() => this.printPlan()}>
                  <Icon name="print" color="blue" />
                  Print
                </Menu.Item>

                {this.state.staff.sarstuffId !== undefined &&
                this.state.staff.sarstuffId !== null ? (
                  ""
                ) : (
                  <Menu.Item
                    onClick={() => this.setState({ edit: !this.state.edit })}
                  >
                    <Icon name="pencil" color="blue" />
                    Edit
                  </Menu.Item>
                )}
              </Menu>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={"equal"} className={"no-pad"}>
            <Grid.Column className="data">
              <Header>Callsign</Header>
              <p>{staff.callsign}</p>
            </Grid.Column>
            <Grid.Column className="data">
              <Header>Phone</Header>
              <p>{staff.phone}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className="data">
              <Header>Status</Header>
              <p>{staff.onCall ? "On-Call" : "Off-Call"}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className="data">
              <Header>Email</Header>
              <p>{staff.email}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={"equal"} className={"no-pad"}>
            <Grid.Column textAlign="left" className="data">
              <Header textAlign="center">Roles</Header>
              <Segment basic>
                {staff.roles && staff.roles.length
                  ? staff.roles.map((role, i) => {
                      return (
                        <Label
                          key={i}
                          size={"large"}
                          style={{ margin: "0.3rem" }}
                        >
                          {role}
                        </Label>
                      );
                    })
                  : "--"}
              </Segment>
            </Grid.Column>
          </Grid.Row>

          {/* <Grid.Row>
            <Grid.Column className="data ">
              <Header>Current Event</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className="data">{this.renderActiveEvents()}</Grid.Column>
          </Grid.Row> */}
        </Grid>
      </Box>
    );
  }
  renderMap() {
    let center = [0, 0];
    let locations = [];

    return (
      <Grid celled className="no-pad no-marg">
        <Grid.Row>
          <Grid.Column className={"module no-pad"}>
            <Header>
              Map
              <span>task map</span>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} className="no-pad">
          <Grid.Column className="no-pad">
            <TaskMap
              center={center}
              mapStyle={{ minHeight: 74 + "vh" }}
              locations={locations}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderJobs() {
    if (this.state.staff.Jobs === undefined) {
      return null;
    }

    return (
      <Segment basic className="no-pad">
        <JobList
          data={this.state.staff.Jobs}
          action={(id) => this.props.history.push(`/jobs/${id}`)}
        />
      </Segment>
    );
  }
  renderShifts() {
    if (this.state.staff.Rotas === undefined) {
      return null;
    }
    const rotas = this.state.staff.Rotas.map((r) => {
      console.log(r);
      if (r.rota) {
        return (
          <Table.Row
            key={r._id}
            onClick={() => this.props.history.push(`/staff/rota/${r.rota._id}`)}
          >
            <Table.Cell collapsing>{r.rota ? r.rota.type : "--"}</Table.Cell>
            <Table.Cell collapsing>
              {this.renderDate(r.rota.startTime)}
            </Table.Cell>

            <Table.Cell collapsing>
              {this.renderDate(r.rota.endTime)}
            </Table.Cell>
            <Table.Cell collapsing>
              <Moment fromNow>{r.rota.endTime}</Moment>
            </Table.Cell>
          </Table.Row>
        );
      }
      return;
    });
    return (
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Start Time</Table.HeaderCell>

            <Table.HeaderCell>End Time</Table.HeaderCell>
            <Table.HeaderCell>Starts in</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rotas}</Table.Body>
      </Table>
    );
  }
  renderLocation() {
    let location = this.state.location.properties;
    let gps = this.state.location.geometry;
    let staff = this.state.staff.location;
    return (
      <Segment basic>
        <Form>
          <Geosuggest
            autoComplete="off"
            ref={(el) => (this._geoSuggest = el)}
            placeholder="Tesco, Ashford, Kent"
            inputClassName="geosuggest__input"
            country={this.props.settings.country}
            //   location={new window.google.maps.LatLng(center[1], center[0])}
            //   radius={10000}
            onSuggestSelect={this.onSuggestSelect}
            suggestsClassName="geosuggest__suggests"
            suggestsHiddenClassName="geosuggest__suggests--hidden"
            suggestItemClassName="geosuggest__item"
          />
        </Form>
        <Divider />
        <Grid padded>
          <Grid.Row columns={2}>
            <Grid.Column>
              {staff.properties && (
                <Box title="Current Address" className="paddedBox">
                  <p>{staff.properties.name}</p>
                  <p>{staff.properties.street}</p>
                  <p>{staff.properties.town}</p>
                  <p>{staff.properties.county}</p>
                  <p>{staff.properties.postcode}</p>
                  <p>
                    Lat: {staff.geometry.coordinates[1]} Lng:{" "}
                    {staff.geometry.coordinates[0]}
                  </p>
                </Box>
              )}
            </Grid.Column>
            <Grid.Column>
              {gps.coordinates.length > 0 && (
                <Box title="Address results" className="paddedBox">
                  <p>{location.name}</p>
                  <p>{location.street}</p>
                  <p>{location.town}</p>
                  <p>{location.county}</p>
                  <p>{location.postcode}</p>
                  <p>
                    Lat: {gps.coordinates[1]} Lng: {gps.coordinates[0]}
                  </p>
                  <Divider />
                  <Button.Group widths={2}>
                    <Button negative onClick={this.resetLoc}>
                      Cancel
                    </Button>
                    <Button positive onClick={this.updateAddress}>
                      Add Address
                    </Button>
                  </Button.Group>
                </Box>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  render() {
    const panes = [
      {
        menuItem: "Jobs",
        render: () => (
          <Tab.Pane attached={false} basic className="no-pad">
            {this.renderJobs()}
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Shifts",
        render: () => (
          <Tab.Pane attached={false} basic className="no-pad">
            {this.renderShifts()}
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Location",
        render: () => (
          <Tab.Pane attached={false} basic>
            {this.renderLocation()}
          </Tab.Pane>
        ),
      },
    ];
    return (
      <Segment basic>
        <Grid stackable className=" no-pad">
          <Grid.Row>
            <Grid.Column width={6}>{this.renderDetails()}</Grid.Column>

            <Grid.Column width={10}>
              <Box title="Info">
                <Tab
                  menu={{ secondary: true, pointing: false }}
                  panes={panes}
                />
              </Box>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Settings_Receiver(StaffPage, (settings) => {
  return {
    settings,
  };
});
