import React from "react";
import {
  Grid,
  Input,
  Segment,
  Button,
  Menu,
  Table,
  Icon,
  Header,
  Accordion,
  Label,
  Modal,
  Loader,
  Message,
  Statistic,
  Divider,
} from "semantic-ui-react";
import moment from "moment";
import LoggingForm from "./loggingForm.js";
import fileDownloader from "js-file-download";
import Display from "../../../lib/displayClass";
import api from "../../../actions/api.js";
import Job_Receiver from "../../../contexts/job_context/Job_Receiver";
import Settings_Receiver from "../../../contexts/settings_context/Settings_Receiver";
import TheLoader from "../../common/TheLoader";
import Box from "../../common/box.js";
import AppConfig from "../../../_appConfig.js";

const intervalTime = 60;
class LogPage extends React.Component {
  autoReloadInterval = null;
  state = {
    showAccessRequest: false,
    autoReload: false,
    lastId: "",
    searchQuery: "",
    related: "",
    relatedNum: false,
    isFiltered: false,
    taskList: [],
    display: {},
    modalOpen: false,
    welfare: {},

    printing: false,
    printingError: null,

    teamList: [],
    resList: [],
    resourceData_error: null,
    resourceData_loading: true,

    dash: {
      hasDecisions: 0,
      reqDecisions: 0,
    },
    dash_loading: false,
    dash_error: null,

    logs: [],
    logs_loading: false,
    logs_error: null,

    taskStatusChange_loading: false,
    taskStatusChange_error: null,
  };
  componentDidMount() {
    this.props.job._refresh();
    if (this.props.job.status !== "Closed") {
      this.enableAutoReload();
      // this.getData();
    } else {
      this.setState({
        showAccessRequest: true,
      });
    }
    this.getDisplay();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.job !== prevProps.job) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.disableAutoReload();
  }
  getData() {
    this.getLogs();
    this.getResources();
  }
  getLogs = () => {
    // This is done as two operations
    this.setState(
      {
        logs_loading: true,
        logs_error: null,
      },
      () => {
        api.logs
          .job(this.props.job._id)
          .then((res) => {
            this.setState({
              logs: res,
            });
          })
          .catch((err) => {
            let errorMessage =
              "There was an unknown network error while retrieving data from the server";
            if (err.response !== undefined) {
              if (err.response.data.message !== undefined) {
                errorMessage = err.response.data.message;
              }
            }
            this.setState({
              logs_error: errorMessage,
            });
          })
          .finally(() => {
            this.setState({
              logs_loading: false,
            });
          });
      }
    );
    this.setState(
      {
        dash_loading: true,
        dash_error: null,
      },
      () => {
        api.jobs
          .dash(this.props.job._id)
          .then((res) => {
            this.setState({
              dash: res,
            });
          })
          .catch((err) => {
            let errorMessage =
              "There was an unknown network error while retrieving data from the server";
            if (err.response !== undefined) {
              if (err.response.data.message !== undefined) {
                errorMessage = err.response.data.message;
              }
            }
            this.setState({
              dash_error: errorMessage,
            });
          })
          .finally(() => {
            this.setState({
              dash_loading: false,
            });
          });
      }
    );
  };
  getResources = () => {
    if (
      this.props.job &&
      this.props.job.Resources &&
      this.props.job.Resources.length
    ) {
      let resources = this.props.job.Resources;
      let list = resources.map((r) => {
        let name = r.firstName + " " + r.lastName;
        return { key: r._id, text: name, value: r._id };
      });
      this.setState({
        resourcesList: list,
      });
    }
  };
  accessLog = () => {
    this.setState(
      {
        showAccessRequest: false,
      },
      () => {
        this.getData();
      }
    );
  };
  toggleColumn = (col) => {
    this.setState(
      {
        display: {
          ...this.state.display,
          [col]: !this.state.display[col],
        },
      },
      () => {
        this.setDisplay();
      }
    );
  };
  getDisplay = () => {
    let display = Display.getLog();
    if (display) {
      this.setState({
        display: display,
      });
    }
  };
  setDisplay = () => {
    let display = this.state.display;
    Display.setLog(display);
  };
  enableAutoReload = () => {
    this.setState({
      autoReload: true,
    });
    if (!this.autoReloadInterval) {
      this.autoReloadInterval = setInterval(() => {
        this.getData();
      }, intervalTime * 1000);
    }
  };
  disableAutoReload = () => {
    this.setState({
      autoReload: false,
    });
    if (this.autoReloadInterval) {
      clearInterval(this.autoReloadInterval);
    }
  };
  exportLog = () => {
    this.setState({
      printing: true,
      printingError: null,
    });
    api.event
      .printLog(this.props.event._id)
      .then((theData) => {
        this.setState({
          printing: false,
        });
        fileDownloader(theData, "log.pdf");
      })
      .catch((err) => {
        let errorMessage =
          "There was an unknown network error while retrieving data from the server";
        if (err.response !== undefined) {
          if (err.response.data.message !== undefined) {
            errorMessage = err.response.data.message;
          }
        }
        this.setState({
          printingError: errorMessage,
        });
      });
  };
  filterList(e) {
    this.setState({
      searchQuery: e.target.value.toLowerCase(),
      isFiltered: true,
    });
  }
  onDecide = (e) => {
    this.setState({ related: e });
  };
  onRelated = (refNum) => {
    this.setState({ isFiltered: true, relatedNum: refNum });
  };
  onClear = () => {
    this.getLogs();
    this.setState({
      isFiltered: false,
      related: {},
      relatedNum: false,
      searchQuery: "",
    });
  };
  reset = () => {
    this.setState({ isFiltered: false, related: {}, relatedNum: false });
  };
  submit = (data) => {
    let related = "";
    let jobID = this.props.job._id;
    if (
      this.state.related !== undefined &&
      this.state.related.refNum !== undefined
    ) {
      related = this.state.related.refNum;
    }
    data = {
      ...data,
      related: related,
      job: jobID,
    };

    let finalFormData = new FormData();
    let keys = Object.keys(data);
    for (let i in keys) {
      finalFormData.append(keys[i], data[keys[i]]);
    }

    return api.logs.add(finalFormData).then((log) => {
      this.getLogs();
      this.setState({ related: "" });
    });
  };
  getLogAttachment = (id) => {
    api.logs
      .getLogAttachment(id)
      .then((res) => {
        window.open(AppConfig.api_server + res.link, "_blank");
      })
      .catch((err) => { });
  };
  renderDate = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YY HH:mm");
    } else {
      return "--";
    }
  };
  getFilteredLogs() {
    let logItems = this.state.logs.filter((log) => {
      let messageMatch = log.message
        ? log.message.toLowerCase().search(this.state.searchQuery) !== -1
        : false;
      let teamMatch = log.resource
        ? log.resource.toLowerCase().search(this.state.searchQuery) !== -1
        : false;
      let taskMatch = log.task
        ? log.task.toLowerCase().search(this.state.searchQuery) !== -1
        : false;
      let logNumMatch = log.refNum
        ? (log.refNum + "").search(this.state.searchQuery) !== -1
        : false;
      return messageMatch || teamMatch || logNumMatch || taskMatch;
    });
    logItems = logItems.filter((log) => {
      if (!this.state.relatedNum) {
        return true;
      }
      return (
        this.state.relatedNum === log.related ||
        log.refNum === this.state.relatedNum
      );
    });
    return logItems;
  }
  outstanding = () => {
    api.event.outstanding(this.props.match.params.job_id).then((res) => {
      this.setState({
        logs: res,
        isFiltered: true,
      });
    });
  };
  allDecisions = () => {
    api.event.decisions(this.props.match.params.job_id).then((res) => {
      this.setState({
        logs: res,
        isFiltered: true,
      });
    });
  };
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  handleTaskIndex = (e, titleProps) => {
    const { index } = titleProps;
    const { activeTaskIndex } = this.state;
    const newIndex = activeTaskIndex === index ? -1 : index;

    this.setState({ activeTaskIndex: newIndex });
  };
  lastContact = (resource) => {
    let logs = this.state.logs;
    let teamLogs = logs.filter((log) => {
      return resource === log.resource;
    });
    if (teamLogs.length) {
      let log = moment(teamLogs[0].createdAt);
      let now = moment();
      let diff = now.diff(log, "minutes");
      if (this.props.system.config !== undefined) {
        if (this.props.system.config.defaults !== undefined) {
          let threshold = this.props.system.config.defaults.welfareCheck;
          if (diff > threshold) {
            return true;
          }
        }
      }
    }
    return false;
  };
  handleOpen = () => this.setState({ modalOpen: true, activeTaskIndex: null });
  handleClose = () =>
    this.setState({ modalOpen: false, activeTaskIndex: null });
  handleTaskStatusChange = (task, status, res) => {
    // this.setState(
    //     {
    //         taskStatusChange_loading: true,
    //         taskStatusChange_error: null
    //     },
    //     () => {
    //         api.incident.tasks
    //             .update(this.props.chosenIncident.incident_id, task._id, {
    //                 status: status,
    //                 _res: res
    //             })
    //             .then(() => {
    //                 this.getData();
    //             })
    //             .catch(err => {
    //                 let errorMessage =
    //                         "There was an unknown network error while processing the request on the server";
    //                 if (err.response !== undefined){
    //                     if (err.response.data.message !== undefined){
    //                         errorMessage = err.response.data.message;
    //                     }
    //                 }
    //                 this.setState({
    //                     taskStatusChange_error: errorMessage
    //                 });
    //             })
    //             .finally(() => {
    //                 this.setState({
    //                     taskStatusChange_loading: false
    //                 });
    //             });
    //     }
    // );
  };
  doWelfareCheck = (data) => {
    if (data) {
      this.setState({
        welfare: data,
        modalOpen: true,
      });
    }
  };
  updateWelfareCheck = (type) => {
    if (type === "good") {
      let data = {
        resource: this.state.welfare.name,
        priority: "low",
        type: "info",
        message: "Welfare Check, all good.",
      };
      this.submit(data).then(() => {
        this.handleClose();
      });
    } else if (type === "bad") {
      let data = {
        resource: this.state.welfare.name,
        priority: "medium",
        type: "info",
        message: "Welfare Check, theres a problem, more info to follow.",
      };
      this.submit(data).then(() => {
        this.handleClose();
      });
    } else {
      let data = {
        // resource: this.state.welfare.name,
        priority: "medium",
        type: "info",
        message: `Welfare Check, couldnt reach ${this.state.welfare.name}.`,
      };
      this.submit(data).then(() => {
        this.handleClose();
      });
    }
  };
  processLogFlag = (log) => {
    if (log.meta !== undefined) {
      switch (
      log.meta.flag.toLowerCase() // enforce lowercase checks
      ) {
        case "location":
          return (
            <Button
              onClick={() => {
                this.props.history.push("/map", {
                  name: log.resource,
                  latlng: log.meta.data.latlng,
                  acc: log.meta.data.acc,
                });
              }}
              floated={"right"}
            >
              View on Map
            </Button>
          );
        default:
          return null;
      }
    }
  };
  renderPrintingModal() {
    return (
      <Modal open={this.state.printing}>
        <Modal.Header>Creating PDF</Modal.Header>
        <Modal.Content>
          {this.state.printingError ? (
            <Modal.Description>
              <Message>
                <Message.Header>PDF creation error</Message.Header>
                {this.state.printingError}
              </Message>
              <Button
                color={"red"}
                basic
                onClick={() => this.setState({ printing: false })}
              >
                Close
              </Button>
            </Modal.Description>
          ) : (
              <Modal.Description>
                <p>Please wait while we create the PDF...</p>
                <Loader />
              </Modal.Description>
            )}
        </Modal.Content>
      </Modal>
    );
  }
  renderLogs(filteredList) {
    if (filteredList.length === 0) {
      return (
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan="5">No Logs</Table.Cell>
          </Table.Row>
        </Table.Body>
      );
    }
    const list = filteredList.map((log, index) => {
      let attachedFile = "";
      if (
        log.attachedFile !== undefined &&
        log.attachedFile !== null &&
        log.attachedFile.filename !== undefined
      ) {
        // eslint-disable-next-line
        attachedFile = (
          <p>
            ---
            <br />
            Attached File:{" "}
            <a href={"#"} onClick={() => this.getLogAttachment(log._id)}>
              {log.attachedFile.filename}
            </a>
          </p>
        );
      }
      return (
        <Table.Row
          className={log.isRead ? "" : "unread_log"}
          negative={log.priority === "High"}
          key={log._id + "-" + index}
        >
          <Table.Cell collapsing>{log.refNum}</Table.Cell>
          {this.state.display.date && (
            <Table.Cell collapsing>{this.renderDate(log.createdAt)}</Table.Cell>
          )}
          {this.state.display.resource && (
            <Table.Cell singleLine>{log.resource}</Table.Cell>
          )}
          <Table.Cell>
            {log.message}
            {attachedFile}
            {this.processLogFlag(log)}
          </Table.Cell>
          {this.state.display.type && (
            <Table.Cell collapsing>{log.type}</Table.Cell>
          )}
          {this.state.display.priority && (
            <Table.Cell collapsing>{log.priority}</Table.Cell>
          )}
          {this.state.display.loggedBy && (
            <Table.Cell singleLine>{log.loggedBy}</Table.Cell>
          )}
          {this.state.display.decision && (
            <Table.Cell collapsing>
              {log.reqDecision ? (
                <Button
                  basic
                  icon
                  color={log.hasDecision ? "green" : "red"}
                  onClick={() => this.onDecide(log)}
                  size="mini"
                  title="decision"
                >
                  {log.hasDecision ? (
                    <Icon name="check" />
                  ) : (
                      <Icon name="question" />
                    )}
                </Button>
              ) : (
                  ""
                )}
              {log.related ? (
                <Button
                  color="green"
                  content={log.related}
                  size="mini"
                  onClick={() => this.onRelated(log.related)}
                // label={{
                //   as: "a",
                //   basic: true,
                //   // color: "green",
                //   size: "mini",
                //   pointing: "left",
                //   content: log.related
                // }}
                />
              ) : (
                  ""
                )}
            </Table.Cell>
          )}
        </Table.Row>
      );
    });
    return <Table.Body>{list}</Table.Body>;
  }
  renderDecisions(disabled) {
    let colour = "green";
    if (this.state.dash.reqDecisions > 0) {
      colour = "red";
    }
    return (
      <Box title="Decisions">
        <Segment
          basic
          className="decisionHolder"
          style={disabled ? { height: "auto" } : { height: 310 }}
        >
          <Statistic size="large" className="stat no-marg" color="green">
            <Statistic.Value>{this.state.dash.hasDecisions}</Statistic.Value>
            <Statistic.Label style={{ color: "white" }}>Made</Statistic.Label>
          </Statistic>
          <Statistic size="large" className="stat no-marg" color={colour}>
            <Statistic.Value>{this.state.dash.reqDecisions}</Statistic.Value>
            <Statistic.Label style={{ color: "white" }}>
              Required
            </Statistic.Label>
          </Statistic>
        </Segment>
      </Box>
    );
  }
  render() {
    let disabled = false;
    if (this.props.job.status === "Closed") {
      disabled = true;
    }
    if (this.state.showAccessRequest) {
      return (
        <Segment basic className="no-pad" textAlign={"center"}>
          <Message color={"yellow"}>
            <Message.Header>Access Request</Message.Header>This Incident is
            closed therefore access to the log is restricted. If you require
            access please click the button below, note this will trigger a audit
            trail!
          </Message>
          <Button color={"blue"} onClick={() => this.accessLog()}>
            View Log
          </Button>
        </Segment>
      );
    }
    let theLogs = this.getFilteredLogs();
    return (
      <Segment basic className="no-vpad" style={{ marginBottom: "3rem" }}>
        {this.renderPrintingModal()}
        <Grid stackable className="no-pad no-marg">
          <Grid.Row stretched>
            <Grid.Column width={12} className="no-pad">
              <Box title="Job Log Form">
                <LoggingForm
                  submit={this.submit}
                  related={this.state.related}
                  reset={this.reset}
                  settings={this.props.settings}
                  resources={this.state.resourcesList}
                  disabled={disabled}
                />
              </Box>
            </Grid.Column>
            <Grid.Column width={4} className="no-pad">
              {this.renderDecisions(disabled)}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} className="no-pad">
              <Box title="The Logs">
                <TheLoader loading={this.state.logs_loading} />
                <Menu stackable>
                  <Menu.Item>
                    <Input
                      icon="search"
                      placeholder="Search..."
                      value={this.state.searchQuery}
                      onChange={this.filterList.bind(this)}
                      className={"menuInput"}
                    />
                  </Menu.Item>
                  <Menu.Menu position="right">
                    {this.state.isFiltered && (
                      <Menu.Item
                        className={"filtered"}
                        onClick={() => this.onClear()}
                      >
                        <Icon name="refresh" />
                        Clear
                      </Menu.Item>
                    )}
                    <Menu.Item
                      active={this.state.autoReload}
                      // color={"green"}
                      onClick={(props, e) => {
                        if (this.state.autoReload === false) {
                          this.enableAutoReload();
                        } else {
                          this.disableAutoReload();
                        }
                      }}
                    >
                      <Icon name="sync" />
                      {this.state.autoReload
                        ? "Turn OFF auto-reload"
                        : "Turn ON auto-reload"}
                    </Menu.Item>
                    {/* <Menu.Item onClick={this.exportLog}>
                      <Icon name="external" />
                      Export
                    </Menu.Item> */}
                  </Menu.Menu>
                </Menu>

                <Table attached className="logTable" stackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>ID</Table.HeaderCell>
                      {this.state.display.date && (
                        <Table.HeaderCell>Date</Table.HeaderCell>
                      )}
                      {this.state.display.resource && (
                        <Table.HeaderCell>Resource</Table.HeaderCell>
                      )}
                      <Table.HeaderCell>Message</Table.HeaderCell>
                      {this.state.display.type && (
                        <Table.HeaderCell>Type</Table.HeaderCell>
                      )}
                      {this.state.display.priority && (
                        <Table.HeaderCell>Priority</Table.HeaderCell>
                      )}
                      {this.state.display.loggedBy && (
                        <Table.HeaderCell collapsing>
                          Logged By
                        </Table.HeaderCell>
                      )}
                      {this.state.display.decision && (
                        <Table.HeaderCell collapsing textAlign="center">
                          Decisions
                        </Table.HeaderCell>
                      )}
                    </Table.Row>
                  </Table.Header>
                  {this.renderLogs(theLogs)}
                </Table>
              </Box>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

let TheLogPage = Settings_Receiver(LogPage, (settings) => {
  return {
    settings,
  };
});

export default Job_Receiver(TheLogPage, (job) => {
  return {
    job,
  };
});
