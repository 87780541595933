import React, { Component } from "react";
import ReactMapboxGl, { ZoomControl, ScaleControl, GeoJSONLayer, Source, Layer } from "react-mapbox-gl";
import { Segment, Button, Popup as ToolTip, Icon, Header, Menu } from "semantic-ui-react";
import * as turf from "@turf/turf";
import Default_Styles from "./styles/mainStyles";
import RadioAssets_Styles from "./styles/radioAssets";
const { token, styles } = require("./config.json");
const Map = ReactMapboxGl({
  accessToken: token,
  preserveDrawingBuffer: true,
});
const selectedStyles = ["outdoor", "sat", "broadNet"];
const switchStyles = Object.keys(styles).filter((k) => selectedStyles.includes(k));
const RASTER_SOURCE_OPTIONS = {
  type: "raster",
  // tiles: ["https://ecn.t0.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=z"],
  tiles: ["https://ecn.t0.tiles.virtualearth.net/tiles/r{quadkey}?g=7080&lbl=l1&productSet=mmOS&key=AoMKVVkBRY3_8gv8UJmlIHXp8DCIGGzv9Yw_50Q4WIqC5ujgtVszQK-xLpB34hF2"],
  // tiles: ["https://dev.virtualearth.net/REST/v1/Imagery/Metadata/Aerial?key=AoMKVVkBRY3_8gv8UJmlIHXp8DCIGGzv9Yw_50Q4WIqC5ujgtVszQK-xLpB34hF2"],
  tileSize: 256,
  maxzoom: 16,
  minzoom: 12,
};
const linePaint1 = {
  "line-width": 2,
  "line-opacity": 0.6,
  "line-color": "#1192e7",
  "line-dasharray": [1, 1],
};
const linePaint2 = {
  "line-width": 2,
  "line-opacity": 0.6,
  "line-color": "#fff",
  "line-dasharray": [1, 1],
};
const fillPaint = {
  "fill-color": "red",
  "fill-opacity": 0.08,
};

export default class Sarstuff_Map extends Component {
  state = {
    styleKey: "broadNet",
    marker: {},
    loading: true,
    reload: true,
    radioAssets: true,
    floodAlerts: false,
    visible: true,
    os: false,
    center: undefined,
  };
  componentDidMount() {
    if (this.props.marker) {
      this.setState({
        marker: this.props.marker,
        loading: false,
        center: this.props.marker.geometry && this.props.marker.geometry.coordinates,
      });
    }
    if (this.map) {
      setTimeout(() => {
        this.mapBounds();
      }, 1000);
    }
  }
  mapBounds = () => {
    let data = [];
    if (this.map) {
      if (this.props.locations && this.props.locations.length > 0) {
        this.props.locations.forEach((loc, i) => {
          if (loc) {
            if (loc.geoJSON.geometry && loc.geoJSON.geometry.coordinates) {
              data.push(loc.geoJSON.geometry.coordinates);
            }
          }
        });
        let list = turf.multiPoint(data);
        if (list.geometry.coordinates.length) {
          let bbox = turf.bbox(list);
          if (this.map.state.map !== undefined) {
            this.map.state.map.fitBounds(bbox, { padding: 80, maxZoom: 14 });
          }
        }
      }
      if (this.props.jobs && this.props.jobs.length > 0) {
        this.props.jobs.forEach((loc, i) => {
          if (loc) {
            if (loc.geometry && loc.geometry.coordinates) {
              data.push(loc.geometry.coordinates);
            }
          }
        });
        let list = turf.multiPoint(data);
        if (list.geometry.coordinates.length) {
          let bbox = turf.bbox(list);
          if (this.map.state.map !== undefined) {
            this.map.state.map.fitBounds(bbox, { padding: 80, maxZoom: 14 });
          }
        }
      }
      if (this.props.rotaStaff && this.props.rotaStaff.length > 0) {
        this.props.rotaStaff.forEach((loc, i) => {
          if (loc) {
            if (loc.location.geometry && loc.location.geometry.coordinates) {
              data.push(loc.location.geometry.coordinates);
            }
          }
        });
        let list = turf.multiPoint(data);
        if (list.geometry.coordinates.length) {
          let bbox = turf.bbox(list);
          if (this.map.state.map !== undefined) {
            this.map.state.map.fitBounds(bbox, { padding: 80, maxZoom: 14 });
          }
        }
      }
      if (this.props.resources && this.props.resources.length > 0) {
        this.props.resources.forEach((loc, i) => {
          if (loc) {
            if (loc.staff.location.geometry && loc.staff.location.geometry.coordinates) {
              data.push(loc.staff.location.geometry.coordinates);
            }
          }
        });

        let list = turf.multiPoint(data);
        if (list.geometry.coordinates.length) {
          let bbox = turf.bbox(list);
          if (this.map.state.map !== undefined) {
            this.map.state.map.fitBounds(bbox, { padding: 80, maxZoom: 14 });
          }
        }
      }
      if (this.props.location && this.props.location.geoJSON) {
        let maxZoom = 16;
        if (this.props.distance) {
          maxZoom = 14;
        }
        // this.flyTo(this.props.location.geoJSON.geometry.coordinates);
        if (this.props.location.geoJSON.geometry.coordinates && this.props.location.geoJSON.geometry.coordinates.length) {
          data.push(this.props.location.geoJSON.geometry.coordinates);
          let bbox = turf.bbox(this.props.location.geoJSON);

          this.map.state.map.fitBounds(bbox, {
            padding: 80,
            maxZoom: 14,
            animate: false,
          });
        }
      }
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.marker !== prevProps.marker) {
      this.setState({
        marker: this.props.marker,
        loading: false,
      });
    }
    if (this.props.flyTo !== prevProps.flyTo) {
      if (this.props.flyTo.length) {
        this.map.state.map.flyTo({
          center: this.props.flyTo,
          zoom: 17,
          bearing: 0,
          pitch: 0,
        });
      }
    }
  }
  onStyleLoad = (map) => {
    const { onStyleLoad } = this.props;
    this.setState({
      loading: false,
    });
    return onStyleLoad && onStyleLoad(map);
  };
  nextStyle = () => {
    const { styleKey } = this.state;
    const currentIndex = switchStyles.indexOf(styleKey);
    const nextIndex = currentIndex === switchStyles.length - 1 ? 0 : currentIndex + 1;
    this.setState({
      styleKey: switchStyles[nextIndex],
    });
  };
  resetMap = () => {
    setTimeout(() => {
      this.mapBounds();
    }, 500);
    return this.map.state.map.flyTo({
      center: this.props.center,
      zoom: 14,
      bearing: 0,
      pitch: 0,
    });
  };
  onClickMap = (map, evt) => {
    if (evt.lngLat) {
      let coords = [parseFloat(evt.lngLat.lng.toFixed(5)), parseFloat(evt.lngLat.lat.toFixed(5))];
      if (coords.length && this.props.data) {
        this.props.data(coords);
      }
    }
  };
  mapHack = () => {
    this.setState(
      {
        reload: false,
      },
      () =>
        this.setState(
          {
            os: !this.state.os,
          },
          () => {
            this.setState({
              reload: true,
            });
          }
        )
    );
  };
  renderData = (data) => {
    let features = data
      .filter((loc) => {
        if (loc.geoJSON.geometry.coordinates.length) {
          return true;
        }
        return false;
      })
      .map((loc) => {
        return loc.geoJSON;
      });
    return {
      type: "FeatureCollection",
      features: features,
    };
  };
  renderLayersBar() {
    return (
      <div className="layerHolder">
        <Menu vertical>
          {this.props.radioAssets !== undefined && this.props.radioAssets.length >= 1 && (
            <Menu.Item active={this.state.radioAssets} onClick={() => this.setState({ radioAssets: !this.state.radioAssets })}>
              Radio Assets
            </Menu.Item>
          )}
          {this.props.showFlood !== undefined && this.props.showFlood > 0 && (
            <Menu.Item
              active={this.state.floodAlerts}
              onClick={() =>
                this.setState({ floodAlerts: !this.state.floodAlerts }, () => {
                  if (this.state.floodAlerts === true) {
                    if (this.props.floodAlerts !== undefined && this.props.floodAlerts.length) {
                    } else {
                      this.props.getFloodAlerts();
                    }
                  }
                })
              }
            >
              Flood Alerts
            </Menu.Item>
          )}
        </Menu>
      </div>
    );
  }
  renderMapBar() {
    return (
      <div className="mapBar">
        <ToolTip
          trigger={
            <Button icon onClick={(e) => this.resetMap(e)} circular>
              <Icon color="orange" name="compass" />
            </Button>
          }
          content="reset bearing and pitch"
          basic
        />
        <ToolTip
          trigger={
            <Button icon onClick={(e) => this.nextStyle(e)} circular>
              <Icon name="world" />
            </Button>
          }
          content="Change Map Type"
          basic
        />
        <ToolTip
          trigger={
            <Button icon onClick={() => this.mapHack()} circular toggle active={this.state.os}>
              OS
            </Button>
          }
          content="Toggle OS Map"
          basic
        />
      </div>
    );
  }
  renderJobs() {
    if (this.props.jobs)
      if (this.props.jobs.length) {
        return this.props.jobs.map((loc, i) => {
          if (loc) {
            if (loc.geometry) {
              return (
                <GeoJSONLayer
                  data={loc}
                  linePaint={Default_Styles.linePaint}
                  circleOnClick={() => this.props.goto(loc.properties._id)}
                  circlePaint={Default_Styles.circlePaint}
                  symbolLayout={Default_Styles.symbolMainLayout}
                  symbolPaint={
                    this.state.styleKey === "outdoor" ? Default_Styles.lightSymbolPaint : this.state.os ? Default_Styles.symbolOSPaint : Default_Styles.symbolPaint
                    // this.state.styleKey === "outdoor"
                    //   ? Default_Styles.lightSymbolPaint
                    //   : Default_Styles.symbolMainPaint
                  }
                  key={i}
                />
              );
            }
          }
          return null;
        });
      }
  }
  renderFlood() {
    // let areas = this.renderData(this.props.area);
    return this.props.floodAlerts.map((f, i) => {
      return (
        <GeoJSONLayer key={i} id={`flood-${i}`} data={f} linePaint={this.state.styleKey === "sat" && this.state.os === false ? linePaint2 : linePaint1} fillPaint={fillPaint} />
      );
    });
  }
  renderRadioAssets() {
    if (this.props.radioAssets) {
      let onlineAsets = this.props.radioAssets.filter((a) => {
        return a.onlineStatus === "green";
      });
      let assets = {
        type: "FeatureCollection",
        features: onlineAsets.map((asset) => {
          let cleanAsset = {
            ...asset,
          };
          delete cleanAsset.lkp;
          return {
            ...asset.lkp.geoJSON,
            properties: {
              ...asset.lkp.geoJSON.properties,
              ...cleanAsset,
            },
          };
        }),
      };
      return <GeoJSONLayer id="radioAssets" data={assets} symbolLayout={RadioAssets_Styles.SymbolLayout} symbolPaint={RadioAssets_Styles.SymbolPaint} />;
    }
  }
  renderKey() {
    return (
      <div className={`key ${this.state.os && "dark"}`}>
        <Segment basic>
          <Header>Job Key</Header>
          <p>
            <Icon name="circle" color="red" /> Open
          </p>
          <p>
            <Icon name="circle" color="blue" /> In Progress
          </p>
          <p>
            <Icon name="circle" color="yellow" />
            Completed{" "}
          </p>
          {/* <p>
            <Icon name="circle" color="green" />
            Closed
          </p> */}
        </Segment>
      </div>
    );
  }
  render() {
    let mapStyle = {
      height: 100 + "%",
      width: 100 + "%",
    };
    if (this.props.mapStyle) {
      mapStyle = this.props.mapStyle;
    } else {
      mapStyle = {
        minHeight: 70 + "vh",
        width: 100 + "%",
      };
    }
    const { styleKey, os } = this.state;
    return (
      <Segment className="mapHolder no-pad" basic>
        <Map
          ref={(e) => {
            this.map = e;
          }}
          // zoom={[14]}
          center={this.props.center}
          style={styles[styleKey]}
          onStyleLoad={this.onStyleLoad}
          className="basicMap"
          onClick={this.onClickMap}
          containerStyle={mapStyle}
        >
          <Source id="OS" tileJsonSource={RASTER_SOURCE_OPTIONS} />
          {os && <Layer type="raster" id={"1"} sourceId="OS" />}
          {this.renderMapBar()}
          {this.renderLayersBar()}

          <ZoomControl />
          <ScaleControl position={"bottom-left"} />
          {this.props.jobs && this.state.reload && this.renderJobs()}
          {this.state.radioAssets && this.state.reload && this.renderRadioAssets()}
          {this.state.floodAlerts && this.props.floodAlerts && this.state.reload && this.renderFlood()}
          {this.renderKey()}
        </Map>
      </Segment>
    );
  }
}
