import React, { Component } from "react";

import { Header, Grid, Icon, Image, Divider } from "semantic-ui-react";
import moment from "moment";
export default class WindBar extends Component {
  state = {
    options: {
      chart: {
        id: "temp",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      yaxis: {
        lines: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: "white",
            fontSize: "12px",
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        lines: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            colors: "white",
            fontSize: "12px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      series: [
        {
          name: "Temp data",
          data: [],
        },
      ],
    },
  };
  componentDidMount() {
    if (this.props.data) {
      this.setState({
        options: {
          ...this.state.options,
          series: [
            {
              name: "Temp data",
              data: this.props.data,
            },
          ],
        },
      });
    }
  }

  render() {
    let hour = this.props.data.map((data, i) => {
      let cur_dir = "0deg";
      cur_dir = data.wind - 180 + "deg";
      let colour = "white ";
      let bak = "rgb(11, 23, 32) ";
      if (data.hour === moment().format("HH:00")) {
        colour = "#EB8C87";
        bak = "#154170";
      }
      return (
        <Grid.Column
          key={i}
          textAlign="center"
          style={{
            backgroundColor: bak,
            paddingTop: 10,
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
        >
          <Image centered src={data.icon} />
          <p className={"condition"}>{data.text}</p>

          <p>
            <small>{data.pressure} mb</small>
          </p>
          <h4>Wind</h4>
          <Icon
            name="arrow up"
            size="big"
            style={{
              color: "orange",
              transform: `rotate(${cur_dir})`,
            }}
          />
          <p style={{ marginTop: "1rem" }}>{data.dir}</p>
          <p style={{ marginTop: "1rem" }}>
            {data.speed} <small>mph</small>
          </p>
          <Divider />
          <p style={{ margin: "1rem" }}>{data.hour}</p>
        </Grid.Column>
      );
    });
    return (
      <React.Fragment>
        {/* <Header>{this.props.label}</Header> */}
        <Grid stackable centered className={"forcastBox"}>
          <Grid.Row columns={12}>{hour}</Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}
