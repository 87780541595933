import AppConfig from "../_appConfig";
class DisplayClass {
  log = {
    firstLoad: true,
    loggedBy: true,
    type: false,
    priority: true,
    data: true,
    resource: true,
    decision: true,
  };
  setLog = (log) => {
    this.log = log;
    localStorage.setItem(AppConfig.app_storage_prefix + "_display", JSON.stringify(this.log));
  };
  getLog = () => {
    if (this.log.firstLoad === true) {
      let temp = localStorage.getItem(AppConfig.app_storage_prefix + "_display");
      if (temp !== null) {
        this.log = JSON.parse(temp);
        this.log.firstLoad = false;
      }
    }
    return this.log;
  };
}
export default new DisplayClass();
