import React from "react";
import API from "../../actions/api";
import {Button, Checkbox, Divider, Form, Icon, Label, Loader, Message, Modal, TextArea} from "semantic-ui-react";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";
const InlineError = ({ text }) => (
    <span style={{ color: "#ae5b56" }}>{text}</span>
);

class SendMessage_SMSForm extends React.Component {
    state = {
        formSubmitting: false,
        formProcessing: false,
        data: {
            replyInbox: false,
            message: "",
        },
        quickTemplate: "",
        errors: {},
        smsError: null,
        smsRecipientErrors: [],
        smsDashboard: {creditBalance: 0, sendingAs: false}
    };
    componentDidMount() {
        API.sms.dashboard().then(res => {
            this.setState({
                smsDashboard: res
            })
        }).catch(err => {

        })
    }

    handleCheckboxChange(props, e) {
        this.setState({
            data: { ...this.state.data, [e.name]: e.checked },
        });
    }
    handleDropdownChange_QuickTemplates(props, e) {
        this.setState({
            quickTemplate: e.value,
        });
    }
    addInboxShortcode(){
        this.setState({
            data: { ...this.state.data, message: this.state.data.message + " #CODE# "},
        });
    }
    onChange = (e) => {
        this.setState({
            data: { ...this.state.data, [e.target.name]: e.target.value },
        });
    };
    onSubmit = () => {
        if (this.props.onOuterDisable !== undefined) {
            this.props.onOuterDisable(true);
        }
        this.setState(
            {
                formProcessing: true,
                errors: {},
                smsRecipientErrors: [],
            },
            () => {
                if (this.state.data.message.trim().length <= 0) {
                    if (this.props.onOuterDisable !== undefined) {
                        this.props.onOuterDisable(false);
                    }
                    this.setState({
                        formProcessing: false,
                        errors: {
                            message: "You must enter a message",
                        },
                    });
                    return;
                }
                if (this.props.recipients.length <= 0) {
                    if (this.props.onOuterDisable !== undefined) {
                        this.props.onOuterDisable(false);
                    }
                    this.setState({
                        formProcessing: false,
                        errors: {
                            recipients: "You must select at least one recipient",
                        },
                    });
                    return;
                }
                this.setState(
                    {
                        formSubmitting: true,
                    },
                    () => {
                        API.sms
                            .send(
                                {
                                    recipients: this.props.recipients,
                                    replyInbox: this.state.data.replyInbox,
                                    message: this.state.data.message,
                                },
                                (this.props.job !== undefined && this.props.job._id !== undefined ? this.props.job._id : null)
                            )
                            .then((res) => {
                                this.setState(
                                    {
                                        smsSuccess: true,
                                        data: {
                                            replyInbox: false,
                                            message: "",
                                        },
                                    },
                                    () => {
                                        this.props.handleRemoveRecipient(this.props.recipients);
                                    }
                                );
                            })
                            .catch((err) => {
                                let errorMessage =
                                        "There was an unexpected error while processing your request, please try again shortly.";
                                if (err.response !== undefined) {
                                    errorMessage = err.response.data.message;
                                }
                                if (errorMessage === "recipient_errors") {
                                    errorMessage =
                                        "There are some validation errors with the recipients, please view the errors and correct them before continuing.";
                                    this.setState({
                                        smsRecipientErrors: err.response.data.recipientErrors,
                                    });
                                }
                                this.setState({
                                    smsError: errorMessage,
                                });
                            })
                            .finally(() => {
                                if (this.props.onOuterDisable !== undefined) {
                                    this.props.onOuterDisable(false);
                                }
                                this.setState({
                                    formSubmitting: false,
                                    formProcessing: false,
                                });
                            });
                    }
                );
            }
        );
    };

    renderSmsRecipientErrors() {
        if (this.state.smsRecipientErrors.length !== 0) {
            return (
                <Message color={"red"}>
                    <Message.Header>Recipient Errors</Message.Header>
                    <ul>
                        {this.state.smsRecipientErrors.map((item) => {
                            return (
                                <li>
                                    {item.recipient.displayName}:- {item.error}
                                </li>
                            );
                        })}
                    </ul>
                </Message>
            );
        }
        return null;
    }
    renderSubmittingProcess() {
        if (this.state.smsSuccess) {
            return (
                <Modal open={true} onClose={() => this.setState({ smsSuccess: false })}>
                    <Modal.Header>Success</Modal.Header>
                    <Modal.Content>
                        <Message positive>
                            SMS successfully queued for delivery by SMS gateway, please
                            expect your message to arrive within the next few minutes. You
                            can review delivery reports in the "Sent Messages" screen.
                        </Message>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            color="green"
                            inverted
                            onClick={() => this.setState({ smsSuccess: false })}
                        >
                            Close
                        </Button>
                        {/*<Button color="green" inverted onClick={() => this.setState({ smsSuccess: false })}>Review Delivery Reports</Button>*/}
                    </Modal.Actions>
                </Modal>
            );
        }
        if (this.state.smsError !== null) {
            return (
                <Modal open={true} onClose={() => this.setState({ smsError: null })}>
                    <Modal.Header>Error</Modal.Header>
                    <Modal.Content>
                        <Message color={"red"}>{this.state.smsError}</Message>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            color="green"
                            inverted
                            onClick={() => this.setState({ smsError: null })}
                        >
                            Close
                        </Button>
                    </Modal.Actions>
                </Modal>
            );
        }
        return (
            <Modal
                open={this.state.formSubmitting}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                closeOnDocumentClick={false}
            >
                <Modal.Header>Please Wait</Modal.Header>
                <Modal.Content>
                    <Loader
                        active={true}
                        inline="centered"
                        indeterminate={false}
                        size={"large"}
                        content={"Sending SMS..."}
                    />
                </Modal.Content>
            </Modal>
        );
    }
    renderMessageSize() {
        let data = this.state.data;
        let messageLength = data.message.length;

        if (data.replyInbox) messageLength = messageLength + 4;
        let totalMessages = Math.ceil(messageLength / 160);
        let totalCharLimit = 160 * totalMessages;
        if (totalMessages > 1) totalCharLimit = 153 * totalMessages;

        return (
            <p>
                Total SMS Messages: <b>{totalMessages}</b> | Characters:{" "}
                <b>
                    {messageLength}/{totalCharLimit}
                </b>
            </p>
        );
    }
    renderSMSPreview() {
        let finalText = this.state.data.message;
        if ((!finalText.includes("###CODE###") && !finalText.includes("#CODE#")) && this.state.data.replyInbox) {
            finalText = "###CODE###: " + finalText;
        }
        finalText = finalText.replace(/###CODE###/g, "0000").replace(/#CODE#/g, "0000");
        return <p>{finalText}</p>;
    }
    render() {
        const { errors, data } = this.state;
        let disableCreateReplyInbox = false; //TODO
        return (
            <Form onSubmit={this.onSubmit} loading={this.state.formProcessing}>
                {this.renderSmsRecipientErrors()}
                {this.renderSubmittingProcess()}
                <Form.Field error={!!errors.recipients}>
                    <label htmlFor="recipient" style={{ marginBottom: 20 }}>
                        Recipients ({this.props.recipients.length})
                        {this.props.recipients.length >= 1 && (
                            <Button
                                negative={true}
                                size={"mini"}
                                floated={"right"}
                                style={{ marginBottom: 10 }}
                                onClick={() => {
                                    this.props.handleRemoveRecipient(this.props.recipients);
                                }}
                            >
                                Remove All
                            </Button>
                        )}
                        {/*<Button*/}
                        {/*    color={"green"}*/}
                        {/*    size={"mini"}*/}
                        {/*    floated={"right"}*/}
                        {/*    style={{ marginBottom: 10 }}*/}
                        {/*    onClick={e => {*/}
                        {/*        e.preventDefault();*/}
                        {/*        this.showAddManualModal();*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    Add Manual*/}
                        {/*</Button>*/}
                    </label>
                    <div className={"SMS_RecipientBox"}>
                        {this.props.recipients.map((recipient, i) => {
                            return (
                                <Label key={i}>
                                    {recipient.displayName}
                                    <Icon
                                        name="delete"
                                        onClick={() => this.props.handleRemoveRecipient(recipient)}
                                    />
                                </Label>
                            );
                        })}
                    </div>
                    {errors.recipients && <InlineError text={errors.recipients} />}
                </Form.Field>
                {/*    <Form.Field>*/}
                {/*        <label htmlFor="alertType">Quick Templates</label>*/}
                {/*        <Select name={"smsPrefix"} value={this.state.quickTemplate} onChange={this.handleDropdownChange_QuickTemplates.bind(this)}*/}
                {/*                options={[{key: 0, value: null, text: "[NO TEMPLATE]"}].concat(this.props.quickTemplates.map(item => {return {key: item._id, value: item._id, text: item.title}}))}/>*/}
                {/*        <Button size={"tiny"} compact floated={"right"} onClick={(e) => { e.preventDefault(); this.insertQuickTemplate(); }}>Insert</Button>*/}
                {/*    </Form.Field>*/}
                <Form.Field error={!!errors.message}>
                    <label htmlFor="message">Message</label>
                    <TextArea
                        name="message"
                        id="message"
                        placeholder="SMS message content"
                        style={{ minHeight: 120, marginBottom: 10 }}
                        value={data.message}
                        onChange={this.onChange}
                    />
                    {errors.message && <InlineError text={errors.message} />}
                    {this.renderMessageSize()}
                </Form.Field>
                <Form.Field>
                    <Checkbox
                        name={"replyInbox"}
                        label={"Create Reply Inbox"}
                        title={
                            "(Add ###CODE### to your message content to be replaced with the reply inbox code)"
                        }
                        checked={data.replyInbox}
                        disabled={disableCreateReplyInbox}
                        onChange={this.handleCheckboxChange.bind(this)}
                    />
                    {data.replyInbox && (<div className={"pt-1 pl-7 text-sm text-gray-500"}>If you like to set the location of the reply inbox code within your sms message, add the shortcode: <span className={"bold text-white cursor-pointer"} onClick={this.addInboxShortcode.bind(this)}>#CODE#</span></div>)}
                </Form.Field>
                <Divider />
                <h3>SMS Preview</h3>
                {this.renderSMSPreview()}
                <Divider />
                <Form.Group widths={"equal"} className={"form-actions"}>
                    <Form.Field>
                        <Button positive floated={"right"} basic>
                            Send SMS
                        </Button>
                    </Form.Field>
                </Form.Group>
                <div className={"p-2 text-xs text-gray-400"}>SMS Credits Available: <strong>{this.state.smsDashboard.creditBalance}</strong>{this.state.smsDashboard.sendingAs !== false && (<> | Sending From: ({this.state.smsDashboard.sendingAs.phoneNumber}) {this.state.smsDashboard.sendingAs.title}</>)}</div>
            </Form>
        );
    }
}

export default Job_Receiver(SendMessage_SMSForm, (job) => {
    return {
        job,
    };
});