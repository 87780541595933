import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container, Segment, Responsive } from "semantic-ui-react";
import clientList from "./clientList";
import addClient from "./addClient";
import clientPage from "./clientPage";

export default class ClientPages extends Component {
  state = {};
  componentDidMount = () => {};
  render() {
    return (
      <Container fluid style={{ padding: 0 }}>
        <Responsive as={Segment} basic className={"mainContent"} attached>
          <Switch>
            <Route exact path={``} component={clientList} />
            <Route exact path={`/clients/add`} component={addClient} />
            <Route path={`predefined/clients/:id`} component={clientPage} />
          </Switch>
        </Responsive>
      </Container>
    );
  }
}
