import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { SemanticToastContainer } from "react-semantic-toasts";
import { AuthenticationWrapper } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import { Toaster } from "react-hot-toast";
import AppConfig from "./_appConfig";
import FrontendUpdateChecker from "./lib/FrontendUpdateChecker";
import Navbar from "./components/common/Navbar2";
import FooterNav from "./components/common/footerNav";
import SettingsProvider from "./contexts/settings_context/Settings_Provider";
import api from "./actions/api";
import Home from "./components/home";
import TheMainLog from "./components/log";
import Staff from "./components/staff";
import Settings from "./components/settings";
import JobPages from "./components/jobs";
import PredifinedPages from "./components/predefined";
import addJob from "./components/jobs/addJob";
import theMap from "./components/theMap";
import jobManager from "./components/jobs/jobManager";
import SMS from "./components/sms";
import ArchivePages from "./components/archive";
import alerts from "./components/alerts";
import linksPage from "./components/links/linksPage";
import LiveFeeds from "./components/live-feeds";
import HasWebAccess from "./lib/HasWebAccess";

const App = () => {
  return (
    <AuthenticationWrapper
      config={{
        clientId: AppConfig.auth.clientId,
        loginServer: AppConfig.auth.loginServer,
        applicationRoot:
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port ? ":" + window.location.port : "") +
          "/",
        localPrefix: AppConfig.app_storage_prefix,
        applicationServer: AppConfig.api_server,
      }}
    >
        <HasWebAccess>
            <SemanticToastContainer position="top-right" />
            <Toaster position="top-right" />
            <FrontendUpdateChecker />
            <SettingsProvider api={api}>
                <div className="wrapper ">
                    <Suspense fallback={<div>Loading</div>}>
                        <Navbar />
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/map" component={theMap} />
                            <Route exact path="/alerts" component={alerts} />
                            <Route exact path="/jobs/manager" component={jobManager} />
                            <Route exact path="/jobs/add" component={addJob} />
                            <Route path="/jobs/:job_id" component={JobPages} />
                            <Route path="/predefined" component={PredifinedPages} />
                            <Route path="/settings" component={Settings} />
                            <Route path="/staff" component={Staff} />
                            <Route path="/archive" component={ArchivePages} />
                            <Route exact path="/links" component={linksPage} />
                            <Route exact path="/live-feeds" component={LiveFeeds} />
                            <Route path="/log" component={TheMainLog} />
                            <Route path="/sms" component={SMS} />
                        </Switch>
                        <FooterNav />
                    </Suspense>
                </div>
            </SettingsProvider>
        </HasWebAccess>
    </AuthenticationWrapper>
  );
};
export default App;