import React, { Component } from "react";
import { Message } from "semantic-ui-react";
import { FiAlertTriangle, FiAlertCircle, FiAlertOctagon } from "react-icons/fi";

export default class AlertBox extends Component {
  render() {
    let color = "blue";
    let icon = <FiAlertCircle size={"2.5rem"} />;
    if (this.props.priority) {
      switch (this.props.priority) {
        case "low":
          break;
        case "medium":
          color = "orange";
          icon = <FiAlertOctagon size={"2.5rem"} />;
          break;
        case "high":
          color = "red";
          icon = <FiAlertTriangle size={"2.5rem"} />;
          break;
        default:
          color = "blue";
          icon = <FiAlertCircle size={"2.5rem"} />;
          break;
      }
    }

    return <Message className={"clickable alerts"} color={color} onClick={this.props.action} icon={icon} header={this.props.header} content={this.props.content} />;
  }
}
