import React from "react";

import {
  List,
  Segment,
  Button,
  Input,
  Form,
  Item,
  Header,
  Divider,
  Icon,
  Table,
} from "semantic-ui-react";
import Box from "../box";

//TODO make this ordered
export default class SettingListLinks extends React.Component {
  state = {
    data: {
      content: "",
      label: "",
    },
    formData: [],
    isEdit: false,
  };
  componentDidMount() {
    if (this.props.defaultXtra) {
      this.setState({
        label: this.props.defaultXtra,
      });
    }
  }
  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };
  handleSubmit = () => {
    if (this.state.data._id) {
      let fd = this.props.data.map((item) => {
        if (item._id === this.state.data._id) {
          return {
            ...item,
            content: this.state.data.content,
            label: this.state.data.label,
          };
        }
        return item;
      });
      let field = this.props.field;
      const data = {};
      data[field] = fd;
      this.props.submit(data).then(() => {
        this.setState({
          isEdit: false,
          data: {
            content: "",
            label: "",
          },
        });
      });
    } else {
      let fd = this.props.data;
      fd.push({
        content: this.state.data.content,
        label: this.state.data.label,
      });
      this.setState({
        isEdit: false,
        data: {
          content: "",
          label: "",
        },
      });
      let field = this.props.field;
      const data = {};
      data[field] = fd;
      this.props.submit(data);
    }
  };
  handleDelete = (obj) => {
    let newList = this.props.data.filter((item, i) => {
      return obj !== item;
    });
    this.setState({
      formData: newList,
    });
    let field = this.props.field;
    const data = {};
    data[field] = newList;
    this.props.delete(data);
  };
  render() {
    const form = (
      <Segment basic className="no-marg">
        <Form onSubmit={this.handleSubmit}>
          <Form.Group>
            <Form.Field width={10}>
              <Input
                fluid
                name="content"
                onChange={this.handleChange}
                value={this.state.data.content}
                placeholder={this.props.placeholder}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Input
                name="label"
                onChange={this.handleChange}
                value={this.state.data.label}
                placeholder={this.props.placeholderLabel}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Button icon basic color="green">
                {this.state.isEdit ? (
                  <Icon name="check" color="blue" />
                ) : (
                  <Icon name="plus" color="green" />
                )}
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
    let list;
    if (this.props.data) {
      list = this.props.data.map((item, i) => {
        return (
          <Table.Row key={i} className="listSpace">
            <Table.Cell>{item.content}</Table.Cell>
            <Table.Cell>{item.label}</Table.Cell>
            <Table.Cell width={2}>
              <Button
                circular
                icon="close"
                floated="right"
                size="tiny"
                color="red"
                onClick={() => this.handleDelete(item)}
              />
              <Button
                circular
                icon="pencil"
                floated="right"
                size="tiny"
                color="blue"
                onClick={() => this.setState({ data: item, isEdit: true })}
              />
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <Box title="External Links">
        {form}

        <Table celled className="no-marg">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Link</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{list}</Table.Body>
        </Table>
      </Box>
    );
  }
}
