import React, { Component } from "react";
import {
  Grid,
  Header,
  Segment,
  Popup,
  Button,
  Table,
  Divider,
  Form,
  Input,
  Checkbox,
  Menu,
  Icon,
} from "semantic-ui-react";
import LocationMap from "../../common/mapping/taskMap";
import SettingsList from "../../common/lists/settingList";

import api from "../../../actions/api";
import moment from "moment";
import CommonFunctions from "../../../assets/utils/commonFunctions";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import Settings_Receiver from "../../../contexts/settings_context/Settings_Receiver";
import Box from "../../common/box";

class LocationPage extends Component {
  state = {
    activeItem: "jobs",
    jobs: [],
    loading: true,
    errors: {},
    data: {
      geoJSON: {
        properties: {
          name: "",
          address: "",
        },
      },
    },

    grid: "",
    coords: null,
    flyTo: {},
    location: {
      departments: [],
      // geoJSON: {
      //   name: "",
      //   address: ""
      // }
    },
    tasks: [],
    edit: false,
    clients: {
      formList: [],
    },
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    api.locations.show(this.props.match.params.id).then((res) => {
      this.setState({
        location: {
          ...this.state.location,
          ...res,
        },
        data: res,
        loading: false,
      });
      // }
    });
    api.client.all().then((clients) => {
      this.setState({
        clients,
      });
    });
  };
  getGrid = (lat, lng) => {
    return CommonFunctions.getGrid(
      lat,
      lng,
      this.props.settings.country.gridType
    );
  };
  getLatLng = (grid) => {
    return CommonFunctions.getLatLng(
      grid,
      this.props.settings.country.gridType
    );
  };
  getLatLngEN = (e, n) => {
    return CommonFunctions.getLatLngEN(e, n);
  };
  getLatLngUTM = (data) => {
    return CommonFunctions.getLatLngUTM(data);
  };

  findUTM = () => {
    const errors = this.validateGrid(this.state.grid);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.getLatLngUTM(this.state.grid);
      let geoJSON = {
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [data.longitude, data.latitude],
          },
          properties: {
            name: data.grid,
            grid: data.grid,
          },
        },
      };
      this.setState({
        marker: geoJSON,
        grid: "",
      });
    }
  };
  findGrid = () => {
    const errors = this.validateGrid(this.state.grid);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.getLatLng(this.state.grid);
      this.setState({
        coords: data,
        grid: "",
      });
    }
  };
  handleDropdownChange(props, e) {
    this.setState({
      location: {
        ...this.state.location,
        geoJSON: {
          ...this.state.location.geoJSON,
          properties: {
            ...this.state.location.geoJSON.properties,
            [e.name]: e.value,
          },
        },
      },
    });
  }
  handleClientDropdownChange = (props, e) => {
    this.setState({
      location: {
        ...this.state.location,
        client: e.value,
      },
    });
  };
  onChange = (e) => {
    this.setState({
      location: {
        ...this.state.location,
        geoJSON: {
          ...this.state.location.geoJSON,
          properties: {
            ...this.state.location.geoJSON.properties,
            [e.target.name]: e.target.value,
          },
        },
      },
    });
  };
  validateGrid = (data) => {
    const errors = {};
    //TODO Tim can you check the first two characters are text and the reaming digits are even in number
    if (!data) errors.grid = true;
    return errors;
  };
  handleGridChange = (e) => {
    this.setState({
      grid: e.target.value.toUpperCase(),
    });
  };
  handleCheckboxChange = (props, e) => {
    this.setState({
      location: { ...this.state.location, [e.name]: e.checked },
    });
  };
  handleSubmit = (data) => {
    return api.locations.update(this.props.match.params.id, data).then(() => {
      this.getData();
    });
  };
  handleDelete = (data) => {
    return api.locations.update(this.props.match.params.id, data).then(() => {
      this.getData();
    });
  };

  goto = (type = "event", id) => {
    if (type === "event") {
      this.props.history.push(`/calendar/${id}`);
    } else if (type === "course") {
      this.props.history.push(`/training/courses/${id}`);
    }
  };
  addPoint = (data) => {
    if (data) {
      return api.locations
        .updateCoords(this.props.match.params.id, data.geoJSON)
        .then(() => {
          this.getData();
        });
    }
  };
  onSubmit = () => {
    let data = this.state.location;
    if (data) {
      api.locations.update(this.props.match.params.id, data).then(() => {
        this.setState({
          edit: false,
        });
        this.getData();
      });
    }
  };
  resetForm = () => {
    this.setState({
      data: this.state.location,
      edit: false,
    });
  };

  handleData = (data) => {
    if (this.state.edit) {
      if (this.state.location.geoJSON.geometry.type === "Point") {
        this.setState({
          location: {
            ...this.state.location,
            geoJSON: {
              ...this.state.location.geoJSON,
              geometry: {
                ...this.state.location.geoJSON.geometry,
                coordinates: data,
              },
              properties: {
                ...this.state.location.geoJSON.properties,
                grid: this.getGrid(data[1], data[0]),
                // name: this.getGrid(data[1], data[0])
              },
            },
          },
        });
      } else {
        let newData = this.createSector(data);
        if (newData) {
          this.setState({
            location: {
              ...this.state.location,
              geoJSON: {
                ...this.state.location.geoJSON,
                geometry: newData.geometry,
                properties: {
                  ...this.state.location.geoJSON.properties,
                  grid: this.getGrid(data[1], data[0]),
                  // name: this.getGrid(data[1], data[0])
                },
              },
            },
          });
        }
      }
    }
  };
  renderMap() {
    let center, marker, mast;
    let disabled = false;
    if (this.state.location && this.state.location.geoJSON) {
      if (this.state.location.geoJSON.geometry.type === "Point") {
        marker = this.state.location;
        mast = null;
        center = this.state.location.geoJSON.geometry.coordinates;
      }
      return (
        <Box title={this.state.location.geoJSON.properties.name}>
          <LocationMap
            center={center}
            location={marker}
            data={this.handleData}
            mast={mast}
            flyTo={this.state.flyTo}
            readOnly={disabled}
            edit={true}
            country={this.props.settings.country}
          />
        </Box>
      );
    }
  }
  renderLocation() {
    const { activeItem } = this.state;
    let loc = this.state.location;
    if (loc && loc.geoJSON) {
      let properties = loc.geoJSON.properties;
      return (
        <Segment basic className="no-pad">
          <Box title={properties.name}>
            <Grid celled textAlign="center" className=" dataGrid  no-marg">
              <Grid.Row>
                <Grid.Column width={16} className=" no-pad">
                  <Menu widths={2}>
                    {!loc.hide && (
                      <Popup
                        basic
                        trigger={
                          <Menu.Item onClick={this.createGPX}>
                            <Icon name="plus" color="green" />
                            Add Job
                          </Menu.Item>
                        }
                        content="Add a job"
                      />
                    )}
                    {Authentication.hasScope("locations:update") && (
                      <Popup
                        basic
                        trigger={
                          <Menu.Item
                            onClick={() => this.setState({ edit: true })}
                          >
                            <Icon name="pencil" />
                            Edit
                          </Menu.Item>
                        }
                        content="Edit location"
                      />
                    )}
                  </Menu>
                </Grid.Column>
              </Grid.Row>
              {loc.hide && (
                <Grid.Row>
                  <Grid.Column className="data" width={16}>
                    <Header color="red">** Location Archived **</Header>
                  </Grid.Column>
                </Grid.Row>
              )}
              {loc.client && (
                <Grid.Row>
                  <Grid.Column className="data" width={16}>
                    <Header>Client</Header>
                    <p>{loc.client.name}</p>
                  </Grid.Column>
                </Grid.Row>
              )}
              {properties.address && (
                <Grid.Row>
                  <Grid.Column className="data" width={16}>
                    <Header>Address</Header>
                    <p>{properties.address}</p>
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Column className="data" width={8}>
                  <Header>Grid</Header>
                  <p>{properties.grid}</p>
                </Grid.Column>

                <Grid.Column className="data" width={8}>
                  <Header>What3Words</Header>
                  <p>{properties.w3w}</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid celled className=" dataGrid  no-marg">
              <Grid.Row>
                <Grid.Column>
                  <Menu widths={2}>
                    <Menu.Item
                      active={activeItem === "jobs"}
                      onClick={() => this.setState({ activeItem: "jobs" })}
                    >
                      <Icon name="wrench" />
                      Active Jobs
                    </Menu.Item>
                    <Menu.Item
                      active={activeItem === "depts"}
                      onClick={() => this.setState({ activeItem: "depts" })}
                    >
                      <Icon name="building" />
                      Departments
                    </Menu.Item>
                  </Menu>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  {activeItem === "jobs" && this.renderJobs()}
                  {activeItem === "depts" && this.renderDepts()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Box>
        </Segment>
      );
    }
  }
  renderEditLocation() {
    return (
      <Segment>
        <Form onSubmit={this.onSubmit}>
          <Form.Field>
            <label>Client</label>
            <Form.Dropdown
              name="client"
              selection
              value={
                this.state.location.client ? this.state.location.client._id : ""
              }
              options={this.state.clients.formList}
              onChange={this.handleClientDropdownChange}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="name">Name</label>
            <Input
              name="name"
              placeholder="name"
              value={this.state.location.geoJSON.properties.name}
              onChange={this.onChange}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="address">Address</label>
            <Input
              name="address"
              placeholder="address"
              value={
                this.state.location.geoJSON.properties.address
                  ? this.state.location.geoJSON.properties.address
                  : ""
              }
              onChange={this.onChange}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="hide">Location Visability</label>
            <Checkbox
              style={{ padding: 10 }}
              toggle
              id="hide"
              name="hide"
              checked={this.state.location.hide}
              label={
                this.state.location.hide ? "Un-Hide location" : "Hide location."
              }
              onChange={this.handleCheckboxChange}
            />
          </Form.Field>

          <Divider />
          <Form.Group widths={"equal"} className={"form-actions"}>
            <Form.Field>
              <Button negative floated={"left"} onClick={this.resetForm}>
                Cancel
              </Button>
            </Form.Field>
            <Form.Field>
              <Button positive floated={"right"}>
                Update
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
  renderDepts() {
    return (
      <Segment basic className="no-pad">
        <SettingsList
          submit={this.handleSubmit}
          delete={this.handleDelete}
          data={this.state.location.departments}
          placeholder="A&E, Post Room...."
          title="Departments"
          field="departments"
        />
      </Segment>
    );
  }
  renderJobs() {
    const events = this.state.jobs.map((event) => {
      let row = "";
      if (event.hide) {
        row = "hidden";
      }
      return (
        <Table.Row
          key={event._id}
          className={row}
          onClick={() => this.goto(event.class, event._id)}
        >
          <Table.Cell>
            {moment(event.start).format("DD-MM-YY HH:mm")}
          </Table.Cell>
          <Table.Cell>{moment(event.end).format("DD-MM-YY HH:mm")}</Table.Cell>
          <Table.Cell>
            {event.class === "course" ? event.type : event.details}
          </Table.Cell>
          <Table.Cell>
            {event.class === "course"
              ? event.organiserName
              : event.organiser.name}
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table stackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Start</Table.HeaderCell>
            <Table.HeaderCell>End</Table.HeaderCell>
            <Table.HeaderCell>Details</Table.HeaderCell>
            <Table.HeaderCell>Organiser</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{events}</Table.Body>
      </Table>
    );
  }
  render() {
    return (
      // <Segment basic loading={this.state.loading}>
      <Grid stackable className="no-marg no-pad">
        <Grid.Row>
          <Grid.Column width={8}>
            {this.state.edit
              ? this.renderEditLocation()
              : this.renderLocation()}
          </Grid.Column>
          <Grid.Column width={8}>{this.renderMap()}</Grid.Column>
        </Grid.Row>
      </Grid>
      // </Segment>
    );
  }
}
export default Settings_Receiver(LocationPage, (settings) => {
  return {
    settings,
  };
});
