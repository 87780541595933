import React from "react";

export default function Tooltip({ text, style, top = true }) {
  return (
    <span
      className={`tooltip rounded shadow-lg p-2 bg-gray-600 text-gray-200 ${
        top ? "-mt-12" : "mt-12"
      } ${style}`}
    >
      {text}
    </span>
  );
}
