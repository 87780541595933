import React from "react";
import { NavLink } from "react-router-dom";
import { Menu, Header, Dropdown, Icon } from "semantic-ui-react";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";
class JobNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: "",
        };
    }
    handleItemClick = () => { };
    render() {
        const { activeItem } = this.state;
        return (
            <Menu attached="top" stackable className={"settings-menu"}>
                <Menu.Item>
                    <Header as={'h3'} className={"primary"}>
                        SMS {this.props.job !== undefined && this.props.job._id !== undefined && "for (" + this.props.job.jobNum + ")"}
                    </Header>
                </Menu.Item>
                <Menu.Item name={"inbox"} as={NavLink} to={this.props.match.url + ""} active={activeItem === "inbox"} exact onClick={this.handleItemClick}>
                    Inbox
                </Menu.Item>
                {/* <Menu.Item name={"dash"} as={NavLink} to={this.props.match.url} active={activeItem === "dash"} exact onClick={this.handleItemClick}>
                    Dashboard
                </Menu.Item> */}
                <Menu.Item name={"send"} as={NavLink} to={this.props.match.url + "/send"} active={activeItem === "send"} exact onClick={this.handleItemClick}>
                    Send
                </Menu.Item>
                <Menu.Item name={"sent"} as={NavLink} to={this.props.match.url + "/sent"} active={activeItem === "sent"} exact onClick={this.handleItemClick}>
                    Sent Messages
                </Menu.Item>

            </Menu>
        );
    }
}
export default Job_Receiver(JobNav, (job) => {
    return {
        job,
    };
});
