import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import LocationList from "../common/lists/locationList";
import Box from "../common/box";

import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import api from "../../actions/api";

class ArchivedLocations extends Component {
  state = {
    addLoc: false,
    locations: [],
    clients: [],
    settings: {},
  };
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.locations.archived().then((locations) => {
      this.setState({
        locations: locations.locations,
        locs: locations,
      });
    });
    api.settings.formLists().then((settings) => {
      this.setState({
        settings,
      });
    });
    api.client.all().then((clients) => {
      this.setState({
        clients,
      });
    });
  };
  goto = (id) => {
    this.props.history.push(`/predefined/locations/${id}`);
  };

  render() {
    return (
      <Segment basic>
        <Box title="Archived locations">
          <LocationList data={this.state.locations} action={this.goto} />
        </Box>
      </Segment>
    );
  }
}
export default Settings_Receiver(ArchivedLocations, (settings) => {
  return {
    settings,
  };
});
