import React, { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";

class TheLoader extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    label: PropTypes.string
  };
  render() {
    return (
      <Dimmer active={this.props.loading}>
        <Loader indeterminate>
          {this.props.label ? this.props.label : "Loading hopefully...."}
        </Loader>
      </Dimmer>
    );
  }
}
export default TheLoader;
