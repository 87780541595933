import { APIRequest } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import AppConfig from "../_appConfig";
import axios from "axios";
const server = AppConfig.api_server;
const weatherAPI = "https://api.weatherapi.com/v1";
const weatherKey = "38474fa8811d4090a8a202417202604";
const EAServer = "https://environment.data.gov.uk/flood-monitoring/id/";

export default {
  _getBuildNumber: () => APIRequest.get(server + `/_buildNumber`).then((res) => res.data),
  settings: {
    setMapCenter: (data) =>
      APIRequest.patch(server + `/settings/system/mapdefault`, data).then(
        (res) => res.data
      ),
    all: () =>
      APIRequest.get(server + `/settings/system`).then((res) => res.data),
    formLists: () =>
      APIRequest.get(server + `/settings/system/form`).then((res) => res.data),
    update: (data) =>
      APIRequest.patch(server + `/settings/system`, data).then(
        (res) => res.data
      ),
  },
  jobs: {
    all: () => APIRequest.get(server + `/jobs`).then((res) => res.data),
    closed: () => APIRequest.get(server + `/jobs/closed`).then((res) => res.data),
    exportClosedDebriefs: (fromDate, toDate) => APIRequest.get(server + `/jobs/closed/debrief_export?from=${fromDate}&to=${toDate}`).then((res) => res.data),
    mainDash: () =>
      APIRequest.get(server + `/logs/dash`).then((res) => res.data),
    mainOutstanding: () =>
      APIRequest.get(server + `/logs/outstanding`).then((res) => res.data),
    dash: (id) =>
      APIRequest.get(server + `/logs/${id}/dash`).then((res) => res.data),
    active: (id) =>
      APIRequest.get(server + `/jobs/active`).then((res) => res.data),
    show: (id) =>
      APIRequest.get(server + `/jobs/${id}`).then((res) => res.data),
    add: (data) =>
      APIRequest.post(server + `/jobs`, data).then((res) => res.data),
    update: (id, data) =>
      APIRequest.patch(server + `/jobs/${id}`, data).then((res) => res.data),
    close: (id, data) => APIRequest.patch(server + `/jobs/${id}/close`, data).then((res) => res.data),
    open: (id, data) =>
      APIRequest.patch(server + `/jobs/${id}/open`, data).then(
        (res) => res.data
      ),
    assign: (id, data) =>
      APIRequest.post(server + `/jobs/${id}/assign`, data).then(
        (res) => res.data
      ),
    unassign: (id, data) =>
      APIRequest.post(server + `/jobs/${id}/unassign`, data).then(
        (res) => res.data
      ),
    merge: (data) =>
      APIRequest.post(server + `/jobs/merge`, data).then((res) => res.data),
    addDebrief: (id, data) =>
      APIRequest.post(server + `/jobs/${id}/debrief`, data).then(
        (res) => res.data
      ),
    editDebrief: (id, data) =>
      APIRequest.patch(server + `/jobs/${id}/debrief/${data._id}`, data).then(
        (res) => res.data
      ),
    getResources: (id) =>
      APIRequest.get(server + `/jobs/${id}/resources`).then((res) => res.data),
    setResourceStatus: (id, rid, data) =>
      APIRequest.patch(server + `/jobs/${id}/resource/${rid}`, data).then(
        (res) => res.data
      ),
    getSMSResources: (job_id) =>
      APIRequest.get(server + `/jobs/${job_id}/resources/sms_responses`).then(
        (res) => res.data
      ),
    print: (job_id) =>
      APIRequest.post(
        server + `/jobs/${job_id}/print`,
        {},
        {
          responseType: "blob",
        }
      ).then((res) => res.data),
    weather: {
      current: (lat, lng) =>
        fetch(
          weatherAPI + `/current.json?key=` + weatherKey + `&q=${lat},${lng}`
        )
          .then((response) => response.json())
          .then((data) => data),
      forcast: (lat, lng) =>
        fetch(
          weatherAPI +
            `/forecast.json?key=` +
            weatherKey +
            `&q=${lat},${lng}&days=2`
        )
          .then((response) => response.json())
          .then((data) => data),
    },
  },
  locations: {
    all: () => APIRequest.get(server + `/locations`).then((res) => res.data),
    archived: () =>
      APIRequest.get(server + `/locations/archive`).then((res) => res.data),
    show: (id) =>
      APIRequest.get(server + `/locations/${id}`).then((res) => res.data),
    depts: (id) =>
      APIRequest.get(server + `/locations/${id}/depts`).then((res) => res.data),
    add: (data) =>
      APIRequest.post(server + `/locations`, data).then((res) => res.data),
    update: (id, data) =>
      APIRequest.patch(server + `/locations/${id}`, data).then(
        (res) => res.data
      ),
  },
  logs: {
    all: () => APIRequest.get(server + `/logs`).then((res) => res.data),
    archived: () =>
      APIRequest.get(server + `/logs/archived`).then((res) => res.data),
    job: (id) => APIRequest.get(server + `/logs/${id}`).then((res) => res.data),
    add: (data) =>
      APIRequest.post(server + `/logs`, data).then((res) => res.data),
    getLogAttachment: (id) =>
      APIRequest.get(server + `/logs/${id}/attachment`).then((res) => res.data),
  },
  staff: {
    all: () => APIRequest.get(server + "/staff").then((res) => res.data),
    //testing for app
    yourDetails: () => APIRequest.get(server + "/staff/my_details").then((res) => res.data),
    myJobs: () => APIRequest.get(server + "/jobs/myjobs").then((res) => res.data),
    status: (jid, rid) => APIRequest.get(server + `/jobs/${jid}/resource/${rid}`).then((res) => res.data),
    //end of testing apis
    oncall: () => APIRequest.get(server + "/staff/oncall").then((res) => res.data),
    dash: () => APIRequest.get(server + "/staff/dash").then((res) => res.data),
    show: (id) => APIRequest.get(server + `/staff/${id}`).then((res) => res.data),
    add: (data) => APIRequest.post(server + "/staff", data).then((res) => res.data),
    update: (id, data) => APIRequest.patch(server + `/staff/${id}`, data).then((res) => res.data),
    updateLocation: (id, data) => APIRequest.patch(server + `/staff/${id}/location`, data).then((res) => res.data),
    roles: () => APIRequest.get(server + `/staff/roles`).then((res) => res.data),
    forceSync: () => APIRequest.get(server + "/staff/force_sync").then(res => res.data)
  },
  rota: {
    all: () => APIRequest.get(server + "/rota").then((res) => res.data),
    current: () =>
      APIRequest.get(server + "/rota/current").then((res) => res.data),
    show: (id) =>
      APIRequest.get(server + `/rota/${id}`).then((res) => res.data),
    add: (data) =>
      APIRequest.post(server + "/rota", data).then((res) => res.data),
    add2rota: (id, data) =>
      APIRequest.post(server + `/rota/${id}/add2rota`, data).then(
        (res) => res.data
      ),
    removeFromrota: (id, data) =>
      APIRequest.post(server + `/rota/${id}/removefrom`, data).then(
        (res) => res.data
      ),
    update: (id, data) =>
      APIRequest.patch(server + `/rota/${id}`, data).then((res) => res.data),
    deleteRota: (id) =>
      APIRequest.delete(server + `/rota/${id}`).then((res) => res.data),
  },
  client: {
    all: () => APIRequest.get(server + "/client").then((res) => res.data),
    archived: () =>
      APIRequest.get(server + "/client/archive").then((res) => res.data),
    show: (id) =>
      APIRequest.get(server + `/client/${id}`).then((res) => res.data),
    jobs: (id) =>
      APIRequest.get(server + `/client/${id}/jobs`).then((res) => res.data),
    add: (data) =>
      APIRequest.post(server + "/client", data).then((res) => res.data),
    update: (id, data) =>
      APIRequest.patch(server + `/client/${id}`, data).then((res) => res.data),
  },
  sarmap: {
    assets: () =>
      APIRequest.get(server + `/sarmap/assets`).then((res) => res.data),
  },
  alerts: {
    all: () => APIRequest.get(server + `/alerts`).then((res) => res.data),
    job: (id) => APIRequest.get(server + `/alerts/job/${id}`).then((res) => res.data),
  },
  sms: {
    dashboard: (job_id = null) =>
      APIRequest.get(
        server + `/sms/dashboard` + (job_id !== null ? "?job_id=" + job_id : "")
      ).then((res) => res.data),
    send: (data, job_id = null) =>
      APIRequest.post(
        server + `/sms/send` + (job_id !== null ? "?job_id=" + job_id : ""),
        data
      ).then((res) => res.data),
    sentMessages: {
      all: (job_id = null, isInbox = false) =>
        APIRequest.get(
          server + `/sms/sent?isInbox=${isInbox}` + (job_id !== null ? "&job_id=" + job_id : "")
        ).then((res) => res.data),
      show: (sent_id, job_id = null) =>
        APIRequest.get(
          server +
            `/sms/sent/${sent_id}` +
            (job_id !== null ? "?job_id=" + job_id : "")
        ).then((res) => res.data),
      messages: (sent_id, job_id = null) =>
        APIRequest.get(
          server +
            `/sms/sent/${sent_id}/messages` +
            (job_id !== null ? "?job_id=" + job_id : "")
        ).then((res) => res.data),
    },
  },
  flood: {
    alertsC: (county) => axios.get(EAServer + `floods?county=${county}`).then(res => res.data),
  },
  live_feeds: {
      getAssets: () => APIRequest.get(server + `/live_feeds/assets`).then(res => res.data),
      getAsset: (asset_id) => APIRequest.get(server + `/live_feeds/assets/${asset_id}`).then(res => res.data),
      getAssetFeeds: (asset_id) => APIRequest.get(server + `/live_feeds/assets/${asset_id}/feeds`).then(res => res.data),
      isFeedLive: (asset_id, feed_id) => APIRequest.get(server + `/live_feeds/assets/${asset_id}/feeds/${feed_id}/is_live`).then(res => res.data),
      getActiveCentralUserFeeds: () => APIRequest.get(server + `/live_feeds/central_users/active`).then(res => res.data),
  },
};
