import React from "react";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";

class Dashboard extends React.Component {
    render(){
     
        return (
            <p>Dashboard area coming soon....</p>
        );
    }
}
export default Job_Receiver(Dashboard, (job) => {
    return {
        job,
    };
});