import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  XIcon,
  InboxInIcon,
  ChatIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { NavLink, withRouter } from "react-router-dom";
import api from "../../actions/api";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import Logo from "../../assets/images/cad.png";
import Tooltip from "./ui/Tooltip";

function Navbar2({ match, history }){
  const [alerts, setAlerts] = useState(0);
  const [floodAlerts, setFloodAlerts] = useState(0);

  const getData = useCallback(() => {
    // Get all job alerts
    api.alerts.all().then(data => setAlerts(data.length));

    // Get all active flood alerts
    let user = Authentication.getUserData();
    if (user.organisation.counties){
      api.flood.alertsC(user.organisation.counties).then(floodAlerts => {
        setFloodAlerts(floodAlerts.items.filter(f => f.severityLevel !== 4).length);
      });
    }
  }, [])

  useEffect(() => {
    getData();

    const interVal = setInterval(() => getData(), 30 * 1000); // Run this every 30 seconds
    return () => {
      clearInterval(interVal);
    };
  }, [getData]);

  const solutions = [
    {
      name: "Dashboard",
      description: "Incident Dashboard",
      href: `${match.url}`,
    },
    {
      name: "The Log",
      description: "Incident locations",
      href: `/log`,
    },
    {
      name: "Map",
      description: "Master Map",
      href: `/map`,
    },
    {
      name: "Job Manager",
      description: "Job manager",
      href: `/jobs/manager`,
    },
    {
      name: "Global SMS",
      description: "SMS section",
      href: `/sms`,
    },
  ];
  const rightMenu = () => {
    let color = "bg-green-500";
    let fcolor = "bg-green-500";

    if (alerts > 0) {
      color = "bg-red-500";
    }
    if (floodAlerts > 0) {
      fcolor = "bg-blue-500";
    }

    return (
      <div className="p-2 flex gap-6 justify-between">
        <div onClick={() => history.push("/alerts")} className="flex gap-4 items-center text-lg cursor-pointer ">
          Alerts:
          <span className={`has-tooltip px-2 rounded-md font-bold text-xl text-white ${color}`}>
            <Tooltip text="Job Alerts" top={false} style={"bg-red-800 border-2 border-red-400"} />
            {alerts}
          </span>
          <span className={`has-tooltip px-2 rounded-md font-bold text-xl text-white ${fcolor}`}>
            <Tooltip text="Flood Alerts" top={false} style={"bg-blue-800 border-2 border-blue-400"} />
            {floodAlerts}
          </span>
        </div>
        <button onClick={() => history.push("/jobs/add")} className="py-1 px-2 bg-green-700 rounded-lg text-white flex items-center font-bold tracking-wider">
          <PlusIcon className="h-5 mr-2 text-green-300" />
          Add Job
        </button>
      </div>
    );
  };
  return (
    <Disclosure as="nav" className="bg-gray-900">
      {({ open }) => (
        <>
          <div className=" mx-auto py-2 px-2">
            <div className="flex items-center justify-between h-16">
              <div className="flex  items-center">
                <div className="hidden md:block">
                  <div className=" pt-2 flex items-center justify-center text-center space-x-4 text-xl">
                    <div className="hidden lg:flex  flex-col px-2 py-2 justify-center items-center ">
                      <img className="h-14 w-auto " src={Logo} alt="TeamSite" />
                    </div>
                    <NavLink
                      exact
                      to={`/`}
                      activeClassName="bg-gray-800 "
                      className="text-white px-2 py-2 rounded-md 
                       font-medium font-mono hover:bg-gray-800 hover:text-gray-200"
                    >
                      Dash
                    </NavLink>
                    <NavLink
                      to={`/log`}
                      activeClassName="bg-gray-800 "
                      className="text-white px-2 py-2 rounded-md  font-medium font-mono hover:bg-gray-800 hover:text-gray-200"
                    >
                      The Log
                    </NavLink>
                    <NavLink
                      to={`/map`}
                      activeClassName="bg-gray-800 "
                      className="text-white px-2 py-2 rounded-md  font-medium font-mono hover:bg-gray-800 hover:text-gray-200"
                    >
                      Map
                    </NavLink>
                    <NavLink
                      exact
                      to={`/jobs/manager`}
                      activeClassName="bg-gray-800 "
                      className="text-white px-2 py-2 rounded-md  font-medium font-mono hover:bg-gray-800 hover:text-gray-200"
                    >
                      Job Manager
                    </NavLink>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="text-xl inline-flex justify-center w-full px-4 py-2 font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-gray-800 hover:text-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                          Global SMS
                          <ChevronDownIcon
                            className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="z-50 absolute right-0 w-56 mt-2 origin-top-right bg-gray-800 divide-y divide-gray-700 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="text-center p-2">SMS Menu</div>
                          <div className="px-1 py-1 ">
                            <Menu.Item>
                              {({ active }) => (
                                <NavLink
                                  to={`/sms/send`}
                                  className={`${
                                    active
                                      ? "bg-violet-500 text-gray-400"
                                      : "text-white"
                                  } group flex rounded-md items-center w-full px-2 py-2 text-md`}
                                >
                                  {active ? (
                                    <ChatIcon
                                      className="w-5 h-5 mr-2"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <ChatIcon
                                      className="w-5 h-5 mr-2"
                                      aria-hidden="true"
                                    />
                                  )}
                                  Send
                                </NavLink>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <NavLink
                                  to={`/sms/inbox`}
                                  className={`${
                                    active
                                      ? "bg-violet-500 text-gray-400"
                                      : "text-white"
                                  } group flex rounded-md items-center w-full px-2 py-2 text-md`}
                                >
                                  {active ? (
                                    <InboxInIcon
                                      className="w-5 h-5 mr-2"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <InboxInIcon
                                      className="w-5 h-5 mr-2"
                                      aria-hidden="true"
                                    />
                                  )}
                                  Inbox
                                </NavLink>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <NavLink
                                  to={`/sms/sent`}
                                  className={`${
                                    active
                                      ? "bg-violet-500 text-gray-400"
                                      : "text-white"
                                  } group flex rounded-md items-center w-full px-2 py-2 text-md`}
                                >
                                  {active ? (
                                    <InboxInIcon
                                      className="w-5 h-5 mr-2"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <InboxInIcon
                                      className="w-5 h-5 mr-2"
                                      aria-hidden="true"
                                    />
                                  )}
                                  Sent Messages
                                </NavLink>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>

              <div className=" hidden sm:block antialiased  ml-auto px-4 py-2 justify-center items-center text-gray-400 tracking-wide">
                {rightMenu()}
              </div>
              <div className="-mr-2 w-full flex justify-between md:hidden">
                {/* Mobile menu button */}
                <img className="h-12 w-auto " src={Logo} alt="TeamSite" />

                <Disclosure.Button className="bg-gray-900 inline-flex items-center justify-between p-4 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <div className="bg-gray-900 ">Menu</div>
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {solutions.map((item, itemIdx) => (
                <Fragment key={itemIdx}>
                  <NavLink
                    exact
                    to={item.href}
                    activeClassName="bg-gray-900 "
                    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base text-xl border-b border-gray-800  font-medium"
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </NavLink>
                </Fragment>
              ))}
              <div className="p-2 text-center">{rightMenu()}</div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
export default withRouter(Navbar2);