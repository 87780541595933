import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import moment from "moment";
export default class LocationList extends Component {
  renderDate = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YY HH:mm");
    } else {
      return "--";
    }
  };

  renderData() {
    return this.props.data.map((l) => {
      let loc = l.geoJSON.properties;
      return (
        <Table.Row key={l._id} onClick={() => this.props.action(l._id)}>
          <Table.Cell width={3}>{loc.name}</Table.Cell>
          <Table.Cell>{loc.town}</Table.Cell>
          <Table.Cell>{loc.postcode}</Table.Cell>
          <Table.Cell>{loc.w3w}</Table.Cell>
          <Table.Cell>{l.client ? l.client.name : "--"}</Table.Cell>
        </Table.Row>
      );
    });
  }
  render() {
    return (
      <Table stackable selectable className="no-marg">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Town</Table.HeaderCell>
            <Table.HeaderCell>Postcode</Table.HeaderCell>
            <Table.HeaderCell>W3W</Table.HeaderCell>
            <Table.HeaderCell>Client</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderData()}</Table.Body>
      </Table>
    );
  }
}
