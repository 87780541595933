import React, { Component } from "react";
import { Table, Message, Label } from "semantic-ui-react";
import moment from "moment";
import Moment from "react-moment";

export default class JobList extends Component {
  renderDate = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YY HH:mm");
    } else {
      return "--";
    }
  };
  returnColour = (status) => {
    if (status) {
      if (this.props.settings && this.props.settings.responderStatus) {
        let colour = this.props.settings.responderStatus.filter((r) => {
          return r.name === status;
        });
        if (colour[0]) {
          return colour[0].color;
        } else {
          return "grey";
        }
      }
    }
    return "grey";
  };
  renderData() {
    const { extended, client } = this.props;
    if (this.props.data.length > 0) {
      return this.props.data.map((event) => {
        return (
          <Table.Row key={event._id} onClick={() => this.props.action(event._id)} className={event.status === "Completed" ? "subtleRow" : ""} negative={event.flag ? true : false}>
            <Table.Cell collapsing>{event.jobNum?.substring(4)}</Table.Cell>
            <Table.Cell collapsing>
              {event.type}
              <br />
              {event.client && event.client.name}
            </Table.Cell>

            {extended && <Table.Cell>{event.otherRef}</Table.Cell>}
            {client && <Table.Cell>{event.secondRef}</Table.Cell>}
            {extended && <Table.Cell>{event.details}</Table.Cell>}
            <Table.Cell>{this.renderDate(event.date)}</Table.Cell>
            <Table.Cell singleLine>{event.status}</Table.Cell>
            <Table.Cell singleLine>{event.priority}</Table.Cell>
            {!this.props.future && (
              <Table.Cell textAlign="center" className={event.ReqDecision > 0 ? "negative" : "positive"}>
                {event.ReqDecision}
              </Table.Cell>
            )}
            {/* {extended && ( */}
            <Table.Cell>
              {event.Resources &&
                event.Resources.map((r, i) => {
                  if (r) {
                    return (
                      <Label
                        key={i}
                        style={{
                          background: this.returnColour(r.status),
                          color: "white",
                        }}
                      >
                        {r.firstName} {r.lastName} {r.callsign && r.callsign}
                        {r.status}
                      </Label>
                    );
                  }
                })}
            </Table.Cell>
            {/* )} */}
            {this.props.future && (
              <Table.Cell>
                <Moment fromNow>{event.date}</Moment>
              </Table.Cell>
            )}
            {this.props.outcome && <Table.Cell>{event.outcome}</Table.Cell>}
            {this.props.debriefs && <Table.Cell collapsing>{event.Debriefs.hours}</Table.Cell>}
            {this.props.debriefs && <Table.Cell collapsing>{event.Debriefs.mileage}</Table.Cell>}
          </Table.Row>
        );
      });
    } else {
      return (
        <Table.Row>
          <Table.Cell colSpan={extended ? 9 : 9}>
            <Message negative>No jobs</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
  }
  render() {
    const { extended, client } = this.props;
    return (
      <Table stackable selectable celled className="no-marg jobList">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Job</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            {extended && <Table.HeaderCell collapsing>3rd Party Ref</Table.HeaderCell>}
            {client && <Table.HeaderCell collapsing>Our Second Ref</Table.HeaderCell>}
            {extended && <Table.HeaderCell>Details</Table.HeaderCell>}
            <Table.HeaderCell width={2}>Date</Table.HeaderCell>
            <Table.HeaderCell width={1}>Status</Table.HeaderCell>
            <Table.HeaderCell width={1}>Priority</Table.HeaderCell>
            {!this.props.future && <Table.HeaderCell width={1}>Requires Decision</Table.HeaderCell>}
            {/* {extended && <Table.HeaderCell>Resources</Table.HeaderCell>} */}
            <Table.HeaderCell>Resources</Table.HeaderCell>
            {this.props.future && <Table.HeaderCell>Due</Table.HeaderCell>}
            {this.props.outcome && <Table.HeaderCell>Outcome</Table.HeaderCell>}
            {this.props.debriefs && <Table.HeaderCell>Hours</Table.HeaderCell>}
            {this.props.debriefs && <Table.HeaderCell>Mileage</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderData()}</Table.Body>
      </Table>
    );
  }
}
