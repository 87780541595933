import React, { Component } from "react";
import { Grid, Segment, Icon, Header } from "semantic-ui-react";
import TaskMap from "../common/mapping/taskMap";
import api from "../../actions/api";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";

class ResourceMap extends Component {
  state = {
    job: {},
    allStaff: [],
    rotaStaff: [],
    resources: [],
  };
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState) {
    // if (this.props.job !== prevProps.job) {
    //   this.setState({
    //     job: this.props.job,
    //   });
    // }
  }
  getData = () => {
    api.jobs.show(this.props.match.params.id).then((job) => {
      this.setState(
        {
          job,
        },
        () => {
          this.getRotaStaff();
        }
      );
    });
    api.staff.oncall().then((allStaff) => {
      this.setState({
        allStaff,
        loading: false,
      });
    });
    api.jobs.getResources(this.props.match.params.id).then((resources) => {
      this.setState({
        resources,
      });
    });
  };
  getRotaStaff = () => {
    let rotas = this.state.job.Rotas;
    if (rotas.length) {
      Promise.all(rotas.map((r) => api.rota.show(r._id))).then((res) => {
        let rotaStaff = [];
        res.map((r) => {
          r.Staff.map((s) => {
            rotaStaff.push(s);
          });
        });
        this.setState({
          rotaStaff: rotaStaff,
        });
      });
    }
  };
  renderFilterStaff = (list) => {
    let staff = this.state.rotaStaff;
    this.state.resources.forEach((r) => {
      staff.push(r.staff);
    });
    list = list.filter((i) => {
      let ii = staff.filter((aa) => aa._id === i._id);
      if (ii[0] !== undefined) {
        return false;
      }
      return true;
    });
    return list;
  };
  renderFilterRotaStaff = (list) => {
    let staff = this.state.resources;

    list = list.filter((i) => {
      let ii = staff.filter((aa) => aa.staff._id === i._id);
      if (ii[0] !== undefined) {
        return false;
      }
      return true;
    });

    return list;
  };
  setFlyTo = (data) => {
    this.setState({
      flyTo: data,
    });
  };
  addTo = (data) => {
    let staff = {
      staff: data._id,
    };

    return api.jobs.assign(this.props.match.params.id, staff).then(() => {
      this.getData();
    });
  };
  renderMap(center) {
    return (
      <TaskMap
        center={center}
        data={() => {
          /* no nothing */
        }}
        staff={this.renderFilterStaff(this.state.allStaff)}
        rotaStaff={this.renderFilterRotaStaff(this.state.rotaStaff)}
        resources={this.state.resources}
        flyTo={this.state.flyTo}
        zones={this.state.zones}
        locations={this.state.job.locations}
        updateResource={this.addTo}
        mapStyle={{ height: "77vh" }}
        mapKey={
          <div className="key">
            <Segment basic>
              <Header>Staff Key</Header>
              <p>
                <Icon name="circle" color="purple" /> All Staff
              </p>
              <p>
                <Icon name="circle" color="orange" />
                On Shift
              </p>
              <p>
                <Icon name="circle" color="green" />
                Active Resource
              </p>
            </Segment>
          </div>
        }
      />
    );
  }

  render() {
    const { job } = this.state;
    let center = [0.521725, 51.278708];
    console.log("JOB:", job);
    if (job && job.locations && job.locations[0]) {
      center = job.locations[0].geoJSON.geometry.coordinates;
    }

    return (
      <Grid stackable className="no-marg no-pad">
        <Grid.Row columns={1} className="no-pad">
          <Grid.Column className="no-pad">{this.renderMap(center)}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default Job_Receiver(ResourceMap, (job) => {
  return {
    job,
  };
});
