import React from "react";
import {Table, Button, Icon, Label, Message} from "semantic-ui-react";

const StaffList = ({data = [], settings = {responderStatus: []}, title = "", showHeader = false, expanded = false, showInArrow = false, showOutArrow = false, rowAction = () => {}, action = () => {}, includeNonOps = false}) => {
    let baseColumnCount = 3;
    if(showInArrow) baseColumnCount += 1;
    if(showOutArrow) baseColumnCount += 1;

    const returnColour = status => {
        if (status && settings.responderStatus){
            let colour = settings.responderStatus.filter((r) => {
                return r.name === status;
            });
            if (colour[0]){
                return colour[0].color;
            } else {
                return "grey";
            }
        }
        return "grey";
    };
    const renderMembers = () => {
        if (data.length > 0){
            if (!includeNonOps){
                data = data.filter(a => a.operational);
            }
            return data.map(item => {
                return (
                    <Table.Row key={item._id} onClick={() => rowAction(item._id)} negative={item.operational === false} warning={item.operational && !item.onCall} positive={item.operational && item.onCall}>
                        {showInArrow && (<Table.Cell collapsing>
                            <Button positive basic icon onClick={() => action(item)}><Icon name={"arrow left"}/></Button>
                        </Table.Cell>)}
                        <Table.Cell collapsing>
                            {item.name ? item.name : (
                                <React.Fragment>
                                    {item.firstName} {item.lastName} {(item.callsign !== undefined && item.callsign !== "") && <span>({item.callsign})</span>}
                                </React.Fragment>
                            )}
                        </Table.Cell>
                        {expanded && (
                            <React.Fragment>
                                <Table.Cell collapsing>
                                    {item.phone}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    {item.email}
                                </Table.Cell>
                            </React.Fragment>
                        )}
                        <Table.Cell>
                            {item.roles && item.roles.length ? item.roles.map((role, i) => {
                            return (<Label key={i} size="tiny">{role}</Label>);
                        }) : "--"}
                        </Table.Cell>
                        <Table.Cell>
                            {item.status ? <Label style={{background: returnColour(item.status), color: "white"}}>{item.status}</Label> : <Label style={{background: "gray", color: "white"}}>Unknown</Label>}
                        </Table.Cell>
                        {showOutArrow && (<Table.Cell collapsing>
                            <Button negative basic icon onClick={() => action(item)}><Icon name={"arrow right"}/></Button>
                        </Table.Cell>)}
                    </Table.Row>
                );
            });
        }
        return (
            <Table.Row>
                <Table.Cell colSpan={expanded ? baseColumnCount + 2 : baseColumnCount}>
                    <Message negative>There are no results to display.</Message>
                </Table.Cell>
            </Table.Row>
        );
    };

    return (
        <Table stackable selectable className="no-marg">
            {title.length >= 1 && (
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell collapsing colSpan={expanded ? baseColumnCount + 2 : baseColumnCount}>{title}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            )}
            {showHeader && <Table.Header>
                <Table.Row>
                    {showInArrow && (<Table.HeaderCell collapsing>{""}</Table.HeaderCell>)}
                    <Table.HeaderCell collapsing>Name</Table.HeaderCell>
                    {expanded && (
                        <React.Fragment>
                            <Table.HeaderCell collapsing>Phone</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Email</Table.HeaderCell>
                        </React.Fragment>
                    )}
                    <Table.HeaderCell collapsing>Roles</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Status</Table.HeaderCell>
                    {showOutArrow && (<Table.HeaderCell collapsing>{""}</Table.HeaderCell>)}
                </Table.Row>
            </Table.Header>}
            <Table.Body>{renderMembers()}</Table.Body>
        </Table>
    );
}
export default StaffList;
