export default {
  SymbolLayout: {
    "icon-image": [
      "match",
      ["get", "type"],
      "Handset",
      "circle-white-2",
      "Vehicle",
      "car-15",
      "Device",
      "mobile-phone-15",
      "circle-white-2",
    ],
    // "icon-size": 2,
    "icon-allow-overlap": true,
    // "icon-ignore-placement": true,
    "text-allow-overlap": true,
    // "text-ignore-placement": true,
    "text-field": "{title}",
    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
    "text-radial-offset": 1,
    "text-variable-anchor": ["top", "bottom", "left", "right"],
    "text-justify": "auto",
  },
  SymbolPaint: {
    "text-halo-color": "#000",
    "text-halo-width": 15,
    "icon-color": "#ff5566",
    "text-color": [
      "match",
      ["get", "onlineStatus"],
      "green",
      "#0de80d",
      "red",
      "red",
      "yellow",
      "orange",
      "purple",
    ],
  },
};
