import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import Proptypes from "prop-types";

class FileUploader extends Component {
  state = {
    isHovered: false
  };
  onFileLoad = e => {
    if (e.currentTarget.files[0] === undefined) {
      return;
    }
    const file = e.currentTarget.files[0];
    this.props.onFileToUpload(file);
  };
  render() {
    let isCompact = this.props.compact !== undefined;
    return (
      <Segment
        basic
        className="inner-container no-pad no-marg"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          className={` field draggable-container ${this.state.isHovered &&
            "draggable-container-hovered"}`}
          style={isCompact ? { minHeight: "4em" } : {}}
        >
          <input
            type="file"
            id="file-browser-input"
            name="file-browser-input"
            ref={input => (this.fileInput = input)}
            onDragOver={() => this.setState({ isHovered: true })}
            onDragLeave={() => this.setState({ isHovered: false })}
            onChange={e => this.onFileLoad(e)}
          />
          <div className="helper-text">
            Drop File Here <br />
            (max 5mb)
          </div>
        </div>
        {/* <div className="file-browser-container">
          <Button basic color="blue" onClick={(e) => {e.preventDefault(); this.fileInput.click();}}>
            Browse
          </Button>
        </div> */}
      </Segment>
    );
  }
}
FileUploader.propTypes = {
  onFileToUpload: Proptypes.func.isRequired,
  compact: Proptypes.bool
};
export default FileUploader;
