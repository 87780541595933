import React from "react";
import { Grid, Input, Segment, Button, Menu, Table, Icon, Modal, Loader, Message, Statistic } from "semantic-ui-react";
import moment from "moment";
import LoggingForm from "./loggingForm.js";
import fileDownloader from "js-file-download";
import Display from "../../lib/displayClass";
import api from "../../actions/api.js";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import TheLoader from "../common/TheLoader";
import Box from "../common/box.js";
import AppConfig from "../../_appConfig.js";

const intervalTime = 60;
class TheLog extends React.Component {
  autoReloadInterval = null;
  state = {
    showAccessRequest: false,
    autoReload: false,
    lastId: "",
    searchQuery: "",
    related: "",
    relatedNum: false,
    isFiltered: false,
    jobs: [],
    display: {},
    modalOpen: false,
    welfare: {},

    printing: false,
    printingError: null,

    teamList: [],
    resList: [],
    resourceData_error: null,
    resourceData_loading: true,

    dash: [],
    dash_loading: false,
    dash_error: null,

    logs: [],
    logs_loading: false,
    logs_error: null,

    taskStatusChange_loading: false,
    taskStatusChange_error: null,
  };
  componentDidMount() {
    if (this.props.job.status !== "Closed") {
      this.enableAutoReload();
      this.getData();
    } else {
      this.setState({
        showAccessRequest: true,
      });
    }
    this.getDisplay();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.job !== prevProps.job) {
      this.getData();
    }
  }
  componentWillUnmount() {
    this.disableAutoReload();
  }
  getData() {
    this.getLogs();
    this.getJobs();
  }
  getLogs = () => {
    // This is done as two operations
    this.setState(
      {
        logs_loading: true,
        logs_error: null,
      },
      () => {
        api.logs
          .all()
          .then((res) => {
            this.setState({
              logs: res,
            });
          })
          .catch((err) => {
            let errorMessage = "There was an unknown network error while retrieving data from the server";
            if (err.response !== undefined) {
              if (err.response.data.message !== undefined) {
                errorMessage = err.response.data.message;
              }
            }
            this.setState({
              logs_error: errorMessage,
            });
          })
          .finally(() => {
            this.setState({
              logs_loading: false,
            });
          });
      }
    );
    this.setState(
      {
        dash_loading: true,
        dash_error: null,
      },
      () => {
        api.jobs
          .mainDash()
          .then((res) => {
            this.setState({
              dash: res,
            });
          })
          .catch((err) => {
            let errorMessage = "There was an unknown network error while retrieving data from the server";
            if (err.response !== undefined) {
              if (err.response.data.message !== undefined) {
                errorMessage = err.response.data.message;
              }
            }
            this.setState({
              dash_error: errorMessage,
            });
          })
          .finally(() => {
            this.setState({
              dash_loading: false,
            });
          });
      }
    );
  };
  getJobs = () => {
    //TODO put proper error handling in here
    api.jobs
      .active()
      .then((jobs) => {
        this.setState({
          jobs: jobs.formList,
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };
  accessLog = () => {
    this.setState(
      {
        showAccessRequest: false,
      },
      () => {
        this.getData();
      }
    );
  };
  toggleColumn = (col) => {
    this.setState(
      {
        display: {
          ...this.state.display,
          [col]: !this.state.display[col],
        },
      },
      () => {
        this.setDisplay();
      }
    );
  };
  getDisplay = () => {
    let display = Display.getLog();
    if (display) {
      this.setState({
        display: display,
      });
    }
  };
  setDisplay = () => {
    let display = this.state.display;
    Display.setLog(display);
  };
  enableAutoReload = () => {
    this.setState({
      autoReload: true,
    });
    if (!this.autoReloadInterval) {
      this.autoReloadInterval = setInterval(() => {
        this.getData();
      }, intervalTime * 1000);
    }
  };
  disableAutoReload = () => {
    this.setState({
      autoReload: false,
    });
    if (this.autoReloadInterval) {
      clearInterval(this.autoReloadInterval);
    }
  };
  exportLog = () => {
    this.setState({
      printing: true,
      printingError: null,
    });
    api.log
      .printLog(this.props.event._id)
      .then((theData) => {
        this.setState({
          printing: false,
        });
        fileDownloader(theData, "log.pdf");
      })
      .catch((err) => {
        let errorMessage = "There was an unknown network error while retrieving data from the server";
        if (err.response !== undefined) {
          if (err.response.data.message !== undefined) {
            errorMessage = err.response.data.message;
          }
        }
        this.setState({
          printingError: errorMessage,
        });
      });
  };
  filterList(e) {
    this.setState({
      searchQuery: e.target.value.toLowerCase(),
      isFiltered: true,
    });
  }
  onDecide = (e) => {
    this.setState({ related: e });
  };
  onRelated = (refNum) => {
    this.setState({ isFiltered: true, relatedNum: refNum });
  };
  onClear = () => {
    this.getLogs();
    this.setState({
      isFiltered: false,
      related: {},
      relatedNum: false,
      searchQuery: "",
    });
  };
  reset = () => {
    this.setState({ isFiltered: false, related: {}, relatedNum: false });
  };
  submit = (data) => {
    let related = "";
    let jobID = null;
    if (this.state.related !== undefined && this.state.related.refNum !== undefined) {
      related = this.state.related.refNum;
      if (this.state.related.job) {
        jobID = this.state.related.job._id;
      }
    }
    data = {
      ...data,
      related: related,
    };
    if (jobID !== null) {
      data.job = jobID;
    }

    let finalFormData = new FormData();
    let keys = Object.keys(data);
    for (let i in keys) {
      finalFormData.append(keys[i], data[keys[i]]);
    }

    return api.logs.add(finalFormData).then((log) => {
      this.getLogs();
      this.setState({ related: "" });
    });
  };
  getLogAttachment(id) {
    api.logs
      .getLogAttachment(id)
      .then((res) => {
        window.open(AppConfig.api_server + res.link, "_blank");
      })
      .catch((err) => {});
  }
  getLogAttachmentLink(id) {
    return api.logs
      .getLogAttachment(id)
      .then((res) => {
        return AppConfig.api_server + res.link;
      })
      .catch((err) => {});
  }
  renderDate = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YY HH:mm");
    } else {
      return "--";
    }
  };
  getFilteredLogs() {
    let logItems = this.state.logs.filter((log) => {
      let messageMatch = log.message ? log.message.toLowerCase().search(this.state.searchQuery) !== -1 : false;
      let teamMatch = log.resource ? log.resource.toLowerCase().search(this.state.searchQuery) !== -1 : false;
      let jobMatch = log.job ? log.job.jobNum.toLowerCase().search(this.state.searchQuery) !== -1 : false;
      let logNumMatch = log.refNum ? (log.refNum + "").search(this.state.searchQuery) !== -1 : false;
      return messageMatch || teamMatch || logNumMatch || jobMatch;
    });
    logItems = logItems.filter((log) => {
      if (!this.state.relatedNum) {
        return true;
      }
      return this.state.relatedNum === log.related || log.refNum === this.state.relatedNum;
    });
    return logItems;
  }
  outstanding = () => {
    api.jobs.mainOutstanding().then((res) => {
      this.setState({
        logs: res,
        isFiltered: true,
      });
    });
  };
  allDecisions = () => {
    api.event.decisions(this.props.match.params.job_id).then((res) => {
      this.setState({
        logs: res,
        isFiltered: true,
      });
    });
  };
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  handleTaskIndex = (e, titleProps) => {
    const { index } = titleProps;
    const { activeTaskIndex } = this.state;
    const newIndex = activeTaskIndex === index ? -1 : index;

    this.setState({ activeTaskIndex: newIndex });
  };
  // lastContact = (resource) => {
  //   let logs = this.state.logs;
  //   let teamLogs = logs.filter((log) => {
  //     return resource === log.resource;
  //   });
  //   if (teamLogs.length) {
  //     let log = moment(teamLogs[0].createdAt);
  //     let now = moment();
  //     let diff = now.diff(log, "minutes");
  //     if (this.props.system.config !== undefined) {
  //       if (this.props.system.config.defaults !== undefined) {
  //         let threshold = this.props.system.config.defaults.welfareCheck;
  //         if (diff > threshold) {
  //           return true;
  //         }
  //       }
  //     }
  //   }
  //   return false;
  // };
  handleOpen = () => this.setState({ modalOpen: true, activeTaskIndex: null });
  handleClose = () => this.setState({ modalOpen: false, activeTaskIndex: null });
  handleTaskStatusChange = (task, status, res) => {
    // this.setState(
    //     {
    //         taskStatusChange_loading: true,
    //         taskStatusChange_error: null
    //     },
    //     () => {
    //         api.incident.tasks
    //             .update(this.props.chosenIncident.incident_id, task._id, {
    //                 status: status,
    //                 _res: res
    //             })
    //             .then(() => {
    //                 this.getData();
    //             })
    //             .catch(err => {
    //                 let errorMessage =
    //                         "There was an unknown network error while processing the request on the server";
    //                 if (err.response !== undefined){
    //                     if (err.response.data.message !== undefined){
    //                         errorMessage = err.response.data.message;
    //                     }
    //                 }
    //                 this.setState({
    //                     taskStatusChange_error: errorMessage
    //                 });
    //             })
    //             .finally(() => {
    //                 this.setState({
    //                     taskStatusChange_loading: false
    //                 });
    //             });
    //     }
    // );
  };

  processLogFlag = (log) => {
    if (log.meta !== undefined) {
      switch (
        log.meta.flag.toLowerCase() // enforce lowercase checks
      ) {
        case "location":
          return (
            <Button
              onClick={() => {
                this.props.history.push("/map", {
                  name: log.resource,
                  latlng: log.meta.data.latlng,
                  acc: log.meta.data.acc,
                });
              }}
              floated={"right"}
            >
              View on Map
            </Button>
          );
        default:
          return null;
      }
    }
  };
  renderPrintingModal() {
    return (
      <Modal open={this.state.printing}>
        <Modal.Header>Creating PDF</Modal.Header>
        <Modal.Content>
          {this.state.printingError ? (
            <Modal.Description>
              <Message>
                <Message.Header>PDF creation error</Message.Header>
                {this.state.printingError}
              </Message>
              <Button color={"red"} basic onClick={() => this.setState({ printing: false })}>
                Close
              </Button>
            </Modal.Description>
          ) : (
            <Modal.Description>
              <p>Please wait while we create the PDF...</p>
              <Loader />
            </Modal.Description>
          )}
        </Modal.Content>
      </Modal>
    );
  }
  renderLogs(filteredList) {
    if (filteredList.length === 0) {
      return (
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan="5">No Logs</Table.Cell>
          </Table.Row>
        </Table.Body>
      );
    }
    const list = filteredList.map((log, index) => {
      let attachedFile = "";
      if (log.attachedFile !== undefined && log.attachedFile !== null && log.attachedFile.filename !== undefined) {
        // eslint-disable-next-line
        attachedFile = (
          // <Popup basic trigger={
          <p>
            ---
            <br />
            Attached File:{" "}
            <a href={"#"} onClick={() => this.getLogAttachment(log._id)}>
              {log.attachedFile.filename}
            </a>
          </p>
          // }
          //   content={
          //     <Image src={() => this.getLogAttachmentLink(log._id)} />
          //   }
          // />
        );
      }
      return (
        <Table.Row className={log.isRead ? "" : "unread_log"} negative={log.priority === "High"} key={log._id + "-" + index}>
          <Table.Cell collapsing>{log.refNum}</Table.Cell>
          {this.state.display.date && <Table.Cell collapsing>{this.renderDate(log.createdAt)}</Table.Cell>}
          {this.state.display.job && (
            <Table.Cell width={1} singleLine>
              {log.job && log.job.jobNum}
            </Table.Cell>
          )}
          {this.state.display.second && <Table.Cell collapsing>{log.job && log.job.secondRef}</Table.Cell>}
          {this.state.display.resource && (
            <Table.Cell collapsing width={1}>
              {log.resource}
            </Table.Cell>
          )}
          {this.state.display.otherRef && <Table.Cell singleLine>{log.otherRef}</Table.Cell>}

          <Table.Cell>
            {log.message}
            {attachedFile}
            {this.processLogFlag(log)}
          </Table.Cell>
          {this.state.display.type && <Table.Cell collapsing>{log.type}</Table.Cell>}
          {this.state.display.priority && <Table.Cell collapsing>{log.priority}</Table.Cell>}
          {this.state.display.loggedBy && <Table.Cell singleLine>{log.loggedBy}</Table.Cell>}
          {this.state.display.decision && (
            <Table.Cell collapsing>
              {log.reqDecision ? (
                <Button basic icon color={log.hasDecision ? "green" : "red"} onClick={() => this.onDecide(log)} size="mini" title="decision">
                  {log.hasDecision ? <Icon name="check" /> : <Icon name="question" />}
                </Button>
              ) : (
                ""
              )}
              {log.related ? (
                <Button
                  color="green"
                  content={log.related}
                  size="mini"
                  onClick={() => this.onRelated(log.related)}
                  // label={{
                  //   as: "a",
                  //   basic: true,
                  //   // color: "green",
                  //   size: "mini",
                  //   pointing: "left",
                  //   content: log.related
                  // }}
                />
              ) : (
                ""
              )}
            </Table.Cell>
          )}
        </Table.Row>
      );
    });
    return <Table.Body>{list}</Table.Body>;
  }
  renderDecisions() {
    return (
      <Box title="Information">
        <Segment basic className="decisionHolder" style={{ height: 310 }}>
          <Statistic inverted size="large" className="stat no-marg">
            <Statistic.Value>{this.state.dash.hasDecisions}</Statistic.Value>
            <Statistic.Label>Decisions Made</Statistic.Label>
          </Statistic>
          <Statistic inverted size="large" className="stat no-marg" onClick={this.outstanding}>
            <Statistic.Value>{this.state.dash.reqDecisions}</Statistic.Value>
            <Statistic.Label>Decisions Required</Statistic.Label>
          </Statistic>
        </Segment>
      </Box>
    );
  }

  render() {
    if (this.state.showAccessRequest) {
      return (
        <Segment basic className="no-pad" textAlign={"center"}>
          <Message color={"yellow"}>
            <Message.Header>Access Request</Message.Header>This Incident is closed therefore access to the log is restricted. If you require access please click the button below,
            note this will trigger a audit trail!
          </Message>
          <Button color={"blue"} onClick={() => this.accessLog()}>
            View Log
          </Button>
        </Segment>
      );
    }
    let theLogs = this.getFilteredLogs();
    return (
      <Segment basic className="no-vpad" style={{ marginBottom: "3rem" }}>
        {this.renderPrintingModal()}
        <Grid stackable className="no-pad no-marg">
          <Grid.Row stretched>
            <Grid.Column width={12} className="no-pad">
              <Box title="Job Log Form">
                <LoggingForm
                  submit={this.submit}
                  related={this.state.related}
                  reset={this.reset}
                  settings={this.props.settings}
                  // resources={
                  // [].concat(
                  //       this.state.teamList
                  //         .filter((item) => item.tasks.length)
                  //         .filter((item) => !item.isDisbanded)
                  //         .map((item) => {
                  //           return {
                  //             ...item,
                  //             _type: "team",
                  //           };
                  //         }),
                  //       this.state.resList
                  //         .filter((item) => item.tasks.length)
                  //         .filter((item) => !(!item.assignable.team && !item.assignable.task))
                  //         .map((item) => {
                  //           return {
                  //             ...item,
                  //             _type: "resource",
                  //           };
                  //         })
                  // )
                  // }
                  jobs={this.state.jobs}
                />
              </Box>
            </Grid.Column>
            <Grid.Column width={4} className="no-pad">
              {this.renderDecisions()}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className="no-pad">
              <Box title="The Log for the last 48hrs">
                <TheLoader loading={this.state.logs_loading} />
                <Menu stackable>
                  <Menu.Item>
                    <Input icon="search" placeholder="Search..." value={this.state.searchQuery} onChange={this.filterList.bind(this)} className={"menuInput"} />
                  </Menu.Item>
                  <Menu.Menu position="right">
                    {this.state.isFiltered && (
                      <Menu.Item className={"filtered"} onClick={() => this.onClear()}>
                        <Icon name="refresh" />
                        Clear
                      </Menu.Item>
                    )}
                    <Menu.Item
                      active={this.state.autoReload}
                      // color={"green"}
                      onClick={(props, e) => {
                        if (this.state.autoReload === false) {
                          this.enableAutoReload();
                        } else {
                          this.disableAutoReload();
                        }
                      }}
                    >
                      <Icon name="sync" />
                      {this.state.autoReload ? "Turn OFF auto-reload" : "Turn ON auto-reload"}
                    </Menu.Item>
                    {/* <Menu.Item onClick={this.exportLog}>
                      <Icon name="external" />
                      Export
                    </Menu.Item> */}
                  </Menu.Menu>
                </Menu>
                <Table className="filter" stackable singleLine attached>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell collapsing>Show/Hide:</Table.HeaderCell>
                      <Table.Cell width={1} onClick={() => this.toggleColumn("date")} warning={!this.state.display.date}>
                        Date
                      </Table.Cell>
                      <Table.Cell width={1} collapsing onClick={() => this.toggleColumn("job")} warning={!this.state.display.job}>
                        Job
                      </Table.Cell>
                      <Table.Cell width={1} onClick={() => this.toggleColumn("second")} warning={!this.state.display.second}>
                        Our Other Ref.
                      </Table.Cell>
                      <Table.Cell width={1} onClick={() => this.toggleColumn("resource")} warning={!this.state.display.resource}>
                        Resource
                      </Table.Cell>
                      <Table.Cell width={1} collapsing onClick={() => this.toggleColumn("otherRef")} warning={!this.state.display.otherRef}>
                        3rd Part Ref.
                      </Table.Cell>

                      <Table.HeaderCell />
                      <Table.Cell width={1} onClick={() => this.toggleColumn("type")} warning={!this.state.display.type}>
                        Type
                      </Table.Cell>
                      <Table.Cell width={1} onClick={() => this.toggleColumn("priority")} warning={!this.state.display.priority}>
                        Priority
                      </Table.Cell>
                      <Table.Cell width={1} onClick={() => this.toggleColumn("loggedBy")} warning={!this.state.display.loggedBy}>
                        Logged By
                      </Table.Cell>
                      <Table.Cell width={1} onClick={() => this.toggleColumn("decision")} warning={!this.state.display.decision}>
                        Decisions
                      </Table.Cell>
                    </Table.Row>
                  </Table.Header>
                </Table>
                <Table attached className="logTable" stackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>ID</Table.HeaderCell>
                      {this.state.display.date && <Table.HeaderCell>Date</Table.HeaderCell>}
                      {this.state.display.job && <Table.HeaderCell>Job</Table.HeaderCell>}
                      {this.state.display.second && <Table.HeaderCell>Our Other Ref.</Table.HeaderCell>}
                      {this.state.display.resource && <Table.HeaderCell>Resource</Table.HeaderCell>}
                      {this.state.display.otherRef && <Table.HeaderCell>3rd Party Ref.</Table.HeaderCell>}

                      <Table.HeaderCell>Message</Table.HeaderCell>
                      {this.state.display.type && <Table.HeaderCell>Type</Table.HeaderCell>}
                      {this.state.display.priority && <Table.HeaderCell>Priority</Table.HeaderCell>}
                      {this.state.display.loggedBy && <Table.HeaderCell collapsing>Logged By</Table.HeaderCell>}
                      {this.state.display.decision && (
                        <Table.HeaderCell collapsing textAlign="center">
                          Decisions
                        </Table.HeaderCell>
                      )}
                    </Table.Row>
                  </Table.Header>
                  {this.renderLogs(theLogs)}
                </Table>
              </Box>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
let TheMainLogPage = Settings_Receiver(TheLog, (settings) => {
  return {
    settings,
  };
});
export default Job_Receiver(TheMainLogPage, (job) => {
  return {
    job,
  };
});
