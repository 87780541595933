import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container, Segment, Responsive } from "semantic-ui-react";
import AddLocation from "./locations/addLocation";
import LocationsDash from "./locations/dash";
import SubNav from "./subNav";
import LocationPage from "./locations/locationPage";
import ClientPages from "./clients";
import dash from "./dash";
import clientPage from "./clients/clientPage";

export default class PredifinedPages extends Component {
  state = {};
  componentDidMount = () => {};
  render() {
    return (
      <Container fluid style={{ padding: 0 }}>
        <SubNav match={this.props.match} history={this.props.history} />
        <Responsive as={Segment} basic className={"mainContent"} attached>
          <Switch>
            <Route
              exact
              path={`/predefined/locations`}
              component={LocationsDash}
            />
            <Route
              exact
              path={`/predefined/locations/:id`}
              component={LocationPage}
            />
            <Route
              exact
              path={`/predefined/locations/add`}
              component={AddLocation}
            />
            <Route exact path={`/predefined/clients`} component={ClientPages} />
            <Route path={`/predefined/clients/:id`} component={clientPage} />
            <Route path={`/predefined`} component={dash} />
            {/* <Route exact path={`/events/:id/tasks/:tid`} component={TaskDash} />
            <Route exact path={`/events/:id/tasks/:tid/update`} component={UpdateTask} />
            <Route exact path={`/events/:id/tasks`} render={(props) => <TaskList {...props} />} /> */}
          </Switch>
        </Responsive>
      </Container>
    );
  }
}
