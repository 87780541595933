import React, { Component } from "react";
import { Segment, Menu, Image } from "semantic-ui-react";
import Settings_Receiver from "../../../../contexts/settings_context/Settings_Receiver";
import Sarstuff_Map from "./Sarstuff_Map";
import OS_Map from "./OS_Map";
import MapLoader from "./google/mapLoader";
import logo from "../../../../assets/images/sarstuff_square.png";

class TheMapBox extends Component {
  state = {
    center: [0.521725, 51.278708],
    map: "sarstuff",
  };

  componentDidUpdate(prevProps, prevState){
    if (this.props.center !== prevProps.center){
      if (this.props.center !== undefined && this.props.center !== []){
        this.setState({
          center: this.props.center,
        });
      }
    }
  }

  /**
   * Renders
   */
  renderGoogleMap() {
    let locations = [],
      masts = [];
    if (this.props.locations) {
      locations = this.props.locations.filter((loc) => {
        return loc.hide !== true && loc.geoJSON.geometry.type === "Point";
      });
      masts = this.props.locations.filter((loc) => {
        return loc.hide !== true && loc.geoJSON.geometry.type === "Polygon";
      });
    }
    return (
      <MapLoader
        mapStyle={this.props.mapStyle}
        center={this.state.center}
        locations={locations}
        masts={masts}
        radioAssets={this.props.radioAssets}
        jobs={this.props.jobs}
        handleZoneHover={this.handleZoneClick}
        handleTrackClick={this.handleTrackClick}
        zoom={this.props.zoom ? this.props.zoom : 12}
      // style={{ minHeight: "80vh" }}
      />
    );
  }
  renderSarstuffMap() {
    let locations = [];
    if (this.props.locations) {
      locations = this.props.locations.filter((loc) => {
        return loc.hide !== true && loc.geoJSON.geometry.type === "Point";
      });
    }
    return (
      <Sarstuff_Map
        mapStyle={this.props.mapStyle}
        center={this.state.center}
        jobs={this.props.jobs}
        locations={locations}
        onClick={this.handleZoneClick}
        mapKey={this.props.mapKey}
        radioAssets={this.props.radioAssets}
        floodAlerts={this.props.floodAlerts}
        showFlood={this.props.showFlood}
        getFloodAlerts={this.props.getFloodAlerts}
        goto={(d) => this.props.goto(d)}
      />
    );
  }
  renderOSMaps() {
    let locations = [],
      masts = [];
    if (this.props.locations) {
      locations = this.props.locations.filter((loc) => {
        return loc.hide !== true && loc.geoJSON.geometry.type === "Point";
      });
      masts = this.props.locations.filter((loc) => {
        return loc.hide !== true && loc.geoJSON.geometry.type === "Polygon";
      });
    }
    return (
      <OS_Map
        center={this.state.center}
        radioAssets={this.props.radioAssets}
        locations={locations}
        jobs={this.props.jobs}
        mapStyle={this.props.mapStyle}
        onClick={this.handleZoneClick}
        zoom={this.props.zoom ? this.props.zoom : 6}
      />
    );
  }
  renderMaps() {
    return (
      <Segment className="no-pad no-marg">
        {this.state.map === "google" && this.renderGoogleMap()}
        {this.state.map === "sarstuff" && this.renderSarstuffMap()}
        {this.state.map === "os" && this.renderOSMaps()}
      </Segment>
    );
  }
  render() {
    if(this.state.center.length !== 2){
        return (
            <div className={"p-2 m-4 bg-red-700"}>There is no MAP center defined, unable to load map.</div>
        );
    }
    return (
      <Segment.Group className=" no-marg theMapbox">
        <Segment className="no-pad">
          <Menu widths="3">
            <Menu.Item
              active={this.state.map === "sarstuff"}
              onClick={() => this.setState({ map: "sarstuff" })}
            >
              <Image size="mini" src={logo} style={{ marginRight: 10 }} /> SarStuff Maps
            </Menu.Item>
            <Menu.Item
              active={this.state.map === "google"}
              onClick={() => this.setState({ map: "google" })}
            >
              Google Map
            </Menu.Item>
            <Menu.Item
              active={this.state.map === "os"}
              onClick={() => this.setState({ map: "os" })}
            >
              OS Map
            </Menu.Item>
          </Menu>
        </Segment>
        {this.renderMaps()}
      </Segment.Group>
    );
  }
}
export default Settings_Receiver(TheMapBox, (settings) => {
  return {
    settings,
  };
});
