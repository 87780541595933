import React, { useEffect, useState } from "react";
import {Form, Modal, Message, Button, Divider, Loader} from "semantic-ui-react";
import moment from "moment";
import {DateInput} from "semantic-ui-calendar-react";
import API from "../../actions/api";

const ExportDebriefs = ({ onClose }) => {
    const [isExporting, setIsExporting] = useState(false);
    const [fromDate, setFromDate] = useState(moment().subtract(1, "month").format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(moment().subtract(1, "day").format("YYYY-MM-DD"));

    const onExport = () => {
        setIsExporting(true);

        API.jobs.exportClosedDebriefs(fromDate, toDate).then(res => {
            let data_blob = new Blob([res.join("\n")], { type: "text/csv" });
            let a = document.createElement("a");

            a.setAttribute("hidden", "");
            a.setAttribute("href", window.URL.createObjectURL(data_blob));
            a.setAttribute("download", `${moment().format("YYYY-MM-DD")}.csv`);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            onClose();
        }).finally(() => {
            setIsExporting(false);
        });
    }

    return (
        <Modal open={true} closeOnDimmerClick={false} size={"large"}>
            <Modal.Header>Export Debriefs</Modal.Header>

            {!isExporting && <React.Fragment>
                <Modal.Content>
                    <Message info>Please select the date range that you like to export debriefs for. Results will be downloaded into a Excel SpreadSheet.</Message>
                    <Form>
                        <Form.Group widths={2}>
                            <Form.Field>
                                <DateInput
                                    autoComplete={"off"}
                                    animation={"off"}
                                    name="from"
                                    label="From"
                                    dateFormat="YYYY-MM-DD"
                                    value={fromDate}
                                    iconPosition="left"
                                    onChange={(p, e) => {setFromDate(e.value)}}
                                    startMode={"day"}
                                    closable
                                    maxDate={toDate}
                                />
                            </Form.Field>
                            <Form.Field>
                                <DateInput
                                    autoComplete={"off"}
                                    animation={"off"}
                                    name="to"
                                    label="To"
                                    dateFormat="YYYY-MM-DD"
                                    value={toDate}
                                    iconPosition="left"
                                    onChange={(p, e) => {setToDate(e.value); if(fromDate > e.value){ setFromDate(e.value) }}}
                                    startMode={"day"}
                                    maxDate={moment().subtract(1, "day").format(
                                        "YYYY-MM-DD"
                                    )}
                                    closable
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative floated={"left"} onClick={onClose}>Cancel</Button>
                    <Button positive onClick={onExport}>Export</Button>
                    <Divider clearing hidden />
                </Modal.Actions>
            </React.Fragment>}
            {isExporting && <React.Fragment>
                <Modal.Content>
                    <Loader inline>Exporting...</Loader>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={onClose}>Cancel</Button>
                    <Divider clearing hidden />
                </Modal.Actions>
            </React.Fragment>}
        </Modal>
    );
}
export default ExportDebriefs;