import React, { Component } from "react";
import {
  Segment,
  Header,
  Form,
  Button,
  Icon,
  Modal,
  Select,
  Grid,
  Input,
  Divider,
  Statistic,
} from "semantic-ui-react";
import api from "../../actions/api";
import TheLoader from "../common/TheLoader";
import Box from "../common/box";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";

class PredefinedDash extends Component {
  state = {
    clients: [],
    locations: [],
    loading: true,
  };

  componentDidMount() {
    this._getData();
  }
  componentDidUpdate(prevProps) {}
  _getData = () => {
    api.client.all().then((clients) => {
      this.setState({
        clients: clients.clients,

        loading: false,
      });
    });
    api.locations.all().then((locations) => {
      this.setState({
        locations: locations.locations,

        loading: false,
      });
    });
  };
  getRolesCount = (item) => {
    let total = 0;
    this.state.staff.forEach((staff) => {
      if (staff.roles.indexOf(item) !== -1) {
        total++;
      }
    });
    return total;
  };

  goto = (id) => {
    this.props.history.push(`/predefined/${id}`);
  };

  render() {
    const { clients, locations } = this.state;
    return (
      <Segment basic>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={16}>
              <Box title="info">
                <Segment basic>
                  <Statistic.Group widths={2}>
                    <Statistic inverted>
                      <Statistic.Value>{clients.length}</Statistic.Value>
                      <Statistic.Label>Clients</Statistic.Label>
                    </Statistic>
                    <Statistic inverted>
                      <Statistic.Value>{locations.length}</Statistic.Value>
                      <Statistic.Label>Locations</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                </Segment>
              </Box>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Settings_Receiver(PredefinedDash, (settings) => {
  return {
    settings,
  };
});
