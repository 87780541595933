import React, { Component } from "react";
import Job_Receiver from "../../../contexts/job_context/Job_Receiver";
import Box from "../../common/box";
import * as turf from "@turf/turf";
import moment from "moment";
import { Segment, Header, Image, Grid, Divider } from "semantic-ui-react";
import api from "../../../actions/api";
import VertBar from "../../common/charts/vertBar";
import WindBar from "../../common/charts/windBar";

class WeatherPage extends Component {
  state = {
    distance: 0,
    current: {
      location: {},
      current: {
        condition: {},
      },
    },
    forecast: {
      forecastday: [],
    },
  };
  componentDidMount() {
    this.props.job._refresh();
    if (this.props.job.locations) {
      this.setState(
        {
          geoJSON: this.props.job.locations[0].geoJSON,
        },
        () => this.getCurrentWeather()
      );
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.job !== prevProps.job) {
      this.setState(
        {
          geoJSON: this.props.job.locations[0].geoJSON,
        },
        () => this.getCurrentWeather()
      );
    }
  }
  getCurrentWeather = async () => {
    if (this.state.geoJSON) {
      try {
        api.jobs.weather
          .current(
            this.state.geoJSON.geometry.coordinates[1],
            this.state.geoJSON.geometry.coordinates[0]
          )
          .then((result) => this.setState({ current: result }))
          .then(() => {
            this.getForcast();
          });
        // DarkSkyApi.loadItAll("flags", position).then((result) => this.setState({ weather: result }));
      } catch (err) {
        console.log(err);
      }
    }
  };

  getForcast = () => {
    if (this.state.geoJSON) {
      api.jobs.weather
        .forcast(
          this.state.geoJSON.geometry.coordinates[1],
          this.state.geoJSON.geometry.coordinates[0]
        )
        .then((res) => {
          this.setState({
            forecast: res.forecast,
          });
        });
    }
  };
  getDistance = () => {
    let from = turf.point(this.props.event.geoJSON.geometry.coordinates);
    if (this.state.current.location.lon) {
      let to = turf.point([
        this.state.current.location.lon,
        this.state.current.location.lat,
      ]);
      let options = { units: "kilometers" };
      let distance = turf.distance(from, to, options).toFixed(1);
      if (distance > 0.1) {
        this.setState({
          distance,
        });
      }
    }
  };
  renderTempBox(data) {
    return (
      <Grid celled>
        <Grid.Row style={{ height: "8vh" }}>
          <Grid.Column>
            <Image size="mini" centered src={data.condition.icon} />
            {data.temp_c}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched style={{ height: "20vh" }}>
          <Grid.Column>{data.temp_c}</Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ height: "10vh" }}>
          <Grid.Column>{moment.unix(data.time_epoch).format("HH")}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderTempChart(labels, data) {
    if (labels && data) {
      return <VertBar labels={labels} data={data} />;
    }
    return null;
  }
  renderForecast() {
    let forecast = this.state.forecast;
    let temp = [];
    let precip = [];
    let wind = [];
    if (forecast.forecastday.length) {
      let afterNow = forecast.forecastday[0].hour.filter((well) => {
        return moment
          .unix(well.time_epoch)
          .isSameOrAfter(moment().subtract(3, "hours"));
      });
      afterNow = afterNow.concat(forecast.forecastday[1].hour);
      afterNow = afterNow.filter((day) => {
        return moment
          .unix(day.time_epoch)
          .isSameOrBefore(moment().add(8, "hours"));
      });

      temp = afterNow.map((data) => {
        let fillColor = "rgb(17, 146, 231)";
        let strokeColor = "rgb(6, 93, 151)";
        if (
          moment.unix(data.time_epoch).format("HH") === moment().format("HH")
        ) {
          fillColor = "#154170";
          strokeColor = "rgb(6, 93, 151)";
        }
        return {
          x: moment.unix(data.time_epoch).format("HH:00"),
          y: data.temp_c.toFixed(),
          fillColor: fillColor,
          strokeColor: strokeColor,
        };
      });
      precip = afterNow.map((data) => {
        let fillColor = "rgb(17, 146, 231)";
        let strokeColor = "rgb(6, 93, 151)";
        if (
          moment.unix(data.time_epoch).format("HH") === moment().format("HH")
        ) {
          fillColor = "#154170";
          strokeColor = "rgb(6, 93, 151)";
        }
        return {
          x: [
            moment.unix(data.time_epoch).format("HH:00"),
            data.chance_of_rain + "%",
          ],
          y: data.precip_mm.toFixed(),
          fillColor: fillColor,
          strokeColor: strokeColor,
        };
      });
      wind = afterNow.map((data) => {
        return {
          hour: moment.unix(data.time_epoch).format("HH:00"),
          wind: data.wind_degree,
          speed: data.wind_mph.toFixed(),
          dir: data.wind_dir,
          icon: data.condition.icon,
          text: data.condition.text,
          pressure: data.pressure_mb,
          humidity: data.humidity,
        };
      });
    }

    return (
      <Grid centered celled className="no-marg">
        <Grid.Row columns={2}>
          <Grid.Column textAlign="center">
            <Header>
              <small>location: </small>
              {this.state.current.location && this.state.current.location.name}
              <small>({this.state.distance}km away)</small>
            </Header>
          </Grid.Column>

          <Grid.Column textAlign="center">
            <Header>
              <small>Updated: </small>
              {moment(this.state.current.current.last_updated).format(
                "DD-MM-YY HH:mm"
              )}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <WindBar data={wind} label={"Wind mph"} />
            <Divider />
            <VertBar data={temp} label={"Temperature "} />
            <Divider />
            <VertBar data={precip} label={"Precipitation mm"} height={150} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  render() {
    return (
      <Segment basic>
        <Box title={"Weather"} className={"weatherBox"}>
          {this.state.forecast.forecastday.length > 0 && this.renderForecast()}
        </Box>
      </Segment>
    );
  }
}
export default Job_Receiver(WeatherPage, (job) => {
  return {
    job,
  };
});
