import React from "react";
import { Route, Switch } from "react-router-dom";
import { Container, Segment } from "semantic-ui-react";
import SettingsNav from "./settingsNav";
import Dash from "./dash";
import JobSettings from "./jobSettings";
import StaffSettings from "./staffSettings";
import smsSettings from "./smsSettings";
import appSettings from "./appSettings";
import ChangeLog from "./changeLog";

export class SettingsPage extends React.Component {
  render() {
    return (
      <Container fluid style={{ padding: 0 }}>
        <SettingsNav match={this.props.match} />
        <Segment basic className="no-pad">
          <Switch>
            <Route exact path={`${this.props.match.url}/job`} component={JobSettings} />
            <Route exact path={`${this.props.match.url}/staff`} component={StaffSettings} />
            <Route exact path={`${this.props.match.url}/sms`} component={smsSettings} />
            <Route exact path={`${this.props.match.url}/app`} component={appSettings} />
            <Route exact path={`${this.props.match.url}/change_log`} component={ChangeLog} />
            <Route exact path={`${this.props.match.url}`} component={Dash} />
          </Switch>
        </Segment>
      </Container>
    );
  }
}
export default SettingsPage;
