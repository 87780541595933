import React from "react";
import { Segment, Button, Header } from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default class OrderedLocationList extends React.Component {
  state = {
    textvalue: "",
    isMobile: false,
    formData: [],
    columns: {
      column1: {
        id: "column1",
        title: "List",
        itemIDs: [],
      },
    },
    //This is for when i have more than one column.
    columnOrder: ["column1"],
  };
  columnID = "column1";
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        formData: this.props.data.map((item) => {
          return {
            ...item,
            _id:
              ("" + item._id).substr(0, 4) !== "loc-"
                ? "loc-" + item._id
                : item._id,
          };
        }),
        columns: {
          column1: {
            ...this.state.columns.column1,
            itemIDs: this.props.data.map((item) => {
              return ("" + item._id).substr(0, 4) !== "loc-"
                ? "loc-" + item._id
                : item._id;
            }),
          },
        },
      });
    }
  }
  handleChange = (e) => {
    this.setState({
      textvalue: e.target.value,
    });
  };
  handleSubmit = () => {
    let fd = this.state.formData;
    fd.push({
      content: this.state.textvalue,
      isMobile: this.state.isMobile,
    });
    this.setState(
      {
        formData: fd,
        textvalue: "",
        isMobile: false,
      },
      () => {
        const data = {};
        data[this.props.field] = this.state.formData;
        this.props.submit(data);
      }
    );
  };
  handleUpdate = () => {
    const data = {};
    data[this.props.field] = this.state.columns[this.columnID].itemIDs.map(
      (itemID, index) => {
        let itemRaw = this.state.formData.filter((a) => {
          return a._id === itemID;
        });
        return itemRaw[0];
      }
    );
    this.props.submit(data);
  };
  handleEdit = (item, index) => {
    if (this.props.allowEdit) {
      this.props.edit(item, index);
    }
  };
  handleDelete = (obj) => {
    let newList = this.state.formData.filter((item, i) => {
      return obj !== item;
    });
    this.setState({
      formData: newList,
    });
    let field = this.props.field;
    const data = {};
    data[field] = newList;
    this.props.delete(data);
  };
  onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    // Removing from the source list
    let columnSource = this.state.columns[source.droppableId];
    let newSourceItemIDs = Array.from(columnSource.itemIDs);
    newSourceItemIDs.splice(source.index, 1);
    let finalSourceColumn = {
      ...columnSource,
      itemIDs: newSourceItemIDs,
    };
    // Adding to the destination list
    let columnDestination;
    if (destination.droppableId === source.droppableId) {
      columnDestination = finalSourceColumn;
    } else {
      // Update the source then get the destination list
      this.setState({
        columns: {
          ...this.state.columns,
          [finalSourceColumn.id]: finalSourceColumn,
        },
      });
      columnDestination = this.state.columns[destination.droppableId];
    }
    let newDestinationItemIDs = Array.from(columnDestination.itemIDs);
    newDestinationItemIDs.splice(destination.index, 0, draggableId);
    let finalDestinationColumn = {
      ...columnSource,
      itemIDs: newDestinationItemIDs,
    };
    // Finally update the destination in state
    this.setState(
      {
        columns: {
          ...this.state.columns,
          [finalDestinationColumn.id]: finalDestinationColumn,
        },
      },
      () => {
        this.handleUpdate();
      }
    );
  };

  render() {
    return (
      <Segment basic className="no-pad">
        <Header>{this.props.title}</Header>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId={this.columnID}>
            {(provided, snapshot) => (
              <div
                className="ui grid celled orderedList"
                role="list"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {this.state.columns[this.columnID].itemIDs.map(
                  (itemID, index) => {
                    let itemRaw = this.state.formData.filter((a) => {
                      return a._id === itemID;
                    });
                    if (itemRaw.length === 0) {
                      return null;
                    }
                    let item = itemRaw[0]; //TODO error checking
                    if (item.geoJSON) {
                      return (
                        <Draggable
                          key={item._id}
                          draggableId={item._id}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                role="listitem"
                                className="ui row "
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  className="column"
                                  style={{
                                    width: "30%",
                                    alignItems: "left",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "0.4rem",
                                  }}
                                >
                                  <div className="rowBox">
                                    {item.geoJSON.properties.type && (
                                      <p>
                                        <span>Type: </span>{" "}
                                        {item.geoJSON.properties.type}
                                      </p>
                                    )}
                                  </div>
                                  <div className="rowBox">
                                    {item.geoJSON.properties.department && (
                                      <p>
                                        <span>Dept: </span>{" "}
                                        {item.geoJSON.properties.department}
                                      </p>
                                    )}
                                  </div>
                                  <div className="rowBox">
                                    {item.geoJSON.properties.time && (
                                      <p>
                                        <span>Time: </span>{" "}
                                        {item.geoJSON.properties.time}
                                      </p>
                                    )}
                                  </div>
                                  <div className="rowBox">
                                    {item.geoJSON.properties.job && (
                                      <p>
                                        <span>Job: </span>{" "}
                                        {item.geoJSON.properties.job}
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="column"
                                  style={{
                                    width: "58%",
                                    alignItems: "left",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "0.4rem",
                                  }}
                                >
                                  <div className="rowBox">
                                    {item.geoJSON.properties.name && (
                                      <p>
                                        <span>Name:</span>{" "}
                                        {item.geoJSON.properties.name}
                                      </p>
                                    )}
                                  </div>
                                  <div className="rowBox">
                                    {item.geoJSON.properties.town && (
                                      <p>
                                        <span>Town: </span>
                                        {item.geoJSON.properties.town}
                                      </p>
                                    )}
                                    {item.geoJSON.properties.postcode && (
                                      <p>
                                        <span>Postcode: </span>
                                        {item.geoJSON.properties.postcode}
                                      </p>
                                    )}
                                  </div>
                                  <div className="rowBox">
                                    {item.geoJSON.properties.town && (
                                      <p>
                                        <span>w3w: </span>
                                        {item.geoJSON.properties.w3w}
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="column"
                                  style={{
                                    width: "12%",
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "0.4rem",
                                  }}
                                >
                                  {this.props.allowEdit && (
                                    <Button
                                      disabled={this.props.disabled}
                                      icon="pencil"
                                      size="tiny"
                                      color="blue"
                                      onClick={() =>
                                        this.handleEdit(item, index)
                                      }
                                    />
                                  )}
                                  <Button
                                    disabled={this.props.disabled}
                                    icon="close"
                                    size="tiny"
                                    color="red"
                                    onClick={() => this.handleDelete(item)}
                                  />
                                </div>
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    }
                  }
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Segment>
    );
  }
  // render() {
  //   return (
  //     <Segment basic>
  //       <Header>{this.props.title}</Header>
  //       <DragDropContext onDragEnd={this.onDragEnd}>
  //         <Droppable droppableId={this.columnID}>
  //           {(provided, snapshot) => (
  //             <div
  //               className="ui grid celled"
  //               role="list"
  //               ref={provided.innerRef}
  //               {...provided.droppableProps}
  //             >
  //               {this.state.columns[this.columnID].itemIDs.map(
  //                 (itemID, index) => {
  //                   let itemRaw = this.state.formData.filter((a) => {
  //                     return a._id === itemID;
  //                   });
  //                   if (itemRaw.length === 0) {
  //                     return null;
  //                   }
  //                   let item = itemRaw[0]; //TODO error checking
  //                   return (
  //                     <Draggable
  //                       key={item._id}
  //                       draggableId={item._id}
  //                       index={index}
  //                     >
  //                       {(provided, snapshot) => {
  //                         return (
  //                           <div
  //                             role="listitem"
  //                             className="ui row "
  //                             ref={provided.innerRef}
  //                             {...provided.draggableProps}
  //                             {...provided.dragHandleProps}
  //                           >
  //                             <div
  //                               className="column"
  //                               style={{
  //                                 width: "30%",
  //                                 alignItems: "center",
  //                                 display: "flex",
  //                                 flexDirection: "column",
  //                               }}
  //                             >
  //                               {item.geoJSON.properties.type && (
  //                                 <p>{item.geoJSON.properties.type}</p>
  //                               )}

  //                               {item.geoJSON.properties.name && (
  //                                 <p>{item.geoJSON.properties.name}</p>
  //                               )}
  //                             </div>

  //                             <div
  //                               className="column"
  //                               style={{
  //                                 width: "60%",
  //                                 alignItems: "center",
  //                                 display: "flex",
  //                                 flexDirection: "column",
  //                               }}
  //                             >
  //                               {item.geoJSON.properties.address && (
  //                                 <p>{item.geoJSON.properties.address}</p>
  //                               )}
  //                               {item.geoJSON.properties.grid && (
  //                                 <p>{item.geoJSON.properties.grid}</p>
  //                               )}
  //                             </div>

  //                             <div
  //                               className="column"
  //                               style={{
  //                                 width: "10%",
  //                                 alignItems: "center",
  //                                 display: "flex",
  //                                 justifyContent: "center",
  //                               }}
  //                             >
  //                               <Button
  //                                 icon="close"
  //                                 size="tiny"
  //                                 color="red"
  //                                 onClick={() => this.handleDelete(item)}
  //                               />
  //                             </div>
  //                           </div>
  //                           // <div role="listitem" className=" item locationList listSpace" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
  //                           //   <span style={{ flex: 1 }}>{item.geoJSON.properties.type && item.geoJSON.properties.type} - </span>
  //                           //   <span style={{ flex: 1 }}>{item.geoJSON.properties.name && item.geoJSON.properties.name} - </span>
  //                           //   <span style={{ flex: 1 }}>{item.geoJSON.properties.address && item.geoJSON.properties.address} - </span>
  //                           //   <span style={{ flex: 1 }}>{item.geoJSON.properties.grid && item.geoJSON.properties.grid}</span>
  //                           //   <Button circular icon="close" floated="right" size="tiny" color="red" onClick={() => this.handleDelete(item)} />
  //                           //   <Divider />
  //                           // </div>
  //                         );
  //                       }}
  //                     </Draggable>
  //                   );
  //                 }
  //               )}
  //               {provided.placeholder}
  //             </div>
  //           )}
  //         </Droppable>
  //       </DragDropContext>
  //     </Segment>
  //   );
  // }
}
