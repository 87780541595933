import React, { Component } from "react";

import InlineError from "../common/forms/errorMessage.js";

import {
  Segment,
  Header,
  Form,
  Button,
  Icon,
  Modal,
  Select,
  Grid,
  Input,
  Divider,
} from "semantic-ui-react";
import api from "../../actions/api";
import TheLoader from "../common/TheLoader";
import StaffList from "../common/lists/staffList";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import Box from "../common/box";

class StaffListPage extends Component {
  state = {
    data: {
      firstName: "",
      lastName: "",
      phone: "",
      phoneSecondary: "",
      roles: [],
    },
    filter: "",
    filtered: [],
    addModal: false,
    warning: {},
    errors: {},
    staff: [],
    roles: [],
    loading: true,
  };

  componentDidMount() {
    this._getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.settings !== prevProps.settings) {
      this.getStaffRoles();
    }
  }
  _getData = () => {
    api.staff.all().then((staff) => {
      this.setState({
        staff,
        filtered: staff,
        loading: false,
      });
    });
  };
  getRolesCount = (item) => {
    let total = 0;
    this.state.staff.forEach((staff) => {
      if (staff.roles.indexOf(item) !== -1) {
        total++;
      }
    });
    return total;
  };
  getStaffRoles = () => {
    if (this.props.settings) {
      const data = this.props.settings.orgRoles;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: key,
          text: data[key],
          value: data[key],
        });
      }

      this.updateStaffRoles(dataArray);
    }
  };
  updateStaffRoles = (data) => {
    this.setState({ roles: data });
  };
  handleAddClose = () => {
    this.setState({
      addModal: false,
    });
    this.resetForm();
  };
  resetForm = () => {
    this.setState({
      data: {
        firstName: "",
        lastName: "",
        phone: "",
        phoneSecondary: "",
        roles: [],
      },
    });
  };
  onChange = (e) => {
    if (this.state.errors[e.target.name]) {
      const errors = this.validate(this.state.data);
      this.setState({ errors });
    }
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };
  handleChange = (event, { name, value }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  validate = (data) => {
    const errors = {};
    if (!data.firstName) errors.firstName = "please enter first name";
    if (!data.lastName) errors.lastName = "please enter last name";
    if (!data.phone) errors.phone = "please enter a phone number";
    return errors;
  };
  onSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      let data = this.state.data;
      api.staff.add(data).then((res) => {
        this.handleAddClose();
        this._getData();
      });
    }
  };
  filterBy = (data) => {
    if (data) {
      if (data === this.state.filter) {
        data = "";
      }
      this.setState(
        {
          filter: data,
        },
        () => {
          if (this.state.filter !== "") {
            let list = this.state.staff;
            list = list.filter((item) => {
              let hasRoles = item.roles.filter((role) => {
                return role === this.state.filter;
              });
              return hasRoles.length >= 1;
            });
            this.setState({
              filtered: list,
            });
          } else {
            this.setState({
              filtered: this.state.staff,
            });
          }
        }
      );
    }
  };
  filterList = (e) => {
    let updatedList = this.state.staff;
    updatedList = updatedList.filter((item) => {
      let name = item.firstName + " " + item.lastName;
      return name.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
    });
    this.setState({ filtered: updatedList });
  };
  goto = (id) => {
    this.props.history.push(`/staff/${id}`);
  };
  renderStaffList() {
    return (
      <Box
        title="Staff List"
        // action={() => this.setState({ addModal: true })}
        // icon={<Icon name="plus" color="green" />}
      >
        <StaffList data={this.state.filtered} showHeader expanded includeNonOps rowAction={this.goto} />
      </Box>
    );
  }
  renderFilter() {
    return (
      <Box title="Filter">
        <Input
          className="inputStyle"
          autoComplete="off"
          name="name"
          fluid
          placeholder="Please enter a name"
          icon="search"
          onChange={this.filterList}
        />
        <Segment basic className="rolesList">
          {this.props.settings.orgRoles !== undefined
            ? this.props.settings.orgRoles.sort().map((data) => {
                return (
                  <Button
                    key={data}
                    // size="tiny"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                    fluid
                    inverted
                    toggle
                    active={this.state.filter === data}
                    onClick={() => this.filterBy(data)}
                  >
                    <span>{data}</span>
                    <span>{this.getRolesCount(data)}</span>
                  </Button>
                );
              })
            : "no roles"}
          {this.state.filter !== "" && (
            <Button
              negative
              fluid
              inverted
              onClick={() => this.setState({ filter: "" }, this._getData())}
            >
              clear
            </Button>
          )}
        </Segment>
      </Box>
    );
  }
  renderAddStaffModal() {
    const { errors, data, warning } = this.state;
    return (
      <Modal
        open={this.state.addModal}
        onClose={this.handleAddClose}
        centered={false}
        size="large"
        closeOnDimmerClick={false}
      >
        <Header
          icon="user"
          content={`Add ${data.firstName} ${data.lastName}`}
        />
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field error={!!errors.firstName}>
                <label htmlFor="firstName">First Name</label>
                <input
                  name="firstName"
                  id="firstName"
                  placeholder="Name"
                  value={data.firstName}
                  onChange={this.onChange}
                  className={warning.firstName ? "fieldWarning" : "good"}
                />
                {errors.firstName && <InlineError text={errors.firstName} />}
              </Form.Field>
              <Form.Field error={!!errors.lastName}>
                <label htmlFor="lastName">Last Name</label>
                <input
                  name="lastName"
                  id="lastName"
                  placeholder="lastName"
                  value={data.lastName}
                  onChange={this.onChange}
                  className={warning.lastName ? "fieldWarning" : "good"}
                />
                {errors.lastName && <InlineError text={errors.lastName} />}
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field error={!!errors.phone}>
                <label htmlFor="phone">Phone</label>
                <input
                  name="phone"
                  id="phone"
                  placeholder="phone"
                  value={data.phone}
                  onChange={this.onChange}
                  className={warning.phone ? "fieldWarning" : "good"}
                />
                {errors.phone && <InlineError text={errors.phone} />}
              </Form.Field>
              <Form.Field error={!!errors.phoneSecondary}>
                <label htmlFor="phoneSecondary">Secondary Phone</label>
                <input
                  name="phoneSecondary"
                  id="phoneSecondary"
                  placeholder="Secondary phone"
                  value={data.phoneSecondary}
                  onChange={this.onChange}
                  className={warning.phoneSecondary ? "fieldWarning" : "good"}
                />
                {errors.phoneSecondary && (
                  <InlineError text={errors.phoneSecondary} />
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field error={!!errors.email}>
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  id="phone"
                  placeholder="email"
                  value={data.email}
                  onChange={this.onChange}
                  className={warning.email ? "fieldWarning" : "good"}
                />
                {errors.email && <InlineError text={errors.email} />}
              </Form.Field>
            </Form.Group>
            <Divider horizontal>Admin</Divider>
            <Form.Group widths={2}>
              <Form.Field>
                <label htmlFor="callsign">Callsign</label>
                <input
                  name="callsign"
                  id="callsign"
                  placeholder="callsign"
                  value={data.callsign}
                  onChange={this.onChange}
                  className={warning.callsign ? "fieldWarning" : "good"}
                />
                {errors.callsign && <InlineError text={errors.callsign} />}
              </Form.Field>
              <Form.Field>
                <label htmlFor="roles">Roles</label>
                <Select
                  placeholder={"pick one"}
                  name={"roles"}
                  multiple
                  options={this.state.roles}
                  value={this.state.data.roles}
                  onChange={this.handleChange}
                  // onBlur={this.updateMisperModel}
                  className={warning.roles ? "fieldWarning" : "good"}
                  //   disabled={disabled}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative floated={"left"} onClick={this.handleAddClose}>
            Cancel
          </Button>
          <Button color="green" onClick={this.onSubmit} inverted>
            <Icon name="save" />
            Add {data.firstName}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  render() {
    return (
      <Segment basic>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={4}>
              {this.renderAddStaffModal()}
              {this.renderFilter()}
            </Grid.Column>
            <Grid.Column width={12}>
              <TheLoader loading={this.state.loading} />
              {this.renderStaffList()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Settings_Receiver(StaffListPage, (settings) => {
  return {
    settings,
  };
});
