export default {
  circleLayout: { visibility: "visible" },
  circlePaint: {
    "circle-stroke-width": 2,
    "circle-radius": 6,
    "circle-blur": 0.3,
    "circle-stroke-color": "#fff",
    "circle-color": "#1192E7",
  },
  circleSmallPaint: {
    "circle-stroke-width": 2,
    "circle-radius": 8,
    "circle-blur": 0.3,
    "circle-stroke-color": "#ccc",
    "circle-color": "#ff0000",
  },
  circleStaffPaint: {
    "circle-stroke-width": 2,
    "circle-radius": 6,
    "circle-blur": 0.3,
    "circle-stroke-color": "#ffa872",
    "circle-color": "orange",
  },
  circleAllStaffPaint: {
    "circle-stroke-width": 2,
    "circle-radius": 6,
    "circle-blur": 0.3,
    "circle-stroke-color": "#bf77f9",
    "circle-color": "purple",
  },
  circleResourcesPaint: {
    "circle-stroke-width": 2,
    "circle-radius": 6,
    "circle-blur": 0.3,
    "circle-stroke-color": "#aaffb3",
    "circle-color": "green",
  },
  markerPaint: {
    "circle-stroke-width": 3,
    "circle-radius": 6,
    "circle-blur": 0.15,
    "circle-stroke-color": "blue",
    "circle-color": "yellow",
  },
  symbolLayout: {
    "text-field": "{type}\n{name} \n",
    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
    "text-radial-offset": 1,
    "text-variable-anchor": ["top", "bottom", "left", "right"],
    "text-justify": "auto",
    "text-letter-spacing": 0.2,
  },
  symbolSmallLayout: {
    "text-field": "{name} \n{type}",
    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],

    "text-size": 18,
    "text-radial-offset": 1,
    "text-variable-anchor": ["top", "bottom", "left", "right"],
    "text-justify": "auto",
    "text-letter-spacing": 0.2,
    "icon-allow-overlap": true,
    "text-allow-overlap": true,
    "icon-anchor": "bottom",
  },
  symbolStaffLayout: {
    "text-field": "{staff}",
    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
    "text-offset": [0, 0.6],
    // "text-anchor": "top",
    "text-variable-anchor": ["top-left", "bottom-right", "left"],
    // "text-radial-offset": 1,
    "text-letter-spacing": 0.2,
  },
  symbolPaintAllStaff: {
    "text-color": "#bf77f9",
    "text-halo-color": "#fff",
    "text-halo-width": 0.1,
  },
  symbolPaintStaff: {
    "text-color": "#ffa872",
    "text-halo-color": "#fff",
    "text-halo-width": 0.1,
  },
  symbolPaintResourceStaff: {
    "text-color": "#aaffb3",
    "text-halo-color": "#fff",
    "text-halo-width": 0.1,
  },
  symbolPaint: {
    "text-color": "#fff",
    "text-halo-color": "#333",
    "text-halo-width": 0.1,
  },
  symbolOSPaint: {
    "text-color": "#000",
    "text-halo-color": "#fff",
    "text-halo-width": 1,
    "text-halo-blur": 2,
  },
  lightSymbolPaint: {
    "text-color": "#333",
    "text-halo-color": "#fff",
    "text-halo-width": 0.1,
  },
  linePaint: {
    "line-width": 4,
    "line-opacity": 0.8,
    "line-color": "blue",
  },
  lineLayout: {
    "line-cap": "square",
    "line-join": "round",
  },
};
