import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import StaffDash from "./staffDash";
import StaffPage from "./staffPage";
import StaffNav from "./subNav";
import staffList from "./staffList";
import staffRota from "./staffRota";
import rotaPage from "./rotaPage";
import yourDetails from "./yourDetails";

export default class Staff extends Component {
  render() {
    return (
      <Container fluid style={{ padding: 0 }}>
        <StaffNav match={this.props.match} history={this.props.history} />
        <Switch>
          <Route exact path={`/staff`} component={StaffDash} />
          <Route exact path={`/staff/your-details`} component={yourDetails} />
          <Route exact path={`/staff/rota`} component={staffRota} />
          <Route exact path={`/staff/rota/:id`} component={rotaPage} />
          <Route exact path={`/staff/list`} component={staffList} />
          <Route exact path={`/staff/:id`} component={StaffPage} />
        </Switch>
      </Container>
    );
  }
}
