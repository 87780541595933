import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ErrorCatchPage from "./_ErrorCatchPage";

import "semantic-ui-css/semantic.min.css";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import "./main.css";
import "./custom.css";

ReactDOM.render(
    <ErrorCatchPage>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </ErrorCatchPage>,
    document.getElementById("root")
);
serviceWorker.unregister();
