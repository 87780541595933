import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import clientList from "./clientList";
import jobs from "./jobs";
import locations from "./locations";
import log from "./log";

export default class ArchivePages extends Component {
  render() {
    return (
      <Container fluid style={{ padding: 0 }}>
        <Switch>
          <Route exact path={`/archive/jobs`} component={jobs} />
          <Route exact path={`/archive/log`} component={log} />
          <Route exact path={`/archive/clients`} component={clientList} />
          <Route exact path={`/archive/locations`} component={locations} />
        </Switch>
      </Container>
    );
  }
}
