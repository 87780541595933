import React from "react";
import { NavLink } from "react-router-dom";
import { Menu, Header, Dropdown, Icon } from "semantic-ui-react";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";
class JobNav extends React.Component {
  state = {
    activeItem: "",
    job: {},
  };
  componentDidMount() {
    this.props.job._refresh();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.job !== prevProps.job) {
      this.setState({
        job: this.props.job,
      });
    }
  }

  handleItemClick = () => { };

  render() {
    // console.log(this.props);
    // console.log(this.state);
    const { activeItem } = this.state;
    return (
      <Menu attached="top" stackable className={"settings-menu"}>
        <Menu.Item>
          <Header as={"h3"} className={"primary"}>
            Job {this.props.job.jobNum?.substring(4)}
          </Header>
        </Menu.Item>
        <Menu.Item
          name={"home"}
          as={NavLink}
          to={this.props.match.url}
          active={activeItem === "home"}
          exact
          onClick={this.handleItemClick}
        >
          Dash
        </Menu.Item>
        <Menu.Item
          name={"log"}
          as={NavLink}
          to={this.props.match.url + "/log"}
          active={activeItem === "log"}
          exact
          onClick={this.handleItemClick}
        >
          Log
        </Menu.Item>
        <Menu.Item
          name={"locations"}
          as={NavLink}
          to={this.props.match.url + "/locations"}
          active={activeItem === "locations"}
          exact
          onClick={this.handleItemClick}
        >
          Locations
        </Menu.Item>
        <Menu.Item
          name={"map"}
          as={NavLink}
          to={this.props.match.url + "/map"}
          active={activeItem === "map"}
          exact
          onClick={this.handleItemClick}
        >
          Map
        </Menu.Item>
        <Menu.Item
          name={"weather"}
          as={NavLink}
          to={this.props.match.url + "/weather"}
          active={activeItem === "weather"}
          exact
          onClick={this.handleItemClick}
        >
          Weather
        </Menu.Item>
        <Menu.Item
          name={"sms"}
          as={NavLink}
          to={this.props.match.url + "/sms"}
          active={activeItem === "sms"}
          onClick={this.handleItemClick}
        >
          Job SMS
        </Menu.Item>
        <Dropdown text="Resources" pointing className="link item">
          <Dropdown.Menu>
            <Dropdown.Item
              name="resources"
              active={activeItem === "resources"}
              onClick={() =>
                this.props.history.push(this.props.match.url + `/resources`)
              }
            >
              <Icon name="users" /> Resources Page
            </Dropdown.Item>
            <Dropdown.Item
              name="resourcemap"
              active={activeItem === "resourcemap"}
              onClick={() =>
                this.props.history.push(this.props.match.url + `/resourcemap`)
              }
            >
              <Icon name="map" /> Resources Map
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Menu.Menu position="right">
          <Dropdown text="Admin" pointing className="link item">
            <Dropdown.Menu>
              <Dropdown.Item
                disabled={this.state.job.status === "Closed" ? true : false}
                name="edit"
                active={activeItem === "edit"}
                onClick={() =>
                  this.props.history.push(this.props.match.url + `/close`)
                }
              >
                <Icon name="lock" />
                {this.state.job.status === "Closed"
                  ? "Job already closed"
                  : "Close the Job"}
              </Dropdown.Item>
              <Dropdown.Item
                disabled={this.state.job.status !== "Closed" ? true : false}
                name="reopen"
                active={activeItem === "reopen"}
                onClick={() =>
                  this.props.history.push(this.props.match.url + `/reopen`)
                }
              >
                <Icon name="unlock" />
                Re Open Job
              </Dropdown.Item>
              <Dropdown.Item
                disabled={true}
                name="incident"
                active={activeItem === "incident"}
                onClick={this.handleItemClick}
              >
                <Icon name="sync" /> Convert to Incident
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    );
  }
}
export default Job_Receiver(JobNav, (job) => {
  return {
    job,
  };
});
