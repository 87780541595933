import VideoPlayer from "./videoPlayer";
import React from "react";

const SingleFeedDisplay = ({ name, feedOptions, pollForLive= false }) => {
    const handlePlayerReady = (player) => {
        // playerRef.current = player;
        // // you can handle player events here
        // player.on("waiting", () => {
        //     console.log("player is waiting");
        // });
        // player.on("dispose", () => {
        //     console.log("player will dispose");
        // });
    };

    return (
        <div className="w-1/2 p-2 rounded-xl bg-gray-800 border border-gray-700">
            <div>{name}</div>
            <VideoPlayer
                dontPollForLive={!pollForLive}
                options={feedOptions}
                onReady={handlePlayerReady}
            />
        </div>
    );
}
export default SingleFeedDisplay;