import React, { useEffect, useState } from "react";
import { Segment, Grid, Button } from "semantic-ui-react";
import api from "../../actions/api";
import JobList from "../common/lists/jobList";
import Box from "../common/box";
import ExportDebriefs from "./exportDebriefs";

const JobArchive = ({history}) => {
    const [showExportWindow, setShowExportWindow] = useState(false);
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        api.jobs.closed().then((jobs) => {
            setJobs(jobs);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const goTo = (data) => {
        history.push(`/jobs/${data}`);
    };

    return (
        <Segment basic>
            {showExportWindow && <ExportDebriefs onClose={() => setShowExportWindow(false)} />}
            <Grid stackable>
                {/*<Grid.Row>*/}
                {/*    <Grid.Column computer={16}>*/}
                {/*        <Segment>*/}
                {/*            <Grid className="no-marg" centered>*/}
                {/*                <Grid.Row columns={4}>*/}
                {/*                    <Grid.Column textAlign="center">*/}
                {/*                        <Button>Week to Date</Button>*/}
                {/*                    </Grid.Column>*/}
                {/*                    <Grid.Column textAlign="center">*/}
                {/*                        <Button>Month to Date</Button>*/}
                {/*                    </Grid.Column>*/}
                {/*                    <Grid.Column textAlign="center">*/}
                {/*                        <Button>Year to Date</Button>*/}
                {/*                    </Grid.Column>*/}
                {/*                    <Grid.Column textAlign="center">*/}
                {/*                        <Button>All Jobs</Button>*/}
                {/*                    </Grid.Column>*/}
                {/*                </Grid.Row>*/}
                {/*            </Grid>*/}
                {/*        </Segment>*/}
                {/*    </Grid.Column>*/}
                {/*</Grid.Row>*/}
                <Grid.Row>
                    <Grid.Column computer={16}>
                        <Box title={"Closed/Completed Jobs"} action={() => {setShowExportWindow(true)}} icon={"Export Debriefs"}>
                            <JobList
                                data={jobs}
                                action={goTo}
                                extended={false}
                                outcome={true}
                                debriefs={true}
                            />
                        </Box>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};
export default JobArchive;