import React from "react";
import { withRouter } from "react-router-dom";
import {
  Table,
  Button,
  Icon,
  Label,
  Popup,
  Message,
  Header,
  Dropdown,
} from "semantic-ui-react";
import Moment from "react-moment";

class ResourceList extends React.Component {
  state = {};

  returnColour = (status) => {
    if (status && this.props.settings.responderStatus) {
      let colour = this.props.settings.responderStatus.filter((r) => {
        return r.name === status;
      });
      if (colour[0]) {
        return colour[0].color;
      } else {
        return "grey";
      }
    }
    return "grey";
  };
  renderMembers() {
    if (this.props.data && this.props.data.length) {
      const members = this.props.data.map((item) => {
        let callsign = "";

        if (item.staff) {
          if (item.staff.callsign !== undefined && item.staff.callsign !== "") {
            callsign = <span>({item.staff.callsign})</span>;
          }
          return (
            <Table.Row key={item._id} warning={item.isArchived === true}>
              {this.props.in && (
                <Table.Cell collapsing>
                  <Button
                    positive
                    basic
                    icon
                    onClick={() => this.props.action(item)}
                  >
                    <Icon name={"arrow left"} />
                  </Button>
                </Table.Cell>
              )}
              <Table.Cell
                collapsing
                onClick={
                  this.props.action !== undefined
                    ? () => this.props.action(item.staff._id)
                    : () => {}
                }
              >
                {item.name ? (
                  item.name
                ) : (
                  <Header as={"h4"}>
                    {item.staff.firstName} {item.staff.lastName} {callsign}
                  </Header>
                )}
              </Table.Cell>

              {this.props.expanded && (
                <React.Fragment>
                  <Table.Cell
                    collapsing
                    onClick={
                      this.props.action !== undefined
                        ? () => this.props.action(item.staff._id)
                        : () => {}
                    }
                  >
                    {item.staff.phone}
                    <br />
                    {item.staff.email}
                  </Table.Cell>
                </React.Fragment>
              )}
              {!this.props.hideRoles && (
                <Table.Cell
                  onClick={
                    this.props.action !== undefined
                      ? () => this.props.action(item.staff._id)
                      : () => {}
                  }
                >
                  {item.staff.roles && item.staff.roles.length
                    ? item.staff.roles.map((role, i) => {
                        return (
                          <Label key={i} size="tiny">
                            {role}
                          </Label>
                        );
                      })
                    : "--"}
                </Table.Cell>
              )}
              <Table.Cell
                collapsing
                className={"headerCell"}
                style={{ background: this.returnColour(item.status) }}
              >
                <Header as={"h4"}>
                  Status:{" "}
                  <Dropdown text={item.status} floating labeled>
                    <Dropdown.Menu>
                      <Dropdown.Header icon="tags" content="Set Status" />
                      <Dropdown.Divider />
                      {this.props.status &&
                        this.props.status.map((s) => {
                          return (
                            <Dropdown.Item
                              key={s._id}
                              onClick={() => this.props.setStatus(item, s)}
                            >
                              {s.name}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Header>
              </Table.Cell>
              {this.props.lastHeard && (
                <Table.Cell collapsing>
                  <Popup
                    basic
                    trigger={
                      <Header as={"h4"}>
                        LL:{" "}
                        <Moment format={"DD-MM-YY HH:mm"}>
                          {item.lastContact}
                        </Moment>
                      </Header>
                    }
                    content={"Last Log relating to this resource"}
                  />{" "}
                </Table.Cell>
              )}
              {this.props.out && (
                <Table.Cell collapsing>
                  <Button
                    negative
                    basic
                    icon
                    onClick={() => this.props.action(item)}
                  >
                    <Icon name={"arrow right"} />
                  </Button>
                </Table.Cell>
              )}
            </Table.Row>
          );
        }
      });
      return members;
    } else {
      return (
        <Table.Row>
          <Table.Cell colSpan={3}>
            <Message negative>No resources assigned</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
  }
  render() {
    // console.log(this.props);
    return (
      <Table stackable selectable className="no-marg">
        {!this.props.hideHeader && (
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                collapsing
                colSpan={this.props.expanded ? 3 : 4}
              >
                {this.props.title}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        )}
        <Table.Body>{this.renderMembers()}</Table.Body>
      </Table>
    );
  }
}
export default withRouter(ResourceList);
