import React, { Component } from "react";
import { Segment, Grid, Message, Menu, Header } from "semantic-ui-react";
import Settings_Receiver from "../contexts/settings_context/Settings_Receiver";
import api from "../actions/api";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";

import { FiAlertTriangle, FiAlertCircle, FiAlertOctagon } from "react-icons/fi";

import Box from "./common/box";
import FloodAlert from "./alerts/FloodAlert";
class Alerts extends Component {
  repeatingTask = null;
  state = {
    activeItem: "job",
    alerts: [],
    radioAssets: [],
    floodAlerts: [],
  };
  componentDidMount() {
    this.getData();
  }
  componentWillUnmount() {}
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  getData = () => {
    let user = Authentication.getUserData();

    api.alerts.all().then((alerts) => {
      this.setState({
        alerts,
      });
    });
    if (user.organisation.counties) {
      api.flood.alertsC(user.organisation.counties).then((floodAlerts) => {
        this.setState({
          floodAlerts: floodAlerts.items,
        });
      });
    }
  };
  goto = (id) => {
    if (id) {
      this.props.history.push(`/jobs/${id}`);
    }
  };
  renderAlerts() {
    let alerts = this.state.alerts.map((a, i) => {
      let color = "blue";
      let icon = <FiAlertCircle size={"2.5rem"} />;
      switch (a.priority) {
        case "low":
          break;
        case "medium":
          color = "orange";
          icon = <FiAlertOctagon size={"2.5rem"} />;
          break;
        case "high":
          color = "red";
          icon = <FiAlertTriangle size={"2.5rem"} />;
          break;
        default:
          color = "blue";
          icon = <FiAlertCircle size={"2.5rem"} />;
          break;
      }
      return (
        <Message
          className={"clickable alerts"}
          color={color}
          onClick={() => this.goto(a.job)}
          key={(a, i)}
          icon={icon}
          header={a.type}
          content={a.alert}
        />
      );
    });
    return alerts;
  }
  renderFloodAlerts() {
    let alerts = this.state.floodAlerts
      .filter((f) => {
        return f.severityLevel !== 4;
      })
      .map((a, i) => {
        return <FloodAlert data={a} key={i} />;
      });
    return alerts;
  }
  render() {
    const { activeItem, floodAlerts } = this.state;
    let floodA = floodAlerts.filter((f) => {
      return f.severityLevel !== 4;
    });
    return (
      <Grid className="no-marg">
        <Grid.Row className="no-pad">
          <Grid.Column className="no-pad">
            <Menu className="subMenu">
              <Menu.Item>
                <Header as={"h3"} className={"primary"}>
                  Alerts Menu
                </Header>
              </Menu.Item>
              <Menu.Item
                name="job"
                active={activeItem === "job"}
                onClick={this.handleItemClick}
              >
                Job
              </Menu.Item>
              <Menu.Item
                name="flood"
                active={activeItem === "flood"}
                onClick={this.handleItemClick}
              >
                Flood
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <Grid.Column width={16}>
          {activeItem === "job" && (
            <Box title={"Job Alerts"}>
              <Segment basic>
                {this.state.alerts.length === 0 ? (
                  <Message
                    className={"clickable alerts"}
                    positive
                    icon={"check"}
                    content={"Currently no alerts."}
                  />
                ) : (
                  this.renderAlerts()
                )}
              </Segment>
            </Box>
          )}
          {activeItem === "flood" && (
            <Box title={"Flood Alerts"}>
              <Segment basic>
                {floodA.length === 0 ? (
                  <Message
                    className={"clickable alerts"}
                    positive
                    icon={"check"}
                    content={"Currently no alerts."}
                  />
                ) : (
                  this.renderFloodAlerts()
                )}
              </Segment>
            </Box>
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

export default Settings_Receiver(Alerts, (settings) => {
  return {
    settings,
  };
});
