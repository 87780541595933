import React, { Component } from "react";
import Box from "../common/box";
import moment from "moment";

import {Segment, Header, Statistic, Popup, Grid, Modal, Message, Button, Loader, Icon} from "semantic-ui-react";
import api from "../../actions/api";
import { Calendar, momentLocalizer } from "react-big-calendar";
import CustomToolbar from "../common/calendarToolbar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import API from "../../actions/api";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);
class StaffDash extends Component {
  state = {
    rotas: [],
    dash: {},
    loading: true,
    syncRunning: false,
    syncSuccess: false,
    syncFailed: false
  };

  componentDidMount() {
    this._getData();
  }
  componentDidUpdate(prevProps) {}
  _getData = () => {
    api.staff.dash().then((dash) => {
      this.setState({
        dash,

        loading: false,
      });
    });
    api.rota.all().then((rotas) => {
      this.setState({
        rotas: rotas.rotas,

        loading: false,
      });
    });
  };
  handleForceSync = () => {
      this.setState({
          syncRunning: true,
          syncSuccess: false,
          syncFailed: false
      }, () => {
          API.staff.forceSync().then(() => {
              this.setState({
                  syncSuccess: true
              });
          }).catch(err => {
              this.setState({
                  syncFailed: true
              });
          })
      });
  };
  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.color;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };
  goto = (e) => {
    this.props.history.push(`/staff/rota/${e._id}`);
  };
  CustomToolbarCapsule = (props) => {
    return class CustomToolbarInstance extends CustomToolbar {
      static defaultProps = { ...props };
    };
  };
  renderCalender() {
    const CustomEvent = (event) => {
      const style = {
        borderRadius: 10,
        // opacity: 0.9,
        padding: "2em",
      };
      return (
        <Popup
          inverted
          position="top center"
          size="small"
          style={style}
          trigger={
            <div>
              <strong>
                {event.event.type} {event.event.rotaNum} On Shift:{" "}
                {event.event.OnShift}
              </strong>
            </div>
          }
        >
          <Grid celled stackable className="popupGrid">
            <Grid.Row columns={1}>
              <Grid.Column textAlign="center">
                <p className="boxHeader">
                  {event.event.type} <br />
                  {event.event.rotaNum}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column textAlign="center">
                <Header className="no-pad no-marg">Start</Header>

                <p>{moment(event.event.startTime).format("HH:mm")}</p>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header className="no-pad no-marg">End</Header>

                <p>{moment(event.event.endTime).format("HH:mm")}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column textAlign="center">
                <p>On Shift: {event.event.OnShift}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Popup>
      );
    };
    return (
      <Segment className="calendarHolder" style={{ minHeight: "74vh" }}>
        <Calendar
          views={["month", "week", "day"]}
          step={60}
          localizer={localizer}
          events={this.state.rotas}
          titleAccessor="type"
          startAccessor={(rota) => {
            return moment(rota.startTime).toDate();
          }}
          endAccessor={(rota) => {
            return moment(rota.endTime).toDate();
          }}
          defaultDate={new Date()}
          tooltipAccessor="details"
          onSelectEvent={(e) => this.goto(e)}
          eventPropGetter={this.eventStyleGetter}
          components={{
            event: CustomEvent,
            // toolbar: this.CustomToolbarCapsule({
            //   ADD: this.addEventShow,
            //   canAdd: canAdd,
            // }),
          }}
        />
      </Segment>
    );
  }

  render() {
    const { dash } = this.state;
    return (
      <Segment basic>
        {this.state.syncRunning && <Modal open={true}>
              <Modal.Header>Syncing Members</Modal.Header>
              <Modal.Content>
                  {this.state.syncSuccess || this.state.syncFailed ? (
                      <Modal.Description>
                          {this.state.syncFailed && <Message negative>
                              There was an issue while trying to re-sync your staff list from SarStuff Central. Please try again in a few moments and contact support if the issue persists via: support@sarstuff.co.uk
                          </Message>}
                          {this.state.syncSuccess && <Message positive>
                              Successfully re-synced your staff list from SarStuff Central.
                          </Message>}
                          <Button
                              color={"red"}
                              basic
                              onClick={() => this.setState({ syncRunning: false })}
                          >
                              Close
                          </Button>
                      </Modal.Description>
                  ) : (
                      <Modal.Description>
                          <Loader inline content={"Please wait while we re-sync your staff list from SarStuff Central..."} />
                      </Modal.Description>
                  )}
              </Modal.Content>
          </Modal>}
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={6}>
              <Box title={"Team Status"}>
                <Segment basic className="statHolder">
                  <Statistic inverted size="large" className="stat no-marg">
                    <Statistic.Value>{dash.onCall}</Statistic.Value>
                    <Statistic.Label>On Call</Statistic.Label>
                  </Statistic>
                  <Statistic inverted size="large" className="stat no-marg">
                    <Statistic.Value>{dash.offCall}</Statistic.Value>
                    <Statistic.Label>Off Call</Statistic.Label>
                  </Statistic>
                  <Statistic inverted size="large" className="stat no-marg">
                    <Statistic.Value>{dash.onShift}</Statistic.Value>
                    <Statistic.Label>On Shift</Statistic.Label>
                  </Statistic>
                </Segment>
              </Box>
              <div className={"pt-4"}>
                  <Button onClick={this.handleForceSync} primary><Icon name="sync" /> Re-Sync Staff with SarStuff Central</Button>
              </div>
            </Grid.Column>
            <Grid.Column width={10}>{this.renderCalender()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Settings_Receiver(StaffDash, (settings) => {
  return {
    settings,
  };
});
