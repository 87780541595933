import React, { Component } from "react";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";

import { Segment, Grid, Checkbox, Header, Form, Popup, Icon, Divider } from "semantic-ui-react";
import SettingsList from "../common/lists/settingList";
import api from "../../actions/api";
import RotaTypesWithRoles from "../common/lists/incidentTypeModules";
import Box from "../common/box";

class AppSettings extends Component {
  state = {
    data: {},
  };
  updateFormTimeout = 0;

  componentDidMount() {
    if (this.props.settings) {
      this.setState({
        data: this.props.settings,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings !== prevProps.settings) {
      this.setState({
        data: this.props.settings,
      });
    }
  }

  handleCheckboxChange = (props, e) => {
    this.setState(
      {
        updating: true,
        data: { ...this.state.data, [e.name]: e.checked },
      },
      () => {
        if (this.updateFormTimeout) {
          clearTimeout(this.updateFormTimeout);
        }
        this.updateFormTimeout = setTimeout(() => {
          return this.handleSubmit(this.state.data)
            .then((res) => {
              this.setState({
                updating: false,
              });
              //TODO add a fancy screen notification that the title has successfully updated
            })
            .catch((err) => {
              console.error("Unable to save", err);
            });
        }, 500);
      }
    );
  };
  handleSubmit = (data) => {
    return api.settings.update(data).then(() => {
      this.props.settings._refresh();
    });
  };
  handleDelete = (data) => {
    return api.settings.update(data).then(() => {
      this.props.settings._refresh();
    });
  };
  render() {
    const { data } = this.state

    return (
      <Segment basic>
        <Grid stackable>
          <Grid.Row >
            <Grid.Column width={6}>
              <Box title={"Job Settings"}>
                <Segment basic textAlign="center">
                  <Form>
                    <Popup
                      basic
                      content={
                        "Allow team members to add a job via the app."
                      }
                      position={"top left"}
                      trigger={
                        <Header className={"withIcon"}>
                          Allow App to add Job
                          <Icon
                            name="info circle"
                            color="yellow"
                            size="small"
                          />
                        </Header>
                      }
                    />
                    <Checkbox
                      toggle
                      name="allowAppAddJob"
                      className={"goodToggle"}
                      checked={data.allowAppAddJob}
                      onChange={this.handleCheckboxChange}
                    />

                  </Form>
                </Segment>
              </Box>
              <Divider hidden />

            </Grid.Column>


          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Settings_Receiver(AppSettings, (settings) => {
  return {
    settings,
  };
});
