import React, { Component } from "react";
import {
  Segment,
  Menu,
  Icon,
  Modal,
  Button,
  Form,
  Divider,
  Header,
} from "semantic-ui-react";
import LocationList from "../../common/lists/locationList";
import Box from "../../common/box";
import LocationBox from "../../common/locationBox";
import Settings_Receiver from "../../../contexts/settings_context/Settings_Receiver";
import api from "../../../actions/api";
import PredefinedLocBox from "../../common/predefinedLocBox";

class LocationsDash extends Component {
  state = {
    addLoc: false,
    locations: [],
    clients: [],
    settings: {},
  };
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.locations.all().then((locations) => {
      this.setState({
        locations: locations.locations,
        locs: locations,
      });
    });
    api.settings.formLists().then((settings) => {
      this.setState({
        settings,
      });
    });
    api.client.all().then((clients) => {
      this.setState({
        clients,
      });
    });
  };
  goto = (id) => {
    this.props.history.push(`/predefined/locations/${id}`);
  };
  addLocation = (data) => {
    if (data) {
      return api.locations.add(data).then(() => {
        this.getData();
        this.setState({
          addLoc: false,
        });
      });
    }
  };
  renderAddLocation() {
    return (
      <Modal
        size="large"
        open={this.state.addLoc}
        onClose={() => this.setState({ addLoc: false })}
        centered={false}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header>Add Location</Modal.Header>
        <Modal.Content className="no-pad">
          <PredefinedLocBox
            gridType={this.props.settings.gridType}
            addLoc={this.addLocation}
            cancel={() => this.setState({ addLoc: false })}
            formLists={this.state.settings}
            locs={this.state.locs}
            clients={this.state.clients.formList}
          />
        </Modal.Content>
      </Modal>
    );
  }
  render() {
    return (
      <Segment className="no-pad">
        {this.renderAddLocation()}
        <Menu>
          <Menu.Item onClick={() => this.setState({ addLoc: true })}>
            <Icon name="plus" /> Add
          </Menu.Item>
          <Menu.Menu position="right">
            <div className="ui right aligned category search item">
              <div className="ui transparent icon input">
                {/* <input className="prompt" type="text" placeholder="Search locations..." /> */}
                {/* <i className="search link icon" /> */}
              </div>
              <div className="results" />
            </div>
          </Menu.Menu>
        </Menu>
        <Box title="locations">
          <LocationList data={this.state.locations} action={this.goto} />
        </Box>
      </Segment>
    );
  }
}
export default Settings_Receiver(LocationsDash, (settings) => {
  return {
    settings,
  };
});
