import React, { Component } from "react";
import {
  Modal,
  Form,
  Header,
  Button,
  Dropdown,
  Grid,
  Menu,
  Icon,
  Segment,
  Tab,
  Input,
  Divider,
} from "semantic-ui-react";
import api from "../../actions/api";
import Geosuggest from "react-geosuggest";
import OrderedLocationList from "../common/lists/orderedLocationList";
import CommonFunctions from "../../assets/utils/commonFunctions.js";
import TaskMap from "../common/mapping/taskMap";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";
import { DateTimeInput } from "semantic-ui-calendar-react";
import moment from "moment";
import Box from "../common/box";

const priority = [
  { key: 1, text: "Urgent", value: "Urgent" },
  { key: 2, text: "Emergency", value: "Emergency" },
  { key: 3, text: "Non Urgent", value: "Non Urgent" },
  { key: 4, text: "Relay Run", value: "Relay Run" },
  { key: 5, text: "Pre Booked", value: "Pre Booked" },
  // { key: 3, text: "High", value: "High" }
];
const status = [
  { key: 1, text: "Open", value: "Open" },
  { key: 2, text: "In Progress", value: "In Progress" },
  { key: 3, text: "Completed", value: "Completed" },
  { key: 4, text: "Closed", value: "Closed" },
];
const counter = [
  { key: 0, text: "None", value: 0 },
  { key: 1, text: "1", value: 1 },
  { key: 2, text: "2", value: 2 },
  { key: 3, text: "3", value: 3 },
  { key: 4, text: "4", value: 4 },
  { key: 5, text: "5", value: 5 },
  { key: 6, text: "6", value: 6 },
  { key: 7, text: "7", value: 7 },
  { key: 8, text: "8", value: 8 },
  { key: 9, text: "9", value: 9 },
  { key: 10, text: "10", value: 10 },
];
class ReOpenJob extends Component {
  state = {
    center: [],
    data: {
      type: "Ad-Hoc Delivery",
      priority: "Non Urgent",
      location: {},
      locations: [],
      date: moment(),
      blood: 0,
      plasma: 0,
      milk: 0,
      nappies: 0,
      samples: 0,
      medication: 0,
      platelets: 0,
      plasma: 0,
      equipment: 0,
      items: 0,
      formula: 0,
    },
    addLocType: "predefined",
    depts: {
      formList: [],
    },
    location: {
      geoJSON: {
        type: "Feature",
        properties: {},
      },
    },
    errors: {},
    settings: {
      JobTypes: [],
      LocTypes: [],
    },
    locs: {
      formList: [],
    },
    clients: {
      formList: [],
    },
    locBox: false,
    w3w: {
      first: "",
      second: "",
      third: "",
    },
    marker: {
      geoJSON: {
        geometry: {},
      },
    },
    en: {
      easting: "",
      northing: "",
    },
    grid: "",
  };
  componentDidMount() {
    this.getData();
    this.props.job._refresh();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.predefined !== prevState.predefined) {
      if (this.state.predefined !== "") {
        this.getDepartments();
      }
    }
    if (this.props.job !== prevProps.job) {
      this.setState({
        data: this.props.job,
      });
    }
  }
  getData = () => {
    api.settings.formLists().then((settings) => {
      this.setState({
        settings,
      });
    });
    api.locations.all().then((locations) => {
      this.setState({
        locs: {
          formList: locations.formList,
          locations: locations.locations,
        },
      });
    });
    api.client.all().then((clients) => {
      this.setState({
        clients: {
          formList: clients.formList,
          clients: clients.clients,
        },
      });
    });
  };

  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };

  onSubmitJob = () => {
    api.jobs.open(this.props.job._id, this.state.data).then((job) => {
      this.props.job._refresh();
      this.props.history.push(`/jobs/${job._id}`);
    });
  };

  render() {
    const { data, errors } = this.state;

    let formDisabled = true;
    if (data.locations.length) {
      formDisabled = false;
    }
    let loc = "";
    if (data.locations.length) {
      loc = "check";
    }

    return (
      <Segment basic>
        <Box title={`Open Job ${this.props.job.jobNum}`}>
          <Grid celled className="no-marg">
            <Grid.Row>
              <Grid.Column computer={16}>
                <Segment basic>
                  <Form>
                    <Form.Group widths={3}>
                      <Form.Field>
                        <Form.Dropdown
                          name="type"
                          label="Job Type"
                          value={this.state.data.type}
                          onChange={this.handleDropdownChange}
                          options={this.state.settings.JobTypes}
                          selection
                          required={true}
                        />
                        {errors.type && (
                          <p style={{ color: "red" }}>{errors.type}</p>
                        )}
                      </Form.Field>

                      <Form.Field>
                        <Form.Dropdown
                          name="priority"
                          label="Priority"
                          value={this.state.data.priority}
                          onChange={this.handleDropdownChange}
                          options={priority}
                          selection
                          required={true}
                        />
                        {errors.priority && (
                          <p style={{ color: "red" }}>{errors.priority}</p>
                        )}
                      </Form.Field>

                      <Form.Field>
                        <label>Client</label>
                        <Dropdown
                          name="client"
                          label="client"
                          value={this.state.data.client}
                          onChange={this.handleDropdownChange}
                          options={this.state.clients.formList}
                          selection
                          search
                          placeholder="Client"
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Status</label>
                        <Dropdown
                          name="status"
                          label="Status"
                          value={this.state.data.status}
                          onChange={this.handleDropdownChange}
                          options={status}
                          selection
                          search
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group widths={3}>
                      <Form.Field>
                        <DateTimeInput
                          autoComplete={"off"}
                          animation={"off"}
                          name="date"
                          label="Date"
                          dateTimeFormat="YYYY/MM/DD HH:mm"
                          value={moment(this.state.data.date).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                          iconPosition="left"
                          onChange={this.handleDropdownChange}
                          startMode={"day"}
                          closable
                        />
                      </Form.Field>
                      <Form.Field>
                        <DateTimeInput
                          autoComplete={"off"}
                          animation={"off"}
                          name="startTime"
                          label="Start Time"
                          dateTimeFormat="YYYY/MM/DD HH:mm"
                          value={
                            this.state.data.startTime
                              ? moment(this.state.data.startTime).format(
                                  "YYYY-MM-DD HH:mm"
                                )
                              : ""
                          }
                          iconPosition="left"
                          onChange={this.handleDropdownChange}
                          startMode={"day"}
                          closable
                        />
                      </Form.Field>
                      <Form.Field>
                        <DateTimeInput
                          autoComplete={"off"}
                          animation={"off"}
                          name="endTime"
                          label="End Time"
                          dateTimeFormat="YYYY/MM/DD HH:mm"
                          value={
                            this.state.data.endTime
                              ? moment(this.state.data.endTime).format(
                                  "YYYY-MM-DD HH:mm"
                                )
                              : ""
                          }
                          iconPosition="left"
                          onChange={this.handleDropdownChange}
                          startMode={"day"}
                          closable
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group widths={2}>
                      <Form.Field>
                        <Form.TextArea
                          rows={8}
                          name="details"
                          label="Details"
                          value={this.state.data.details}
                          onChange={this.onChange}
                          placeholder="Details about the job"
                        />
                      </Form.Field>
                      <Form.Field>
                        <Form.TextArea
                          rows={8}
                          name="outcome"
                          label="Outcome/Notes"
                          value={this.state.data.outcome}
                          onChange={this.onChange}
                          placeholder="Outcome of the job"
                        />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Segment basic>
                  <div className="formActions">
                    <Button
                      negative
                      onClick={() => this.props.history.push(`/`)}
                    >
                      Cancel
                    </Button>
                    <Button
                      positive
                      floated="right"
                      onClick={this.onSubmitJob}
                      disabled={formDisabled}
                    >
                      <Icon name="check" />
                      Re open the job
                    </Button>
                  </div>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Box>
      </Segment>
    );
  }
}
let ReOpenTheJob = Settings_Receiver(ReOpenJob, (settings) => {
  return {
    settings,
  };
});
export default Job_Receiver(ReOpenTheJob, (job) => {
  return {
    job,
  };
});
