import React, { useState, useEffect } from "react";
import API from "../../actions/api";
import { Divider, Message } from "semantic-ui-react";
import SingleFeedDisplay from "./_components/singleFeedDisplay";
import CentralUserFeedsList from "./_components/centralUserFeedsList";

const LiveFeeds = () => {
  const [assets, setAssets] = useState([]);
  const [feeds, setFeeds] = useState([]);

  useEffect(() => {
    API.live_feeds.getAssets().then((res) => {
      setAssets(res);
    });
  }, []);
  useEffect(() => {
    Promise.all(
      assets.map(async (asset) => {
        return {
          ...asset,
          feeds: await API.live_feeds.getAssetFeeds(asset._id),
        };
      })
    ).then((assetsFeeds) => {
      setFeeds(assetsFeeds);
    });
  }, [assets]);

  return (
    <div className="p-4">
      <h1 className={"text-3xl pb-2"}>RTMP Asset Feeds</h1>
      {feeds.length === 0 && (
        <Message color="black">There are no RTMP Asset Feeds defined in SarStuff Central.</Message>
      )}
      {feeds.map((asset) => {
        return (
          <div className={"p-4 mt-4 border border-gray-700"} key={asset._id}>
            <h1 className={"text-xl"}>{asset.name}</h1>
            <div className="w-full flex gap-2">
              {asset.feeds.map((feed) => {
                return (
                    <SingleFeedDisplay name={feed.alias} feedOptions={{
                        asset_id: asset._id,
                        id: feed._id,
                        sources: feed.streamFeeds,
                    }} key={feed._id} pollForLive />
                );
              })}
            </div>
          </div>
        );
      })}
      <Divider />
      <h1 className={"text-3xl pb-2"}>Mobile App Feeds</h1>
      <CentralUserFeedsList />
    </div>
  );
}
export default LiveFeeds;