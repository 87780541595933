import React, { Component } from "react";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import { Grid, Segment, Table, Icon, Header } from "semantic-ui-react";
import api from "../../actions/api";
import Box from "../common/box";
class LinksPage extends Component {
  state = {
    disabled: true,
    links: [],
    config: {
      defaults: {
        welfareCheck: 0,
      },
    },
  };
  updateFormTimeout = 0;
  componentDidMount() {
    this.props.settings._refresh();
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.settings !== prevProps.settings) {
      this.setState({
        links: this.props.settings.externalLinks,
      });
    }
  };
  renderLink() {
    const links = this.state.links.map((link) => {
      return (
        <Table.Row key={link._id}>
          <Table.Cell>{link.label}</Table.Cell>
          <Table.Cell selectable>
            <a href={link.content} target="_blank" rel="noopener noreferrer">
              <Icon name="globe" /> {link.content}
            </a>
          </Table.Cell>
        </Table.Row>
      );
    });
    return <Table.Body>{links}</Table.Body>;
  }
  render() {
    return (
      <Segment basic>
        <Box title={"Extenal Links"}>
          <Grid celled className="no-marg">
            <Grid.Row>
              <Grid.Column>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Link</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  {this.renderLink()}
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Box>
      </Segment>
    );
  }
}

export default Settings_Receiver(LinksPage, (settings) => {
  return {
    settings,
  };
});
