import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import Nav from "./SMS_Navigation";
import Dashboard from "./Dashboard";
import SendMessage from "./SendMessage";
import SentMessages from "./SentMessages";
import Inbox from "./Inbox";

export default class Staff extends Component {
    render() {
        return (
            <Container fluid style={{ padding: 0 }}>
                <Nav match={this.props.match} history={this.props.history} />
                <Switch>
                    <Route exact path={`${this.props.match.url}`} component={Inbox} />
                    <Route exact path={`${this.props.match.url}/send`} component={SendMessage} />
                    <Route exact path={`${this.props.match.url}/sent`} component={SentMessages} />
                    <Route exact path={`${this.props.match.url}/inbox`} component={Inbox} />
                </Switch>
            </Container>
        );
    }
}