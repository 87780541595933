import React from "react";
import { Marker, MarkerClusterer } from "@react-google-maps/api";
import Map300Ring from "./map300Ring";
import MapRingContent from "./mapRingContent";
const options = {
  //   imagePath: "images/markerclusterer/m",
  minimumClusterSize: 3,
};

export default ({ locations, type, handleMarkerClick, rings, show300 }) => {
  const defaultMarkerIcon = {
    path: window.google.maps.SymbolPath.CIRCLE,
    scale: 5,
    strokeWeight: 1,
    fillColor: "red",
    fillOpacity: 1,
    labelOrigin: new window.google.maps.Point(0, 3),
  };
  return (
    <MarkerClusterer options={options}>
      {(clusterer) => {
        return locations.map((loc, index) => {
          if (loc.geometry.type !== "Point") {
            return;
          }
          const onClick = () => handleMarkerClick(loc.properties);
          // const icon = defaultMarkerIcon;
          return (
            <React.Fragment key={index}>
              {loc.ipp && (
                <React.Fragment>
                  {show300 && (
                    <Map300Ring
                      center={{
                        lat: loc.geometry.coordinates[1],
                        lng: loc.geometry.coordinates[0],
                      }}
                    />
                  )}
                  {rings &&
                    rings.length &&
                    rings.map((ring) => {
                      return (
                        <MapRingContent
                          key={ring._id}
                          radius={ring.value * 1000}
                          colour={ring.color}
                          label={ring.label}
                          center={{
                            lat: loc.geometry.coordinates[1],
                            lng: loc.geometry.coordinates[0],
                          }}
                        />
                      );
                    })}
                </React.Fragment>
              )}

              <Marker
                key={index}
                position={{
                  lat: loc.geometry.coordinates[1],
                  lng: loc.geometry.coordinates[0],
                }}
                clusterer={clusterer}
                label={{
                  text: loc.properties.type + " " + loc.properties.job,
                  fontSize: "14px",

                  className:
                    "bg-white p-1 mt-2 rounded-md border border-gray-400",
                  color: "black",
                }}
                text={loc.properties.type}
                onClick={onClick}
                icon={defaultMarkerIcon}
              />
            </React.Fragment>
          );
        });
      }}
    </MarkerClusterer>
  );
};
