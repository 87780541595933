export default {
  circleLayout: { visibility: "visible" },
  circlePaint: {
    "circle-stroke-width": 2,
    "circle-radius": 6,
    "circle-blur": 0.3,
    "circle-stroke-color": "#fff",
    "circle-color": "#1192E7",
    "circle-color": ["match", ["get", "status"], "Open", "red", "In Progress", "blue", "Completed", "yellow", "Closed", "green", "purple"],
  },
  circleSmallPaint: {
    // "circle-stroke-width": 2,
    "circle-radius": 3,
    "circle-blur": 0.3,
    // "circle-stroke-color": "#ccc",
    "circle-color": "#000",
  },
  circleStaffPaint: {
    "circle-stroke-width": 2,
    "circle-radius": 6,
    "circle-blur": 0.3,
    "circle-stroke-color": "#ffa872",
    "circle-color": "orange",
  },
  circleAllStaffPaint: {
    "circle-stroke-width": 2,
    "circle-radius": 6,
    "circle-blur": 0.3,
    "circle-stroke-color": "#bf77f9",
    "circle-color": "purple",
  },
  circleResourcesPaint: {
    "circle-stroke-width": 2,
    "circle-radius": 6,
    "circle-blur": 0.3,
    "circle-stroke-color": "#aaffb3",
    "circle-color": "green",
  },
  markerPaint: {
    "circle-stroke-width": 3,
    "circle-radius": 6,
    "circle-blur": 0.15,
    "circle-stroke-color": "blue",
    "circle-color": "yellow",
  },
  symbolLayout: {
    "text-field": "{type}\n{name} \n",
    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
    "text-offset": [0, 0.6],
    "text-anchor": "top",
    "text-letter-spacing": 0.2,
  },
  symbolMainLayout: {
    "text-field": "{job}\n{type} \n {CADNum}",
    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
    "text-offset": [0, 0.6],
    "text-anchor": "top",
    "text-letter-spacing": 0.2,
  },
  symbolSmallLayout: {
    "text-field": "{type}\n{name} \n",
    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
    "text-offset": [0, 0.6],
    "text-anchor": "top",
    "text-letter-spacing": 0.2,
    "icon-allow-overlap": true,
    "text-allow-overlap": true,
    "icon-anchor": "bottom",
  },
  symbolStaffLayout: {
    "text-field": "{staff}",
    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
    "text-offset": [0, 0.6],
    "text-anchor": "top",
    "text-letter-spacing": 0.2,
  },
  symbolPaint: {
    "text-color": "#fff",
    "text-halo-color": "#333",
    "text-halo-width": 0.1,
  },
  symbolMainPaint: {
    "text-color": "#eeffaa",
    "text-halo-color": "#333",
    "text-halo-width": 0.1,
  },
  lightSymbolPaint: {
    "text-color": "#333",
    "text-halo-color": "#fff",
    "text-halo-width": 0.1,
  },
  linePaint: {
    "line-width": 4,
    "line-opacity": 0.8,
    "line-color": "blue",
  },
  lineLayout: {
    "line-cap": "square",
    "line-join": "round",
  },
};
