import React, { Component } from "react";
import { Segment, Header, Grid, Button, Table } from "semantic-ui-react";
import api from "../../actions/api";
import JobList from "../common/lists/jobList";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import Box from "../common/box";
import moment from "moment";
class LogArchive extends Component {
  state = {
    logs: [],
    settings: [],
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    api.logs
      .archived()
      .then((logs) => {
        this.setState({
          logs: logs,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  renderLogs() {
    let list = this.state.logs.map((l) => {
      return (
        <Table.Row key={l._id}>
          <Table.Cell>{moment(l.date).format("DD-MM-YYYY HH:mm")}</Table.Cell>
          <Table.Cell>{l.job && l.job.jobNum}</Table.Cell>
          <Table.Cell>{l.resource}</Table.Cell>
          <Table.Cell>{l.job && l.job.otherRef}</Table.Cell>
          <Table.Cell>{l.message}</Table.Cell>
          <Table.Cell>{l.type}</Table.Cell>
          <Table.Cell>{l.priority}</Table.Cell>
          <Table.Cell>{l.loggedBy}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Job</Table.HeaderCell>
            <Table.HeaderCell>Resource</Table.HeaderCell>
            <Table.HeaderCell>Other Ref.</Table.HeaderCell>
            <Table.HeaderCell>Message</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Priority</Table.HeaderCell>
            <Table.HeaderCell>By</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{list}</Table.Body>
      </Table>
    );
  }
  renderFilterBox() {
    return (
      <Segment>
        <Grid className="no-marg" centered>
          <Grid.Row columns={4}>
            <Grid.Column textAlign="center">
              <Button>Week to Date</Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button>Month to Date</Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button>Year to Date</Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button>All Logs</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
  render() {
    return (
      <Segment basic>
        <Header textAlign="center">Old Logs not working yet</Header>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column computer={16}>{this.renderFilterBox()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={16}>
              <Box title={"Logs"}>{this.renderLogs()}</Box>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
export default Settings_Receiver(LogArchive, (settings) => {
  return {
    settings,
  };
});
