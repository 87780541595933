import React, { Component } from "react";
import { Modal, Form, Header, Button, Table, Grid, Segment, Icon, Menu, Message, Divider, Label, Select, Loader, Checkbox } from "semantic-ui-react";
import moment from "moment";
import api from "../../actions/api";
import Box from "../common/box";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import { DateTimeInput } from "semantic-ui-calendar-react";
import fileDownloader from "js-file-download";
import ResourceList from "../common/lists/resourceList";
import AlertBox from "../common/alertBox";

class JobPage extends Component {
  state = {
    addDate: false,
    openAdd: false,
    edit: false,
    dateType: null,
    job: {
      locations: [],
    },
    logs: [],
    resources: [],
    data: {
      date: moment().toISOString(),
      hours: "",
      mileage: "",
      donateExpenses: false,
    },
    rotas: [],
    alerts: [],
  };

  componentDidMount() {
    this.props.settings._refresh();
    this.getData();
    this.getLogs();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.job !== prevProps.job) {
      this.setState(
        {
          job: this.props.job,
        },
        () => {
          api.jobs.getResources(this.props.job._id).then((resources) => {
            this.setState({
              resources,
            });
          });
        }
      );
      this.getLogs();
    }
  }
  setDate = (data) => {
    this.setState({
      addDate: true,
      dateType: data,
    });
  };
  cancelDate = () => {
    this.setState({
      addDate: false,
      dateType: null,
    });
  };
  getData = () => {
    this.props.settings._refresh();
    api.jobs.show(this.props.match.params.id).then((job) => {
      this.setState({
        job,
      });
    });
    api.jobs.getResources(this.props.match.params.id).then((resources) => {
      this.setState({
        resources,
      });
    });
    api.rota.all().then((rotas) => {
      this.setState({
        rotas,
      });
    });
    api.alerts.job(this.props.match.params.id).then((alerts) => {
      console.log(alerts);
      this.setState({
        alerts,
      });
    });
  };
  getLogs = () => {
    if (this.props.job._id) {
      api.logs.job(this.props.job._id).then((logs) => {
        this.setState({
          logs,
        });
      });
    }
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  openAdd = () => {
    this.setState({
      openAdd: true,
    });
  };
  closeAdd = () => {
    this.setState({
      openAdd: false,
      data: {
        date: moment().toISOString(),
        hours: "",
        mileage: "",
      },
    });
  };
  doWelfareCheck = (data) => {
    this.setState({
      welfare: data,
    });
    this.handleOpen();
  };
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };
  onSubmitDate = () => {
    let data = {};
    if (this.state.dateType === "Start") {
      data.startTime = this.state.data.date;
      data.status = "In Progress";
    }
    if (this.state.dateType === "Completed") {
      data.endTime = this.state.data.date;
      data.status = "Completed";
    }
    if (this.state.dateType === "Date") {
      data.date = this.state.data.date;
    }

    return api.jobs.update(this.props.job._id, data).then(() => {
      this.setState({
        loading: false,
        addDate: false,
      });

      this.getData();
    });
  };
  submit = (data) => {
    let jobID = this.props.job._id;

    data = {
      ...data,
      job: jobID,
    };

    let finalFormData = new FormData();
    let keys = Object.keys(data);
    for (let i in keys) {
      finalFormData.append(keys[i], data[keys[i]]);
    }

    return api.logs.add(finalFormData).then((log) => {
      // this.handleClose();
      this.getData();
    });
  };
  onSubmitDebrief = () => {
    let data = this.state.data;
    api.jobs.addDebrief(this.props.match.params.id, data).then(() => {
      this.closeAdd();
      this.getData();
    });
  };
  onSubmitEditDebrief = () => {
    let data = this.state.data;
    api.jobs.editDebrief(this.props.match.params.id, data).then(() => {
      this.closeAdd();
      this.getData();
    });
  };
  updateWelfareCheck = (type) => {
    if (type === "good") {
      let data = {
        resource: this.state.welfare.alert,
        resourceID: this.state.welfare.staffID,
        priority: "Low",
        type: "info",
        message: "Welfare Check, all good.",
      };
      this.submit(data).then(() => {
        this.handleClose();
      });
    } else if (type === "bad") {
      let data = {
        resource: this.state.welfare.alert,
        resourceID: this.state.welfare.staffID,
        priority: "High",
        reqDecision: true,
        type: "info",
        message: "Welfare Check, theres a problem, more info to follow.",
      };
      this.submit(data).then(() => {
        this.handleClose();
      });
    } else {
      let data = {
        // resource: this.state.welfare.name,
        priority: "Medium",
        type: "info",
        message: `Welfare Check, couldnt reach ${this.state.welfare.alert}.`,
      };
      this.submit(data).then(() => {
        this.handleClose();
      });
    }
  };
  setStatus = (res, value) => {
    let data = {
      status: value.name,
    };
    api.jobs.setResourceStatus(this.state.job._id, res.staff._id, data).then(() => {
      this.getData();
    });
  };
  handleOpen = () => this.setState({ welfareModalOpen: true, activeTaskIndex: null });
  handleClose = () => this.setState({ welfareModalOpen: false, activeTaskIndex: null });
  printJob = () => {
    this.setState({
      printing: true,
      printingError: null,
    });
    // let img = this.map.map.state.map.getCanvas().toDataURL();
    api.jobs
      .print(this.state.job._id)
      .then((theData) => {
        this.setState({
          printing: false,
        });

        // Return the below as HTML for debugging

        fileDownloader(theData, "job.pdf");
        // fileDownloader(theData, "task.html");
      })
      .catch((err) => {
        console.error(err);
      });
  };
  renderDateModal() {
    return (
      <Modal open={this.state.addDate} onClose={() => this.setState({ addDate: false })} centered={false} size="tiny" closeOnDimmerClick={true}>
        <Header icon="calendar alternate" content={`Add ${this.state.dateType} Date`} />
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>{this.state.dateType} Date</label>
              <DateTimeInput
                autoComplete={"off"}
                animation={"off"}
                name="date"
                dateTimeFormat="YYYY/MM/DD HH:mm"
                value={moment(this.state.data.date).format("YYYY-MM-DD HH:mm")}
                iconPosition="left"
                onChange={this.handleDropdownChange}
                startMode={"day"}
                closable
              />
            </Form.Field>
            <div className="formActions">
              <Form.Button negative onClick={() => this.cancelDate()}>
                Cancel
              </Form.Button>
              <Form.Button positive onClick={this.onSubmitDate}>
                <Icon name="calendar check" />
                Add
              </Form.Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
  renderAddDebrief() {
    return (
      <Modal open={this.state.openAdd} onClose={() => this.setState({ openAdd: false, edit: false })} centered={false} size="tiny" closeOnDimmerClick={true}>
        <Modal.Header> {this.state.edit ? "Edit " : "Add "} Debrief</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label htmlFor="type">Resource</label>
              <Select
                placeholder={"pick one"}
                name={"resource"}
                options={
                  this.state.job && this.state.job.Resources
                    ? this.state.job.Resources.map((r) => {
                        return {
                          key: r._id,
                          text: r.firstName + " " + r.lastName,
                          value: r._id,
                        };
                      })
                    : []
                }
                value={this.state.data.resource && this.state.data.resource._id}
                onChange={this.handleDropdownChange.bind(this)}
              />
            </Form.Field>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Hours</label>
                <Form.Input name="hours" value={this.state.data.hours} onChange={this.onChange} placeholder="2" />
              </Form.Field>
              <Form.Field>
                <label>Mileage</label>
                <Form.Input name="mileage" value={this.state.data.mileage} onChange={this.onChange} placeholder="45" />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Dontated Expenses</label>
              <Checkbox
                toggle
                checked={this.state.data.donateExpenses}
                label="Donate expenses to team"
                onChange={() =>
                  this.setState({
                    data: {
                      ...this.state.data,
                      donateExpenses: !this.state.data.donateExpenses,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Notes/Details</label>
              <Form.TextArea name="details" value={this.state.data.details} onChange={this.onChange} placeholder="any notes or information" />
            </Form.Field>
            <div className="formActions">
              <Form.Button negative onClick={() => this.closeAdd()}>
                Cancel
              </Form.Button>
              {this.state.edit ? (
                <Form.Button primary onClick={this.onSubmitEditDebrief}>
                  <Icon name="calendar check" />
                  Update
                </Form.Button>
              ) : (
                <Form.Button positive onClick={this.onSubmitDebrief}>
                  <Icon name="calendar check" />
                  Add
                </Form.Button>
              )}
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
  renderAlerts() {
    const { alerts } = this.state;
    return (
      <Box title={"Job Alerts"}>
        <Segment basic>
          {alerts.length === 0 && <Message positive>No Alerts</Message>}
          {alerts.map((a, i) => {
            return <AlertBox key={i} header={a.type} priority={a.priority} content={a.alert} action={a.type === "Welfare Check" ? () => this.doWelfareCheck(a) : undefined} />;
          })}
        </Segment>
      </Box>
    );
  }
  renderOutcome() {
    const { job } = this.state;

    return (
      <Box title={"Job Outcome"}>
        <Segment basic>
          <p>{job.outcome ? job.outcome : "--"}</p>
        </Segment>
      </Box>
    );
  }
  renderDebriefs() {
    const { job } = this.state;

    return (
      <Box title={"Job Debriefs"}>
        <Segment basic className="no-pad">
          <Table celled structured stackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Resource</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Hours</Table.HeaderCell>
                <Table.HeaderCell>Mileage</Table.HeaderCell>
                <Table.HeaderCell>Exp. Donated</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {job.Debriefs.map((d) => {
                return (
                  <React.Fragment key={d._id}>
                    <Table.Row>
                      <Table.Cell rowSpan={2}>
                        {d.resource && d.resource.firstName} {d.resource && d.resource.lastName}
                      </Table.Cell>
                      <Table.Cell>{moment(d.createdAt).format("DD-MM-YYYY HH:mm")}</Table.Cell>
                      <Table.Cell>{d.hours}</Table.Cell>
                      <Table.Cell>{d.mileage}</Table.Cell>
                      <Table.Cell>{d.donateExpenses ? "Yes" : "No"}</Table.Cell>
                      <Table.Cell>
                        <Button
                          size="tiny"
                          inverted
                          primary
                          onClick={() =>
                            this.setState({
                              openAdd: true,
                              data: d,
                              edit: true,
                            })
                          }
                        >
                          Edit
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key={d._id}>
                      <Table.Cell colSpan={5}>{d.details}</Table.Cell>
                    </Table.Row>
                  </React.Fragment>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
      </Box>
    );
  }
  renderWelfareCheckModal() {
    return (
      <Modal open={this.state.welfareModalOpen} onClose={this.handleClose} size="small" centered={false}>
        <Modal.Header>Welfare Check for {this.state.welfare && this.state.welfare.name}</Modal.Header>
        <Modal.Content>
          <Grid textAlign="center">
            <Grid.Row columns={1}>
              <Grid.Column>
                <h2>Contact with {this.state.welfare && this.state.welfare.name} and....</h2>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>
                <Button negative onClick={() => this.updateWelfareCheck("bad")} size="small">
                  <Icon name="warning sign" />
                  Theres a Problem!
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button positive onClick={() => this.updateWelfareCheck("good")} size="small">
                  <Icon name="checkmark" />
                  All Good
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button primary onClick={() => this.updateWelfareCheck("no-contact")} size="small">
                  <Icon name="exclamation" />
                  No contact
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => this.handleClose()} inverted>
            <Icon name="close" /> cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderPanel() {
    return (
      <Box title="Resources">
        <ResourceList
          data={this.state.resources}
          expanded={false}
          hideRoles={false}
          hideHeader={true}
          lastHeard={true}
          action={(staffID) => this.props.history.push(`/staff/${staffID}`)}
          setStatus={this.setStatus}
          status={this.props.settings.responderStatus}
          settings={this.props.settings}
        />
      </Box>
    );
  }
  renderExtra() {
    return (
      <Segment basic>
        {this.state.job.extraData.map((e, i) => {
          return (
            <Label key={i} size={"large"}>
              {e.name} x {e.value}
            </Label>
          );
        })}
      </Segment>
    );
  }
  renderPrintingModal() {
    return (
      <Modal open={this.state.printing}>
        <Modal.Header>Creating PDF</Modal.Header>
        <Modal.Content>
          {this.state.printingError ? (
            <Modal.Description>
              <Message>
                <Message.Header>PDF creation error</Message.Header>
                {this.state.printingError}
              </Message>
              <Button color={"red"} basic onClick={() => this.setState({ printing: false })}>
                Close
              </Button>
            </Modal.Description>
          ) : (
            <Modal.Description>
              <p>Please wait while we create the PDF...</p>
              <Loader />
            </Modal.Description>
          )}
        </Modal.Content>
      </Modal>
    );
  }
  render() {
    const { job } = this.state;
    let statusCheck = "";
    if (job.status === "Closed") {
      statusCheck = " warning";
    }
    console.log(job);
    return (
      <Grid stackable className="no-marg">
        <Grid.Row>
          <Grid.Column width={10}>
            <Segment basic className="no-pad">
              {this.renderWelfareCheckModal()}
              {this.renderDateModal()}
              {this.renderAddDebrief()}
              {this.renderPrintingModal()}
              <Box
                menu={
                  <Menu widths={3}>
                    <Menu.Item onClick={() => this.props.history.push(this.props.match.url + `/edit`)}>
                      <Icon name="pencil" /> Edit
                    </Menu.Item>
                    <Menu.Item onClick={() => this.printJob()}>
                      <Icon name="print" /> Print
                    </Menu.Item>
                    <Menu.Item onClick={this.openAdd}>
                      <Icon name="plus" color="green" /> Add Debrief
                    </Menu.Item>
                  </Menu>
                }
              >
                <Grid celled stackable textAlign="center" className="no-marg dataGrid">
                  {job.client && (
                    <Grid.Row columns={2}>
                      <Grid.Column className="data ">
                        <Header>Client</Header>
                        <p>{job.client && job.TheClient}</p>
                      </Grid.Column>
                      <Grid.Column className="data ">
                        <Header>3rd Party Ref.</Header>
                        <p>{job.otherRef ? job.otherRef : "--"}</p>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <Grid.Row columns={3}>
                    <Grid.Column className="data clickable" onClick={() => this.setDate("Date")}>
                      <Header>
                        Date <Icon name="calendar alternate" />
                      </Header>
                      <p>{job.date ? moment(job.date).format("DD-MM-YYYY HH:mm") : "--"}</p>
                    </Grid.Column>
                    <Grid.Column className="data clickable" onClick={() => this.setDate("Start")}>
                      <Header>
                        Start Date <Icon name="calendar alternate" />
                      </Header>
                      <p>{job.startTime ? moment(job.startTime).format("DD-MM-YYYY HH:mm") : "--"}</p>
                    </Grid.Column>
                    <Grid.Column className="data clickable" onClick={() => this.setDate("Completed")}>
                      <Header>
                        Finish Date <Icon name="calendar alternate" />
                      </Header>
                      <p>{job.endTime ? moment(job.endTime).format("DD-MM-YYYY HH:mm") : "--"}</p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={3}>
                    <Grid.Column className={`data ${statusCheck}`}>
                      <Header>Status</Header>
                      <p>{job.status ? job.status : "--"}</p>
                    </Grid.Column>
                    <Grid.Column className="data">
                      <Header>Type</Header>
                      <p>{job.type ? job.type : "--"}</p>
                    </Grid.Column>
                    <Grid.Column className="data">
                      <Header>Priority</Header>
                      <p>{job.priority ? job.priority : "--"}</p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={4}>
                    <Grid.Column className={`data `}>
                      <Header>Created By</Header>
                      <p>{job.loggedBy ? job.loggedBy : "--"}</p>
                    </Grid.Column>
                    <Grid.Column className="data">
                      <Header>Created @</Header>
                      <p>{job.createdAt ? moment(job.createdAt).format("DD-MM-YYYY HH:mm") : "--"}</p>
                    </Grid.Column>
                    <Grid.Column className="data">
                      <Header>Our Other Ref</Header>
                      <p>{job.secondRef ? job.secondRef : "--"}</p>
                    </Grid.Column>
                    <Grid.Column className="data">
                      <Header>3rd Party Ref</Header>
                      <p>{job.otherRef ? job.otherRef : "--"}</p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1}>
                    <Grid.Column className="data" textAlign="left">
                      <Header>Details</Header>
                      <p>{job.details ? job.details : "--"}</p>
                    </Grid.Column>
                  </Grid.Row>
                  {job.extraData && job.extraData.length > 0 && (
                    <Grid.Row columns={1}>
                      <Grid.Column className="data">
                        <Header>Extra Data</Header>
                        {this.renderExtra()}
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Grid>
              </Box>
            </Segment>
            {job.Debriefs && job.Debriefs.length > 0 && this.renderDebriefs()}
          </Grid.Column>
          <Grid.Column width={6}>
            {this.renderAlerts()}
            <Divider hidden />
            {job.status === "Closed" && this.renderOutcome()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}> {this.renderPanel()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
let TheJobPage = Settings_Receiver(JobPage, (settings) => {
  return { settings };
});
export default Job_Receiver(TheJobPage, (job) => {
  return { job };
});
