import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container, Segment, Responsive } from "semantic-ui-react";
import JobPage from "./jobPage";
import JobNav from "./subNav";
import JobMap from "./jobMap";
import Job_Provider from "../../contexts/job_context/Job_Provider";
import api from "../../actions/api";
import LogPage from "./log";
import jobLocations from "./jobLocations";
import editJob from "./editJob";
import jobResources from "./jobResources";
import ResourceMap from "./resourceMap";
import closeJob from "./closeJob";
import weather from "./weather/newweather";
import SMS from "../sms";
import reopenJob from "./reopenJob";

export default class JobPages extends Component {
  state = {};
  componentDidMount = () => {};
  render() {
    return (
      <Container fluid style={{ padding: 0 }}>
        <Job_Provider api={api}>
          <JobNav
            match={this.props.match}
            history={this.props.history}
            job={this.props.job}
          />
          {/* <Responsive as={Segment} basic className={"mainContent"} attached> */}
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}/map`}
              component={JobMap}
            />
            <Route
              exact
              path={`${this.props.match.url}/log`}
              component={LogPage}
            />
            <Route
              exact
              path={`${this.props.match.url}/locations`}
              component={jobLocations}
            />
            <Route
              exact
              path={`${this.props.match.url}/resources`}
              component={jobResources}
            />
            <Route
              exact
              path={`${this.props.match.url}/weather`}
              component={weather}
            />
            <Route
              exact
              path={`/jobs/:id/resourcemap`}
              component={ResourceMap}
            />
            <Route path={`/jobs/:id/sms`} component={SMS} />

            <Route path={`${this.props.match.url}/edit`} component={editJob} />
            <Route exact path={`/jobs/:id/close`} component={closeJob} />
            <Route exact path={`/jobs/:id/reopen`} component={reopenJob} />
            <Route path={`/jobs/:id`} component={JobPage} />
            {/* <Route exact path={`/events/:id/tasks/:tid`} component={TaskDash} />
            <Route exact path={`/events/:id/tasks/:tid/update`} component={UpdateTask} />
            <Route exact path={`/events/:id/tasks`} render={(props) => <TaskList {...props} />} /> */}
          </Switch>
          {/* </Responsive> */}
        </Job_Provider>
      </Container>
    );
  }
}
