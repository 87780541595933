import React, { useState, useEffect } from "react";
import OvenPlayer from "ovenplayer";
import API from "../../../actions/api";

export const VideoPlayer = (props) => {
  const { options } = props;
  const [isLive, setIsLive] = useState(false);

  const runCheck = () => {
    if (props.dontPollForLive !== undefined && props.dontPollForLive){
      if (!isLive) {
        setIsLive(true);
      }
      return;
    }
    API.live_feeds.isFeedLive(options.asset_id, options.id).then((res) => {
      setIsLive(res);
    });
  };
  useEffect(() => {
    runCheck();
    const interVal = setInterval(() => {
      runCheck();
    }, 5000);
    return () => {
      clearInterval(interVal);
    };
  }, []);

  useEffect(() => {
    const player = OvenPlayer.create("video-player_" + options.id, {
      sources: [
        {
          label: "Low Latency Feed",
          type: "webrtc",
          file: options.sources.ws,
        },
        {
          label: "HLS Feed",
          type: "hls",
          file: options.sources.hls,
        },
      ],
    });
    if (!isLive) {
      player.remove();
    } else {
      player.on("ready", () => {
        player.play();
      });
    }
  }, [options, isLive]);

  return (
    <React.Fragment>
      <div id={"video-player_" + options.id}>{""}</div>
      {!isLive && <p>Stream currently offline</p>}
    </React.Fragment>
  );
};
export default VideoPlayer;
