import React, {useEffect, useState} from "react";
import API from "../../../actions/api";
import {Message} from "semantic-ui-react";
import SingleFeedDisplay from "./singleFeedDisplay";

const CentralUserFeedsList = () => {
    const [centralUsers, setCentralUsers] = useState([]);

    const repeatingTask = () => {
        API.live_feeds.getActiveCentralUserFeeds().then((res) => {
            const toAdd = res.filter((a) => {
                return centralUsers.filter((b) => b._id === a._id).length === 0;
            });
            const toRemove = centralUsers.filter((a) => {
                return res.filter((b) => b._id === a._id).length === 0;
            });
            if (toAdd.length || toRemove.length) {
                setCentralUsers(res);
            }
        })
            .catch((err) => {});
    };
    useEffect(() => {
        const pollingCentralFeeds = setInterval(() => repeatingTask(), 5000);
        return () => {
            clearInterval(pollingCentralFeeds);
        };
    }, [centralUsers]);

    return (
        <React.Fragment>
            {centralUsers.length === 0 && (
                <Message color="black">
                    There are no active user Mobile App Feeds
                </Message>
            )}
            {centralUsers.map((centralUserAsset) => {
                return (
                    <div className={"p-4 mt-4 border border-gray-700"} key={centralUserAsset._id}>
                        <h1 className={"text-xl"}>{centralUserAsset.name}</h1>
                        <div className="w-full flex gap-2">
                            {centralUserAsset.feeds.map((feed) => {
                                return (
                                    <SingleFeedDisplay name={feed.alias} feedOptions={{
                                        asset_id: centralUserAsset._id,
                                        id: feed._id,
                                        sources: feed.streamFeeds,
                                    }} key={feed._id} />
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </React.Fragment>
    );
};
export default CentralUserFeedsList;