import React, { Component } from "react";
import {
  Grid,
  Header,
  Segment,
  Divider,
  Menu,
  Form,
  Button,
  Icon,
  Input,
  Dropdown,
  Table,
  Label,
} from "semantic-ui-react";
import Geosuggest from "react-geosuggest";
import CommonFunctions from "../../assets/utils/commonFunctions";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import api from "../../actions/api";
import TaskMap from "../common/mapping/taskMap";
import Box from "./box";
import OrderedLocationList from "./lists/orderedLocationList";
import PropTypes from "prop-types";

//TODO on all edit locations need to show them rather than overwrite
class LocationBox extends Component {
  static propTypes = {
    addLoc: PropTypes.func.isRequired,
    locations: PropTypes.array,
    depts: PropTypes.array,
    cancel: PropTypes.func,
    predefined: PropTypes.bool,
    formLists: PropTypes.object,
  };
  state = {
    center: [0.9, 51.1],
    locations: [],
    w3w: {
      first: "",
      second: "",
      third: "",
    },
    type: "address",
    marker: {
      geoJSON: {
        geometry: {},
      },
    },
    en: {
      easting: "",
      northing: "",
    },
    editMarker: null,
    errors: {},
    grid: "",
    val: {},
    suggestion: false,
    zones: [],
    updated: false,
    settings: {
      locTypes: [],
    },
  };
  componentDidMount() {
    if (this.props.locations) {
      this.setState({
        locations: [].concat(this.props.locations),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.locations !== prevProps.locations) {
      this.setState({
        locations: [].concat(this.props.locations),
      });
    }
  }

  getData = () => {
    api.locations.all().then((locations) => {
      this.setState({
        locations,
      });
    });
  };
  validateGrid = (data) => {
    const errors = {};
    //TODO Tim can you check the first two characters are text and the reaming digits are even in number
    if (!data) errors.grid = true;
    return errors;
  };
  validateEN = (e, n) => {
    const errors = {};
    //TODO Tim can you check the first two characters are text and the reaming digits are even in number
    if (!e) errors.e = true;
    if (!n) errors.n = true;
    return errors;
  };
  handleGridChange = (e) => {
    this.setState({
      grid: e.target.value.toUpperCase(),
    });
  };
  handleW3WChange = (e) => {
    this.setState({
      w3w: {
        ...this.state.w3w,
        [e.target.name]: e.target.value,
      },
      w3wError: "",
    });
  };
  handleENChange = (e) => {
    this.setState({
      en: {
        ...this.state.en,
        [e.target.name]: e.target.value,
      },
      w3wError: "",
    });
  };
  handleChange = (e) => {
    this.setState({
      marker: {
        ...this.state.marker,
        geoJSON: {
          ...this.state.marker.geoJSON,
          properties: {
            ...this.state.marker.geoJSON.properties,
            [e.target.name]: e.target.value,
          },
        },
      },
    });
  };
  handleLocationDropdownChange = (props, e) => {
    this.setState({
      marker: {
        ...this.state.marker,
        geoJSON: {
          ...this.state.marker.geoJSON,
          properties: {
            ...this.state.marker.geoJSON.properties,
            // task: this.state.data.type,

            [e.name]: e.value,
          },
        },
      },
    });
  };
  handlePredefinedDropdownChange = (props, e) => {
    if (e.value) {
      let loc = this.props.locs.locations.filter((l) => {
        return l._id === e.value;
      });

      this.setState({
        center: loc[0].geoJSON.geometry.coordinates,
        predefined: loc[0]._id,
        marker: {
          ...this.state.marker,
          geoJSON: {
            ...loc[0].geoJSON,
            properties: {
              ...this.state.marker.geoJSON.properties,
              ...loc[0].geoJSON.properties,
            },
            geometry: {
              ...loc[0].geoJSON.geometry,
            },
          },
        },
      });
    }
  };
  onSuggestSelect = (suggest) => {
    if (suggest) {
      let coords = [suggest.location.lng, suggest.location.lat];
      let components = suggest.gmaps.address_components;
      let admin_area = "";
      let postal_town = "";
      let postal_code = "";
      // eslint-disable-next-line
      let name = "";
      // eslint-disable-next-line
      let street = "";
      let county = "";
      let town = "";
      if (suggest.gmaps.name) {
        name = suggest.gmaps.name;
      }

      for (let component = 0; component < components.length; component++) {
        if (components[component].types[0] === "premise" && name === "") {
          name = components[component].long_name;
        }

        if (components[component].types[0] === "route") {
          street = components[component].long_name;
        }
        if (components[component].types[0] === "locality") {
          admin_area = components[component].long_name;
        }
        if (components[component].types[0] === "postal_town") {
          postal_town = components[component].long_name;
        }
        if (components[component].types[0] === "administrative_area_level_2") {
          county = components[component].long_name;
        }
        if (components[component].types[0] === "postal_code") {
          postal_code = components[component].long_name;
        }
        if (admin_area) {
          town = admin_area;
        } else {
          town = postal_town;
        }
      }
      this.setState({
        suggestion: true,
        marker: {
          geoJSON: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coords,
            },
            properties: {
              ...this.state.marker.geoJSON.properties,
              name: name,
              town: town,
              county: county,
              postcode: postal_code,
              address: suggest.gmaps.formatted_address,
              grid: this.getGrid(suggest.location.lat, suggest.location.lng),
            },
          },
        },

        // val: suggest,
        // zoom: 10
      });
    }
  };
  validate = (data) => {
    const errors = {};
    //TODO Tim can you check the first two characters are text and the reaming digits are even in number
    // if (!data.geoJSON.properties.type) errors.type = "Need to pick a type.";
    return errors;
  };
  onClickMap = (data) => {

    if (data) {
      let coords = [
        parseFloat(data[0].toFixed(5)),
        parseFloat(data[1].toFixed(5)),
      ];
      if (coords.length) {
        // this.props.data(coords);
        this.setState({
          center: coords,
          marker: {
            geoJSON: {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: coords,
              },
              properties: {
                ...this.state.marker.geoJSON.properties,
                job: this.props.job.jobNum,
                grid: this.getGrid(coords[1], coords[0]),
              },
            },
          },
        });
      }
    }
  };
  addPoint = () => {
    console.log('here', this.props);
    const errors = this.validate(this.state.marker);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.state.marker;
      let locs = this.state.locations;
      if (this.state.editMarker !== null) {
        locs = locs.map((item, i) => {
          if (i !== this.state.editMarker) {
            return item;
          } else {
            return data;
          }
        });
      } else {
        locs.push(data);
      }

      this.setState({
        locations: locs,
        updated: true,
        editMarker: null,
        marker: {
          geoJSON: {
            geometry: {},
          },
        },
      });
    }
  };
  updateLocations = () => {
    this.props.addLoc(this.state.locations);
    this.setState(
      {
        updated: false,
      },
      () => {
        this.props.cancel();
      }
    );
  };
  findW3w = () => {
    if (this.state.w3w.first && this.state.w3w.second && this.state.w3w.third) {
      CommonFunctions.findW3w(
        this.state.w3w.first,
        this.state.w3w.second,
        this.state.w3w.third
      )
        .then((w3w) => {
          let marker = {
            geoJSON: {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [w3w.coordinates.lng, w3w.coordinates.lat],
              },
              properties: {
                ...this.state.marker.geoJSON.properties,
                grid: this.getGrid(w3w.coordinates.lat, w3w.coordinates.lng),
                name: w3w.words,
                w3w: w3w.words,
              },
            },
          };
          this.setState({
            marker: marker,
            w3wError: "",
          });
        })
        .catch((error) => {
          // catch errors here
          if (error.code) {
            this.setState({
              w3wError: error.code,
            });
          }
        });
    }
  };
  getGrid = (lat, lng) => {
    return CommonFunctions.getGrid(lat, lng, this.props.gridType);
  };
  getLatLng = (grid) => {
    let coords = CommonFunctions.getLatLng(grid, this.props.settings.gridType);

    return coords;
  };
  getLatLngEN = (e, n) => {
    return CommonFunctions.getLatLngEN(e, n);
  };
  getLatLngUTM = (data) => {
    return CommonFunctions.getLatLngUTM(data);
  };
  findGrid = () => {
    let bob = this.getLatLng(this.state.grid);
    if (bob) {
      let geoJSON = {
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: bob,
          },
          properties: {
            ...this.state.marker.geoJSON.properties,
            name: this.state.grid,
            grid: this.state.grid,
          },
        },
      };
      this.setState({
        marker: geoJSON,
        grid: "",
      });
    }
  };
  findUTM = () => {
    const errors = this.validateGrid(this.state.grid);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.getLatLngUTM(this.state.grid);
      let geoJSON = {
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [data.longitude, data.latitude],
          },
          properties: {
            ...this.state.marker.geoJSON.properties,
            name: data.grid,
            grid: data.grid,
          },
        },
      };
      this.setState({
        marker: geoJSON,
        grid: "",
      });
    }
  };
  findEN = () => {
    const errors = this.validateEN(
      this.state.en.easting,
      this.state.en.northing
    );
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.getLatLngEN(
        this.state.en.easting,
        this.state.en.northing
      );
      let geoJSON = {
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [data.longitude, data.latitude],
          },
          properties: {
            ...this.state.marker.geoJSON.properties,
            name: data.grid,
            grid: this.getGrid(data.latitude, data.longitude),
          },
        },
      };
      this.setState({
        marker: geoJSON,
        grid: "",
      });
    }
  };
  handleOrderedLocationUpdate = (data) => {
    this.setState({
      updated: true,
      locations: data.locations.map((item, i) => {
        return {
          ...item,
          _id: i,
        };
      }),
    });
  };
  renderBasicLocationForm() {
    let disabled = true;
    if (
      this.state.marker.geoJSON.properties &&
      this.state.marker.geoJSON.properties.type
    ) {
      disabled = false;
    }
    return (
      <Segment basic className="no-vpad">
        {this.state.marker.geoJSON &&
          this.state.marker.geoJSON.geometry &&
          this.state.marker.geoJSON.geometry.coordinates && (
            <Form>
              <Form.Field>
                <label>Location Name</label>
                <input
                  name="name"
                  onChange={this.handleChange}
                  defaultValue={this.state.marker.geoJSON.properties.name}
                  placeholder={"name...."}
                />
              </Form.Field>
              <Form.Group widths={4}>
                <Form.Field>
                  <label>Grid</label>
                  <input
                    defaultValue={this.state.marker.geoJSON.properties.grid}
                    readOnly
                  />
                </Form.Field>
                <Form.Field>
                  <label>Type</label>
                  <Dropdown
                    name="type"
                    label="Location Type"
                    value={this.state.marker.geoJSON.properties.type}
                    onChange={this.handleLocationDropdownChange}
                    options={this.props.formLists.LocTypes}
                    selection
                    placeholder="Type"
                    required={true}
                    className="required"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Department</label>
                  <input
                    name="department"
                    defaultValue={
                      this.state.marker.geoJSON.properties.department
                    }
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Time</label>
                  <input
                    name="time"
                    defaultValue={this.state.marker.geoJSON.properties.time}
                    onChange={this.handleChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={2}>
                <Form.Field>
                  <label>Address</label>
                  <input
                    name="address"
                    onChange={this.handleChange}
                    defaultValue={this.state.marker.geoJSON.properties.address}
                    placeholder={"name...."}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Postcode</label>
                  <input
                    name="postcode"
                    onChange={this.handleChange}
                    defaultValue={this.state.marker.geoJSON.properties.postcode}
                    placeholder={"name...."}
                  />
                </Form.Field>
              </Form.Group>

              <Divider />
              <Form.Group widths={"equal"} className={"form-actions"}>
                <Form.Field>
                  <Button
                    content="Cancel"
                    negative
                    fluid
                    onClick={() => this.props.cancel()}
                    labelPosition="left"
                    icon="close"
                  />
                </Form.Field>
                <Form.Field>
                  <Button
                    content={
                      this.state.editMarker === null
                        ? "Select Location"
                        : "Update Location"
                    }
                    fluid
                    labelPosition="right"
                    icon="checkmark"
                    onClick={() => this.addPoint(this.state.marker)}
                    primary
                    disabled={disabled}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          )}
      </Segment>
    );
  }
  renderMap() {
    return (
      <TaskMap
        mapStyle={{ minHeight: "60vh" }}
        center={this.state.center}
        data={this.onClickMap}
        location={this.state.marker}
        zones={this.state.zones}
        locations={this.state.locations}
      />
    );
  }
  renderLocationBar() {
    let w3disabled = true;
    // eslint-disable-next-line
    if (
      this.state.w3w.first !== "" &&
      this.state.w3w.second !== "" &&
      this.state.w3w.third !== ""
    ) {
      w3disabled = false;
    }
    let endisabled = true;
    // eslint-disable-next-line
    if (this.state.en.easting !== "" && this.state.en.northing !== "") {
      endisabled = false;
    }
    let disabled = true;
    if (
      this.state.marker.geoJSON.properties &&
      this.state.marker.geoJSON.properties.type
    ) {
      disabled = false;
    }
    return (
      <Box title="Location Box">
        <Segment basic className="no-pad no-marg">
          <Menu widths={5}>
            <Menu.Item
              fitted="horizontally"
              active={this.state.type === "predefined"}
              onClick={() => this.setState({ type: "predefined" })}
            >
              <Icon name="map signs" size="small" />
              Predefined
            </Menu.Item>
            <Menu.Item
              fitted="horizontally"
              active={this.state.type === "address"}
              onClick={() => this.setState({ type: "address" })}
            >
              <Icon name="map signs" size="small" />
              Address
            </Menu.Item>
            <Menu.Item
              active={this.state.type === "grid"}
              onClick={() => this.setState({ type: "grid" })}
            >
              <Icon name="map" size="small" />
              Grid
            </Menu.Item>
            <Menu.Item
              active={this.state.type === "en"}
              onClick={() => this.setState({ type: "en" })}
            >
              <Icon name="map" size="small" />
              E/N
            </Menu.Item>
            <Menu.Item
              fitted="horizontally"
              active={this.state.type === "w3w"}
              onClick={() => this.setState({ type: "w3w" })}
            >
              W3W
            </Menu.Item>
          </Menu>

          {/* <Divider /> */}
          <Segment basic>
            <Grid>
              {this.state.type === "predefined" && (
                <Grid.Column width={16}>
                  <Segment basic>
                    <Form>
                      <Form.Group>
                        <Form.Field width={4}>
                          <label>Type</label>
                          <Dropdown
                            name="type"
                            label="Location Type"
                            // value={this.state.marker.geoJSON.properties.type}
                            onChange={this.handleLocationDropdownChange}
                            options={this.props.formLists.LocTypes}
                            selection
                            placeholder="Type"
                            required={true}
                            className="required"
                          />
                        </Form.Field>
                        <Form.Field width={3}>
                          <label>Time</label>
                          <Input
                            disabled={disabled}
                            name="time"
                            id={"time"}
                            placeholder="10:00"
                            onChange={this.handleChange}
                          />
                        </Form.Field>
                        <Form.Field width={9}>
                          <label>Location</label>
                          <Dropdown
                            disabled={disabled}
                            name="location"
                            label="Location"
                            value={this.state.predefined}
                            onChange={this.handlePredefinedDropdownChange}
                            options={this.props.locs.formList}
                            selection
                            search
                            placeholder="Location"
                            required={true}
                          />
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Segment>
                </Grid.Column>
              )}
              {this.props.settings.gridType === "OSGB" &&
                this.state.type === "grid" && (
                  <Grid.Column width={16}>
                    <Segment basic>
                      <Form onSubmit={() => this.findGrid()}>
                        <Form.Group>
                          <Form.Field width={4}>
                            <label>Type</label>
                            <Dropdown
                              name="type"
                              label="Location Type"
                              // value={this.state.marker.geoJSON.properties.type}
                              onChange={this.handleLocationDropdownChange}
                              options={this.props.formLists.LocTypes}
                              selection
                              placeholder="Type"
                              required={true}
                              className="required"
                            />
                          </Form.Field>
                          <Form.Field width={3}>
                            <label>Time</label>
                            <Input
                              disabled={disabled}
                              name="time"
                              id={"time"}
                              placeholder="10:00"
                              onChange={this.handleChange}
                            />
                          </Form.Field>
                          <Form.Field
                            error={!!this.state.errors.grid}
                            width={9}
                          >
                            <label>Grid</label>
                            <Input
                              name="grid"
                              id={"grid-search"}
                              action={{ icon: "search" }}
                              placeholder="TQ1234..."
                              value={this.state.grid}
                              onChange={this.handleGridChange}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form>
                    </Segment>
                  </Grid.Column>
                )}
              {this.props.settings.gridType === "OSIE" &&
                this.state.type === "grid" && (
                  <Grid.Column width={16}>
                    <Segment basic>
                      <Form onSubmit={() => this.findGrid()}>
                        <Form.Group>
                          <Form.Field width={4}>
                            <label>Type</label>
                            <Dropdown
                              name="type"
                              label="Location Type"
                              // value={this.state.marker.geoJSON.properties.type}
                              onChange={this.handleLocationDropdownChange}
                              options={this.props.formLists.LocTypes}
                              selection
                              placeholder="Type"
                              required={true}
                              className="required"
                            />
                          </Form.Field>
                          <Form.Field width={3}>
                            <label>Time</label>
                            <Input
                              disabled={disabled}
                              name="time"
                              id={"time"}
                              placeholder="10:00"
                              onChange={this.handleChange}
                            />
                          </Form.Field>
                          <Form.Field
                            error={!!this.state.errors.grid}
                            width={9}
                          >
                            <label>Grid</label>
                            <Input
                              name="grid"
                              id={"grid-search"}
                              action={{ icon: "search" }}
                              placeholder="T01234..."
                              value={this.state.grid}
                              onChange={this.handleGridChange}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form>
                    </Segment>
                  </Grid.Column>
                )}
              {this.props.settings.gridType === "UTM" &&
                this.state.type === "grid" && (
                  <Grid.Column width={16}>
                    <Segment basic>
                      <Form onSubmit={() => this.findUTM()}>
                        <Form.Group>
                          <Form.Field width={4}>
                            <Dropdown
                              name="type"
                              label="Location Type"
                              // value={this.state.marker.geoJSON.properties.type}
                              onChange={this.handleLocationDropdownChange}
                              options={this.props.formLists.LocTypes}
                              selection
                              placeholder="Type"
                              required={true}
                              className="required"
                            />
                          </Form.Field>
                          <Form.Field
                            error={!!this.state.errors.grid}
                            width={12}
                          >
                            <Input
                              name="grid"
                              id={"grid-search"}
                              action={{ icon: "search" }}
                              placeholder="31 N358 567..."
                              value={this.state.grid}
                              onChange={this.handleGridChange}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form>
                    </Segment>
                  </Grid.Column>
                )}

              {this.state.type === "w3w" && (
                <Grid.Column width={16}>
                  {/* <Header textAlign={"center"}>{this.state.marker !== null ? "Grid: " + this.state.marker.geoJSON.properties.grid : "Grid: --"}</Header> */}
                  <Segment basic>
                    <Form>
                      <Form.Group>
                        <Form.Field width={4}>
                          <label>Type</label>
                          <Dropdown
                            name="type"
                            label="Location Type"
                            // value={this.state.marker.geoJSON.properties.type}
                            onChange={this.handleLocationDropdownChange}
                            options={this.props.formLists.LocTypes}
                            selection
                            placeholder="Type"
                            required={true}
                            className="required"
                          />
                        </Form.Field>
                        <Form.Field width={3}>
                          <label>Time</label>
                          <Input
                            disabled={disabled}
                            name="time"
                            id={"time"}
                            placeholder="10:00"
                            onChange={this.handleChange}
                          />
                        </Form.Field>
                        <Form.Field width={3}>
                          <label>First</label>
                          <input
                            name="first"
                            value={this.state.w3w.first}
                            placeholder="first word"
                            onChange={this.handleW3WChange}
                          />
                        </Form.Field>
                        <Form.Field width={3}>
                          <label>Second</label>
                          <input
                            name="second"
                            value={this.state.w3w.second}
                            placeholder="second word"
                            onChange={this.handleW3WChange}
                          />
                        </Form.Field>
                        <Form.Field width={3}>
                          <label>Third</label>
                          <input
                            name="third"
                            value={this.state.w3w.third}
                            placeholder="third word"
                            onChange={this.handleW3WChange}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Field>
                        <Button
                          onClick={() => this.findW3w()}
                          color="blue"
                          fluid
                          disabled={w3disabled}
                        >
                          Find
                        </Button>
                      </Form.Field>
                      {this.state.w3wError && (
                        <Header color="red" textAlign="center">
                          {this.state.w3wError}
                        </Header>
                      )}
                    </Form>
                  </Segment>
                </Grid.Column>
              )}
              {this.state.type === "en" && (
                <Grid.Column width={16}>
                  {/* <Header textAlign={"center"}>{this.state.marker !== null ? "Grid: " + this.state.marker.geoJSON.properties.grid : "Grid: --"}</Header> */}
                  <Segment basic>
                    <Form>
                      <Form.Group>
                        <Form.Field width={4}>
                          <label>Type</label>
                          <Dropdown
                            name="type"
                            label="Location Type"
                            // value={this.state.marker.geoJSON.properties.type}
                            onChange={this.handleLocationDropdownChange}
                            options={this.props.formLists.LocTypes}
                            selection
                            placeholder="Type"
                            required={true}
                            className="required"
                          />
                        </Form.Field>
                        <Form.Field width={3}>
                          <label>Time</label>
                          <Input
                            disabled={disabled}
                            name="time"
                            id={"time"}
                            placeholder="10:00"
                            onChange={this.handleChange}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>Easting</label>
                          <input
                            name="easting"
                            value={this.state.en.easting}
                            placeholder="easting"
                            onChange={this.handleENChange}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>Northing</label>
                          <input
                            name="northing"
                            value={this.state.en.northing}
                            placeholder="northing"
                            onChange={this.handleENChange}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Field>
                        <Button
                          onClick={() => this.findEN()}
                          color="blue"
                          fluid
                          disabled={endisabled}
                        >
                          Find
                        </Button>
                      </Form.Field>
                      {this.state.w3wError && (
                        <Header color="red" textAlign="center">
                          {this.state.w3wError}
                        </Header>
                      )}
                    </Form>
                  </Segment>
                </Grid.Column>
              )}
              {this.state.type === "address" && (
                <Grid.Column width={16} className="no-pad">
                  <Segment basic>
                    <Form>
                      <Form.Group>
                        <Form.Field width={4}>
                          <label>Type</label>
                          <Dropdown
                            name="type"
                            label="Location Type"
                            // value={this.state.marker.geoJSON.properties.type}
                            onChange={this.handleLocationDropdownChange}
                            options={this.props.formLists.LocTypes}
                            selection
                            placeholder="Type"
                            required={true}
                            className="required"
                          />
                        </Form.Field>
                        <Form.Field width={3}>
                          <label>Time</label>
                          <Input
                            disabled={disabled}
                            name="time"
                            id={"time"}
                            placeholder="10:00"
                            onChange={this.handleChange}
                          />
                        </Form.Field>
                        <Form.Field width={9}>
                          <label>Address</label>
                          <Geosuggest
                            autoComplete="off"
                            ref={(el) => (this._geoSuggest = el)}
                            placeholder="Tesco, Ashford, Kent"
                            inputClassName="geosuggest__input"
                            country={this.props.settings.country}
                            //   location={new window.google.maps.LatLng(center[1], center[0])}
                            //   radius={10000}
                            onSuggestSelect={this.onSuggestSelect}
                            suggestsClassName="geosuggest__suggests"
                            suggestsHiddenClassName="geosuggest__suggests--hidden"
                            suggestItemClassName="geosuggest__item"
                          />
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Segment>
                </Grid.Column>
              )}
            </Grid>
          </Segment>
          <Divider />
          {this.state.marker !== null && this.renderBasicLocationForm()}
        </Segment>
      </Box>
    );
  }
  renderLocations() {
    return (
      <OrderedLocationList
        submit={this.handleOrderedLocationUpdate}
        delete={this.handleOrderedLocationUpdate}
        data={this.state.locations.map((item, index) => {
          return {
            ...item,
            _id: index,
          };
        })}
        allowEdit={true}
        edit={(item, index) =>
          this.setState({ marker: item, editMarker: index })
        }
        disabled={this.state.editMarker !== null ? true : false}
        placeholder="task status.."
        title="Job Locations"
        field="locations"
      />
    );
  }
  render() {
    return (
      <Grid columns="2" className="no-pad no-marg">
        <Grid.Column className="no-pad ">
          {this.renderLocationBar()}

          {this.state.updated && (
            <Segment basic>
              <Header>Locations Changed Update?</Header>
              <Button.Group widths={2}>
                <Button fluid negative onClick={() => this.props.cancel()}>
                  <Icon name="close" /> Cancel
                </Button>
                <Button fluid positive onClick={this.updateLocations}>
                  <Icon name="plus" /> Update
                </Button>
              </Button.Group>
            </Segment>
          )}
          {this.renderLocations()}
        </Grid.Column>
        <Grid.Column className="no-vpad "> {this.renderMap()}</Grid.Column>
      </Grid>
    );
  }
}
export default Settings_Receiver(LocationBox, (settings) => {
  return {
    settings,
  };
});
