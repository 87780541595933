import React, { Component } from "react";
import { Segment, Header, Button, Sidebar, Menu } from "semantic-ui-react";
import { GoogleMap, TrafficLayer } from "@react-google-maps/api";
import MapContent from "./mapContent";
import MapPolygonContent from "./mapPolygonContent";
import Tracks from "./tracks";
import MapZoneContent from "./mapZoneContent";
// import MapRingContent from "./mapRingContent";

export default class GoogleMap2 extends Component {
  static defaultProps = {
    // style: { height: "64vh", width: "100%" },
    zoom: 14,
  };
  state = {
    center: {
      lat: 0,
      lng: 0
    },
    map: {},
    showMessage: false,
    drawPoly: false,
    showJobs: true,
    showTraffic: true,
    showAreas: true,
    showPlans: true,
    showTasks: true,
    showMasts: true,
    visible: true,
  };
  componentDidMount() {
    if (this.props.center) {
      this.setState({
        center: {
          lat: parseFloat(this.props.center[1]),
          lng: parseFloat(this.props.center[0]),
        },
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.center !== prevProps.center) {
      // if (this.props.center !== [0, 0]) {
      this.setState({
        center: {
          lat: parseFloat(this.props.center[1]),
          lng: parseFloat(this.props.center[0]),
        },
      });
      // }
    }
  }
  handleMapLoad = (map) => {
    this.setState((state) => ({ ...state, map }));
    this.mapBounds(map);
  };
  mapBounds = (map) => {
    if (this.props.locations && this.props.locations.length > 0) {
      setTimeout(() => {
        let bounds = new window.google.maps.LatLngBounds();
        this.props.locations.forEach((loc) => {
          if (loc.geoJSON.geometry.type === "Polygon") {
            return;
          }
          bounds.extend(
            new window.google.maps.LatLng(
              parseFloat(loc.geoJSON.geometry.coordinates[1]),
              parseFloat(loc.geoJSON.geometry.coordinates[0])
            )
          );
        });

        map.fitBounds(bounds);
      }, 10);
    }
    if (this.props.jobs && this.props.jobs.length > 0) {
      setTimeout(() => {
        let bounds = new window.google.maps.LatLngBounds();
        this.props.jobs.forEach((loc) => {
          if (loc.geometry.type === "Polygon") {
            return;
          }
          bounds.extend(
            new window.google.maps.LatLng(
              parseFloat(loc.geometry.coordinates[1]),
              parseFloat(loc.geometry.coordinates[0])
            )
          );
        });

        map.fitBounds(bounds);
      }, 10);
    }

  };
  handleMarkerClick = (marker) => {
    // console.log("marker Click: ", marker);
  };
  changeMap = () => {
    this.setState({
      type: this.state.map.mapTypeId,
    });
  };
  onLoad = (drawingManager) => {
    // console.log(drawingManager);
  };

  renderMessage() {
    return (
      <div className="messageBox">
        <Header>un-saved changes....</Header>
        <Button positive onClick={() => this.sendChanges()} size="tiny">
          Click when done
        </Button>
      </div>
    );
  }
  renderLayersBar() {
    return (
      <Sidebar.Pushable className="layerBar1">
        <Sidebar
          as={Menu}
          animation="overlay"
          vertical
          visible={this.state.visible}
          width="thin"
        >

          <Menu.Item
            active={this.state.showTraffic}
            onClick={() =>
              this.setState({ showTraffic: !this.state.showTraffic })
            }
          >
            Traffic
          </Menu.Item>

          {this.props.jobs && this.props.jobs.length > 0 && (
            <Menu.Item
              active={this.state.showJobs}
              onClick={() => this.setState({ showJobs: !this.state.showJobs })}
            >
              Jobs
            </Menu.Item>
          )}

        </Sidebar>
      </Sidebar.Pushable>
    );
  }
  render() {
    console.log(this.state.center);
    return (
      <Segment basic style={this.props.mapStyle} className="no-pad googleMap">
        {/* <LoadScript
          googleMapsApiKey={"AIzaSyDP4JsKkKGbc1WZK7B1Dq2GJ0uOhH2MhqM"}
        > */}
        {this.props.center && this.state.center.lat && (
          <GoogleMap
            mapContainerStyle={this.props.mapStyle}
            zoom={this.props.zoom}
            mapTypeId={"hybrid"}
            center={this.state.center}
            // {...this.props.theProps}
            onLoad={this.handleMapLoad}
            onMapTypeIdChanged={this.changeMap}
            onUnmount={() => console.log("Map Unmounted")}
          >
            {this.renderLayersBar()}
            {this.state.showMessage && this.renderMessage()}
            {/* <MapRingContent center={this.state.center} /> */}
            {this.props.locations && (
              <MapContent
                type={this.state.map.mapTypeId}
                locations={this.props.locations}
                handleMarkerClick={this.props.handleMarkerClick}
                rings={this.props.rings}
              />
            )}
            {this.props.jobs && this.state.showJobs && (
              <MapContent
                type={this.state.map.mapTypeId}
                locations={this.props.jobs}
                handleMarkerClick={this.props.handleMarkerClick}
                rings={this.props.rings}
              />
            )}
            {/* {this.props.areas &&
              this.props.areas.length > 0 &&
              this.state.showAreas && (
                <MapPolygonContent
                  zones={this.props.areas}
                  handleZoneHover={this.props.handleZoneHover}
                />
              )} */}
            {/* {this.props.plans &&
              this.props.plans.length > 0 &&
              this.state.showPlans && (
                <MapPolygonContent
                  zones={this.props.plans}
                  handleZoneHover={this.props.handleZoneHover}
                />
              )} */}
            {/* {this.props.tasks &&
              this.props.tasks.length > 0 &&
              this.state.showTasks && (
                <MapPolygonContent
                  zones={this.props.tasks}
                  handleZoneHover={this.props.handleZoneHover}
                />
              )} */}
            {/* {this.props.masts &&
              this.props.masts.length > 0 &&
              this.state.showMasts && (
                <MapPolygonContent
                  zones={this.props.masts}
                  handleZoneHover={this.props.handleZoneHover}
                />
              )} */}
            {/* {this.props.tracks &&
              this.props.tracks.length > 0 &&
              this.state.showTracks && (
                <Tracks
                  zones={this.props.tracks}
                  handleZoneHover={this.props.handleTrackClick}
                />
              )}
            {this.props.zone && (
              <MapZoneContent
                zone={this.props.zone}
                handleZoneHover={this.props.handleZoneHover}
              />
            )} */}
            {this.state.showTraffic && <TrafficLayer autoUpdate />}
          </GoogleMap>
        )}
        {/* </LoadScript> */}
      </Segment>
    );
  }
}
