import React from "react";
import Job from "./Job_Context";
import {withRouter} from "react-router-dom";
import {Loader} from "semantic-ui-react";

class Job_Receiver extends React.Component {
    state = {
        firstLoad: true,
        job: {},
    };
    componentDidMount(){
        this._refreshJob();
    }
    _refreshJob = () => {
        this.props.api.jobs.show(this.props.match.params.job_id).then((res) => {
            this.setState({
                job: res,
                firstLoad: false
            });
        });
    };
    render(){
        return (
            <Job.Provider
                value={{
                    _refresh: this._refreshJob,
                    ...this.state.job,
                }}
            >
                {this.state.firstLoad ? <Loader active/> : this.props.children}
            </Job.Provider>
        );
    }
}

export default withRouter(Job_Receiver);
