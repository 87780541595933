import React, { Component } from "react";
import ReactMapboxGl, {
  ZoomControl,
  ScaleControl,
  GeoJSONLayer,
  Source,
  Layer,
  Feature,
  Popup,
} from "react-mapbox-gl";
import {
  Button,
  Popup as ToolTip,
  Icon,
  Header,
  Segment,
  Grid,
  Divider,
} from "semantic-ui-react";
import * as turf from "@turf/turf";
import Default_Styles from "./styles/mainStyles";
import styled from "styled-components";
import DrawControl from "react-mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { svg } from "./jobMarker";

const RASTER_SOURCE_OPTIONS = {
  type: "raster",
  // tiles: ["https://ecn.t0.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=z"],
  tiles: [
    "https://ecn.t0.tiles.virtualearth.net/tiles/r{quadkey}?g=7080&lbl=l1&productSet=mmOS&key=AoMKVVkBRY3_8gv8UJmlIHXp8DCIGGzv9Yw_50Q4WIqC5ujgtVszQK-xLpB34hF2",
  ],
  // tiles: ["https://dev.virtualearth.net/REST/v1/Imagery/Metadata/Aerial?key=AoMKVVkBRY3_8gv8UJmlIHXp8DCIGGzv9Yw_50Q4WIqC5ujgtVszQK-xLpB34hF2"],
  tileSize: 256,
  maxzoom: 16,
  minzoom: 12,
};
const { token, styles } = require("./config.json");
const Map = ReactMapboxGl({
  accessToken: token,
  preserveDrawingBuffer: true,
});
const selectedStyles = ["outdoor", "sat", "broadNet"];
const switchStyles = Object.keys(styles).filter((k) =>
  selectedStyles.includes(k)
);

// Define layout to use in Layer component
const layoutLayer = { "icon-image": "londonCycle" };

// Create an image for the Layer
const image = new Image();
image.src = "data:image/svg+xml;charset=utf-8;base64," + btoa(svg);
const images = ["londonCycle", image];
const StyledPopup = styled.div`
  background: white;
  color: #3f618c;
  font-weight: 400;
  padding: 0.4rem;
  border-radius: 2px;
  text-align: "center";
`;

export default class TaskMap extends Component {
  state = {
    styleKey: "broadNet",
    os: false,
    reload: true,
    marker: {},
    loading: true,
    showDraw: false,
    center: [0, 0],
  };
  componentDidMount() {
    if (this.map) {
      this.setState({
        center: this.props.center,
      });
      setTimeout(() => {
        this.mapBounds();
      }, 500);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.center !== prevProps.center) {
      this.setState({
        center: this.props.center,
      });
    }
    if (this.props.location !== prevProps.location) {
      this.mapBounds();
    }
    if (this.props.zones !== prevProps.zones) {
      this.mapBounds();
    }
    if (this.props.flyTo !== prevProps.flyTo) {
      this.flyTo(this.props.flyTo);
    }
    if (this.props.addZone !== prevProps.addZone) {
      this.setState({
        showDraw: true,
      });
    }
  }
  flyTo = (coords) => {
    this.map.state.map.jumpTo({
      center: coords,
      zoom: [16],
    });
  };
  handleButtonClick = (e) => {
    e.preventDefault();
    this.setState(
      {
        showDraw: !this.state.showDraw,
      },
      () => {
        if (this.state.showDraw) {
          if (this.props.theZone) {
            this.drawControl.draw.add(this.props.theZone.geoJSON.geometry);
          }
        }
      }
    );
  };
  onStyleLoad = (map) => {
    const { onStyleLoad } = this.props;
    this.setState({
      loading: false,
    });
    return onStyleLoad && onStyleLoad(map);
  };
  nextStyle = () => {
    const { styleKey } = this.state;
    const currentIndex = switchStyles.indexOf(styleKey);
    const nextIndex =
      currentIndex === switchStyles.length - 1 ? 0 : currentIndex + 1;
    this.setState({
      styleKey: switchStyles[nextIndex],
    });
  };
  resetMap = () => {
    setTimeout(() => {
      this.mapBounds();
    }, 500);
    return this.map.state.map.flyTo({
      center: this.props.center,
      zoom: 14,
      bearing: 0,
      pitch: 0,
    });
  };
  onClickMap = (map, evt) => {
    if (evt.lngLat) {
      let coords = [
        parseFloat(evt.lngLat.lng.toFixed(5)),
        parseFloat(evt.lngLat.lat.toFixed(5)),
      ];
      if (coords.length && this.props.data) {
        this.props.data(coords);
      }
    }
  };
  mapHack = () => {
    this.setState(
      {
        reload: false,
      },
      () =>
        this.setState(
          {
            os: !this.state.os,
          },
          () => {
            this.setState({
              reload: true,
            });
          }
        )
    );
  };
  mapBounds = () => {
    let data = [];
    if (this.map) {
      if (this.props.locations && this.props.locations.length > 0) {
        this.props.locations.forEach((loc, i) => {
          if (loc && loc.geoJSON) {
            if (loc.geoJSON.geometry && loc.geoJSON.geometry.coordinates) {
              data.push(loc.geoJSON.geometry.coordinates);
            }
          }
        });
        let list = turf.multiPoint(data);
        if (list.geometry.coordinates.length) {
          let bbox = turf.bbox(list);
          if (this.map.state.map !== undefined) {
            this.map.state.map.fitBounds(bbox, { padding: 80, maxZoom: 14 });
          }
        }
      }
      if (this.props.rotaStaff && this.props.rotaStaff.length > 0) {
        this.props.rotaStaff.forEach((loc, i) => {
          if (loc) {
            if (loc.location.geometry && loc.location.geometry.coordinates) {
              data.push(loc.location.geometry.coordinates);
            }
          }
        });
        let list = turf.multiPoint(data);
        if (list.geometry.coordinates.length) {
          let bbox = turf.bbox(list);
          if (this.map.state.map !== undefined) {
            this.map.state.map.fitBounds(bbox, { padding: 80, maxZoom: 14 });
          }
        }
      }
      if (this.props.resources && this.props.resources.length > 0) {
        this.props.resources.forEach((loc, i) => {
          if (loc) {
            if (
              loc.staff.location.geometry &&
              loc.staff.location.geometry.coordinates
            ) {
              data.push(loc.staff.location.geometry.coordinates);
            }
          }
        });

        let list = turf.multiPoint(data);
        if (list.geometry.coordinates.length) {
          let bbox = turf.bbox(list);
          if (this.map.state.map !== undefined) {
            this.map.state.map.fitBounds(bbox, { padding: 80, maxZoom: 14 });
          }
        }
      }
      if (this.props.location && this.props.location.geoJSON) {
        let maxZoom = 16;
        if (this.props.distance) {
          maxZoom = 14;
        }
        // this.flyTo(this.props.location.geoJSON.geometry.coordinates);
        if (
          this.props.location.geoJSON.geometry.coordinates &&
          this.props.location.geoJSON.geometry.coordinates.length
        ) {
          data.push(this.props.location.geoJSON.geometry.coordinates);
          let bbox = turf.bbox(this.props.location.geoJSON);

          this.map.state.map.fitBounds(bbox, {
            padding: 80,
            maxZoom: 14,
            animate: false,
          });
        }
      }
    }
  };
  onDrawCreate = ({ features }) => {
    this.setState({
      data: features[0],
      changes: true,
    });
  };
  onDrawUpdate = ({ features }) => {
    this.setState({
      data: features[0],
      changes: true,
    });
  };
  onDrawDelete = () => {
    this.setState({
      data: {},
      changes: false,
    });
  };
  onStaffClick = (data) => {
    if (data.staff !== undefined) {
    } else {
      this.setState({
        addingResource: data,
        addResource: true,
      });
    }
  };
  markerClick = (marker) => {
    this.setState({
      center: marker.geoJSON.geometry.coordinates,
      zoom: [14],
      marker,
    });
  };
  cancelAddResource = () => {
    this.setState({
      addingResource: {},
      addResource: false,
    });
  };
  addResource = () => {
    let data = this.state.addingResource;
    this.props.updateResource(data).then(() => {
      this.setState({
        addResource: false,
      });
    });
  };
  sendChanges = () => {
    this.props.zone(this.state.data).then(() => {
      this.setState({
        changes: false,
        showDraw: false,
      });
    });
  };
  renderMarker() {
    if (this.map && !this.state.loading) {
      if (
        this.map.state.map &&
        this.state.marker.geometry.coordinates &&
        this.state.marker.geometry.coordinates.length
      ) {
        // this.map.state.map.panTo({ lng: this.state.marker.geometry.coordinates[0], lat: this.state.marker.geometry.coordinates[1] });
        return (
          <GeoJSONLayer
            id="marker"
            data={this.state.marker}
            circleLayout={Default_Styles.circleLayout}
            circlePaint={Default_Styles.markerPaint}
            symbolLayout={Default_Styles.symbolLayout}
            symbolPaint={Default_Styles.symbolPaint}
          />
        );
      }
    }
  }
  renderData = (data) => {
    let features = data
      .filter((loc) => {
        if (loc.geoJSON && loc.geoJSON.geometry.coordinates.length) {
          return true;
        } else if (loc.geometry.coordinates.length) {
          return true;
        }
        return false;
      })
      .map((loc) => {
        if (loc.geoJSON) {
          return loc.geoJSON;
        } else {
          return loc;
        }
      });
    return {
      type: "FeatureCollection",
      features: features,
    };
  };
  renderZones() {
    if (this.props.zones.length) {
      let zones = this.props.zones.map((zone) => {
        let linePaint = {};
        if (zone.colour) {
          linePaint = {
            "line-color": zone.colour,
          };
        }
        return (
          <GeoJSONLayer
            key={zone._id}
            id={"zones" + zone._id}
            data={zone.geoJSON}
            linePaint={{
              ...Default_Styles.linePaint,
              ...linePaint,
            }}
            symbolLayout={Default_Styles.symbolLayout}
            symbolPaint={Default_Styles.symbolPaint}
          />
        );
      });
      return zones;
    }
  }
  renderZone() {
    if (this.props.theZone) {
      let zone = this.props.theZone;

      let linePaint = {};
      if (zone.colour) {
        linePaint = {
          "line-color": zone.colour,
        };
      }
      return (
        <GeoJSONLayer
          key={zone._id}
          id={"zone" + zone._id}
          data={zone.geoJSON}
          linePaint={{
            ...Default_Styles.linePaint,
            ...linePaint,
          }}
          symbolLayout={Default_Styles.symbolLayout}
          symbolPaint={Default_Styles.symbolPaint}
        />
      );
    }
  }
  renderMapBar() {
    return (
      <div className="mapBar">
        <ToolTip
          trigger={
            <Button icon onClick={(e) => this.resetMap(e)} inverted>
              <Icon color="orange" name="compass" />
            </Button>
          }
          content="reset bearing and pitch"
          basic
        />
        <ToolTip
          trigger={
            <Button icon onClick={(e) => this.nextStyle(e)} inverted>
              <Icon name="world" />
            </Button>
          }
          content="Change Map Type"
          basic
        />
        <ToolTip
          trigger={
            <Button
              icon
              onClick={() => this.mapHack()}
              inverted
              toggle
              active={this.state.os}
            >
              OS
            </Button>
          }
          content="Toggle OS Map"
          basic
        />
        {this.props.theZone && !this.props.disabled && (
          <ToolTip
            trigger={
              <Button
                icon
                onClick={this.handleButtonClick}
                color="red"
                toggle
                inverted
                active={this.state.showDraw}
              >
                <Icon name="pencil" />
              </Button>
            }
            content={"edit zone"}
            basic
          />
        )}
      </div>
    );
  }
  renderStaff() {
    // console.log(this.props.staff);
    if (this.props.staff)
      if (this.props.staff.length) {
        return this.props.staff.map((loc, i) => {
          if (loc.location.geometry) {
            if (loc.location.geometry.coordinates.length) {
              return (
                <GeoJSONLayer
                  circleOnClick={() => this.onStaffClick(loc)}
                  data={loc.location}
                  linePaint={Default_Styles.linePaint}
                  circlePaint={Default_Styles.circleAllStaffPaint}
                  symbolLayout={Default_Styles.symbolStaffLayout}
                  symbolPaint={
                    this.state.styleKey === "outdoor"
                      ? Default_Styles.lightSymbolPaint
                      : this.state.os === true
                      ? Default_Styles.symbolOSPaint
                      : Default_Styles.symbolPaintAllStaff
                  }
                  key={i}
                />
              );
            }
          }
          return null;
        });
      }
  }
  renderResources() {
    if (this.props.resources)
      if (this.props.resources.length) {
        return this.props.resources.map((loc, i) => {
          if (loc.staff.location.geometry) {
            if (loc.staff.location.geometry.coordinates.length) {
              return (
                <GeoJSONLayer
                  circleOnClick={() => this.onStaffClick(loc)}
                  data={loc.staff.location}
                  linePaint={Default_Styles.linePaint}
                  circlePaint={Default_Styles.circleResourcesPaint}
                  symbolLayout={Default_Styles.symbolStaffLayout}
                  symbolPaint={
                    this.state.styleKey === "outdoor"
                      ? Default_Styles.lightSymbolPaint
                      : this.state.os === true
                      ? Default_Styles.symbolOSPaint
                      : Default_Styles.symbolPaintResourceStaff
                  }
                  key={i}
                />
              );
            }
          }
          return null;
        });
      }
  }
  renderRotaStaff() {
    if (this.props.rotaStaff)
      if (this.props.rotaStaff.length) {
        return this.props.rotaStaff.map((loc, i) => {
          if (loc.location.geometry) {
            if (loc.location.geometry.coordinates.length) {
              return (
                <GeoJSONLayer
                  circleOnClick={() => this.onStaffClick(loc)}
                  data={loc.location}
                  linePaint={Default_Styles.linePaint}
                  circlePaint={Default_Styles.circleStaffPaint}
                  symbolLayout={Default_Styles.symbolStaffLayout}
                  symbolPaint={
                    this.state.styleKey === "outdoor"
                      ? Default_Styles.lightSymbolPaint
                      : this.state.os === true
                      ? Default_Styles.symbolOSPaint
                      : Default_Styles.symbolPaintStaff
                  }
                  key={i}
                />
              );
            }
          }
          return null;
        });
      }
  }
  renderLocations() {
    if (this.props.locations)
      if (this.props.locations.length) {
        return this.props.locations.map((loc, i) => {
          if (loc && loc.geoJSON) {
            if (loc.geoJSON.geometry) {
              return (
                <GeoJSONLayer
                  data={loc.geoJSON}
                  linePaint={Default_Styles.linePaint}
                  circlePaint={Default_Styles.circleSmallPaint}
                  symbolLayout={Default_Styles.symbolSmallLayout}
                  symbolPaint={
                    this.state.styleKey === "outdoor"
                      ? Default_Styles.lightSymbolPaint
                      : this.state.os
                      ? Default_Styles.symbolOSPaint
                      : Default_Styles.symbolPaint
                  }
                  key={i}
                />
              );
            }
          }
          return null;
        });
      }
  }
  renderLocation() {
    if (
      this.props.location.geoJSON !== undefined &&
      this.props.location.geoJSON.geometry
    ) {
      if (this.props.location.geoJSON.geometry.coordinates) {
        return (
          <GeoJSONLayer
            data={this.props.location.geoJSON}
            linePaint={Default_Styles.linePaint}
            circlePaint={Default_Styles.circlePaint}
            symbolLayout={Default_Styles.symbolLayout}
            symbolPaint={Default_Styles.symbolPaint}
          />
        );
      }
    }
  }
  renderMessage() {
    const { addingResource } = this.state;
    return (
      <div className="messageBox">
        <Header>
          Add {addingResource.firstName} {addingResource.lastName} to Job{" "}
        </Header>
        <Button negative onClick={() => this.cancelAddResource()} size="tiny">
          Cancel
        </Button>
        <Button positive onClick={() => this.addResource()} size="tiny">
          <Icon name="plus" />
          Click to add
        </Button>
      </div>
    );
  }
  render() {
    // console.log('p', this.props);
    let mapStyle = {
      height: 100 + "%",
      width: 100 + "%",
    };
    if (this.props.mapStyle) {
      mapStyle = this.props.mapStyle;
    } else {
      mapStyle = {
        minHeight: 77 + "vh",

        height: 100 + "%",
        width: 100 + "%",
      };
    }
    const { styleKey, os, marker } = this.state;

    return (
      <Map
        ref={(e) => {
          this.map = e;
        }}
        // zoom={[14]}
        center={this.props.center != null && this.props.center}
        style={styles[styleKey]}
        movingMethod={"jumpTo"}
        onStyleLoad={this.onStyleLoad}
        className="basicMap"
        onClick={this.onClickMap}
        containerStyle={mapStyle}
      >
        <Source id="OS" tileJsonSource={RASTER_SOURCE_OPTIONS} />
        {os && <Layer type="raster" id={"1"} sourceId="OS" />}
        {/* {this.state.changes && this.renderMessage()} */}
        {this.state.addResource && this.renderMessage()}

        {this.renderMapBar()}
        {this.state.reload && this.props.theZone && this.renderZone()}
        {this.state.reload && this.props.zones && this.renderZones()}
        {this.state.reload && this.props.location && this.renderLocation()}
        {this.state.reload && this.props.locations && this.renderLocations()}
        {this.state.reload && this.props.staff && this.renderStaff()}
        {this.state.reload && this.props.rotaStaff && this.renderRotaStaff()}
        {this.state.reload && this.props.resources && this.renderResources()}

        <Layer type="symbol" id="marker" layout={layoutLayer}>
          {this.props.locations &&
            this.props.locations.map((loc, index) => (
              <Feature
                key={index}
                // onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
                // onMouseLeave={this.onToggleHover.bind(this, '')}
                onClick={() => this.markerClick(loc)}
                coordinates={
                  loc.geoJSON ? loc.geoJSON.geometry.coordinates : [0, 0]
                }
              />
            ))}
        </Layer>

        {/* {marker.geoJSON && (
          <Popup key={"loc"} coordinates={marker.geoJSON.geometry.coordinates}>
            <StyledPopup>
              <Button basic floated={"right"} onClick={() => this.setState({ marker: {} })} className="no-pad">
                x
              </Button>
              <Divider hidden />
              <p>{marker.geoJSON.properties.type}</p>
            </StyledPopup>
          </Popup>
        )} */}
        <ZoomControl />
        {this.props.mapKey && this.props.mapKey}
        <ScaleControl position={"bottom-left"} />
      </Map>
    );
  }
}
