import React, { Component } from "react";
import { Modal, Form, Header, Button, Table, Grid, Divider, Segment, Icon } from "semantic-ui-react";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import { DateTimeInput } from "semantic-ui-calendar-react";
import { Link } from "react-router-dom";
import moment from "moment";
import api from "../../actions/api";
import Box from "../common/box";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";
import OrderedLocationList from "../common/lists/orderedLocationList";
import toast from "react-hot-toast";
//TODO add to settings
const priority = [
  { key: 1, text: "Urgent", value: "Urgent" },
  { key: 2, text: "Emergency", value: "Emergency" },
  { key: 3, text: "Non Urgent", value: "Non Urgent" },
  { key: 4, text: "Relay Run", value: "Relay Run" },
  { key: 5, text: "Pre Booked", value: "Pre Booked" },
  // { key: 3, text: "High", value: "High" }
];
class JobManager extends Component {
  state = {
    jobs: [],
    newJob: {
      locations: [],
      details: "",
      priority: "",
      type: "",
      date: "",
    },
    client: null,
    jobsSelected: [],
    errors: {},
    settings: {
      JobTypes: [],
    },
  };
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState) {}
  getData = () => {
    api.jobs.active().then((jobs) => {
      this.setState({
        jobs: jobs.jobs,
      });
    });
    api.settings.formLists().then((settings) => {
      this.setState({
        settings,
      });
    });
  };
  onChange = (e) =>
    this.setState({
      newJob: { ...this.state.newJob, [e.target.name]: e.target.value },
    });
  handleDropdownChange = (props, e) => {
    this.setState({
      newJob: { ...this.state.newJob, [e.name]: e.value },
    });
  };
  reset = () => {
    this.setState({
      newJob: {
        locations: [],
        details: "",
        priority: "",
        type: "",
        date: "",
      },
      client: null,
      jobsSelected: [],
    });
  };
  startMerge = (data) => {
    let newData = this.state.newJob;
    let jobsSelected = this.state.jobsSelected;
    let client = this.state.client;
    jobsSelected = jobsSelected.concat({ id: data._id, ref: data.jobNum });
    // not used yet
    let jobs = jobsSelected.map((j) => {
      return j.jobNum + ", ";
    });
    newData.locations = newData.locations.concat(data.locations);
    if (newData.priority === undefined || newData.priority === "") {
      newData.priority = data.priority;
    }
    if (newData.date === undefined || newData.date === "") {
      newData.date = data.date;
    }
    if (newData.type === undefined || newData.type === "") {
      newData.type = data.type;
    }
    if (newData.client === undefined || (newData.client === "" && data.client)) {
      newData.client = data.client && data.client._id;
      newData.clientName = data.client && data.client.name;
    }
    if (client === undefined || client === null) {
      client = data.client ? data.client._id : "";
    }
    if (data.details !== undefined || data.details !== "") {
      let details = data.details ? data.details : " no details ";
      newData.details = newData.details + "Job: " + data.jobNum + " " + details + " ** \n";
    }

    this.setState({
      newJob: newData,
      jobsSelected,
      client,
    });
  };
  handleMerge = () => {
    let data = this.state.newJob;
    data.jobsSelected = this.state.jobsSelected;
    // console.log(data);
    if (data.locations.length === 0) {
      toast.error("No Locations!");
    }
    if (data.locations.length > 0) {
      api.jobs.merge(data).then(() => {
        this.reset();
        this.getData();
      });
    }
  };
  handleOrderedLocationUpdate = (data) => {
    this.setState({
      updated: true,
      newJob: {
        ...this.state.newJob,
        locations: data.locations.map((item, i) => {
          return {
            ...item,
            _id: i,
          };
        }),
      },
    });
  };

  renderMerge() {
    const { errors } = this.state;
    return (
      <Box title="New Job From Merged Jobs" className="mergeJob no-pad">
        <Segment basic>
          <Form>
            <Form.Group widths={3}>
              <Form.Field>
                <Form.Dropdown
                  name="type"
                  label="Job Type"
                  value={this.state.newJob.type}
                  onChange={this.handleDropdownChange}
                  options={this.state.settings.JobTypes}
                  selection
                  required={true}
                />
                {errors.type && <p style={{ color: "red" }}>{errors.type}</p>}
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  name="priority"
                  label="Priority"
                  value={this.state.newJob.priority}
                  onChange={this.handleDropdownChange}
                  options={priority}
                  selection
                  required={true}
                />
                {errors.priority && <p style={{ color: "red" }}>{errors.priority}</p>}
              </Form.Field>
              <Form.Field>
                <DateTimeInput
                  autoComplete={"off"}
                  animation={"off"}
                  name="date"
                  label="Date/Scheduled Date"
                  dateTimeFormat="YYYY/MM/DD HH:mm"
                  value={moment(this.state.newJob.date).format("YYYY-MM-DD HH:mm")}
                  iconPosition="left"
                  onChange={this.handleDropdownChange}
                  startMode={"day"}
                  closable
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Client Name</label>
              <Header className="no-marg">{this.state.newJob.clientName}</Header>
            </Form.Field>
            <Form.Field>
              <Form.TextArea name="details" label="Details" value={this.state.newJob.details} onChange={this.onChange} placeholder="details about the job" rows={5} />
            </Form.Field>
            <Form.Group widths={2}>
              <Form.Button negative fluid onClick={this.reset}>
                Cancel
              </Form.Button>
              <Form.Button fluid positive onClick={this.handleMerge}>
                Add
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
        <OrderedLocationList
          submit={this.handleOrderedLocationUpdate}
          delete={this.handleOrderedLocationUpdate}
          data={this.state.newJob.locations.map((item, index) => {
            return {
              ...item,
              _id: index,
            };
          })}
          placeholder="task status.."
          title="Job Locations"
          field="locations"
        />
      </Box>
    );
  }
  renderJobs() {
    const jobs = this.state.jobs.map((j, i) => {
      let selected = false;
      let disabled = false;
      let negative = false;
      if (this.state.jobsSelected.some((s) => s.id === j._id)) {
        selected = true;
        disabled = true;
      } else {
        selected = false;
        disabled = false;
      }

      if (this.state.jobsSelected.length !== 0) {
        if (this.state.client === null || this.state.client === "") {
          //is a empty client
          if (j.client !== undefined && j.client._id !== undefined) {
            negative = true;
            disabled = true;
          }
        } else {
          //is not a empty client
          if (j.client === undefined || j.client._id === undefined) {
            negative = true;
            disabled = true;
          } else if (j.client !== undefined && j.client._id !== this.state.client) {
            negative = true;
            disabled = true;
          }
        }
      }

      return (
        <React.Fragment key={i}>
          <Table.Row className="borderTop" positive={selected} negative={negative}>
            <Table.Cell collapsing>
              <Link to={`/jobs/${j._id}`}>{j.jobNum}</Link>
            </Table.Cell>
            <Table.Cell>
              <Header>{j.client ? j.client.name : "--"}</Header>
            </Table.Cell>
            <Table.Cell>{j.priority}</Table.Cell>
            <Table.Cell>{j.type}</Table.Cell>
            <Table.Cell textAlign="right">
              <Button size="tiny" basic inverted onClick={() => this.startMerge(j)} disabled={disabled} icon>
                <Icon name="arrow right" />
              </Button>
            </Table.Cell>
          </Table.Row>
          {j.locations.map((l, i) => {
            if (l !== null && l.geoJSON) {
              return (
                <Table.Row key={i} positive={selected} negative={negative}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>{l.geoJSON.properties.name}</Table.Cell>
                  <Table.Cell>{l.geoJSON.properties.town}</Table.Cell>
                  <Table.Cell colSpan={2}>
                    {l.geoJSON.properties.type} {l.geoJSON.properties.time}
                  </Table.Cell>
                </Table.Row>
              );
            }
          })}
        </React.Fragment>
      );
    });
    return (
      <Box title="Merge Client Related Jobs">
        <Grid celled stackable className={"no-marg"}>
          <Grid.Row>
            <Grid.Column className="no-pad">
              <Table structured className="locTable">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Job Nums.</Table.HeaderCell>
                    <Table.HeaderCell>
                      Client
                      <Divider fitted />
                      Name
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Priority
                      <Divider fitted />
                      Town
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan={2}>
                      Job Type
                      <Divider fitted />
                      Loc. Type
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{jobs}</Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Box>
    );
  }

  render() {
    return (
      <Segment basic>
        <Grid stackable className="no-marg ">
          <Grid.Row className="no-pad">
            <Grid.Column className="no-pad" width={8}>
              {this.renderJobs()}
            </Grid.Column>

            <Grid.Column className="no-pad" width={8}>
              {this.renderMerge()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Settings_Receiver(JobManager, (settings) => {
  return {
    settings,
  };
});
