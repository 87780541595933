import React, { Component } from "react";
import { Table, Grid, Segment, Icon, Menu } from "semantic-ui-react";
import moment from "moment";
import api from "../../actions/api";

import Box from "../common/box";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import TaskMap from "../common/mapping/taskMap";
import LocationBox from "../common/locationBox";
class JobLocs extends Component {
  state = {
    isEdit: false,
    addDate: false,
    dateType: null,
    job: {
      locations: [],
    },
    data: {
      date: moment().toISOString(),
    },
    w3w: {
      first: "",
      second: "",
      third: "",
    },
    marker: {
      geoJSON: {
        geometry: {},
      },
    },
    en: {
      easting: "",
      northing: "",
    },
    grid: "",
    location: {
      geoJSON: {
        type: "Feature",
        properties: {},
      },
    },
    addLocType: "predefined",
    settings: {
      JobTypes: [],
      LocTypes: [],
    },
    depts: [],
    errors: {},
  };
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.job !== prevProps.job) {
      this.setState({
        job: this.props.job,
      });
    }
    if (this.state.predefined !== prevState.predefined) {
      if (this.state.predefined !== "") {
        this.getDepartments();
      }
    }
  }
  setFlyTo = (data) => {
    this.setState({
      flyTo: data,
    });
  };
  getData = () => {
    this.props.job._refresh();
    api.locations.all().then((locations) => {
      this.setState({
        locs: {
          formList: locations.formList,
          locations: locations.locations,
        },
      });
    });
    api.settings.formLists().then((settings) => {
      this.setState({
        settings,
      });
    });
  };
  getDepartments = () => {
    if (this.state.predefined) {
      api.locations.depts(this.state.predefined).then((depts) => {
        this.setState({
          depts,
        });
      });
    }
  };
  setLocType = (type) => {
    this.setState(
      {
        addLocType: type,
      },
      () => {
        this.resetLoc();
      }
    );
  };
  resetLoc = () => {
    this.setState({
      predefined: "",
      grid: "",
      location: {
        geoJSON: {
          type: "Feature",
          properties: {
            type: "",
          },
        },
      },
    });
    if (this._geoSuggest) {
      this._geoSuggest.clear();
    }
  };
  updateLocations = (data) => {
    let locations = {
      locations: data,
    };
    api.jobs.update(this.props.job._id, locations).then(() => {
      this.props.job._refresh();
    });
  };
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };

  renderMenu() {
    return (
      <Segment className="no-pad no-marg">
        <Menu widths={2}>
          <Menu.Item>
            <Icon name="pencil" />
            Edit
          </Menu.Item>
          <Menu.Item>
            <Icon name="sync" />
            Convert to Incident
          </Menu.Item>
        </Menu>
      </Segment>
    );
  }
  renderLocs() {
    const locs = this.state.job.locations.map((loc, i) => {
      if (loc.geoJSON) {
        return (
          <React.Fragment key={i}>
            <Table.Row
              className="borderTop clickable"
              onClick={() => this.setFlyTo(loc.geoJSON.geometry.coordinates)}
            >
              <Table.Cell className="title">
                {loc.geoJSON.properties.type} {loc.geoJSON.properties.time}
              </Table.Cell>
              <Table.Cell className="title">
                {loc.geoJSON.properties.department}
              </Table.Cell>
              <Table.Cell className="title" textAlign="center">
                {loc.geoJSON.properties.grid}
                <br />
                {loc.geoJSON.properties.w3w}
              </Table.Cell>
              {/* <Table.Cell textAlign="right" rowSpan="2">
              <Button icon basic inverted onClick={() => this.setFlyTo(loc.geoJSON.geometry.coordinates)}>
                <Icon name="map marker" />
              </Button>
            </Table.Cell> */}
            </Table.Row>
            <Table.Row
              className=" clickable"
              onClick={() => this.setFlyTo(loc.geoJSON.geometry.coordinates)}
            >
              <Table.Cell colSpan="2">{loc.geoJSON.properties.name}</Table.Cell>
              <Table.Cell colSpan="2">
                {loc.geoJSON.properties.address}
              </Table.Cell>
            </Table.Row>
          </React.Fragment>
        );
      }
      return null;
    });
    return (
      <Box
        title="locations"
        action={() => this.setState({ isEdit: true })}
        icon={<Icon name="pencil" />}
      >
        <Grid celled stackable className={"no-marg"}>
          <Grid.Row>
            <Grid.Column className="no-pad">
              <Table celled structured className="locTable">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Type/Name</Table.HeaderCell>
                    <Table.HeaderCell>Department</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Grid & w3w
                    </Table.HeaderCell>

                    {/* <Table.HeaderCell></Table.HeaderCell> */}
                  </Table.Row>
                </Table.Header>
                <Table.Body>{locs}</Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Box>
    );
  }
  renderMap(center) {
    return (
      <Box title={this.state.job.jobNum + " Map"}>
        <TaskMap
          center={center}
          data={this.mapClick}
          flyTo={this.state.flyTo}
          zones={this.state.zones}
          locations={this.state.job.locations}
        />
      </Box>
    );
  }

  renderLocBox() {
    return (
      <LocationBox
        locs={this.state.locs}
        formLists={this.state.settings}
        locations={this.state.job.locations}
        addLoc={this.updateLocations}
        cancel={() => this.setState({ isEdit: false })}
        job={this.props.job}
      />
    );
  }
  render() {
    const { job, isEdit } = this.state;
    let center = [0.521725, 51.278708];
    if (job && job.locations.length) {
      let firstLoc = job.locations[0];
      if (firstLoc.geoJSON) {
        center = firstLoc.geoJSON.geometry.coordinates;
      }
    }

    return (
      <Grid stackable className="no-marg">
        {!isEdit && (
          <Grid.Row>
            <Grid.Column width={8}>
              <Segment basic className="no-pad">
                {this.renderLocs()}
              </Segment>
            </Grid.Column>
            <Grid.Column width={8}>{this.renderMap(center)}</Grid.Column>
          </Grid.Row>
        )}
        {isEdit && (
          <Grid.Row>
            <Grid.Column width={16}>{this.renderLocBox()}</Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}
let JobLocations = Settings_Receiver(JobLocs, (settings) => {
  return {
    settings,
  };
});
export default Job_Receiver(JobLocations, (job) => {
  return {
    job,
  };
});
