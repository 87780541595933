import React, { Component } from "react";
import {
  Segment,
  Menu,
  Icon,
  Modal,
  Grid,
  Button,
  Form,
  Divider,
  Header,
  Tab,
  Checkbox,
} from "semantic-ui-react";
import JobList from "../../common/lists/jobList";
import Settings_Receiver from "../../../contexts/settings_context/Settings_Receiver";
import api from "../../../actions/api";
import Box from "../../common/box";

class ClientPage extends Component {
  state = {
    addLoc: false,
    editClient: false,
    client: {},
    jobs: [],
    data: {
      type: "",
    },
  };
  componentDidMount() {
    this.getData();
  }
  goto = (id) => {
    this.props.history.push(`/jobs/${id}`);
  };
  getData = () => {
    api.client.show(this.props.match.params.id).then((client) => {
      this.setState({
        client: client,
        data: {
          ...this.state.data,
          ...client,
        },
      });
    });
    api.client.jobs(this.props.match.params.id).then((jobs) => {
      this.setState({
        jobs: jobs,
      });
    });
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };
  handleCheckboxChange(props, e) {
    this.setState({
      data: { ...this.state.data, [e.name]: e.checked },
    });
  }
  updateClient = () => {
    api.client.update(this.props.match.params.id, this.state.data).then(() => {
      this.setState({
        editClient: false,
      });
      this.getData();
    });
  };
  renderDetails() {
    const { client } = this.state;
    return (
      <Segment basic className="no-pad">
        <Box title={"Client Details"}>
          <Menu widths={3}>
            <Menu.Item onClick={() => this.setState({ editClient: true })}>
              <Icon name="pencil" /> Edit
            </Menu.Item>
            <Menu.Item>--</Menu.Item>
            <Menu.Item>--</Menu.Item>
          </Menu>
          <Grid celled textAlign="center" className=" dataGrid  no-marg">
            {client.hide && (
              <Grid.Row>
                <Grid.Column className="data" width={16}>
                  <Header color="red">** Client Archived **</Header>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column className="data" width={8}>
                <Header>Name</Header>
                <p>{client.name}</p>
              </Grid.Column>
              <Grid.Column className="data" width={8}>
                <Header>Type</Header>
                <p>{client.type}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className="data" width={16}>
                <Header>Details</Header>
                <p>{client.details}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Box>
      </Segment>
    );
  }
  renderActive() {
    return (
      <Segment basic className="no-pad">
        <JobList
          client
          data={this.state.jobs.filter((j) => j.status !== "Closed")}
          action={this.goto}
        />
      </Segment>
    );
  }
  renderCompleted() {
    return (
      <Segment basic className="no-pad">
        <JobList
          client
          data={this.state.jobs.filter((j) => j.status === "Closed")}
          action={this.goto}
        />
      </Segment>
    );
  }
  renderInfo() {
    const panes = [
      {
        menuItem: "Active",
        render: () => (
          <Tab.Pane attached={false} className={"no-pad no-marg"} basic>
            {this.renderActive()}
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Completed",
        render: () => (
          <Tab.Pane attached={false} className={"no-pad no-marg"} basic>
            {this.renderCompleted()}
          </Tab.Pane>
        ),
      },
    ];
    return (
      <Box title={"Info"}>
        <Tab menu={{ secondary: true, pointing: false }} panes={panes} />{" "}
      </Box>
    );
  }
  renderEdit() {
    return (
      <Modal
        size="large"
        open={this.state.editClient}
        onClose={() => this.setState({ editClient: false })}
        centered={false}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header>Edit Client</Modal.Header>
        <Modal.Content>
          <Form style={{ width: "100%" }}>
            <Form.Field>
              <Form.Dropdown
                name="type"
                label="Client Type"
                value={this.state.data.type}
                onChange={this.handleDropdownChange}
                options={
                  this.props.settings && this.props.settings.clientTypes
                    ? this.props.settings.clientTypes.map((t, i) => {
                      return { key: i, text: t, value: t };
                    })
                    : []
                }
                selection
                search
                placeholder="NHS, Police,Fire..."
                required={true}
              />
            </Form.Field>
            <Form.Field>
              <label>Client Name</label>
              <input
                name="name"
                onChange={this.onChange}
                value={this.state.data.name}
                placeholder={"name...."}
              />
            </Form.Field>
            <Form.Group widths={2}>
              <Form.Field>
                <label>Details</label>
                <Form.TextArea
                  name={"details"}
                  value={this.state.data.details}
                  onChange={this.onChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Admin Switches</label>
                <Divider />
                <Checkbox
                  label="Archive this client"
                  name="hide"
                  onChange={this.handleCheckboxChange.bind(this)}
                  checked={this.state.data.hide}
                />
              </Form.Field>
            </Form.Group>
            <Divider />
            <Form.Group widths={"equal"} className={"form-actions"}>
              <Form.Field>
                <Button
                  content="Cancel"
                  negative
                  fluid
                  onClick={() => this.setState({ editClient: false })}
                  labelPosition="left"
                  icon="close"
                />
              </Form.Field>
              <Form.Field>
                <Button
                  content="Update"
                  fluid
                  labelPosition="right"
                  icon="checkmark"
                  onClick={this.updateClient}
                  positive
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
  render() {
    return (
      <Grid stackable className="no-marg no-pad">
        {this.renderEdit()}
        <Grid.Row>
          <Grid.Column width={6}>{this.renderDetails()}</Grid.Column>
          <Grid.Column width={10}>{this.renderInfo()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default Settings_Receiver(ClientPage, (settings) => {
  return {
    settings,
  };
});
