import React, { Component } from "react";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import api from "../../actions/api";
import {
  Segment,
  Grid,
  Checkbox,
  Header,
  Form,
  Popup,
  Icon,
  Divider,
} from "semantic-ui-react";
import SettingsList from "../common/lists/settingList";
import Box from "../common/box";

import TheLoader from "../common/TheLoader";

class EventSettings extends Component {
  state = {
    data: {
      hasWelfareCheck: false,
      welfareCheck: "",
      hasExtraData: false,
    },
    updating: false,
  };
  updateFormTimeout = 0;
  componentDidMount() {
    if (this.props.settings) {
      this.setState({
        data: {
          ...this.state.data,
          ...this.props.settings,
        },
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings !== prevProps.settings) {
      this.setState({
        data: {
          ...this.state.data,
          ...this.props.settings,
        },
      });
    }
  }

  handleCheckboxChange = (props, e) => {
    this.setState(
      {
        updating: true,
        data: { ...this.state.data, [e.name]: e.checked },
      },
      () => {
        if (this.updateFormTimeout) {
          clearTimeout(this.updateFormTimeout);
        }
        this.updateFormTimeout = setTimeout(() => {
          return this.handleSubmit(this.state.data)
            .then((res) => {
              this.setState({
                updating: false,
              });
              //TODO add a fancy screen notification that the title has successfully updated
            })
            .catch((err) => {
              console.error("Unable to save", err);
            });
        }, 500);
      }
    );
  };
  handleSubmit = (data) => {
    return api.settings.update(data).then(() => {
      this.props.settings._refresh();
    });
  };
  handleDelete = (data) => {
    return api.settings.update(data).then(() => {
      this.props.settings._refresh();
    });
  };
  onChange = (e) =>
    this.setState(
      {
        updating: true,
        data: { ...this.state.data, [e.target.name]: e.target.value },
      },
      () => {
        if (this.updateFormTimeout) {
          clearTimeout(this.updateFormTimeout);
        }
        this.updateFormTimeout = setTimeout(() => {
          return api.settings
            .update(this.state.data)
            .then((res) => {
              this.props.settings._refresh();
              this.setState({
                updating: false,
              });
              //TODO add a fancy screen notification that the title has successfully updated
            })
            .catch((err) => {
              console.error("Unable to save", err);
            });
        }, 500);
      }
    );
  handleCheckboxChange = (props, e) => {
    this.setState(
      {
        updating: true,
        data: { ...this.state.data, [e.name]: e.checked },
      },
      () => {
        if (this.updateFormTimeout) {
          clearTimeout(this.updateFormTimeout);
        }
        this.updateFormTimeout = setTimeout(() => {
          return api.settings
            .update(this.state.data)
            .then((res) => {
              this.props.settings._refresh();
              this.setState({
                updating: false,
              });
              //TODO add a fancy screen notification that the title has successfully updated
            })
            .catch((err) => {
              console.error("Unable to save", err);
            });
        }, 500);
      }
    );
  };
  render() {
    const { data } = this.state;
    let jobTypes = [];
    let taskTypes = [];
    let clientTypes = [];
    let locTypes = [];
    let extraDataType = [];
    let logTypes = [];
    let multi = false;
    if (this.props.settings.jobTypes) {
      jobTypes = this.props.settings.jobTypes;
    }
    if (this.props.settings.taskTypes) {
      taskTypes = this.props.settings.taskTypes;
    }
    if (this.props.settings.locTypes) {
      locTypes = this.props.settings.locTypes;
    }
    if (this.props.settings.clientTypes) {
      clientTypes = this.props.settings.clientTypes;
    }
    if (this.props.settings.extraDataType) {
      extraDataType = this.props.settings.extraDataType;
    }
    if (this.props.settings.allowMultiAssign) {
      multi = this.props.settings.allowMultiAssign;
    }
    if (this.props.settings.logTypes) {
      logTypes = this.props.settings.logTypes;
    }
    return (
      <Segment basic>
        <Grid stackable>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Box title={"Welfare Check"}>
                <Segment basic>
                  <Form>
                    <Popup
                      basic
                      content={
                        "Welfare check warning after X minutes if no log entries for resource"
                      }
                      position={"top left"}
                      trigger={
                        <Header className={"withIcon"}>
                          Use Welfare Check
                          <Icon
                            name="info circle"
                            color="yellow"
                            size="small"
                          />
                        </Header>
                      }
                    />
                    <Checkbox
                      toggle
                      name="hasWelfareCheck"
                      className={"goodToggle"}
                      checked={data.hasWelfareCheck}
                      onChange={this.handleCheckboxChange}
                    />
                    <Form.Input
                      name="welfareCheck"
                      value={data.welfareCheck}
                      onChange={this.onChange}
                      label="Welfare Check in minutes"
                      disabled={!data.hasWelfareCheck}
                    />
                  </Form>
                </Segment>
              </Box>
              <Divider hidden />
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={jobTypes}
                placeholder="flood, first aid cover..."
                title="Job Types"
                field="jobTypes"
              />
              <Divider hidden />
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={logTypes}
                placeholder="Info, Team....."
                title="Log Types"
                field="logTypes"
              />
            </Grid.Column>
            <Grid.Column>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={locTypes}
                placeholder="pick up, drop off, RTA..."
                title="Location Types"
                field="locTypes"
              />
              <Divider hidden />
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={clientTypes}
                placeholder="NHS, Police...."
                title="Client Types"
                field="clientTypes"
              />
            </Grid.Column>
            <Grid.Column>
              <Box title={"Extra Data"}>
                <Segment basic>
                  <Form>
                    <Popup
                      basic
                      content={"Jobs have extra data"}
                      position={"top left"}
                      trigger={
                        <Header className={"withIcon"}>
                          Use Extra Data
                          <Icon
                            name="info circle"
                            color="yellow"
                            size="small"
                          />
                        </Header>
                      }
                    />
                    <Checkbox
                      toggle
                      name="hasExtraData"
                      className={"goodToggle"}
                      checked={data.hasExtraData}
                      onChange={this.handleCheckboxChange}
                    />
                  </Form>
                </Segment>
              </Box>
              <Divider hidden />
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={extraDataType}
                placeholder="..."
                title="Extra Data Settings"
                field="extraDataType"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Settings_Receiver(EventSettings, (settings) => {
  return {
    settings,
  };
});
