import React from "react";
import {Grid, Segment, Message, Table, Tab, Header, Button, Icon, Label, Divider, Modal} from "semantic-ui-react";
import moment from "moment";
import API from "../../actions/api";
import Box from "../common/box";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";

class Inbox extends React.Component {
    state       = {
        loadingInbox: true,
        inboxes: [],
        messages: {},
        showNotResponded: null,
        showResponded: null,
    };
    autoRefresh = null;
    componentDidMount(){
        API.sms.sentMessages.all((this.props.job !== undefined && this.props.job._id !== undefined ? this.props.job._id : null), true).then((data) => {
            this.setState(
                {
                    loadingInbox: false,
                    inboxes: data.filter((item) => {
                        return item.isAlert;
                    }),
                },
                () => {
                    this.state.inboxes.forEach((inbox) => {
                        this.getMessages(inbox._id);
                    });
                }
            );
        });
        this.autoRefresh = setInterval(() => {
            this.state.inboxes.forEach((inbox) => {
                this.getMessages(inbox._id);
            });
        }, 60 * 1000);
    }
    componentWillUnmount(){
        if (this.autoRefresh !== null){
            clearInterval(this.autoRefresh);
        }
    }
    getMessages(id){
        let messages = this.state.messages;
        messages[id] = {
            isLoading: true,
            messages: [],
            notResponded: [],
        };
        this.setState(
            {
                messages: messages,
            },
            () => {
                Promise.all([
                    API.sms.sentMessages.messages(id, (this.props.job !== undefined && this.props.job._id !== undefined ? this.props.job._id : null)),
                    // API.sms.sentMessages.notResponded(id)
                ]).then((res) => {
                    let messages = this.state.messages;
                    messages[id] = {
                        isLoading: false,
                        messages: res[0],
                        notResponded: [], // todo put this back in res[1]
                    };
                    this.setState({
                        messages: messages,
                    });
                });
            }
        );
    }
    sendNewSMS(notResponded){
        let globalContact   = [];
        let unitMember      = [];
        let incidentContact = [];
        let manual          = [];

        notResponded.forEach((item) => {
            if (item.globalContact !== undefined && item.globalContact !== null){
                globalContact.push(item);
            } else if (item.unitMember !== undefined && item.unitMember !== null){
                unitMember.push(item);
            } else if (item.incidentContact !== undefined && item.incidentContact !== null){
                incidentContact.push(item);
            } else {
                if (item.phoneNumber !== undefined){
                    manual.push({
                        name: item.name || item.phoneNumber,
                        phoneNumber: item.phoneNumber,
                    });
                }
            }
        });
        if (globalContact.length === 0 && unitMember.length === 0 && incidentContact.length === 0 && manual.length === 0){
            alert("ERROR: Unable to trigger new SMS as there appears to no valid contacts!");
            return;
        }
        // console.log("#### SENDING FOLLOWING RECIPIENTS ####", {
        //     recipients: {
        //         globalContact: globalContact,
        //         unitMember: unitMember,
        //         incidentContact: incidentContact,
        //         manual: manual
        //     }
        // });
        this.props.history.push("/sms/send", {
            recipients: {
                globalContact: globalContact,
                unitMember: unitMember,
                incidentContact: incidentContact,
                manual: manual,
            },
        });
    }
    renderMessageTable(messages, isOther = false){
        if (messages.length){
            return (
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>From</Table.HeaderCell>
                            <Table.HeaderCell>At</Table.HeaderCell>
                            <Table.HeaderCell>Via</Table.HeaderCell>
                            <Table.HeaderCell>Message</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {messages.map((message) => {
                            let from = message.phoneNumber;
                            if (message.staff){
                                from = message.name;
                            }
                            return (
                                <Table.Row key={message._id}>
                                    <Table.Cell>{from}</Table.Cell>
                                    <Table.Cell>{moment(message.receivedAt).format("DD/MM/YY HH:mm")}</Table.Cell>
                                    <Table.Cell>{message.isFromApp ? "APP" : "SMS"}</Table.Cell>
                                    <Table.Cell>{isOther ? message.keyword + " " + message.message : message.message}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            );
        }
        return (
            <Message info={true}>
                <Message.Header>No Messages</Message.Header>
                There are no messages to display
            </Message>
        );
    }
    renderInboxTab(inbox){
        let isLoading    = true;
        let messages     = [];
        let notResponded = [];
        let responded    = [];
        if (this.state.messages[inbox._id] !== undefined){
            if (!this.state.messages[inbox._id].isLoading){
                isLoading    = false;
                messages     = this.state.messages[inbox._id].messages;
                notResponded = this.state.messages[inbox._id].notResponded;
                responded    = this.state.messages[inbox._id].messages.filter((i) => i.keyword.toLowerCase() === "yes" || i.keyword.toLowerCase() === "later");
            }
        }
        return (
            <React.Fragment>
                <Modal open={this.state.showNotResponded === inbox._id} onClose={() => this.setState({showNotResponded: null})} closeIcon centered={false}>
                    <Header content={"View Not Responded"}/>
                    <Modal.Content>
                        <Button basic color={"blue"} floated={"right"} onClick={() => this.sendNewSMS(notResponded)}>
                            Send SMS
                        </Button>
                        <Divider hidden={true} clearing={true}/>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Phone Number</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                    <Table.HeaderCell>Status Reported At</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {notResponded.map((recipient, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>{recipient.name}</Table.Cell>
                                            <Table.Cell>{recipient.phoneNumber}</Table.Cell>
                                            <Table.Cell>{recipient.status}</Table.Cell>
                                            <Table.Cell>{recipient.statusReportedAt}</Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </Modal.Content>
                </Modal>
                <Modal open={this.state.showResponded === inbox._id} onClose={() => this.setState({showResponded: null})} closeIcon centered={false}>
                    <Header content={"View Responded Yes/Later"}/>
                    <Modal.Content>
                        <Button basic color={"blue"} floated={"right"} onClick={() => this.sendNewSMS(responded)}>
                            Send SMS
                        </Button>
                        <Divider hidden={true} clearing={true}/>
                        {this.renderMessageTable(responded)}
                    </Modal.Content>
                </Modal>
                {moment(inbox.sentAt).isBefore("2024-02-10T23:30:00Z") && <Message color={"blue"}>
                    <Message.Header>NOTICE</Message.Header>
                    Due to a recently patched bug, all inboxes created before 11:30 PM on 10/02/2024 will no longer receive new messages.
                    Please send a new text message with a new inbox to continue receiving text responses.
                    We apologise for the inconvenience this has caused.
                </Message>}
                <Segment basic className="no-pad">
                    <Grid stackable celled className="no-marg smsInbox">
                        <Grid.Row columns="equal">
                            <Grid.Column width={3}>
                                <Header>
                                    Inbox: <span>{inbox.inboxCode}</span>
                                </Header>
                                <Header>
                                    Expires: <span>{moment(inbox.inboxExpires).format("DD/MM/YY HH:mm")}</span>
                                </Header>
                                <Header>
                                    Sent At: <span>{moment(inbox.sentAt).format("DD/MM/YY HH:mm")}</span>
                                </Header>
                            </Grid.Column>
                            <Grid.Column>
                                <Header>SMS Message:</Header> {inbox.message}
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Button fluid basic color={"green"} size={"tiny"} onClick={() => this.getMessages(inbox._id)}>
                                    Refresh
                                </Button>
                                <Header>
                                    Total Recipients: <span>{inbox.sentTo.length}</span>
                                </Header>
                                <Header>
                                    Total Not Responded:{" "}
                                    <Button
                                        as="span"
                                        labelPosition="left"
                                        onClick={() => {
                                            if (notResponded.length){
                                                this.setState({showNotResponded: inbox._id});
                                            }
                                        }}
                                    >
                                        <Label as="a" basic>
                                            {notResponded.length}
                                        </Label>
                                        <Button size={"mini"} icon>
                                            <Icon name="search"/>
                                        </Button>
                                    </Button>
                                </Header>
                                <Header>
                                    Total Yes/Later:{" "}
                                    <Button
                                        as="span"
                                        labelPosition="left"
                                        onClick={() => {
                                            if (responded.length){
                                                this.setState({showResponded: inbox._id});
                                            }
                                        }}
                                    >
                                        <Label as="a" basic>
                                            {responded.length}
                                        </Label>
                                        <Button size={"mini"} icon>
                                            <Icon name="search"/>
                                        </Button>
                                    </Button>
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    <Grid stackable>
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <Box title="Responded: Yes">
                                    <Segment className="filter no-pad" basic>
                                        {this.renderMessageTable(
                                            messages.filter((message) => {
                                                return message.keyword.toLowerCase() === "yes";
                                            })
                                        )}
                                    </Segment>
                                </Box>
                            </Grid.Column>
                            <Grid.Column>
                                <Box title="Responded: No">
                                    <Segment className="filter no-pad" basic>
                                        {this.renderMessageTable(
                                            messages.filter((message) => {
                                                return message.keyword.toLowerCase() === "no";
                                            })
                                        )}
                                    </Segment>
                                </Box>
                            </Grid.Column>
                            <Grid.Column>
                                <Box title="Responded: Other">
                                    <Segment className="filter no-pad" basic>
                                        {this.renderMessageTable(
                                            messages.filter((message) => {
                                                return message.keyword.toLowerCase() !== "yes" && message.keyword.toLowerCase() !== "no";
                                            }),
                                            true
                                        )}
                                    </Segment>
                                </Box>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </React.Fragment>
        );
    }
    getTabs = () => {
        if (this.state.inboxes.length){
            return this.state.inboxes.map((inbox) => {
                return {
                    key: inbox._id,
                    menuItem: "" + inbox.inboxCode,
                    render: () => this.renderInboxTab(inbox),
                };
            });
        }
        return [];
    };
    render(){
        return (
            <Segment basic>
                <Grid stackable>
                    <Grid.Column width={16}>
                        <Header>Inbox</Header>
                        {this.state.inboxes.length ? (
                            <Tab panes={this.getTabs()} menu={{secondary: true, pointing: true}}/>
                        ) : this.state.loadingInbox ? (
                            <p>Loading...</p>
                        ) : (
                            <Message info>
                                <Message.Header>No Inboxes</Message.Header> There are no active inboxes
                            </Message>
                        )}
                    </Grid.Column>
                </Grid>
            </Segment>
        );
    }
}

export default Job_Receiver(Inbox, (job) => {
    return {
        job,
    };
});