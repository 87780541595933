import React, { Component } from "react";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";
import {
  Grid,
  Segment,
  Form,
  Icon,
  Popup,
  Checkbox,
  Header,
  Button,
  Divider,
} from "semantic-ui-react";
import api from "../../actions/api";
import Box from "../common/box";
import { DateTimeInput } from "semantic-ui-calendar-react";
import moment from "moment";
import SettingsListLinks from "../common/lists/settingListLinks";
class Dash extends Component {
  state = {
    disabled: true,
    data: {
      teamName: "",
      systemName: "",
      rotaDuration: 0,
      rotaStartTime: "",
    },
    config: {
      defaults: {
        welfareCheck: 0,
      },
    },
    mapData: {
      readOnly: true,
      lat: "",
      lng: "",
      setManual: false,
      defaultMapCenter: [0, 0],
    },
  };
  updateFormTimeout = 0;
  componentDidMount() {
    this.props.settings._refresh();
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.settings !== prevProps.settings) {
      this.setState({
        data: {
          ...this.state.data,
          rotaDuration:
            this.props.settings.rotaDuration !== null
              ? this.props.settings.rotaDuration
              : 0,
        },
        mapData: {
          ...this.state.mapData,
          defaultMapCenter: this.props.settings.defaultMapCenter,
        },
      });
    }
  };
  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  handleDropdownChange = (props, e) => {
    this.setState(
      {
        updating: true,
        data: { ...this.state.data, [e.name]: e.value },
      },
      () => {
        if (this.updateFormTimeout) {
          clearTimeout(this.updateFormTimeout);
        }
        this.updateFormTimeout = setTimeout(() => {
          return api.settings
            .update(this.state.data)
            .then((res) => {
              this.props.settings._refresh();
              this.setState({
                updating: false,
              });
              //TODO add a fancy screen notification that the title has successfully updated
            })
            .catch((err) => {
              console.error("Unable to save", err);
            });
        }, 500);
      }
    );
  };
  onChange = (e) =>
    this.setState(
      {
        updating: true,
        data: { ...this.state.data, [e.target.name]: e.target.value },
      },
      () => {
        if (this.updateFormTimeout) {
          clearTimeout(this.updateFormTimeout);
        }
        this.updateFormTimeout = setTimeout(() => {
          return api.settings
            .update(this.state.data)
            .then((res) => {
              this.props.settings._refresh();
              this.setState({
                updating: false,
              });
              //TODO add a fancy screen notification that the title has successfully updated
            })
            .catch((err) => {
              console.error("Unable to save", err);
            });
        }, 500);
      }
    );
  handleCheckboxChange = (props, e) => {
    this.setState(
      {
        updating: true,
        data: { ...this.state.data, [e.name]: e.checked },
      },
      () => {
        if (this.updateFormTimeout) {
          clearTimeout(this.updateFormTimeout);
        }
        this.updateFormTimeout = setTimeout(() => {
          return api.settings
            .update(this.state.data)
            .then((res) => {
              this.props.settings._refresh();
              this.setState({
                updating: false,
              });
              //TODO add a fancy screen notification that the title has successfully updated
            })
            .catch((err) => {
              console.error("Unable to save", err);
            });
        }, 500);
      }
    );
  };
  updateModel = (e) => {
    this.setState({
      updating: true,
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
    if (this.updateFormTimeout) {
      clearTimeout(this.updateFormTimeout);
    }
    this.updateFormTimeout = setTimeout(() => {
      return api.settings
        .update(this.state.data)
        .then((res) => {
          this.setState({
            updating: false,
          });
          //TODO add a fancy screen notification that the title has successfully updated
        })
        .catch((err) => {
          console.error("Unable to save", err);
        });
    }, 500);
  };
  handleSubmit = (data) => {
    return api.settings.update(data).then(() => {
      this.props.settings._refresh();
    });
  };
  handleDelete = (data) => {
    return api.settings.update(data).then(() => {
      this.props.settings._refresh();
    });
  };
  onMapChange = (e) => {
    if (e.target.name === "lat") {
      let bob = this.state.mapData.defaultMapCenter;
      bob[1] = e.target.value;
      this.setState({
        mapData: {
          ...this.state.mapData,
          defaultMapCenter: bob,
          setManual: true,
        },
      });
    }
    if (e.target.name === "lng") {
      let bob = this.state.mapData.defaultMapCenter;
      bob[0] = e.target.value;
      this.setState({
        mapData: {
          ...this.state.mapData,
          defaultMapCenter: bob,
          setManual: true,
        },
      });
    }
  };
  setCenter = () => {
    api.settings.setMapCenter(this.state.mapData).then((res) => {
      this.props.settings._refresh();
      this.setState({
        mapData: {
          ...this.state.mapData,
          readOnly: true,
          setManual: true,
        },
      });
    });
  };
  renderSwitches() {
    return (
      <Box title="Default Map Settings">
        <Segment basic>
          <Form>
            {/* <Form.Group grouped> */}
            {/* <Form.Field>
              <Checkbox
                toggle
                id="appAccess"
                name="appAccess"
                checked={this.state.data.appAccess}
                label="Allow App Access"
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                toggle
                id="haveTeamSite"
                name="haveTeamSite"
                checked={this.state.data.haveTeamSite}
                label="Has Teamsite"
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                toggle
                id="allowMultiAssign"
                name="allowMultiAssign"
                checked={this.state.data.allowMultiAssign}
                label="Allow Multiple Tasks for Resource/Team."
                onChange={this.handleCheckboxChange}
              />
            </Form.Field> */}
            {/* </Form.Group> */}
            <Form.Field>
              <Checkbox
                toggle
                checked={!this.state.mapData.readOnly}
                label="Reset Default Map Center"
                onChange={() =>
                  this.setState({
                    mapData: {
                      ...this.state.mapData,
                      readOnly: !this.state.mapData.readOnly,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Group widths={2}>
              <Form.Field>
                <label>Latitude</label>
                <input
                  placeholder="lat"
                  name="lat"
                  readOnly={this.state.mapData.readOnly}
                  onChange={this.onMapChange}
                  value={
                    this.state.mapData && this.state.mapData.defaultMapCenter[1]
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Longitude</label>
                <input
                  placeholder="lng"
                  name="lng"
                  onChange={this.onMapChange}
                  readOnly={this.state.mapData.readOnly}
                  value={
                    this.state.mapData && this.state.mapData.defaultMapCenter[0]
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group
              widths={2}
              style={{ justifyContent: "space-between", textAlign: "right" }}
            >
              <Form.Field>
                {/* <Button  onClick={this.setCenter} positive>Set</Button> */}
              </Form.Field>
              <Form.Field>
                <Button onClick={this.setCenter} positive>
                  Set
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
      </Box>
    );
  }
  renderRota() {
    const { settings } = this.props;
    let { data } = this.state;

    return (
      <Box title="Rota">
        <Segment basic>
          <p>Hours in: 1 day = 24 | 1 week = 168 | 1 month = 730 (ish)</p>
          <Form>
            <Form.Input
              name="rotaDuration"
              value={data.rotaDuration ? data.rotaDuration : 0}
              onChange={this.onChange}
              label="Rota Duration in hours"
            />
            <Form.Field>
              <DateTimeInput
                autoComplete={"off"}
                animation={"off"}
                name="rotaStartTime"
                label="Rota Start-Time"
                dateTimeFormat="YYYY/MM/DD HH:mm"
                value={
                  settings.rotaStartTime
                    ? moment(settings.rotaStartTime).format("YYYY-MM-DD HH:mm")
                    : ""
                }
                iconPosition="left"
                onChange={this.handleDropdownChange}
                startMode={"day"}
                closable
              />
            </Form.Field>
          </Form>
          <Header>Current Rota: </Header>
        </Segment>
      </Box>
    );
  }
  render() {
    const { settings } = this.props;

    return (
      <Segment basic>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              {/* <Segment>
                <Popup
                  basic
                  content={
                    "If organisation uses the Zello Communications system"
                  }
                  position={"top left"}
                  trigger={
                    <Header className={"withIcon"}>
                      Has Zello
                      <Icon name="info circle" color="yellow" size="small" />
                    </Header>
                  }
                />
                <Checkbox
                  toggle
                  name="hasZello"
                  className={"goodToggle"}
                  checked={settings.hasZello}
                  onChange={this.handleCheckboxChange}
                />
                <Popup
                  basic
                  content={"If organisation uses a GPS system"}
                  position={"top left"}
                  trigger={
                    <Header className={"withIcon"}>
                      Has GPS Devices
                      <Icon name="info circle" color="yellow" size="small" />
                    </Header>
                  }
                />
                <Checkbox
                  toggle
                  name="hasGPS"
                  className={"goodToggle"}
                  checked={settings.hasGPS}
                  onChange={this.handleCheckboxChange}
                />


              </Segment> */}

              <SettingsListLinks
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={this.props.settings.externalLinks}
                placeholder="http://......"
                placeholderLabel="link name"
                title="External Links"
                field="externalLinks"
              />
            </Grid.Column>
            <Grid.Column>
              {/* {this.renderSwitches()} */}
              {/* <Divider hidden /> */}
              {/* {this.renderRota()} */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
export default Settings_Receiver(Dash, (settings) => {
  return {
    settings,
  };
});
