import React, { useState, useEffect } from "react";
import Input from "../../components/common/ui/Input";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import api from "../../actions/api";
export default function StaffRota({ history }) {
  const [duration, setDuration] = useState(0);
  const [rota, setRotas] = useState([]);
  const [data, setData] = useState({
    type: "",
    endTime: "",
    startTime: "",
  });
  const [errors, setErrors] = useState({
    type: "",
    endTime: "",
    startTime: "",
  });
  useEffect(() => {
    getRotas();
  }, []);
  useEffect(() => {
    if (data.startTime !== "" && data.endTime !== "") {
      setDuration(dayjs(data.endTime).diff(dayjs(data.startTime), "hour")); // 7
    }
  }, [data]);
  const getRotas = () => {
    api.rota.all().then((r) => {
      setRotas(r.rotas);
    });
  };
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const clearForm = () => {
    setData({
      type: "",
      endTime: "",
      startTime: "",
    });
  };
  const handleAdd = () => {
    console.log("add", data);
    api.rota.add(data).then((res) => {
      getRotas();
      clearForm();
    });
  };
  const gotoRota = (rota) => {
    history.push(`/staff/rota/${rota}`);
  };
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };
  const addHours = (hours) => {
    let start = dayjs(data.startTime);
    let end = start.add(hours, "hour");
    console.log(hours);
    setData({
      ...data,
      endTime: end,
    });
  };

  return (
    <div className="p-0 sm:p-2  w-full h-full">
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="w-full sm:w-2/6">
          <div className="w-full border-2 border-blue-700  p-2">
            <div className="text-center text-xl">Add Rota</div>
            <div className="flex gap-2">
              <Input
                label={"Name"}
                field={"type"}
                onChange={onChange}
                placeholder="Storm Bob....."
                value={data.type}
                error={errors.type}
                style={"w-full "}
              />
            </div>
            <div className="flex gap-2 mt-2">
              <div className="w-full sm:w-1/2">
                <label
                  htmlFor={"startTime"}
                  className={`block text-sm font-medium ${
                    errors.startTime ? "text-red-700" : "text-blue-400"
                  } `}
                >
                  Start Time
                </label>
                <DatePicker
                  onChange={(date) =>
                    setData({
                      ...data,
                      startTime: date,
                    })
                  }
                  selected={data.startTime ? new Date(data.startTime) : ""}
                  showPopperArrow={false}
                  // showTimeInput
                  showTimeSelect
                  timeIntervals={30}
                  shouldCloseOnSelect={true}
                  timeInputLabel="Time:"
                  dateFormat="dd-MM-yy HH:mm"
                  timeFormat="HH:mm"
                  peekNextMonth
                  minDate={new Date()}
                  className={`relative  w-full p-2 rounded-lg border block w-full text-base  md:text-sm bg-gray-800 border border-gray-700 rounded shadow-sm ${
                    errors.startTime ? "border-red-300" : "border-gray-900"
                  } `}
                />
                <label
                  htmlFor={"endTime"}
                  className={`block text-sm font-medium ${
                    errors.endTime ? "text-red-700" : "text-blue-400"
                  } `}
                >
                  End Time
                </label>
                <DatePicker
                  onChange={(date) =>
                    setData({
                      ...data,
                      endTime: date,
                    })
                  }
                  selected={data.endTime ? new Date(data.endTime) : ""}
                  showPopperArrow={false}
                  // showTimeInput
                  showTimeSelect
                  timeIntervals={15}
                  shouldCloseOnSelect={true}
                  timeInputLabel="Time:"
                  dateFormat="dd-MM-yy HH:mm"
                  peekNextMonth
                  filterTime={filterPassedTime}
                  minDate={data.startTime}
                  className={`relative  w-full p-2 rounded-lg border block w-full text-base  md:text-sm bg-gray-800 border border-gray-700 rounded shadow-sm ${
                    errors.endTime ? "border-red-300" : "border-gray-900"
                  } `}
                />
              </div>
              <div className="w-full sm:w-1/2 p-2">
                <div className="text-xl text-center text-blue-400">
                  Quick Duration
                </div>
                {data.startTime ? (
                  <div className="flex flex-wrap gap-2 mt-2">
                    <button
                      className="px-3 py-1 bg-gray-500 rounded-xl text-lg"
                      onClick={() => addHours(3)}
                    >
                      3hrs
                    </button>
                    <button
                      className="px-3 py-1 bg-gray-500 rounded-xl text-lg"
                      onClick={() => addHours(6)}
                    >
                      6hrs
                    </button>
                    <button
                      className="px-3 py-1 bg-gray-500 rounded-xl text-lg"
                      onClick={() => addHours(8)}
                    >
                      8hrs
                    </button>
                    <button
                      className="px-3 py-1 bg-gray-500 rounded-xl text-lg"
                      onClick={() => addHours(12)}
                    >
                      12hrs
                    </button>
                  </div>
                ) : (
                  "Pick start time."
                )}
              </div>
            </div>
            <div className="p-2 mt-2 border-t border-gray-700 flex justify-between">
              <button onClick={clearForm} className="p-2 bg-red-600 rounded-lg">
                Clear
              </button>
              <button
                onClick={handleAdd}
                className="p-2 bg-green-600 rounded-lg"
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <div className=" w-4/6">
          <table className="table-auto w-full border-2 border-blue-700 ">
            <thead>
              <tr className="border border-blue-700  bg-blue-900">
                <th className="p-2 text-center">RefNum</th>
                <th className="p-2">Type/Name</th>
                <th className="p-2 text-center">Start</th>
                <th className="p-2 text-center">End</th>
                <th className="p-2 text-center">Duration</th>
                <th className="p-2 text-center">On Shift</th>
              </tr>
            </thead>
            <tbody>
              {rota.map((r) => {
                let from = dayjs(r.startTime);
                let to = dayjs(r.endTime);
                let duration = to.diff(from, "hours");

                return (
                  <tr
                    key={r._id}
                    className="border border-blue-700  cursor-pointer"
                    onClick={() => gotoRota(r._id)}
                  >
                    <td className="p-2 text-center">{r.refNum}</td>
                    <td className="p-2">{r.type}</td>
                    <td className="p-2 text-center">
                      {dayjs(r.startTime).format("DD-MM-YY HH:mm")}
                    </td>
                    <td className="p-2 text-center">
                      {dayjs(r.endTime).format("DD-MM-YY HH:mm")}
                    </td>
                    <td className="p-2 text-center">{duration}</td>
                    <td className="p-2 text-center">{r.OnShift}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
