import React                                              from "react";
import {Segment, Icon, Image, Message, Button, Container} from "semantic-ui-react";
import Logo                                               from "./assets/images/cad.png";

export default class ErrorCatchPage extends React.Component {
    state = {
        isError: false
    };
    componentDidCatch(error, info){
        // Display fallback UI
        this.setState({isError: true});
    }
    render(){
        if (this.state.isError){
            return (
                <Container>
                    <Segment basic textAlign={"center"}>
                        <Image src={Logo} style={{width: 150, margin: "auto"}}/>
                        <div className={"p-4 m-4 bg-red-700 white-text"}>
                            <h1 style={{fontSize: "1.75em"}}><Icon name={"bug"}/> There seems to be a problem <Icon name={"bug"}/></h1><br/>
                            Oh no, it looks like you have found a bug!<br/>
                            Please help us improve SarSYS CAD and report this bug to <a className={"underline"} href={"mailto:support@sarstuff.co.uk"}>support@sarstuff.co.uk</a> detailing the exact steps you have taken prior to seeing this screen.
                        </div>
                        <Button positive onClick={() => window.location="/"}><Icon name={"refresh"}/> Reload SarSYS CAD</Button>
                    </Segment>
                </Container>
            );
        }
        return this.props.children;
    }
}