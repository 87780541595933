import React, { Component } from "react";
import {
  Modal,
  Form,
  Header,
  Button,
  Table,
  Grid,
  Divider,
  Segment,
  Icon,
  Menu,
} from "semantic-ui-react";
import TaskMap from "../common/mapping/taskMap";
import moment from "moment";
import api from "../../actions/api";
import Box from "../common/box";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";

class JobMap extends Component {
  state = {
    job: {},
  };
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.job !== prevProps.job) {
      this.setState({
        job: this.props.job,
      });
    }
  }
  getData = () => {
    this.props.job._refresh();
  };
  setFlyTo = (data) => {
    this.setState({
      flyTo: data,
    });
  };
  renderMenu() {
    return (
      <Segment className="no-pad no-marg">
        <Menu>
          <Menu.Item>
            <Icon name="pencil" />
            Edit
          </Menu.Item>
        </Menu>
      </Segment>
    );
  }
  renderLocs() {
    const locs = this.state.job.locations && this.state.job.locations.map((loc, i) => {
      return (
        <React.Fragment key={i}>
          <Table.Row className="borderTop">
            <Table.Cell className="title">
              {loc.geoJSON.properties.type}
            </Table.Cell>
            <Table.Cell className="title">
              {loc.geoJSON.properties.department}
            </Table.Cell>
            <Table.Cell>{loc.geoJSON.properties.grid}</Table.Cell>
            <Table.Cell>{loc.geoJSON.properties.w3w}</Table.Cell>
            <Table.Cell textAlign="right" rowSpan="2">
              <Button
                icon
                basic
                inverted
                onClick={() => this.setFlyTo(loc.geoJSON.geometry.coordinates)}
              >
                <Icon name="map marker" />
              </Button>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan="2">{loc.geoJSON.properties.name}</Table.Cell>
            <Table.Cell colSpan="2">
              {loc.geoJSON.properties.address}
            </Table.Cell>
          </Table.Row>
        </React.Fragment>
      );
    });
    return (
      <Box title="locations">
        <Grid celled stackable className={"no-marg"}>
          <Grid.Row>
            <Grid.Column className="no-pad">
              <Table celled structured className="locTable">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Type/Name</Table.HeaderCell>
                    <Table.HeaderCell>Department</Table.HeaderCell>
                    <Table.HeaderCell>Grid</Table.HeaderCell>
                    <Table.HeaderCell>W3W</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{locs.length > 0 && locs}</Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Box>
    );
  }
  renderMap(center) {

    return (
      <TaskMap
        center={center}
        data={this.mapClick}
        // location={
        //   this.state.location &&
        //   this.state.location.geoJSON.geometry &&
        //   this.state.location
        // }
        flyTo={this.state.flyTo}
        zones={this.state.zones}
        locations={this.state.job.locations && this.state.job.locations.length > 0 ? this.state.job.locations : []}
      />
    );
  }
  render() {
    const { job } = this.state;
    let center = [0.521725, 51.278708];
    if (job && job.locations && job.locations.length > 0) {
      center = job.locations[0].geoJSON.geometry.coordinates;
    }

    return (
      <Grid stackable className="no-marg no-pad">
        <Grid.Row columns={1} className="no-pad">
          <Grid.Column className="no-pad">{this.renderMap(center)}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default Job_Receiver(JobMap, (job) => {
  return {
    job,
  };
});
