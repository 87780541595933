import React from "react";
import { Circle } from "@react-google-maps/api";

export default ({ center, radius, colour, handleMarkerClick }) => {
  // let options = {
  //   strokeColor: "#FF0000",
  //   strokeOpacity: 0.8,
  //   strokeWeight: 2,
  //   fillColor: "#FF0000",
  //   fillOpacity: 0,
  //   clickable: false,
  //   draggable: false,
  //   editable: false,
  //   visible: true,
  //   radius: 30000,
  //   zIndex: 1,
  // };
  return (
    <Circle
      // key={index}
      center={center}
      radius={radius}
      options={{ strokeColor: colour, fillOpacity: 0 }}
    />
  );
};
