import React, { Component } from "react";
import { Segment, Header, Button, Icon } from "semantic-ui-react";

const Box = (props) => {
  return (
    <Segment className={`box no-marg `}>
      {props.title && (
        <Segment basic className=" headerBlock">
          <Header>{props.title}</Header>
          {props.action && (
            <Button basic icon inverted onClick={props.action}>
              {props.icon}
            </Button>
          )}{" "}
        </Segment>
      )}
      {props.menu && props.menu}

      <Segment basic className={`no-pad no-marg ${props.className ? props.className : ""}`}>
        {props.children}
      </Segment>
    </Segment>
  );
};

export default Box;
