import React from "react";
import { NavLink } from "react-router-dom";
import {Menu, Header, Dropdown, Icon, Modal, Message, Button, Loader} from "semantic-ui-react";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";
import API from "../../actions/api";

class JobNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        activeItem: ""
    };
  }
  handleItemClick = () => {};

  render() {
    const { activeItem } = this.state;
    return (
      <Menu attached="top" stackable className={"settings-menu"}>
        <Menu.Item>
          <Header as={"h2"} className={"primary"}>
            Staff
          </Header>
        </Menu.Item>
        <Menu.Item name={"home"} as={NavLink} to={this.props.match.url} active={activeItem === "home"} exact onClick={this.handleItemClick}>
          Dashboard
        </Menu.Item>
        <Menu.Item name={"rota"} as={NavLink} to={this.props.match.url + "/rota"} active={activeItem === "rota"} exact onClick={this.handleItemClick}>
          Rota
        </Menu.Item>
        <Menu.Item name={"list"} as={NavLink} to={this.props.match.url + "/list"} active={activeItem === "list"} exact onClick={this.handleItemClick}>
          List
        </Menu.Item>
      </Menu>
    );
  }
}
export default Job_Receiver(JobNav, (job) => {
  return {
    job,
  };
});
