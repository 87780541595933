import React, { Component } from "react";
import {
  Segment,
  Menu,
  Icon,
  Modal,
  Button,
  Form,
  Divider,
  Header,
  Table,
} from "semantic-ui-react";
import Box from "../../common/box";

import Settings_Receiver from "../../../contexts/settings_context/Settings_Receiver";
import api from "../../../actions/api";

class ClientsList extends Component {
  state = {
    addClient: false,
    clients: [],
    data: {
      name: "",
      details: "",
      type: "",
    },
    settings: {
      ClientTypes: [],
    },
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    api.client.all().then((clients) => {
      this.setState({
        clients: clients.clients,
      });
    });
    api.settings.formLists().then((settings) => {
      this.setState({
        settings,
      });
    });
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };
  addClient = (data) => {
    api.client.add(data).then(() => {
      this.getData();
      this.setState({
        addClient: false,
      });
    });
  };
  renderAddClient() {
    return (
      <Modal
        size="large"
        open={this.state.addClient}
        onClose={() => this.setState({ addClient: false })}
        centered={false}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header>Add Client</Modal.Header>
        <Modal.Content>
          <Form style={{ width: "100%" }}>
            <Form.Field>
              <Form.Dropdown
                name="type"
                label="Client Type"
                value={this.state.data.type}
                onChange={this.handleDropdownChange}
                options={this.state.settings.ClientTypes}
                selection
                search
                placeholder="NHS, Police,Fire..."
                required={true}
              />
            </Form.Field>
            <Form.Field>
              <label>Client Name</label>
              <input
                name="name"
                onChange={this.onChange}
                value={this.state.data.name}
                placeholder={"name...."}
              />
            </Form.Field>
            <Form.Group widths={2}>
              <Form.Field>
                <label>Details</label>
                <Form.TextArea
                  name={"details"}
                  value={this.state.data.details}
                  onChange={this.onChange}
                />
              </Form.Field>
            </Form.Group>
            <Divider />
            <Form.Group widths={"equal"} className={"form-actions"}>
              <Form.Field>
                <Button
                  content="Cancel"
                  negative
                  fluid
                  onClick={() => this.setState({ addClient: false })}
                  labelPosition="left"
                  icon="close"
                />
              </Form.Field>
              <Form.Field>
                <Button
                  content="Add Client"
                  fluid
                  labelPosition="right"
                  icon="checkmark"
                  onClick={() => this.addClient(this.state.data)}
                  positive
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
  renderClients() {
    const list = this.state.clients.map((c) => {
      return (
        <Table.Row
          key={c._id}
          onClick={() =>
            this.props.history.push(`/predefined/clients/${c._id}`)
          }
        >
          <Table.Cell>{c.name}</Table.Cell>
          <Table.Cell>{c.type}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table celled striped stackable selectable className="no-marg">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{list}</Table.Body>
      </Table>
    );
  }
  render() {
    return (
      <Segment className="no-pad">
        {this.renderAddClient()}
        <Menu>
          <Menu.Item onClick={() => this.setState({ addClient: true })}>
            <Icon name="plus" /> Add
          </Menu.Item>
          <Menu.Menu position="right">
            <div className="ui right aligned category search item">
              <div className="ui transparent icon input">
                {/* <input className="prompt" type="text" placeholder="Search clients..." /> */}
                {/* <i className="search link icon" /> */}
              </div>
              <div className="results" />
            </div>
          </Menu.Menu>
        </Menu>
        <Box title="Clients">{this.renderClients()}</Box>
      </Segment>
    );
  }
}
export default Settings_Receiver(ClientsList, (settings) => {
  return {
    settings,
  };
});
