import React from "react";
import { NavLink } from "react-router-dom";
import { Menu, Header } from "semantic-ui-react";

export default class SubNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "",
    };
  }

  handleItemClick = () => {};

  render() {
    const { activeItem } = this.state;
    return (
      <Menu attached="top" stackable className={"settings-menu"}>
        <Menu.Item>
          <Header as={"h2"} className={"primary"}>
            Predefined
          </Header>
        </Menu.Item>
        <Menu.Item name={"home"} as={NavLink} to={this.props.match.url} active={activeItem === "home"} exact onClick={this.handleItemClick}>
          {" "}
          Dashboard
        </Menu.Item>
        <Menu.Item name={"locations"} as={NavLink} to={this.props.match.url + "/locations"} active={activeItem === "locations"} exact onClick={this.handleItemClick}>
          {" "}
          Locations
        </Menu.Item>
        <Menu.Item name={"clients"} as={NavLink} to={this.props.match.url + "/clients"} active={activeItem === "clients"} exact onClick={this.handleItemClick}>
          {" "}
          Clients
        </Menu.Item>
        {/* <Menu.Item name={"staff"} as={NavLink} to={this.props.match.url + "/staff"} active={activeItem === "staff"} exact onClick={this.handleItemClick}>
          Staff
        </Menu.Item> */}
        {/* <Menu.Item
          name={"custom"}
          as={NavLink}
          to={this.props.match.url + "/custom"}
          active={activeItem === "custom"}
          exact
          onClick={this.handleItemClick}
          >
          {" "}
          Custom Values
        </Menu.Item> */}
      </Menu>
    );
  }
}
