import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Header } from "semantic-ui-react";

export default class VertBar extends Component {
  state = {
    options: {
      chart: {
        id: "temp",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      yaxis: {
        lines: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: "white",
            fontSize: "12px",
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        lines: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            colors: "white",
            fontSize: "12px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      series: [
        {
          //   name: "Temp data",
          data: [],
        },
      ],
    },
  };
  componentDidMount() {
    if (this.props.data) {
      this.setState({
        options: {
          ...this.state.options,
          series: [
            {
              //   name: "Temp data",
              data: this.props.data,
            },
          ],
        },
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Header>{this.props.label}</Header>
        <Chart
          options={this.state.options}
          series={this.state.options.series}
          type="bar"
          width={"100%"}
          height={this.props.height ? this.props.height : 320}
        />
      </React.Fragment>
    );
  }
}
