import React, { Component } from "react";
import {Tab, Grid, Message} from "semantic-ui-react";
import api from "../../actions/api";
import StaffList from "../common/lists/staffList";
import Box from "../common/box";
import Job_Receiver from "../../contexts/job_context/Job_Receiver";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";

class JobResources extends Component {
  state = {
    job: {
      locations: [],
      Resources: [],
    },
    rotaStaff: [],
    smsResources: [],
    allStaff: [],
    resources: [],
    loading: true,
  };
  componentDidMount() {
    this._getData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.job !== prevProps.job) {
      this.setState(
        {
          job: this.props.job,
        },
        () => {
          this.getRotaStaff();
        }
      );
    }
  }
  _getData = () => {
    this.props.job._refresh();
    Promise.all([
      api.staff.all(),
      api.jobs.getSMSResources(this.props.job._id)
    ]).then((res) => {
      this.setState({
        allStaff: res[0],
        smsResources: res[1],
        loading: false,
      });
    });
  };
  getRotaStaff = () => {
    let rotas = this.state.job.Rotas;
    if (rotas.length) {
      Promise.all(rotas.map((r) => api.rota.show(r._id))).then((res) => {
        let staff = res.map((r) => {
          return { name: r.type, staff: r.Staff };
        });
        this.setState({
          rotaStaff: staff,
        });
      });
    }
  };
  addTo = (data) => {
    if (data) {
      let staff = {
        staff: data._id,
      };
      api.jobs.assign(this.props.job._id, staff).then(() => {
        this._getData();
      });
    }
  };
  removeFrom = (data) => {

    let staff = {
      staff: data._id,
    };
    api.jobs.unassign(this.props.job._id, staff).then(() => {
      this._getData();
    });
  };
  renderFilterStaff = (list) => {
    let data = []
    data = list;
    let resources = this.state.job.Resources;
    if (data.length) {
      return data.filter((i) => {
        let ii = resources.filter((aa) => aa._id === i._id);
        return ii[0] === undefined;
      });
    }

  };
  renderOnShiftStaff() {
    if (this.state.rotaStaff.length) {
      return this.state.rotaStaff.map((s) => {
        return <StaffList key={s.name} title={s.name} data={this.renderFilterStaff(s.staff)} showInArrow action={this.addTo} />;
      });
    }
      return <div className={"p-3"}><Message negative>There are no staff rota's setup to display.</Message></div>;
  }
  renderStaff() {
    return <StaffList data={this.renderFilterStaff(this.state.allStaff)} showInArrow action={this.addTo} />;
  }
  renderSMSResponders() {
    return <StaffList data={this.renderFilterStaff(this.state.smsResources.filter(i => i.SMS.keyword === "yes" || i.SMS.keyword === "later"))} showInArrow action={this.addTo} />;
  }
  render() {
    const panes = [
      {
        menuItem: "SMS Yes/Later",
        render: () => (
          <Tab.Pane attached={false} className={"no-pad no-marg"} basic>
            {this.renderSMSResponders()}
          </Tab.Pane>
        ),
      },
      {
        menuItem: "On Shift",
        render: () => (
          <Tab.Pane attached={false} className={"no-pad no-marg"} basic>
            {this.renderOnShiftStaff()}
          </Tab.Pane>
        ),
      },
      {
        menuItem: "All Staff",
        render: () => (
          <Tab.Pane attached={false} className={"no-pad no-marg"} basic>
            {this.renderStaff()}
          </Tab.Pane>
        ),
      },
    ];
    return (
      <Grid stackable className="no-marg">
        <Grid.Row>
          <Grid.Column width={8}>
            <Box title="Staff Assigned To Job">
              <StaffList data={this.state.job.Resources} showOutArrow action={this.removeFrom} settings={this.props.settings} />
            </Box>
          </Grid.Column>
          <Grid.Column width={8}>
            <Box title={"Assignable Staff Members"}>
              <Tab menu={{ secondary: true, pointing: false }} panes={panes} />{" "}
            </Box>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
let TheJobResources = Settings_Receiver(JobResources, (settings) => {
  return {
    settings,
  };
});
export default Job_Receiver(TheJobResources, (job) => {
  return {
    job,
  };
});