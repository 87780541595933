import React, {Component} from "react";
import {Button, Header, Loader, Message, Segment} from "semantic-ui-react";
import {Authentication, AuthComponents} from "@liquidcomputing/sarstuff__reactjs-singlesignon";

const HasWebAccess = ({children}) => {

    return (
        <React.Fragment>
            <AuthComponents.Can scope={"sarsys:web:access"}>{children}</AuthComponents.Can>
            <AuthComponents.CanNot scope={"sarsys:web:access"}>
                <Segment basic textAlign={"center"}>
                    <div className={"p-2 m-2 bg-red-700 border rounded"}>
                        <h1 className={"text-3xl font-bold"}>Access Denied</h1>
                        <br />
                        Your account does not belong to one of the following security groups in SarStuff Central: '<strong>SarSYS - Full Access</strong>', '
                        <strong>SarSYS - User Access</strong>' or '<strong>Organisation Admins</strong>' therefore access has been denied.
                        <br /><br />
                        Please speak to your Organisation Admins to assign one of these groups to your account if you believe you should have access to SarSYS.
                    </div>
                    <Button onClick={() => Authentication.logout()} positive>
                        Logout
                    </Button>
                </Segment>
            </AuthComponents.CanNot>
        </React.Fragment>
    );
}
export default HasWebAccess;