import React from "react";
import { toast } from "react-semantic-toasts";
import { Button, Confirm, Segment } from "semantic-ui-react";
import API from "../actions/api";
import BuildNumber from "../_buildNumber";

export default class FrontendUpdateChecker extends React.Component {
  checkInterval = 5; // 5 minute updates check
  updateChecker = null;
  state = {
    showReloadWarning: false,
  };
  componentDidMount() {
    this.checkForUpdates();
    this.updateChecker = setInterval(() => {
      this.checkForUpdates();
    }, this.checkInterval * 60 * 1000);
  }
  checkForUpdates() {
    API._getBuildNumber().then((version) => {
      if (version.toString() !== BuildNumber.toString()) {
        toast({
          type: "info",
          icon: "cloud download",
          title: <h3>Update Available</h3>,
          description: (
            <span>
              <p>
                There is a new version of the <strong>SarSYS [CAD]</strong> application. When you have a free moment please click the "Reload Application" button below to update to
                the latest version.
              </p>
              <Button positive size={"small"} floated={"right"} onClick={() => this.setState({ showReloadWarning: true })}>
                Reload Application
              </Button>
            </span>
          ),
          time: this.checkInterval * 60 * 1000 - 5,
        });
      }
    });
  }

  render() {
    if (this.state.showReloadWarning) {
      //
      return (
        <Confirm
          open
          cancelButton={"No, go back. I'll update later"}
          confirmButton={"I accept, Please reload the application"}
          content={
            <Segment basic textAlign={"center"}>
              <h3 style={{ color: "red" }}>
                WARNING
                <br />
                Please ensure your latest entry has been saved prior to accepting this system update
              </h3>{" "}
              <br />
              Any unsaved changes will be lost during the system update
            </Segment>
          }
          onCancel={() => {
            this.setState({ showReloadWarning: false });
          }}
          onConfirm={() => {
            // eslint-disable-next-line
            window.location = "/";
          }}
        />
      );
    }
    return null;
  }
}
