import React, { Component } from "react";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";

import { Segment, Grid, Checkbox, Header, Form, Popup, Icon, Divider } from "semantic-ui-react";
import SettingsList from "../common/lists/settingList";
import api from "../../actions/api";
import RotaTypesWithRoles from "../common/lists/incidentTypeModules";
import Box from "../common/box";

class SMSSettings extends Component {
  state = {
    data: {},
  };
  updateFormTimeout = 0;

  componentDidMount() {
    if (this.props.settings) {
      this.setState({
        data: this.props.settings,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings !== prevProps.settings) {
      this.setState({
        data: this.props.settings,
      });
    }
  }

  handleCheckboxChange = (props, e) => {
    this.setState(
      {
        updating: true,
        data: { ...this.state.data, [e.name]: e.checked },
      },
      () => {
        if (this.updateFormTimeout) {
          clearTimeout(this.updateFormTimeout);
        }
        this.updateFormTimeout = setTimeout(() => {
          return this.handleSubmit(this.state.data)
            .then((res) => {
              this.setState({
                updating: false,
              });
              //TODO add a fancy screen notification that the title has successfully updated
            })
            .catch((err) => {
              console.error("Unable to save", err);
            });
        }, 500);
      }
    );
  };
  handleSubmit = (data) => {
    return api.settings.update(data).then(() => {
      this.props.settings._refresh();
    });
  };

  handleDelete = (data) => {
    return api.settings.update(data).then(() => {
      this.props.settings._refresh();
    });
  };

  render() {
    let orgRoles = [];
    let rotaTypes = [];
    let smsKeywords = [];

    if (this.props.settings.orgRoles) {
      orgRoles = this.props.settings.orgRoles;
    }
    if (this.props.settings.smsKeywords) {
      smsKeywords = this.props.settings.smsKeywords;
    }

    return (
      <Segment basic>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Segment basic className="no-pad">
                <SettingsList submit={this.handleSubmit} delete={this.handleDelete} data={smsKeywords} placeholder="Yes,No,Later....." title="SMS Keywords" field="smsKeywords" />
              </Segment>
            </Grid.Column>

            <Grid.Column></Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Settings_Receiver(SMSSettings, (settings) => {
  return {
    settings,
  };
});
