import React, { Component } from "react";
import ReactMapboxGl, {
  ZoomControl,
  ScaleControl,
  GeoJSONLayer,
  Source,
  Layer,
  Marker,
} from "react-mapbox-gl";
import { Button, Popup as ToolTip, Icon, Header } from "semantic-ui-react";
import * as turf from "@turf/turf";

import styled from "styled-components";

const RASTER_SOURCE_OPTIONS = {
  type: "raster",
  tiles: [
    "https://ecn.t0.tiles.virtualearth.net/tiles/r{quadkey}?g=7080&lbl=l1&productSet=mmOS&key=AoMKVVkBRY3_8gv8UJmlIHXp8DCIGGzv9Yw_50Q4WIqC5ujgtVszQK-xLpB34hF2",
  ],
  tileSize: 256,
  maxzoom: 16,
  minzoom: 12,
};
const { token, styles } = require("./config.json");
const Map = ReactMapboxGl({
  accessToken: token,
  preserveDrawingBuffer: true,
});
const selectedStyles = ["outdoor", "sat", "broadNet"];
const switchStyles = Object.keys(styles).filter((k) =>
  selectedStyles.includes(k)
);
const Mark = styled.div`
  background-color: blue;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 4px solid #eaa29b;
`;
const linePaint1 = {
  "line-width": 4,
  "line-opacity": 0.6,
  "line-color": "blue",
};
const fillPaint = {
  "fill-color": "red",
  "fill-opacity": 0.08,
};
export default class BasicMap extends Component {
  state = {
    styleKey: "os",
    os: false,
    reload: true,
    marker: {},
    loading: true,
    showDraw: false,
    center: [0.7, 51.2],
  };
  componentDidMount() {
    if (this.map) {
      this.setState({
        center: this.props.center,
      });
      setTimeout(() => {
        this.mapBounds();
      }, 500);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.center !== prevProps.center) {
      this.setState({
        center: this.props.center,
      });
    }
  }
  flyTo = (coords) => {
    this.map.state.map.jumpTo({
      center: coords,
      zoom: [16],
    });
  };
  onStyleLoad = (map) => {
    const { onStyleLoad } = this.props;
    // map.addControl(new GeolocateControl());
    this.setState({
      loading: false,
    });
    return onStyleLoad && onStyleLoad(map);
  };
  nextStyle = () => {
    const { styleKey } = this.state;
    const currentIndex = switchStyles.indexOf(styleKey);
    const nextIndex =
      currentIndex === switchStyles.length - 1 ? 0 : currentIndex + 1;
    this.setState({
      styleKey: switchStyles[nextIndex],
      os: false,
    });
  };
  resetMap = () => {
    setTimeout(() => {
      this.mapBounds();
    }, 500);
    return this.map.state.map.flyTo({
      center: this.props.center,
      zoom: 14,
      bearing: 0,
      pitch: 0,
    });
  };
  onClickMap = (map, evt) => {
    if (evt.lngLat) {
      let coords = [
        parseFloat(evt.lngLat.lng.toFixed(5)),
        parseFloat(evt.lngLat.lat.toFixed(5)),
      ];
      if (coords.length && this.props.data) {
        this.props.data(coords);
      }
    }
  };
  mapHack = () => {
    this.setState(
      {
        reload: false,
      },
      () =>
        this.setState(
          {
            os: !this.state.os,
          },
          () => {
            this.setState({
              reload: true,
            });
          }
        )
    );
  };
  mapBounds = () => {
    if (this.props.area) {
      let bbox = turf.bbox(this.props.area);
      this.map.state.map.fitBounds(bbox, { padding: 20, maxZoom: 16 });
    } else {
      if (this.props.point) {
        this.flyTo(this.props.point);
      }
    }
  };
  renderPoint() {
    if (this.props.point && this.props.point !== [0, 0]) {
      return (
        <Marker coordinates={this.props.point}>
          <Mark />
        </Marker>
      );
    }
    return;
  }
  renderData = (data) => {
    let features = data
      .filter((loc) => {
        if (loc.geoJSON.geometry.coordinates.length) {
          return true;
        } else if (loc.geometry.coordinates.length) {
          return true;
        }
        return false;
      })
      .map((loc) => {
        if (loc.geoJSON) {
          return loc.geoJSON;
        } else {
          return loc;
        }
      });
    return {
      type: "FeatureCollection",
      features: features,
    };
  };
  renderMapBar() {
    return (
      <div className="mapBar">
        <ToolTip
          trigger={
            <Button icon onClick={(e) => this.resetMap(e)} inverted>
              <Icon color="orange" name="compass" />
            </Button>
          }
          content="reset bearing and pitch"
          basic
        />
        <ToolTip
          trigger={
            <Button icon onClick={(e) => this.nextStyle(e)} inverted>
              <Icon name="world" />
            </Button>
          }
          content="Change Map Type"
          basic
        />
        <ToolTip
          trigger={
            <Button
              icon
              onClick={() => this.mapHack()}
              inverted
              toggle
              active={this.state.os}
            >
              OS
            </Button>
          }
          content="Toggle OS Map"
          basic
        />
      </div>
    );
  }

  renderArea() {
    // let areas = this.renderData(this.props.area);
    return (
      <GeoJSONLayer
        id="areas"
        data={this.props.area}
        linePaint={linePaint1}
        fillPaint={fillPaint}
      />
    );
  }
  render() {
    let mapStyle = {
      height: 100 + "%",
      width: 100 + "%",
    };
    if (this.props.mapStyle) {
      mapStyle = this.props.mapStyle;
    } else {
      mapStyle = {
        minHeight: 59 + "vh",

        height: 100 + "%",
        width: 100 + "%",
      };
    }
    const { styleKey, os, marker } = this.state;

    return (
      <Map
        ref={(e) => {
          this.map = e;
        }}
        // zoom={[14]}
        center={this.state.center !== null && this.state.center}
        style={styles[styleKey]}
        movingMethod={"jumpTo"}
        onStyleLoad={this.onStyleLoad}
        className="basicMap"
        onClick={this.onClickMap}
        containerStyle={mapStyle}
      >
        <Source id="OS" tileJsonSource={RASTER_SOURCE_OPTIONS} />
        {os && <Layer type="raster" id={"1"} sourceId="OS" />}
        {this.renderMapBar()}

        {this.state.reload && this.props.point && this.renderPoint()}
        {this.state.reload && this.props.area && this.renderArea()}

        <ZoomControl position={"top-left"} />
        {this.props.mapKey && this.props.mapKey}
        <ScaleControl position={"bottom-left"} />
      </Map>
    );
  }
}
