import React from "react";
import {
  Segment,
  Button,
  Input,
  Form,
  Header,
  Divider,
  Confirm,
  Table,
} from "semantic-ui-react";
import Box from "../box";
import { GithubPicker } from "react-color";

export default class ColourTypeList extends React.Component {
  state = {
    textvalue: "",
    color: "#1273DE",
    order: 0,
    role: "",
    isEdit: false,
    editId: null,
    openDelete: false,
    deleteID: null,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    let field = this.props.field;
    let data = {};
    let fd = this.props.data;
    if (this.state.isEdit) {
      fd = fd.map((item) => {
        if (item._id !== this.state.editId) {
          return item;
        }
        return {
          ...item,
          name: this.state.textvalue,
          color: this.state.color,
          order: this.state.order,
        };
      });
    } else {
      fd.push({
        name: this.state.textvalue,
        color: this.state.color,
        order: this.state.order,
      });
    }
    data[field] = fd;

    this.props.submit(data);
    this.setState({
      textvalue: "",
      color: "#1273DE",
      order: 0,
      isEdit: false,
      role: "",
    });
  };
  handleDelete = () => {
    let newList = this.props.data.filter((item, i) => {
      return this.state.deleteItem !== item;
    });
    let field = this.props.field;
    const data = {};
    data[field] = newList;

    this.props.delete(data);
    this.closeDelete();
  };
  handleEdit = (item) => {
    this.setState({
      isEdit: true,
      editId: item._id,
      textvalue: item.name,
      color: item.color,
      order: item.order,
    });
  };
  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };
  closeDelete = () => {
    this.setState({
      openDelete: false,
      deleteID: null,
    });
  };
  openDelete = (item) => {
    this.setState({
      deleteItem: item,
      openDelete: true,
    });
  };

  render() {
    let addDis = true;
    if (this.state.textvalue !== "") {
      addDis = false;
    }

    const form = (
      <Segment basic>
        <Form onSubmit={this.handleSubmit}>
          {!this.props.disabled && (
            <Form.Group>
              <Form.Field width={6}>
                <label>Colour</label>
                <GithubPicker
                  triangle="hide"
                  color={this.state.color}
                  colors={[
                    "purple",
                    "#fc5830",
                    "#d1b00e",
                    "green",
                    "#638ae6",
                    "red",
                    "olive",
                  ]}
                  onChangeComplete={this.handleChangeComplete}
                />
              </Form.Field>

              <Form.Field width={2}>
                <label>Order</label>
                <Input
                  name={"order"}
                  onChange={this.handleChange}
                  value={this.state.order}
                  required={true}
                />
              </Form.Field>
              <Form.Field width={8}>
                <label>Name</label>
                <Input
                  name={"textvalue"}
                  action={
                    this.state.isEdit
                      ? { icon: "pencil", color: "blue" }
                      : { icon: "plus", color: "green", disabled: addDis }
                  }
                  onChange={this.handleChange}
                  value={this.state.textvalue}
                  placeholder={this.props.placeholder}
                  required={true}
                  style={{ background: this.state.color }}
                />
              </Form.Field>
            </Form.Group>
          )}
          <Divider />

          {this.state.isEdit && (
            <Button
              negative
              basic
              onClick={() =>
                this.setState({
                  isEdit: false,
                  textvalue: "",
                  modules: {},
                })
              }
            >
              Cancel
            </Button>
          )}
        </Form>
      </Segment>
    );
    let list;
    if (this.props.data) {
      list = this.props.data.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0)).map((item, i) => {
          return (
            <Table.Row key={i}>
              <Table.Cell>
                <Header as="h2">{item.name}</Header>
              </Table.Cell>
              <Table.Cell style={{ background: item.color }}>{""}</Table.Cell>
              <Table.Cell textAlign="center">{item.order}</Table.Cell>
              <Table.Cell>
                <Button
                  disabled={this.props.disabled}
                  circular
                  icon="close"
                  floated="right"
                  size="tiny"
                  color="red"
                  onClick={() => this.openDelete(item)}
                />
                <Button
                  disabled={this.props.disabled}
                  circular
                  icon="pencil"
                  floated="right"
                  size="tiny"
                  color="blue"
                  onClick={() => this.handleEdit(item)}
                />
              </Table.Cell>
            </Table.Row>
          );
        });
    }
    return (
      <Box title={this.props.title}>
        <Confirm
          header={`Are you sure you want to delete this ${this.props.title} entry?`}
          content={`${this.state.deleteItem !== undefined ? this.state.deleteItem.name : ""}`}
          open={this.state.openDelete}
          onCancel={this.closeDelete}
          onConfirm={this.handleDelete}
        />
        {form}
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Colour</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Order</Table.HeaderCell>
              <Table.HeaderCell>{""}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{list}</Table.Body>
        </Table>
      </Box>
    );
  }
}