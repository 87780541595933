import React from "react";
import {
  Header,
  Grid,
  Segment,
  Divider,
  List,
  Button,
  Checkbox,
  Tab,
  Message,
} from "semantic-ui-react";
import API from "../../actions/api";
import SendMessage_SMSForm from "./SendMessage_SMSForm";

class SendMessage_RecipientSelector extends React.Component {
  state = {
    filterTabIndex: 0,
    filterSelected: [],
    showOnCall: true,
    showOperational: true,

    selectedRecipients: [],
    buttonsDisabled: false,
  };
  handleFilterItemToggle(item) {
    let items = [].concat(this.state.filterSelected);
    if (items.indexOf(item.title) !== -1) {
      this.setState({
        filterSelected: items.filter((a) => a !== item.title),
      });
    } else {
      items.push(item.title);
      this.setState({
        filterSelected: items,
      });
    }
  }
  handleAddRecipient(smsRecipient) {
    if (!this.doesRecipientExist(smsRecipient)) {
      let selectedRecipients = this.state.selectedRecipients;
      selectedRecipients.push(smsRecipient);
      this.setState({
        selectedRecipients,
      });
    }
  }
  handleRemoveRecipient(smsRecipient) {
    let list = [];
    if (!Array.isArray(smsRecipient)) {
      list.push(smsRecipient);
    } else {
      list = smsRecipient;
    }
    list = list.filter((item) => this.doesRecipientExist(item));
    if (list.length !== 0) {
      let selectedRecipients = this.state.selectedRecipients;
      this.setState({
        selectedRecipients: selectedRecipients.filter((item) => {
          let exist = list.filter((a) => a.key === item.key);
          if (exist[0] !== undefined) {
            return false;
          }
          return true;
        }),
      });
    }
  }
  doesRecipientExist(smsRecipient) {
    let a = this.state.selectedRecipients.filter(
      (b) => b.key === smsRecipient.key
    );
    return a.length;
  }
  formatToSMSRecipient(type, item) {
    return {
      key: type + ":" + item._id,
      type,
      _id: item._id,
      displayName: item.displayName,
    };
  }

  renderFilters() {
    if (
      this.props.recipientCollection[this.state.filterTabIndex] === undefined
    ) {
      return <Message warning>Invalid filter selection</Message>;
    }
    let source = this.props.recipientCollection[this.state.filterTabIndex];
    if (source.filters.length === 0) {
      return (
        <Message>
          There are no filters to select, displaying all recipients.
        </Message>
      );
    }
    return (
      <Segment className={"sms_filter"}>
        {source.filters.map((item, index) => {
          return (
            <Checkbox
              disabled={this.state.buttonsDisabled}
              key={index}
              label={item.title}
              checked={this.state.filterSelected.indexOf(item.title) !== -1}
              onClick={() => this.handleFilterItemToggle(item)}
            />
          );
        })}
      </Segment>
    );
  }
  renderList() {
    if (
      this.props.recipientCollection[this.state.filterTabIndex] === undefined
    ) {
      return <Message warning>Invalid filter selection</Message>;
    }
    let source = this.props.recipientCollection[this.state.filterTabIndex];
    let finalRecipients = source.recipients.filter((item) => {
      if (this.state.filterSelected.length === 0) {
        return true; // Default show all if there are no filters
      }
      for (let filterIndex in this.state.filterSelected) {
        let filterText = this.state.filterSelected[filterIndex];
        let filter = source.filters.filter((a) => a.title === filterText);
        if (filter[0] !== undefined) {
          if (source.filter(filter[0], item)) {
            return true;
          }
        }
      }
      return false;
    });
    if(source.type === "staff"){
        finalRecipients = finalRecipients.filter(item => item.operational === this.state.showOperational && item.onCall === this.state.showOnCall)
    }
    return (
      <Segment>
        <Header>
          {source.title}{" "}
          <Button
            disabled={this.state.buttonsDisabled}
            positive
            floated={"right"}
            size={"tiny"}
            onClick={() => {
              for (let i in finalRecipients) {
                this.handleAddRecipient(
                  this.formatToSMSRecipient(source.type, finalRecipients[i])
                );
              }
            }}
          >
            Add All
          </Button>
        </Header>
        <Divider />
        {source.type === "staff" && <Grid columns={2}>
            <Grid.Column><Checkbox toggle label={"Show On-Call"} checked={this.state.showOnCall} onChange={() => this.setState({ showOnCall: !this.state.showOnCall })} /></Grid.Column>
            <Grid.Column><Checkbox toggle label={"Show Operational"} checked={this.state.showOperational} onChange={() => this.setState({ showOperational: !this.state.showOperational })} /></Grid.Column>
        </Grid>}
        <Divider />
        <List divided verticalAlign={"middle"} className={"sms_recipient_list"}>
          {finalRecipients.map((item, index) => {
            let smsRecipient = this.formatToSMSRecipient(source.type, item);
            return (
              <List.Item key={index}>
                <List.Content floated="right">
                  {this.doesRecipientExist(smsRecipient) ? (
                    <Button
                      disabled={this.state.buttonsDisabled}
                      negative
                      size={"small"}
                      onClick={() => this.handleRemoveRecipient(smsRecipient)}
                    >Remove</Button>
                  ) : (
                    <Button
                      disabled={this.state.buttonsDisabled}
                      positive
                      size={"small"}
                      onClick={() => this.handleAddRecipient(smsRecipient)}
                    >Add</Button>
                  )}
                </List.Content>
                <List.Content style={{ paddingTop: 10 + "px" }}>
                  {item.displayName}
                </List.Content>
              </List.Item>
            );
          })}
        </List>
      </Segment>
    );
  }
  renderTabMenu() {
    return (
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={this.props.recipientCollection.map((item) => {
          return {
            menuItem: item.title,
            render: () => this.renderFilters(),
          };
        })}
        onTabChange={(e, data) => {
          this.setState({
            filterTabIndex: data.activeIndex,
            filterSelected: [], // Reset the existing filters when changing collection/source
          });
        }}
      />
    );
  }
  render() {
    // TODO make this screen more mobile friendly
    if (
      this.props.recipientCollection === undefined ||
      this.props.recipientCollection.length === 0
    ) {
      throw new Error(
        "Initialization Failure: Form can not be loaded with no recipient collections configured and passed into props"
      );
    }
    return (
      <Grid stackable>
        <Grid.Column width={16}>{this.renderTabMenu()}</Grid.Column>
        <Grid.Column width={10}>
          <Segment>
            <SendMessage_SMSForm
              onOuterDisable={(isDisable) =>
                this.setState({ buttonsDisabled: isDisable })
              }
              recipients={this.state.selectedRecipients}
              handleRemoveRecipient={(a) => this.handleRemoveRecipient(a)}
              quickTemplates={[]}
            />
          </Segment>
        </Grid.Column>
        <Grid.Column width={6}>{this.renderList()}</Grid.Column>
      </Grid>
    );
  }
}

export default class SendMessage extends React.Component {
  state = {
    firstLoad: true,
    loading: true,
    recipientCollection: [],
  };
  componentDidMount() {
    this.getData();
  }
  getData() {
    Promise.all([API.staff.all(), API.staff.roles()]).then((res) => {
      this.setState({
        firstLoad: false,
        loading: false,
        recipientCollection: [
          // Statically code this single collection for now
          {
            title: "Staff",
            type: "staff",
            filters: res[1].map((item) => {
              return {
                title: item,
              };
            }),
            recipients: res[0].filter(a => !a.isRemoved).map((item) => {
              return {
                ...item,
                displayName: item.firstName + " " + item.lastName, // TODO put the staff members callsign in
              };
            }),
            filter: (filter, recipient) => {
              return recipient.roles.indexOf(filter.title) !== -1;
            },
          },
        ],
      });
    });
  }
  render() {
    return (
      <Segment loading={this.state.loading}>
        <Header>Send SMS</Header>
        {!this.state.firstLoad && (
          <SendMessage_RecipientSelector
            recipientCollection={this.state.recipientCollection}
          />
        )}
      </Segment>
    );
  }
}
