import React from "react";
import { Grid, Header, Segment } from "semantic-ui-react";
import moment from "moment";
import BasicMap from "./basicMap";
import axios from "axios";
export default class FloodAlert extends React.Component {
  state = {
    polygon: {},
  };
  componentDidMount() {
    this.getPolygon();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.getPolygon();
    }
  }

  getPolygon = () => {
    if (this.props.data.floodArea) {
      let url = this.props.data.floodArea.polygon;
      var b = "s";
      var output = [url.slice(0, 4), b, url.slice(4)].join("");

      axios.get(output).then((polygon) => {
        this.setState({
          polygon: polygon.data,
        });
      });
    }
  };
  render() {
    const { data } = this.props;

    return (
      <Segment basic className="no-pad">
        <Grid celled stackable textAlign="center" className="no-marg dataGrid">
          <Grid.Row columns={2}>
            <Grid.Column className="data ">
              <Header>Area Name</Header>
              <p>{data.eaAreaName}</p>
            </Grid.Column>
            <Grid.Column className="data ">
              <Header>Severity</Header>
              <p>
                {data.severity} @{" "}
                {moment(data.timeRaised).format("DD-MM-YY HH:mm")}
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column className="data ">
              <Header>Description</Header>
              <p>{data.description}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column className="data ">
              <Header>Message</Header>
              <p>{data.message}</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {this.state.polygon.type && (
          <BasicMap
            area={this.state.polygon}
            center={[0.51358938, 51.280941]}
          />
        )}
      </Segment>
    );
  }
}
